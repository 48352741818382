import React, { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import { ModalsState } from "../../redux/modals/modals_types";
import ModalInput from "../ui/Inputs/ModalInput";
import SkeletonLoading from "../skeleton-loading/SkeletonLoading";
import ModalWindow from "./ModalWindow";
import toast from "react-hot-toast";
import { CarState } from "redux/car/car_types";
import { TyresState } from "redux/tyres/tyres_types";
import { ACTION_TYPES } from "constants/actions";
import SelectInput from "components/select-input/SelectInput";
import ModalTextarea from "components/ui/Textarea/ModalTextarea";

const ModalAddAction = () => {
  const [type, setType] = useState<string>("");
  const [runLast, setRunLast] = useState<string>("");
  const [run, setRun] = useState<string>("");
  const [tread_start, setTreadStart] = useState<string>("");
  const [tread, setTread] = useState<string>("");
  const [to, setTo] = useState<string>("");
  const [reason, setReason] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [readonly, setReadonly] = useState<boolean>(false);
  const [model, setModel] = useState<string>("");
  const [brand, setBrand] = useState<string>("");
  const [size, setSize] = useState<string>("");
  const [errors, setErrors] = useState<any>({
    type: [],
    run: [],
    tread: [],
    comment: [],
    reason: [],
    to: [],
    runLast: [],
  });

  const [actionTypes, setActionTypes] = useState<Array<any>>([]);

  useEffect(() => {
    setActionTypes([
      { value: "moving", label: ACTION_TYPES.moving },
      { value: "repair", label: ACTION_TYPES.repair },
      { value: "store", label: ACTION_TYPES.store },
      { value: "utilization", label: ACTION_TYPES.utilization },
    ]);
  }, []);

  const onChangeType = (value: string) => {
    setType(value);
  };

  const { toggleModal, addAction } = useActions();
  const [submiting, setSubmiting] = useState<boolean>(false);
  const modals: ModalsState = useTypedSelector((state) => state.modals);
  const { car }: CarState = useTypedSelector((state) => state.car);
  const { tyre }: TyresState = useTypedSelector((state) => state.tyres);

  const handleClose = () => {
    toggleModal({ toggle: false, modal: "modal_add_action" });
    setType("");
    setRun("");
    setTread("");
    setTo("");
    setReason("");
    setComment("");
    setErrors({
      type: [],
      run: [],
      tread: [],
      comment: [],
      reason: [],
      to: [],
      runLast: [],
    });
  };

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (car && tyre) {
      let data = {};
      if (type == "utilization" || type == "repair") {
        data = {
          tyre_id: tyre.id,
          tread: +tread,
          type,
          run: +run,
          reason,
          comment,
        };
      }
      if (type == "store") {
        data = {
          tyre_id: tyre.id,
          tread: +tread,
          type,
          run: +run,
          reason,
          vehicle_id: car.id,
        };
      }
      if (type == "moving") {
        data = {
          tyre_id: tyre.id,
          tread: +tread,
          type,
          run: +run,
          from: car.id,
          to,
        };
      }

      try {
        if (errors.run.length == 0) {
          setSubmiting(true);
          await addAction(data, type);
          toast.success("Вы успешно добавили событие");
          handleClose();
        } else return;
      } catch (error: any) {
        console.log(error);
        if (error?.response?.data?.errors) {
          setErrors({
            ...errors,
            type: error?.response?.data?.errors?.type || [],
            run: error?.response?.data?.errors?.run || [],
            tread: error?.response?.data?.errors?.tread || [],
            comment: error?.response?.data?.errors["comment"] || [],
            reason: error?.response?.data?.errors["reason"] || [],
            to: error?.response?.data?.errors["to"] || [],
          });
        } else if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
      setSubmiting(false);
    }
  };

  const handleChangeRun = (e: React.FormEvent<HTMLInputElement>) => {
    setErrors({
      ...errors,
      run: [],
    });
    setRun((e.target as HTMLInputElement).value);
    if (
      tyre &&
      tyre.last_action &&
      tyre?.last_action?.run > +(e.target as HTMLInputElement).value
    ) {
      setErrors({
        ...errors,
        run: "Пробег не может быть меньше предыдущего",
      });
      return;
    }
  };

  const handleChangeTread = (e: React.FormEvent<HTMLInputElement>) => {
    setErrors({
      ...errors,
      tread: [],
    });
    setTread((e.target as HTMLInputElement).value);
    if (
      tyre &&
      tyre.last_action &&
      tyre?.last_action?.tread < +(e.target as HTMLInputElement).value
    ) {
      setErrors({
        ...errors,
        tread: "Глубина не может быть больше предыдущего",
      });
      return;
    }
  };

  useEffect(() => {
    setReadonly(true);
  }, [tyre]);

  if (submiting) {
    return (
      <ModalWindow
        isShow={modals.modal_add_action}
        title="Добавление шины"
        handleClose={handleClose}
        dialogClassName="modal__box-big"
      >
        <SkeletonLoading height="382px" />
      </ModalWindow>
    );
  }

  return (
    <ModalWindow
      isShow={modals.modal_add_action}
      title="Добавление события"
      handleClose={handleClose}
      dialogClassName="modal__box-big"
    >
      <form id="addTransport" onSubmit={handleSave} className="modal-content__box">
        <div>
          <p className="model-title__bold">Информация о шине</p>
        </div>
        <div>
          <p className="model-title__bold">Модель и размеры шин</p>
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="Бренд"
              readOnly={readonly}
              // length={7}
              value={tyre ? tyre.brand : brand}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setBrand((e.target as HTMLInputElement).value)
              }
              errors={errors.brand}
            />
            <ModalInput
              titleClass="modal-password_title"
              title="Модель"
              readOnly={readonly}
              // length={3}
              value={tyre ? tyre.model : model}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setModel((e.target as HTMLInputElement).value)
              }
              errors={errors.model}
            />
          </div>
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              classes={["modal-title__third"]}
              title="Размер"
              readOnly={readonly}
              // length={3}
              value={tyre ? tyre.size : size}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setSize((e.target as HTMLInputElement).value)
              }
              errors={errors.size}
            />
            <ModalInput
              titleClass="modal-password_title"
              classes={["modal-title__third"]}
              title="Пробег"
              readOnly={readonly}
              type="number"
              // length={3}
              value={tyre && tyre.last_action ? tyre.last_action.run : runLast}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setRunLast((e.target as HTMLInputElement).value)
              }
              errors={errors.runLast}
            />
            <ModalInput
              titleClass="modal-password_title"
              classes={["modal-title__third"]}
              title="Глубина протектора"
              readOnly={readonly}
              type="number"
              // length={3}
              value={tyre && tyre.last_action ? tyre.last_action.tread : tread_start}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setTreadStart((e.target as HTMLInputElement).value)
              }
              errors={errors.runLast}
            />
          </div>
          <h4 className="point__title">Вид</h4>
          <SelectInput
            value={type}
            options={actionTypes}
            onChange={onChangeType}
            className="react-select--mini react-select--margin "
            placeholder="Выберите событие"
          />
        </div>
        {type !== "" && (
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="Пробег"
              // length={12}
              value={run}
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleChangeRun(e)}
              errors={errors.run}
            />
            <ModalInput
              titleClass="modal-password_title"
              title="Глубина протектора"
              // length={12}
              value={tread}
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleChangeTread(e)}
              errors={errors.tread}
            />
          </div>
        )}
        {type == "moving" && (
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="ТС, на которое переместить"
              classes={["modal-title__half"]}
              value={to}
              type="number"
              // length={16}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setTo((e.target as HTMLInputElement).value)
              }
              errors={errors.to}
            />
          </div>
        )}
        {type == "store" && (
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="Причина"
              // length={32}
              value={reason}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setReason((e.target as HTMLInputElement).value)
              }
              errors={errors.reason}
            />
          </div>
        )}
        {(type == "utilization" || type == "repair") && (
          <>
            <ModalInput
              titleClass="modal-password_title"
              title="Причина"
              // length={32}
              value={reason}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setReason((e.target as HTMLInputElement).value)
              }
              errors={errors.reason}
            />
            <ModalTextarea
              titleClass="modal-password_title"
              title="Комментарий"
              // length={64}
              rowsLength={2}
              value={comment}
              onChange={(e: React.FormEvent<HTMLTextAreaElement>) => {
                setComment((e.target as HTMLTextAreaElement).value);
              }}
              errors={errors.comment}
            />
          </>
        )}
      </form>

      <div className="modal-edit__button-box">
        <button form="addTransport" type="submit" className="modal__button">
          Сохранить
        </button>
      </div>
    </ModalWindow>
  );
};

export default ModalAddAction;
