import styled, { keyframes } from "styled-components";

export const SpinnerArea = styled.div`
  width: 100%;
  height: 2rem;
  position: relative;
`;

const spinnerKeyFrame = keyframes`
  to{
    transform: rotate(360deg)
  }
`;

export const Spinner = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -2.4px;
  margin-top: -2.4px;
  width: 4.8px;
  height: 4.8px;
  border-radius: 4.8px;
  box-shadow: 12px 0px 0 0 rgba(28, 182, 193, 0.2), 9.7px 7.1px 0 0 rgba(28, 182, 193, 0.4),
    3.7199999999999998px 11.4px 0 0 rgba(28, 182, 193, 0.6),
    -3.7199999999999998px 11.4px 0 0 rgba(28, 182, 193, 0.8), -9.7px 7.1px 0 0 #1cb6c1;
  animation: ${spinnerKeyFrame} 0.8s infinite linear;
`;
