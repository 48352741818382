import { useTypedSelector } from "../../hooks/useTypedSelector";
import SkeletonLoading from "../skeleton-loading/SkeletonLoading";
import Point, { PointMoveType, PointStopType } from "./Point";
import { IPointsState } from "../../redux/points/points_types";
import { EventState } from "../../redux/events/events_types";
import { pointsType } from "./pointsHelper";
import { PointCheckContext } from "components/reports/context/PointCheckContext";
import { useContext, useEffect, useState } from "react";

const Points = () => {
  const { stops, points, loading }: IPointsState = useTypedSelector((state) => state.points);
  const { events_period }: EventState = useTypedSelector((state) => state.events);
  const { isMoves, isStops } = useContext(PointCheckContext);
  const [data, setData] = useState<Array<PointMoveType | PointStopType> | null>(null);

  let dataWihtAdd: Array<PointMoveType | PointStopType> = [];
  useEffect(() => {
    if (loading) return;
    dataWihtAdd = pointsType(points, stops, events_period);
    setData(dataWihtAdd);
  }, [loading]);

  if (loading) {
    return (
      <div className="points_list">
        <SkeletonLoading height="40px" />
      </div>
    );
  }

  if (data && data.length == 0) {
    return (
      <div className="points_list">
        <div className="point-start">
          <div className="points_item points_item--none">
            За выбранный период отсутствует сигнал
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="points_list">
      <div className="point-start">
        <div className="stop stop--start">
          <div className="stop_pic">
            <div className="stop_line stop_line--start"></div>
            <div className="stop_circle stop_circle--start stop_circle--first"></div>
          </div>
          <h4 className="point__title--start">Начало маршрута</h4>
        </div>
      </div>

      {data &&
        data.length > 0 &&
        isMoves &&
        isStops &&
        data.map((stop, index) => <Point key={index} {...stop} />)}

      {data &&
        !isMoves &&
        isStops &&
        stops.length > 0 &&
        data.map((stop, index) => stop.type === "stop" && <Point key={index} {...stop} />)}

      {data &&
        isMoves &&
        !isStops &&
        data.length > stops.length &&
        data.map((stop, index) => stop.type === "move" && <Point key={index} {...stop} />)}

      {data &&
        ((isMoves && !isStops && data.length == stops.length) ||
          (!isMoves && isStops && stops.length == 0) ||
          (!isStops && !isMoves)) && (
          <div className="points_item points_item--none">Список пуст</div>
        )}

      <div className="point-start">
        <div className="stop stop--end">
          <div className="stop_pic">
            <div className="stop_line stop_line--end"></div>
            <div className="stop_circle stop_circle--end"></div>
          </div>
          <h4 className="point__title--end">Завершение маршрута</h4>
        </div>
      </div>
    </div>
  );
};

export default Points;
