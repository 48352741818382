import { Outlet } from "react-router-dom";
import SideBarTyres from "../components/sideBar/SideBarTyres";
import DashboardWrapper from "./DashboardWrapper";
import SideBar from "components/sideBar/SideBar";
import TyresHeader from "components/tyres/TyresHeader";
import MobileSwitch from "components/mobile-toggler/MobileSwitch";
import CustomScroll from "react-custom-scroll";

const TyresLayout = () => {
  return (
    <DashboardWrapper>
      <SideBar>
        <SideBarTyres />
      </SideBar>

      <div className="dashboard__view">
        <MobileSwitch />
        <TyresHeader />
        <div className="tyre_container">
          <div className="report">
            <CustomScroll
              allowOuterScroll={false}
              heightRelativeToParent="100%"
              className="rcs-custom-scroll--center"
            >
              <div className="report_box">
                <Outlet />
              </div>
            </CustomScroll>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default TyresLayout;
