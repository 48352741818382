import { VehicleAddress, VehicleState } from "components/cars/Cars.styled";
import CarIcon from "components/cars/car/CarIcon";
import ViewStatusTimestamp from "components/vehicle-view/ViewStatusTimestamp";
import { ICar } from "redux/car/car_types";

const UserVehicleStatistic = ({ car }: { car: ICar }) => {
  return (
    <div>
      {car.last_point === null && (
        <VehicleState>
          <CarIcon lastPoint={car.last_point} />
          <VehicleAddress>Сигнала от данного устройства пока не было</VehicleAddress>
        </VehicleState>
      )}
      {car.last_point && (
        <div className="userVehicle__location">
          <strong style={{ marginBottom: "1rem", fontSize: "13px" }}>[{car.last_point.id}]</strong>
          <ViewStatusTimestamp
            dateFrom={car.last_point.device_created_at}
            dateTo={car.last_point.device_updated_at}
          />
          <a
            href={`/view/${car.id}`}
            style={{ marginBottom: "1rem", fontSize: "13px" }}
            target="_blank"
          >
            Посмотреть
          </a>
        </div>
      )}
    </div>
  );
};

export default UserVehicleStatistic;
