import { Dispatch } from "react";
import api from "../../api";
import { SettingsActions, SettingsActionTypes } from "../settings/settings_types";
import { PointsActions, PointsActionTypes } from "./points_types";
import { IPoint, MapActions, MapActionTypes } from "../map/map_types";

export const getPointStops = (device_id: number, from: string, to: string) => {
  return async (dispatch: Dispatch<PointsActions>) => {
    dispatch({ type: PointsActionTypes.POINTS_LOADING, payload: true });
    const response: any = await api.getPointStops(device_id, from, to);
    dispatch({
      type: PointsActionTypes.GET_POINT_STOPS,
      payload: response.points,
    });
  };
};

export const getPointsPeriod = (
  device_id: number,
  from: string,
  to: string,
  dispatchRoute = false
) => {
  return async (dispatch: Dispatch<PointsActions | SettingsActions | MapActions>) => {
    dispatch({ type: PointsActionTypes.POINTS_LOADING, payload: true });

    let page = 1;
    let response: any;
    let answer: IPoint[] = [];
    do {
      response = await api.getPointsPeriod(device_id, from, to, page);
      answer = [...answer, ...response.points];
      page = response.meta.current_page + 1;
    } while (page <= response.meta.last_page);

    dispatch({
      type: PointsActionTypes.GET_POINTS,
      payload: answer,
    });

    if (dispatchRoute) {
      dispatch({ type: MapActionTypes.CHANGE_ROUTE, payload: answer });
    }
  };
};

export const getPoint = (id: number) => {
  return async (dispatch: Dispatch<PointsActions>) => {
    dispatch({ type: PointsActionTypes.POINTS_LOADING, payload: true });
    const response: any = await api.getPoint(id);
    dispatch({
      type: PointsActionTypes.GET_POINT,
      payload: response.data.point,
    });
  };
};
