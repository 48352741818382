import { Map } from "yandex-maps";
import { MapState } from "../../redux/map/map_types";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useEffect, useState } from "react";
import {
  getStagesAndPoints,
  makeArrows,
  makeClusterWithPoints,
  makeLine,
  makePoint,
} from "./mapHelper";
import { CarState } from "redux/car/car_types";
import { useActions } from "../../hooks/useAction";

const HandleRoute = ({ myMap }: { myMap: Map }) => {
  const map: MapState = useTypedSelector((state) => state.map);
  const { settingsLast } = useTypedSelector((state) => state.settings);
  const { car }: CarState = useTypedSelector((state) => state.car);

  const [myPolylines, setMyPolylines] = useState<any[]>([]);
  const [myArrows, setMyArrows] = useState<any[]>([]);
  const [myClusters, setMyClusters] = useState<any[]>([]);
  const [myStart, setMyStart] = useState<any>(null);
  const [myFinish, setMyFinish] = useState<any>(null);

  const { toggleLoading } = useActions();

  useEffect(() => {
    // рисуем маршрут
    if (map.route.length > 0 && myMap && car) {
      clearRoute();

      const { stages, points, arrowPoints } = getStagesAndPoints(map.route, settingsLast);
      const { coordCount, polylines } = makeLine(stages);

      const arrows = makeArrows(arrowPoints, myMap);

      // линия пути
      if (polylines.length > 0) {
        polylines.forEach((polyline) => {
          myMap.geoObjects.add(polyline);
        });
        setMyPolylines(polylines);
      }

      // стрелки
      if (arrows.length > 0) {
        arrows.forEach((arrow) => {
          myMap.geoObjects.add(arrow);
        });
        setMyArrows(arrows);
      }

      if (coordCount === 0) return;

      const { clusters, start, finish } = makeClusterWithPoints(points, car);

      // точки в кластере
      if (clusters.length > 0) {
        clusters.forEach((cluster) => {
          // @ts-ignore
          myMap.geoObjects.add(cluster);
        });
        setMyClusters(clusters);
      }

      if (start !== null) {
        myMap.geoObjects.add(start);
        setMyStart(start);
      }

      if (finish !== null) {
        myMap.geoObjects.add(finish);
        setMyFinish(finish);
      }

      if (points.length > 0) {
        myMap.panTo([points[points.length - 1].lat, points[points.length - 1].long], {
          flying: true,
          duration: 1000,
        });

        toggleLoading(false);
      }
    }

    if (myMap && map.route.length === 0) {
      clearRoute();
      toggleLoading(false);
    }
  }, [map.route]);

  const clearRoute = () => {
    if (myPolylines.length > 0) {
      clearGeoObjects(myPolylines);
      setMyPolylines([]);
    }

    if (myClusters.length > 0) {
      clearGeoObjects(myClusters);
      setMyClusters([]);
    }

    if (myArrows.length > 0) {
      clearGeoObjects(myArrows);
      setMyArrows([]);
    }

    if (myStart !== null) {
      myMap.geoObjects.remove(myStart);
      setMyStart(null);
    }

    if (myFinish !== null) {
      myMap.geoObjects.remove(myFinish);
      setMyFinish(null);
    }
  };

  const clearGeoObjects = (objects: any[]) => {
    if (myMap)
      objects.forEach((object) => {
        myMap.geoObjects.remove(object);
      });
  };

  const showAll = () => {
    // @ts-ignore
    myMap.setBounds(myMap.geoObjects.getBounds()).then(function () {
      // @ts-ignore
      const zoom = myMap.action.getCurrentState().zoom - 1;
      myMap.setZoom(zoom > 19 ? 19 : zoom, { duration: 1 });
    });
  };

  const showStart = () => {
    myMap
      .panTo([...myStart.geometry.getCoordinates()], {
        flying: true,
        checkZoomRange: true,
        duration: 1000,
      })
      .then(function () {
        // @ts-ignore
        const zoom = myMap.action.getCurrentState().zoom;
        if (zoom < 16) myMap.setZoom(16, { duration: 500 });
      });
  };

  const showFinish = () => {
    myMap
      .panTo([...myFinish.geometry.getCoordinates()], {
        flying: true,
        duration: 1000,
      })
      .then(function () {
        // @ts-ignore
        const zoom = myMap.action.getCurrentState().zoom;
        if (zoom < 16) myMap.setZoom(16, { duration: 500 });
      });
  };

  if (!myFinish && !myStart && map.route.length === 0) return <></>;

  return (
    <div className="map__controls">
      {myStart !== null && (
        <button type="button" className="button button--map" onClick={() => showStart()}>
          Начало движения
        </button>
      )}

      {myFinish !== null && (
        <button type="button" className="button button--map" onClick={() => showFinish()}>
          Конец движения
        </button>
      )}

      {myClusters.length > 0 && (
        <button type="button" className="button button--map" onClick={() => showAll()}>
          Показать весь маршрут
        </button>
      )}
    </div>
  );
};

export default HandleRoute;
