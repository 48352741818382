import SideBar from "../components/sideBar/SideBar";
import MapLayout from "../components/map/Map";
import { Outlet, useNavigate } from "react-router-dom";
import Report from "../components/reports/Report";
import { useTypedSelector } from "../hooks/useTypedSelector";
import Charts from "../components/charts/Charts";
import SkeletonLoading from "../components/skeleton-loading/SkeletonLoading";
import DashboardWrapper from "layouts/DashboardWrapper";
import MobileSwitch from "components/mobile-toggler/MobileSwitch";
import { useEffect } from "react";
import { MEDIA } from "constants/media";
import useWindowSize from "hooks/useWindowSize";
import { useActions } from "hooks/useAction";
import { CarState } from "redux/car/car_types";

const DashboardPage = () => {
  const { isReport, isShow } = useTypedSelector((state) => state.reports);
  const { isCharts } = useTypedSelector((state) => state.settings);
  const { data } = useTypedSelector((state) => state.userMe);
  const { car }: CarState = useTypedSelector((state) => state.car);
  const size = useWindowSize();
  const { toggleIsCharts } = useActions();
  const navigate = useNavigate();

  useEffect(() => {
    if (size.width < MEDIA.desctop) {
      toggleIsCharts(false);
    }
    if (size.width < MEDIA.mobile) {
      if (car) {
        navigate(`/view/${car.id}`);
      }
    }
  }, [size.width]);

  return (
    <DashboardWrapper>
      <SideBar>
        <p>{data.email}</p>
        <Outlet />
      </SideBar>

      <div className="dashboard__view">
        <MobileSwitch />
        {isShow && isReport ? (
          <Report />
        ) : isShow && isCharts ? (
          <Charts />
        ) : isShow ? (
          <div className="aside">
            <div className="report charts">
              <SkeletonLoading height="100vh" width="100vw" theme={true} />
            </div>
          </div>
        ) : (
          <MapLayout />
        )}
      </div>
    </DashboardWrapper>
  );
};

export default DashboardPage;
