import { Dispatch } from "redux";
import { IUser } from "redux/user/user_types";
import { UsersFilterActionTypes, UsersFilterActions } from "./users_filter_types";

export const getUsersFilter = (users: IUser[]) => {
  return async (dispatch: Dispatch<UsersFilterActions>) => {
    dispatch({
      type: UsersFilterActionTypes.GET_USER_FILTER,
      payload: users,
    });
  };
};

export const changeFilterUsersSearch = (payload: string) => {
  return (dispatch: Dispatch<UsersFilterActions>) => {
    dispatch({ type: UsersFilterActionTypes.USER_FILTER_SEARCH, payload });
  };
};

export const changeTypeUsersSearch = (payload: string) => {
  return (dispatch: Dispatch<UsersFilterActions>) => {
    dispatch({ type: UsersFilterActionTypes.USER_TYPE_SEARCH, payload });
  };
};
