import { ICar } from "../../redux/car/car_types";
import { IPoint } from "../../redux/map/map_types";
import VehicleHeader from "../vehicle-view/VehicleHeader";
import PointStatus from "../vehicle-view/PointStatus";
import { memo } from "react";

const Balloon = ({ point, car }: { point: IPoint; car: ICar }) => {
  return (
    <div>
      <VehicleHeader car={car} woComment={true} />
      <PointStatus minified={true} car={car} point={point} />
    </div>
  );
};

export default memo(Balloon);
