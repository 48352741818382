import React, { useEffect, useState } from "react";
import { useActions } from "../../hooks/useAction";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import SelectInput from "components/select-input/SelectInput";
import DateTemplates from "components/date-templates/DateTemplate";
import CustomDatePicker from "components/datepicker/CustomDatePicker";
import moment from "moment";
import InputBlock from "./components/InputsBlock";
import { ActionsState } from "redux/actions/actions_types";
import { ACTION_TYPES } from "constants/actions";
import SkeletonLoading from "components/skeleton-loading/SkeletonLoading";
import VehicleHeader from "components/vehicle-view/VehicleHeader";
import GoBackBtn from "components/ui/Buttons/GoBack";
import { CarState } from "redux/car/car_types";
import { useParams } from "react-router-dom";

const SideBarTyre = () => {
  const { vehicle_id } = useParams<{ vehicle_id?: string }>();
  const { tyre_id } = useParams<{ tyre_id?: string }>();
  const { getCar } = useActions();

  const init = async () => {
    if (!vehicle_id) return;

    getCar(+vehicle_id);
    setFrom(moment(new Date()).format("YYYY-MM-DD"));
    setTo(moment(new Date()).format("YYYY-MM-DD"));
  };

  useEffect(() => {
    init();
  }, []);

  const [from, setFrom] = useState(moment().format("YYYY-MM-DD"));
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));

  const [type, setType] = useState("all");

  const [actionTypes, setActionTypes] = useState<Array<any>>([]);

  useEffect(() => {
    setActionTypes([
      { value: "all", label: "Все типы событий" },
      { value: "adding", label: ACTION_TYPES.adding },
      { value: "moving", label: ACTION_TYPES.moving },
      { value: "repair", label: ACTION_TYPES.repair },
      { value: "store", label: ACTION_TYPES.store },
      { value: "utilization", label: ACTION_TYPES.utilization },
    ]);
  }, []);

  const onChangeType = (value: string) => {
    setType(value);
  };

  const onDatesChange = (from: string, to: string) => {
    setFrom(from);
    setTo(to);
  };

  const { actions, loading }: ActionsState = useTypedSelector((state) => state.actions);
  const { postActionsTyreByPeriod } = useActions();

  const handlerReport = () => {
    if (tyre_id) {
      postActionsTyreByPeriod(from, to, +tyre_id);
    }
  };
  const { loading: loading_car, car }: CarState = useTypedSelector((state) => state.car);

  return (
    <div className="view-container sidebar-grey">
      <div className="view__header background-white">
        <div className="view-goback">
          <GoBackBtn text="Назад" to="/tyres" />
        </div>

        {(!car || loading_car) && <SkeletonLoading height="220px" />}

        {car !== null && !loading_car && <VehicleHeader car={car} />}
      </div>
      <div className="view__inner sidebar-white">
        <h4 className="point__title">Вид</h4>
        <SelectInput
          value={type}
          options={actionTypes}
          onChange={onChangeType}
          className="react-select--mini react-select--margin "
        />
        <h4 className="point__title">Выбор периода</h4>
        <div className="reports reports--charts reports--charts--border margin-bottom-20">
          <DateTemplates onDatesChange={onDatesChange} from={from} to={to} />

          <div className="input-and-button input-and-button--border-top">
            <div className="input-area w50-5 m0">
              <label className="label">Начало периода</label>
              <CustomDatePicker onChange={(date) => setFrom(date)} date={from} />
            </div>

            <div className="input-area w50-5 m0">
              <label className="label">Конец периода</label>
              <CustomDatePicker onChange={(date) => setTo(date)} date={to} />
            </div>
            <button
              className="button button--mint button--with-label w100 m0"
              onClick={handlerReport}
            >
              Показать
            </button>
          </div>
        </div>
        {/* {!loading && actions && actions.actions.length > 0 && <InputBlock />} */}
      </div>
    </div>
  );
};

export default SideBarTyre;
