import { CarState } from "redux/car/car_types";
import { parseEvent } from "../../helpers/events";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IEvent } from "../../redux/events/events_types";
import SkeletonLoading from "../skeleton-loading/SkeletonLoading";
import Violation from "../violation/Violation";
import PaginatedItems from "components/pagination/Pagination";

const compareDates = (date1: string, date2: string) =>
  date1.substring(0, 10) !== date2.substring(0, 10);

const Vioalations = ({ events, text, classPage, eventsLength }: IViolation) => {
  const { car }: CarState = useTypedSelector((state) => state.car);
  const { loading } = useTypedSelector((state) => state.events);
  const { totalPage } = useTypedSelector((state) => state.events);

  let parsed_events: IEvent[] = [];

  events.forEach((event) => {
    if (!event.point) return;

    const part_events = parseEvent(event.point, event.limit);
    parsed_events = parsed_events.concat(part_events);
  });

  if (loading || !car?.id) return <SkeletonLoading height="76px" theme={classPage} />;

  let box;
  if (events.length === 0) {
    box = <div className="violation__text">Отклонений от заданных параметров нет</div>;
  } else {
    box = events.map((event: IEvent, index) => {
      let showDate = false;

      if (
        index === 0 ||
        (index > 0 && compareDates(events[index - 1].created_at, event.created_at))
      )
        showDate = true;

      return <Violation key={event.id} event={event} showDate={showDate} />;
    });
  }

  return (
    <div className="violations">
      <div className="violation__head">
        <p className="violation__title">{text}</p>
        {events.length != 0 && <span className="violation__count">{eventsLength}</span>}
      </div>
      <div className="violation__box">{box}</div>
    </div>
  );
};

export default Vioalations;

export interface IViolation {
  events: IEvent[];
  text: string;
  classPage?: boolean;
  eventsLength: number;
}
