import { useActions } from "../../../hooks/useAction";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import ModalInput from "../../ui/Inputs/ModalInput";
import React from "react";
import { ReactComponent as Loupe } from "../../../assets/images/loupe.svg";

const ModalSearch = () => {
  const carsModal = useTypedSelector((state) => state.carsModal);
  const { changeFilterModalCar } = useActions();
  const handleGroupSearchCar = (value: string) => {
    changeFilterModalCar(value);
  };
  return (
    <ModalInput
      onChange={(e) => handleGroupSearchCar(e.target.value)}
      placeholder="Поиск"
      title={"Выбор транспортных средств"}
      Icon={Loupe}
      value={carsModal.filter}
    />
  );
};

export default ModalSearch;
