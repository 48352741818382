import { Link, useLocation } from "react-router-dom";
import { CarState } from "redux/car/car_types";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const SideBarCarNavigation = () => {
  const { car }: CarState = useTypedSelector((state) => state.car);

  const arrLink = ["sensors", "report", "events"];

  const location = useLocation();

  const activeLink = () => {
    let active = "";
    for (let i = 0; i < arrLink.length; i++) {
      const url = location.pathname.split("/");
      if (url.includes(arrLink[i])) {
        active = arrLink[i];
      }
    }
    return active;
  };

  return (
    <div className="car-navs">
      <Link
        className={"car-nav" + (activeLink() === "" ? " car-nav--active" : "")}
        to={`/view/${car?.id}`}
      >
        <span>Состояние</span>
      </Link>
      <Link
        to={`/view/${car?.id}/sensors`}
        className={"car-nav" + (activeLink() === "sensors" ? " car-nav--active" : "")}
      >
        <span>Датчики</span>
      </Link>
      <Link
        to={`/view/${car?.id}/report`}
        className={"car-nav car-desctop" + (activeLink() === "report" ? " car-nav--active" : "")}
      >
        <span>Отчет и маршруты</span>
      </Link>
      <Link
        to={`/view/${car?.id}/events`}
        className={"car-nav car-desctop" + (activeLink() === "events" ? " car-nav--active" : "")}
      >
        <span>Нарушения</span>
      </Link>
    </div>
  );
};

export default SideBarCarNavigation;
