// import { models } from "../../../helpers/constructorModels";
import { trailers } from "helpers/constructorTrailers";
import { IDesign } from "helpers/constructorTypes";
import { TabIds } from "types/other";
import { models } from "helpers/constructorModels";

const VehicleTyre = ({
  design,
  isFlat,
  tab = TabIds.Bar,
}: {
  design: IDesign;
  isFlat?: boolean;
  tab?: string;
}) => {
  const { modelId, trailerId, positions } = design;

  if (!modelId) return <div>Пустой конструктор</div>;

  const modelAxes = [...models[modelId].axes].reverse();

  const trailerAxes = trailerId !== null ? [...trailers[trailerId].axes].reverse() : [];

  //////////////////////////////////////////////////////////////////////////////////////////
  const getWheelByIndex = (index: number): { classes: string[] } => {
    const classes: string[] = ["wheel"];

    classes.push("wheel--withoutTyre");

    if (isFlat) {
      return {
        classes,
      };
    }

    return { classes };
  };
  //////////////////////////////////////////////////////////////////////////////////////////

  let wheelIndex = 0;

  const axesListModel = modelAxes.map((axis, axisIndex) => {
    const wheels = axis.wheels.map((offset) => {
      wheelIndex++;
      const wIndex = wheelIndex;
      const item = getWheelByIndex(wIndex);

      const classes = item.classes;
      if (axis.mini) classes.push("wheel--mini");

      return (
        <div
          className="wheel-container"
          style={{ transform: `translate(0, ${offset}px)` }}
          key={wIndex}
        >
          <div className={item.classes.join(" ")}></div>
        </div>
      );
    });

    return (
      <div className="axis" key={axisIndex} style={{ transform: `translate(${axis.offset}px, 0)` }}>
        {wheels}
      </div>
    );
  });

  const axesListTrailer = trailerAxes.map((axis, axisIndex) => {
    const wheels = axis.wheels.map((offset) => {
      wheelIndex++;
      const wIndex = wheelIndex;
      const item = getWheelByIndex(wIndex);

      const classes = item.classes;
      if (axis.mini) classes.push("wheel--mini");

      return (
        <div
          className="wheel-container"
          style={{ transform: `translate(0, ${offset}px)` }}
          key={wIndex}
        >
          <div className={classes.join(" ")}></div>
        </div>
      );
    });

    return (
      <div className="axis" key={axisIndex} style={{ transform: `translate(${axis.offset}px, 0)` }}>
        {wheels}
      </div>
    );
  });

  // уменьшать ли масштаб?
  let scale = "scale(1)";
  let totalWheels = models[modelId].wheelCount;
  if (trailerId) totalWheels += trailers[trailerId].wheelCount;

  if (totalWheels >= 12) scale = "scale(0.95)";
  if (totalWheels >= 16) scale = "scale(0.85)";
  if (totalWheels >= 20) scale = "scale(0.79)";

  return (
    <div>
      <div className="view-model__container" style={scale ? { transform: scale } : {}}>
        <div
          className="view-model"
          style={{
            padding: `${models[modelId].globalPadding}px 0`,
          }}
        >
          {isFlat ? models[modelId].flat : models[modelId].color}

          <div className="wheels">{axesListModel}</div>
        </div>

        {trailerId && (
          <div
            className="view-model view-model--trailer"
            style={{
              padding: `${trailers[trailerId].globalPadding}px 0`,
            }}
          >
            {isFlat ? trailers[trailerId].flat : trailers[trailerId].color}

            <div className="wheels">{axesListTrailer}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VehicleTyre;
