import Modal from "react-bootstrap/Modal";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import { ModalsState } from "../../redux/modals/modals_types";

const ModalConfirm = () => {
  const modals: ModalsState = useTypedSelector((state) => state.modals);
  const { closeModalConfirm } = useActions();

  const handleClose = () => {
    if (!!modals.modal_confirm__onCancel) modals.modal_confirm__onCancel();

    closeModalConfirm();
  };

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!!modals.modal_confirm__onConfirm) modals.modal_confirm__onConfirm();

    closeModalConfirm();
  };

  return (
    <Modal show={modals.modal_confirm} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{modals.modal_confirm__title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-confirm__info ">{modals.modal_confirm__content}</div>
        <form onSubmit={submit}>
          <div className="modal-folder__button-box modal-folder__button-box--end">
            <button
              className="modal__button modal__button--white modal__button--red"
              type="button"
              onClick={() => handleClose()}
            >
              Отмена
            </button>
            <button className="modal__button" type="submit">
              Ок
            </button>
          </div>
        </form>
      </Modal.Body>
      <button className="button-exit" onClick={handleClose}></button>
    </Modal>
  );
};

export default ModalConfirm;
