import { ICar } from "../car/car_types";

export interface ICarsModalState {
  data: ICar[];
  filter: string;
}
export interface ISelectedCarModal {
  car: ICar;
  group_id: number;
}
export enum CarsModalActionTypes {
  GET_CARS_MODAL = "GET_CARS_MODAL",
  CARS_MODAL_FILTER = "CARS_MODAL_FILTER",
}

interface GetCarsModal {
  type: CarsModalActionTypes.GET_CARS_MODAL;
  payload: ICar[];
}

interface CarsFilterModal {
  type: CarsModalActionTypes.CARS_MODAL_FILTER;
  payload: string;
}

export type CarsModalActions = GetCarsModal | CarsFilterModal;
