import {
  VehicleInfo,
  VehicleFullNumber,
  VehicleNumber,
  VehicleRegion,
} from "components/cars/Cars.styled";
import { ICar } from "../../redux/car/car_types";

const VehicleHeader = ({
  car,
  woComment = false,
}: {
  car: ICar | ICarPreview;
  woComment?: boolean;
}) => {
  return (
    <div className="vehicle-view">
      <div className="vehicle-header">
        <VehicleInfo>
          <VehicleFullNumber>
            <VehicleNumber>{car?.car_number}</VehicleNumber>
            <VehicleRegion>{car?.region}</VehicleRegion>
          </VehicleFullNumber>

          <div className="vehicle__name">{car?.title}</div>
        </VehicleInfo>
      </div>

      {!woComment && car.comment && <div className="vehicle-comment">{car?.comment}</div>}
    </div>
  );
};

export default VehicleHeader;

interface ICarPreview {
  code: string;
  car_number: string;
  region: string;
  title: string;
  comment: string;
}
