import { round } from "helpers";
import { ReactElement } from "react";
import { ISettings } from "redux/settings/settings_types";
import { TabIds } from "../../../types/other";

const ViewSensorsNaked = ({
  sensors,
  limit,
  tab = TabIds.Bar,
}: {
  sensors: [] | [[number, number]];
  limit: ISettings;
  tab?: string;
}) => {
  const sensorsList = sensors.map((sensor: any, index: number) => {
    let value: number;
    let content: ReactElement;
    const sensorClasses = ["sensor__naked"];

    switch (tab) {
      case TabIds.Temp:
        value = sensor[1];
        content = <div className="wheel-value">{round(value)}°C</div>;

        if (value > limit.temp_max) {
          sensorClasses.push("sensor__naked--yellow");
        }

        break;
      case TabIds.TempLimit:
        value = sensor[1];
        content = <div className="wheel-value">{round(limit.temp_max)}°C</div>;

        if (value > limit.temp_max) {
          sensorClasses.push("sensor__naked--yellow");
        }

        break;
      case TabIds.BarLimits:
        value = sensor[0];
        content = (
          <div className="wheel__limits">
            <span className="wheel__limits-value">{round(limit.bar_min)}</span>
            <span className="wheel__limits-value">{round(limit.bar_max)}</span>
          </div>
        );

        if ((value > 0.45 && value < limit.bar_min) || value > limit.bar_max) {
          sensorClasses.push("sensor__naked--red");
        }

        break;
      default:
        // давление
        value = sensor[0];

        content = <div className="wheel-value">{round(value)}</div>;
        if ((value > 0.45 && value < limit.bar_min) || value > limit.bar_max) {
          sensorClasses.push("sensor__naked--red");
        }

        break;
    }

    return (
      <div key={index} className={sensorClasses.join(" ")}>
        {content}
      </div>
    );
  });

  return (
    <div className="naked__container">
      <div className="sensors__naked">{sensorsList}</div>
    </div>
  );
};

export default ViewSensorsNaked;
