import { useEffect } from "react";
import CustomScroll from "react-custom-scroll";
import { useActions } from "../../hooks/useAction";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Amcharts from "../../pages/Amcharts";
import { IPointsState } from "../../redux/points/points_types";
import { SettingsState } from "../../redux/settings/settings_types";
import SkeletonLoading from "../skeleton-loading/SkeletonLoading";
import { getDataCharts } from "./chartsHelper";

const Charts = () => {
  const { toggleIsCharts } = useActions();
  const { points, loading }: IPointsState = useTypedSelector((state) => state.points);
  const settings: SettingsState = useTypedSelector((state) => state.settings);

  useEffect(() => {
    return () => {
      toggleIsCharts(false);
    };
  }, []);

  if (loading || settings.loading) {
    return (
      <div className="aside">
        <div className="charts">
          <SkeletonLoading height="100vh" width="100vw" theme={true} />
        </div>
      </div>
    );
  }

  const filteredPoints = points.filter((item) => item.sensors.length !== 0);

  if (filteredPoints.length <= 1)
    return (
      <div className="aside">
        <div className="charts">
          <CustomScroll allowOuterScroll={false} heightRelativeToParent="100%">
            <div className="charts__container">
              <div className="chart_box">
                <h5 className="chart__title">Нет точек для построения графика</h5>
              </div>
            </div>
          </CustomScroll>
        </div>
      </div>
    );

  const dataPressure = getDataCharts(filteredPoints, "pressure");
  const dataTemp = getDataCharts(filteredPoints, "temp");

  return (
    <div className="aside">
      <div className="charts">
        <CustomScroll allowOuterScroll={true} heightRelativeToParent="100%">
          <div className="charts__container">
            <div className="chart_box">
              <h5 className="chart__title">
                График <strong>давления</strong>
              </h5>
              <Amcharts data={dataPressure} type="temp" />
            </div>

            <div className="chart_box">
              <h5 className="chart__title">
                График <strong>температуры</strong>
              </h5>
              <Amcharts data={dataTemp} type="pressure" />
            </div>
          </div>
        </CustomScroll>
      </div>
    </div>
  );
};

export default Charts;
