import { GlobalApi } from "../../api/GlobalApi";
import api from "api";
import { IFolder } from "redux/folder/FolderType";

interface IFoldersResponse {
  folders: IFolder[];
}

export const FolderApi = GlobalApi.injectEndpoints({
  endpoints: (build) => ({
    getFolders: build.query<IFolder[], void>({
      query: () => ({
        url: api.getFolders(),
      }),
      providesTags: ["Folders"],
      transformResponse: (response: IFoldersResponse) => response.folders,
    }),
    addFolder: build.mutation<
      { message: string; folder: IFolder },
      { title: string; vehicles: number[] }
    >({
      query: (body) => ({
        url: api.addFolder(),
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error) => {
        if (error) return [];
        return ["Folders"];
      },
    }),
    deleteFolder: build.mutation<{ message: string }, number>({
      query: (id) => ({
        url: api.deleteFolder(id),
        method: "DELETE",
      }),
      invalidatesTags: (result, error) => {
        if (error) return [];
        return ["Folders"];
      },
    }),
    putFolder: build.mutation<any, { id: number; title: string; vehicles: number[] }>({
      query: (body) => ({
        url: api.putFolder(body.id),
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error) => {
        if (error) return [];
        return ["Folders"];
      },
    }),
    getFoldersUser: build.query<IFolder[], number>({
      query: (id) => ({
        url: api.getFoldersUser(id),
      }),
      providesTags: ["Folders"],
      transformResponse: (response: IFoldersResponse) => response.folders,
    }),
  }),
});

export const {
  useAddFolderMutation,
  useDeleteFolderMutation,
  usePutFolderMutation,
  useGetFoldersQuery,
  useGetFoldersUserQuery,
} = FolderApi;
