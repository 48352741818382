import styled, { css } from "styled-components";

interface Props {
  isMapLoading?: boolean;
}

export const MapLoader = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99;
  opacity: 0;
  pointer-events: none;
  ${(props) =>
    props.isMapLoading &&
    css`
      opacity: 1;
      pointer-events: auto;
    `}
  &:before {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    content: "";
    background-color: white;
    z-index: -1;
    opacity: 0;
    transition: 0.2s opacity ease;
    ${(props) =>
      props.isMapLoading &&
      css`
        opacity: 0.5;
      `}
  }
`;
