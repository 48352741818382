import { ChangeEventHandler, memo } from "react";

const ModalTextarea = ({
  value,
  onChange,
  errors = [],
  title,
  length = 64,
  placeholder,
  leftText,
  readOnly,
  name,
  rowsLength,
  classes = [],
  titleClass = "modal-folder__title",
}: IModalTextarea) => {
  const classList = ["input-area"].concat(classes);

  return (
    <div className={classList.join(" ")}>
      {title && <label className={titleClass}>{title}</label>}
      <div className="d-flex align-items-center">
        {leftText && <span className="modal-title--margin modal-title--first">{leftText}</span>}
        <div className="position-relative w-100">
          <textarea
            name={name}
            onChange={onChange}
            maxLength={length}
            readOnly={readOnly}
            rows={rowsLength}
            value={value}
            placeholder={placeholder || ""}
            className={
              errors && errors.length == 0
                ? "modal-input__grow textarea__resize"
                : "modal-input__grow textarea__resize modal-input__grow--error"
            }
          />
        </div>
      </div>

      {errors &&
        Array.isArray(errors) &&
        errors.length > 0 &&
        errors.map((error: any, index: number) => (
          <div key={index} className="input-error-message">
            {error}
          </div>
        ))}
      {errors && typeof errors === "string" && <div className="input-error-message">{errors}</div>}
    </div>
  );
};

export default memo(ModalTextarea);

interface IModalTextarea {
  length?: number;
  title?: string;
  value?: string | number;
  errors?: string[] | null | string;
  placeholder?: string;
  leftText?: string;
  readOnly?: boolean;
  min?: number;
  name?: string;
  classes?: string[];
  titleClass?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  rowsLength?: number;
}
