import React, { useEffect, useState } from "react";
import { useActions } from "../../hooks/useAction";
import SelectInput from "components/select-input/SelectInput";
import DateTemplates from "components/date-templates/DateTemplate";
import CustomDatePicker from "components/datepicker/CustomDatePicker";
import moment from "moment";
import InputBlock from "./components/InputsBlock";
import { ACTION_TYPES } from "constants/actions";

const SideBarTyres = () => {
  const [type, setType] = useState("all");

  const [actionTypes, setActionTypes] = useState<Array<any>>([]);

  const [from, setFrom] = useState(moment().format("YYYY-MM-DD"));
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));

  const [fromError, setFromError] = useState("");
  const [toError, setToError] = useState("");

  useEffect(() => {
    setActionTypes([
      { value: "all", label: "Все типы событий" },
      { value: "adding", label: ACTION_TYPES.adding },
      { value: "moving", label: ACTION_TYPES.moving },
      { value: "repair", label: ACTION_TYPES.repair },
      { value: "store", label: ACTION_TYPES.store },
      { value: "utilization", label: ACTION_TYPES.utilization },
    ]);
  }, []);

  const onChangeType = (value: string) => {
    setType(value);
  };

  const clearErrors = () => {
    setFromError("");
    setToError("");
  };

  const onDatesChange = (from: string, to: string) => {
    setFrom(from);
    setTo(to);
  };

  // const { actions, loading, from, to  }: ActionsState = useTypedSelector((state) => state.actions);
  const { getActionsPeriod } = useActions();

  const handlerReport = () => {
    if (type !== "all") {
      getActionsPeriod(from, to, type);
    } else getActionsPeriod(from, to);
  };

  return (
    <div className="view-container sidebar-grey">
      <div className="view__inner sidebar-white">
        {type && (
          <>
            <h4 className="point__title">Вид</h4>
            <SelectInput
              value={type}
              options={actionTypes}
              onChange={onChangeType}
              className="react-select--mini react-select--margin "
              placeholder=""
            />
          </>
        )}
        <h4 className="point__title">Выбор периода</h4>
        <div className="reports reports--charts reports--charts--border margin-bottom-20">
          <DateTemplates onDatesChange={onDatesChange} from={from} to={to} />

          <div className="input-and-button input-and-button--border-top">
            <div className="input-area w50-5 m0">
              <label className="label">Начало периода</label>
              <CustomDatePicker
                onChange={(date) => {
                  clearErrors();
                  setFrom(date);
                }}
                date={from}
              />
            </div>

            <div className="input-area w50-5 m0">
              <label className="label">Конец периода</label>
              <CustomDatePicker
                onChange={(date) => {
                  clearErrors();
                  setTo(date);
                }}
                date={to}
              />
            </div>
            <button
              className="button button--mint button--with-label w100 m0"
              onClick={handlerReport}
            >
              Показать
            </button>
          </div>
        </div>
        {/* {!loading && actions && actions.actions.length > 0 && <InputBlock/>} */}
      </div>
    </div>
  );
};

export default SideBarTyres;
