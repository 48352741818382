import { memo } from "react";

const Fallback = () => (
  <div className="not-found-page">
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <p className="p p--404">Упс, что-то пошло не так</p>

          <button
            className="button button--fallback"
            onClick={() => window.location.assign(window.location.origin)}
          >
            Перезагрузить страницу
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default memo(Fallback);
