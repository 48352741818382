import { ISensorTabs, SENSOR_TABS, TabModes } from "../../../types/other";

const SensorTabs = ({
  onTabClick,
  tab,
  tabMode = TabModes.ALL,
}: {
  onTabClick: (tab: string) => void;
  tab?: string;
  tabMode?: TabModes;
}) => {
  const tabs: ISensorTabs[] =
    tabMode === TabModes.ALL ? [...SENSOR_TABS] : [SENSOR_TABS[0], SENSOR_TABS[2]];

  const classes = ["sensor-tabs"];
  if (tabMode === TabModes.MAIN) {
    classes.push("sensor-tabs--mini");
  }

  return (
    <div className={classes.join(" ")}>
      {tabs.map((link) => (
        <button
          className={`sensor-tab${tab == link.id ? " sensor-tab--active" : ""}`}
          key={link.id}
          title={link.title}
          onClick={() => onTabClick(link.id)}
        >
          {link.img}
        </button>
      ))}
    </div>
  );
};

export default SensorTabs;
