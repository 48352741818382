import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Container } from "react-bootstrap";
import { mediaQuery } from "helpers/mediaQueries";

interface IHeaderEl {
  variant?: "Cabinet" | "Home";
}

export const HeaderEl = styled.header<IHeaderEl>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background-color: #fff;
  ${({ variant }) =>
    variant === "Cabinet" &&
    css`
      background-image: url(${require(`../../assets/images/waves.png`)});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 0 0;
      /* padding: 0 6px 0px 16px; */
      align-items: center;
      display: flex;
    `}
`;

export const HeaderInner = styled.div`
  z-index: 10;
  width: 100%;
  position: relative;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
`;
export const HeaderContainer = styled(Container)<IHeaderEl>`
  position: relative;
  min-height: 60px;
  padding: 0px 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ variant }) =>
    variant === "Cabinet" &&
    css`
      max-width: 100%;
    `}
`;
export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const LogoType = styled(Link)`
  text-decoration: none;
  transition: 0.1s all ease;
  margin-right: auto;
  &:active {
    transform: translateY(1px);
  }
`;

export const LinkMe = styled(Link)`
  text-decoration: none;
  width: auto;
  font-weight: 400;
  font-size: 12px;
  color: #2f4267;
  margin-left: auto;
  transition: all 0.1s ease;
  border-radius: 5px;
  padding: 7px 10px;
  &:active {
    transform: translateY(1px);
  }
  &:hover {
    color: #2f4267;
    cursor: pointer;
    background-color: #edeff4;
  }
  ${mediaQuery("md")(`
    border: none;
    padding: 0 5px;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.3;
    text-transform: uppercase;
    padding: 5px 10px;
    background-color: #ffffff;
    transition: all 0.05s ease;
    border-radius: 10px;
    text-align: left;
    color: #2F4267;
    text-decoration: none;
    margin-left: 0;
  `)}
`;
