import { FC, useEffect, useState } from "react";
import { ICar } from "../../../redux/car/car_types";
import { useActions } from "../../../hooks/useAction";
import { Vehicle, VehicleWrapper } from "../Cars.styled";
import CarInfo from "./CarInfo";
import { useParams, NavLink } from "react-router-dom";

const CarTyre: FC<ICar> = (car) => {
  const { vehicle_id } = useParams<{ vehicle_id?: string }>();

  return (
    <NavLink to={`/tyres/${car.id}`} style={{ marginBottom: "1rem", display: "block" }}>
      <Vehicle className={vehicle_id && car.id === +vehicle_id ? "active" : ""}>
        <VehicleWrapper>
          <div className="flex-col">
            <CarInfo {...car} />
          </div>
        </VehicleWrapper>
      </Vehicle>
    </NavLink>
  );
};

export default CarTyre;
