import React, { useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import { ModalsState } from "../../redux/modals/modals_types";
import ModalInput from "../ui/Inputs/ModalInput";
import SkeletonLoading from "../skeleton-loading/SkeletonLoading";
import ModalWindow from "./ModalWindow";
import { useCreateNewUserMutation } from "redux/admin/users/UsersApi";
import toast from "react-hot-toast";
import { useGetProfilesQuery } from "redux/admin/profiles/ProfilesApi";
import SelectInput from "components/select-input/SelectInput";

const ModalCreateNewUser = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [profileId, setProfileId] = useState<any>(null);
  const [errors, setErrors] = useState<any>({
    name: [],
    email: [],
    password: [],
    profile_id: [],
  });
  const { toggleModal } = useActions();

  const [createNewUser, { isLoading }] = useCreateNewUserMutation();

  const modals: ModalsState = useTypedSelector((state) => state.modals);

  const handleClose = () => {
    toggleModal({ toggle: false, modal: "modal_create_new_user" });
    setName("");
    setEmail("");
    setPassword("");
    setProfileId(null);
    setErrors({
      name: [],
      email: [],
      password: [],
      profile_id: [],
    });
  };

  const handleSave = async () => {
    await createNewUser({ name, email, password, profile_id: profileId })
      .unwrap()
      .then(() => {
        toast.success("Профиль создан");
        handleClose();
      })
      .catch((error) => {
        if (error?.data?.errors) {
          setErrors({
            ...errors,
            name: error?.data?.errors?.name || [],
            email: error?.data?.errors?.email || [],
            password: error?.data?.errors?.password || [],
            profile_id: error?.data?.errors?.profile_id || [],
          });
        } else if (error?.data?.message) {
          toast.error(error.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  const { data } = useGetProfilesQuery();

  const optionsProfile: any = [];

  if (data) {
    data.profiles.forEach((profile: any) => {
      optionsProfile.push({ value: profile.id, label: profile.title });
    });
  }

  const onChangeProfile = (value: any) => {
    setProfileId(value);
  };

  if (isLoading) {
    return (
      <ModalWindow
        isShow={modals.modal_create_new_user}
        title="Добавление нового пользователя"
        handleClose={handleClose}
      >
        <SkeletonLoading height="402px" />
      </ModalWindow>
    );
  }

  return (
    <ModalWindow
      isShow={modals.modal_create_new_user}
      title="Добавление нового пользователя"
      handleClose={handleClose}
    >
      <div className="modal-content__box">
        <ModalInput
          title="Имя"
          value={name}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setName((e.target as HTMLInputElement).value)
          }
          errors={errors.name}
        />
        <ModalInput
          title="Email"
          value={email}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setEmail((e.target as HTMLInputElement).value)
          }
          errors={errors.email}
        />
        <ModalInput
          title="Пароль"
          value={password}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setPassword((e.target as HTMLInputElement).value)
          }
          errors={errors.password}
        />

        <div className="modal-folder__title">Профиль</div>
        <SelectInput
          onChange={(e) => onChangeProfile(e)}
          options={optionsProfile}
          value={profileId}
          placeholder="Выберите профиль"
        />
        {errors.profile_id && <div className="input-error-message">{errors.profile_id}</div>}
      </div>

      <div className="modal-edit__button-box">
        <button className="modal__button " onClick={() => handleSave()}>
          Сохранить
        </button>
      </div>
    </ModalWindow>
  );
};

export default ModalCreateNewUser;
