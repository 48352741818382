import { VehicleMenu, VehicleMenuClose } from "components/cars/car/CarMenu.styled";
import useClickOutside from "hooks/useClickOutside";
import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { ReactComponent as Cross } from "assets/images/cross.svg";

const TyresHeader = () => {
  const [title, setTitle] = useState<string>("Общая информация");
  const openMenu = () => {
    setIsOpened(true);
  };

  const closeMenu = () => {
    setIsOpened(false);
  };
  const [isOpened, setIsOpened] = useState(false);

  const menu = useRef<HTMLHeadingElement>(null);

  const { vehicle_id } = useParams<{ vehicle_id?: string }>();

  useClickOutside(menu, () => (isOpened ? setIsOpened(false) : {}));
  const arrLink = ["report", "store", "utilization", "journal"];
  const location = useLocation();

  const activeLink = () => {
    let active = "";
    for (let i = 0; i < arrLink.length; i++) {
      const url = location.pathname.split("/");
      if (url.includes(arrLink[i])) {
        active = arrLink[i];
      }
    }
    return active;
  };

  const handlerClick = (e: React.MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    if (e.target instanceof Element) {
      setTitle(e.target.innerHTML);
      setIsOpened(false);
    }
  };

  useEffect(() => {
    if (vehicle_id) setTitle("Общая информация");
  }, [vehicle_id]);

  const link = vehicle_id ? `/tyres/${vehicle_id}` : `/tyres`;

  return (
    <nav className="tyre-header">
      <span
        className={
          "tyre-header-item tire-menu-drop" +
          (activeLink() === "" ? " tyre-header-item--active" : "")
        }
        onClick={openMenu}
      >
        {title}
      </span>
      {isOpened && (
        <VehicleMenu ref={menu} top={"38px"} left={"13px"}>
          {/* { vehicle_id } */}
          <NavLink
            to={link}
            className={
              "vehicle-menu__button" +
              (activeLink() === "report" ? " tyre-header-item--active" : "")
            }
            onClick={(e) => handlerClick(e)}
          >
            Общая информация
          </NavLink>
          {/* <button className="vehicle-menu__button" onClick={(e) => handlerClick(e)}>
            Общая информация
          </button> */}
          <NavLink
            to={vehicle_id ? `/tyres/repair/${vehicle_id}` : `/tyres`}
            className={
              "vehicle-menu__button" +
              (activeLink() === "repair" ? " tyre-header-item--active" : "")
            }
            // className="vehicle-menu__button"
            onClick={(e) => handlerClick(e)}
          >
            ремонт шин
          </NavLink>
          <NavLink
            to={vehicle_id ? `/tyres/history/${vehicle_id}` : `/tyres`}
            className={
              "vehicle-menu__button" +
              (activeLink() === "history" ? " tyre-header-item--active" : "")
            }
            // className="vehicle-menu__button"
            onClick={(e) => handlerClick(e)}
          >
            история шин
          </NavLink>

          <VehicleMenuClose onClick={closeMenu}>
            <Cross className="logo_exit" />
          </VehicleMenuClose>
        </VehicleMenu>
      )}

      <NavLink
        to="/tyres/store"
        className={
          "tyre-header-item" + (activeLink() === "store" ? " tyre-header-item--active" : "")
        }
      >
        склад б/у шин
      </NavLink>
      <NavLink
        to="/tyres/utilization"
        className={
          "tyre-header-item" + (activeLink() === "utilization" ? " tyre-header-item--active" : "")
        }
      >
        утилизация
      </NavLink>
      <NavLink
        to="/tyres/journal"
        className={
          "tyre-header-item" + (activeLink() === "journal" ? " tyre-header-item--active" : "")
        }
      >
        журнал событий
      </NavLink>
    </nav>
  );
};

export default TyresHeader;
