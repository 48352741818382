import TimeLineCustom from "../components/timeLineCustom/TimeLineCustom";
import { useActions } from "../hooks/useAction";
import Amcharts from "./Amcharts";

const TestPage = () => {
  const { toggleModalConfirm } = useActions();

  const open = () => {
    toggleModalConfirm({
      toggle: true,
      title: "Подтвердите действие",
      content: "Тут текст о том, что произойдет",
      onConfirm: сonfirm,
      onCancel: сancel,
    });
  };

  const сonfirm = (): void => {
    console.log("Ok");
  };

  const сancel = (): void => {
    console.log("Отмена");
  };

  return (
    <div>
      <br />
      <br />
      <br />
      <br />

      <h1>Тестовая страница</h1>

      <div>
        <button onClick={() => open()}>Открыть модалку с подтверждением</button>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />

      {/* <Amcharts /> */}

      {/* <TimeLineCustom /> */}
    </div>
  );
};

export default TestPage;
