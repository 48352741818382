export interface IEvent {
  id: number;
  code: number;
  vehicle_id: number;
  limit_id: number;
  point_id: number;
  created_at: string;
  limit?: any;
  point?: any;
}

export interface EventState {
  events: IEvent[];
  events_period: IEvent[];
  events_last: IEvent[];
  currentEvent: IEvent | undefined;
  totalPage: number;
  totalEvents: number;
  loading: boolean;
}

export enum EventsActionsTypes {
  GET_EVENTS = "GET_EVETS",
  EVENT_PERIOD = "EVENT_PERIOD",
  EVENT_LAST = "EVENT_LAST",
  EVENTS_LOADING = "EVENTS_LOADING",
  DELETE_EVENT = "DELETE_EVENT",
  GET_EVENT = "GET_EVENT",
  TOTAL_PAGE = "TOTAL_PAGE",
  TOTAL_EVENTS = "TOTAL_EVENTS",
}

interface GetEvents {
  type: EventsActionsTypes.GET_EVENTS;
  payload: IEvent[];
}

interface GetEventsPeriod {
  type: EventsActionsTypes.EVENT_PERIOD;
  payload: IEvent[];
}

interface EventsLast {
  type: EventsActionsTypes.EVENT_LAST;
  payload: IEvent[];
}

interface EventsLoading {
  type: EventsActionsTypes.EVENTS_LOADING;
  payload: boolean;
}

interface GetEvent {
  type: EventsActionsTypes.GET_EVENT;
  payload: IEvent;
}

interface DeleteEvent {
  type: EventsActionsTypes.DELETE_EVENT;
  payload: number;
}

interface TotalPage {
  type: EventsActionsTypes.TOTAL_PAGE;
  payload: number;
}

interface TotalEvents {
  type: EventsActionsTypes.TOTAL_EVENTS;
  payload: number;
}

export type EventsActions =
  | GetEvents
  | GetEventsPeriod
  | EventsLast
  | EventsLoading
  | GetEvent
  | DeleteEvent
  | TotalPage
  | TotalEvents;
