import styled from "styled-components";
import { baseTheme } from "./Theme.styled";

export const AdminArea = styled.div`
  width: calc(100% - ${baseTheme.sizes.sideBarWidth});
  overflow: auto;
  padding: 20px;
  background-color: #edeff4;
  @media (max-width: 767.9px) {
    width: 50%;
  }
`;
