import React, { memo } from "react";
import { SpinnerArea, Spinner } from "./Loader.styled";

const Loader = () => {
  return (
    <SpinnerArea>
      <Spinner />
    </SpinnerArea>
  );
};

export default memo(Loader);
