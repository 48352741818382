import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFolder, IFolderState } from "./FolderType";

const initialState: IFolderState = {
  folder: null,
};

export const folderSlice = createSlice({
  name: "folder",
  initialState,
  reducers: {
    selectFolder: (state, action: PayloadAction<IFolder>) => {
      state.folder = action.payload;
    },
    unselectFolder: (state, _) => {
      state.folder = null;
    },
    changeTitleFolder: (state, action: PayloadAction<string>) => {
      if (state.folder) state.folder.title = action.payload;
    },
  },
});

export const folderReducer = folderSlice.reducer;
export const folderActions = folderSlice.actions;
