import { FC, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useActions } from "../../hooks/useAction";
// @ts-ignore
import { Link } from "react-router-dom";
import ModalInput from "../ui/Inputs/ModalInput";
import Loader from "components/loader/Loader";

const Login: FC = () => {
  const { login } = useActions();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Невалидный email").required("Введите email"),
      password: Yup.string()
        .min(8, "Минимальная длина пароля - 8 символов")
        .required("Введите пароль"),
    }),

    onSubmit: async (values) => {
      setErrorMessage("");
      setIsLoading(true);
      const result: any = await login(values.email.trim(), values.password.trim());

      if (result.status !== 200 && result.status !== 201) {
        setErrorMessage(result.data.message);
      }
      setIsLoading(false);
    },
  });

  return (
    <div className="login-container" data-testid="login-container">
      <h4 className="h4">Вход в личный кабинет</h4>

      <form onSubmit={formik.handleSubmit} className={errorMessage ? "error-form" : ""}>
        {formik.status}
        <div className="d-flex flex-column" style={{ marginBottom: "0.8rem" }}>
          <ModalInput
            type="text"
            name="email"
            placeholder="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            errors={formik.errors.email}
            classes={["input-area--no-margin"]}
          />
        </div>

        <div className="d-flex flex-column">
          <ModalInput
            type="password"
            name="password"
            placeholder="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            errors={formik.errors.password}
          />
        </div>
        {errorMessage && <div className="input-error-message">{errorMessage}</div>}
        <Link data-testid="reset-password" to="/reset-password" className="login__reset-href">
          Забыли пароль?
        </Link>

        {!isLoading ? (
          <div className="login__buttons">
            <button data-testid="button-login" className="button button--login" type="submit">
              Войти
            </button>
          </div>
        ) : (
          <Loader />
        )}
      </form>
    </div>
  );
};

export default Login;
