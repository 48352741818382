import { useContext, memo } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { CarsModalContext } from "../../context/CarsModalContext";
import Highlighter from "../highlighter/Highlighter";
import { like } from "../../helpers";
import CustomScroll from "react-custom-scroll";
import Checkbox from "../ui/Checkbox/Checkbox";
import { VehicleFullNumber, VehicleNumber, VehicleRegion } from "./Cars.styled";
import { useGetFoldersQuery } from "redux/folder/FolderApi";

const CarsModal = () => {
  const folder = useTypedSelector((state) => state.folder.folder);
  const carsModal = useTypedSelector((state) => state.carsModal);
  const { data, isLoading } = useGetFoldersQuery();

  const { handleAllSelect, selectVehicle, vehiclesId } = useContext(CarsModalContext);

  const filteredCars = carsModal.data.filter(
    (car) =>
      !carsModal.filter ||
      like(car.car_number, carsModal.filter) ||
      like(car.title, carsModal.filter)
  );

  if (!filteredCars.length && !carsModal.filter)
    return (
      <div className="vehicles">
        <div className="message-warning" style={{ backgroundColor: "#fff" }}>
          Список пуст
        </div>
      </div>
    );

  if (!filteredCars.length && carsModal.filter)
    return (
      <div className="vehicles">
        <div className="message-warning" style={{ backgroundColor: "#fff" }}>
          Ничего не найдено
        </div>
      </div>
    );

  interface IMappedFolder {
    [key: number]: string;
  }
  const mappedFolders: IMappedFolder = {};
  if (data)
    data.forEach((folder) => {
      if (folder) mappedFolders[folder.id] = folder.title;
    });

  const carsList = filteredCars.map((car) => {
    const carClasses = ["cars-modal__car"];
    if (vehiclesId.indexOf(car.id) >= 0) carClasses.push("cars-modal__car--active");

    return (
      <label className="cars-modal__group" key={car.id}>
        <div className="cars-modal__group_box">
          <Checkbox
            onChange={() => selectVehicle(car.id)}
            checked={vehiclesId.indexOf(car.id) >= 0}
          />

          <div className={carClasses.join(" ")}>
            <VehicleFullNumber>
              <VehicleNumber>
                <Highlighter text={car.car_number} filter={carsModal.filter} />
              </VehicleNumber>
              <VehicleRegion>{car.region}</VehicleRegion>
            </VehicleFullNumber>
            <div className="cars-modal__car_name" style={{ marginLeft: "12px" }}>
              <Highlighter text={car.title} filter={carsModal.filter} />
            </div>
          </div>
        </div>

        {car.folder_id > 0 && car.folder_id in mappedFolders && (
          <div className="cars-modal__folder">
            <span>в списке:</span> {mappedFolders[car.folder_id]}
          </div>
        )}
      </label>
    );
  });

  return (
    <div className="cars-modal">
      <CustomScroll allowOuterScroll={false} heightRelativeToParent="100%">
        <div className="car-modal__box">{carsList}</div>
      </CustomScroll>

      {filteredCars.length && folder && (
        <button
          role="button"
          className="cars-modal__button"
          onClick={() => {
            handleAllSelect(carsModal.data, filteredCars, carsModal.filter, folder.id);
          }}
        >
          ВЫБРАТЬ / УБРАТЬ ВСЕ
        </button>
      )}
    </div>
  );
};

export default memo(CarsModal);
