import styled from "styled-components";

export const ViewContainer = styled.div`
  background-color: #fff;
  padding: 10px 0 10px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;
export const ViewHeader = styled.div`
  padding: 0 20px;
`;
