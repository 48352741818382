import {
  INotificationsState,
  NotificationsActions,
  NotificationsTypes,
} from "./notifications_types";

const initialState: INotificationsState = {
  notification_resources: [],
  notification_resources_loading: true,
  vehicle_id: 0,
};

const NotificationsReducer = (state = initialState, action: NotificationsActions) => {
  switch (action.type) {
    case NotificationsTypes.GET_NOTIFICATIONS:
      return {
        ...state,
        notification_resources: action.payload,
        notification_resources_loading: false,
      };

    // case NotificationsTypes.NOTIFICATIONS_DEVICE_ID:
    //   return {
    //     ...state,
    //     device_id: action.payload,
    //   };

    case NotificationsTypes.NOTIFICATIONS_TRANSPORT_VEHICLE_ID:
      return {
        ...state,
        vehicle_id: action.payload,
      };

    case NotificationsTypes.NOTIFICATIONS_RESOURCES_LOADING:
      return {
        ...state,
        notification_resources_loading: action.payload,
      };
    case NotificationsTypes.PUT_INSTANT:
      const _notification_resources = state.notification_resources.map((notif) => {
        const newNotif = { ...notif };
        if (newNotif.id == action.payload.notification_id) {
          newNotif.is_instant = action.payload.is_instant;
        }
        return newNotif;
      });

      return {
        ...state,
        notification_resources: _notification_resources,
      };
    default:
      return state;
  }
};

export default NotificationsReducer;
