import TyreDate from "components/tyres/TyreDate";
import TyreHeader from "components/tyres/TyreHeader";
import TyreLineWithReason from "components/tyres/TyreLineWithReason";
import { TyreProps } from "redux/tyres/tyres_types";

const TyreList = ({ from, to, titles, tyres }: TyreListProps) => (
  <>
    <TyreDate from={from} to={to} />
    {tyres && tyres.length > 0 ? (
      <>
        <TyreHeader titles={titles} />
        {tyres.length > 0 && tyres.map((tyre) => <TyreLineWithReason key={tyre.id} {...tyre} />)}
      </>
    ) : (
      <div className="border-text">Ничего не найдено</div>
    )}
  </>
);

export default TyreList;

export interface TyreListProps {
  from: string;
  to?: string;
  titles: string[];
  tyres: TyreProps[];
}
