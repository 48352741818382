import { useFormik } from "formik";
import * as Yup from "yup";
import { useActions } from "../../hooks/useAction";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";

const SignUp = () => {
  const { signIn } = useActions();

  const formik = useFormik({
    initialValues: {
      name: "Демо пользователь",
      email: "demo@demo.demo",
      password: "Qq111111",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Обязательное поле"),
      email: Yup.string().email("Невалидные address").required("Обязательное поле"),
      password: Yup.string()
        .min(8, "Минимальная длина пароля 8 символов")
        .required("Обязательное поле"),
    }),
    validateOnMount: false,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: async (values) => {
      const { name, email, password } = values;
      const result: any = await signIn(name, email, password);
      if (result.status === 200) {
        toast.success("Вы успешно зарегистрировались");
      } else {
        toast.error(result.response.data.message);
      }
    },
  });

  return (
    <div className="login-container">
      <h4 className="h4">Регистрация в системе</h4>

      <form onSubmit={formik.handleSubmit}>
        <div className="d-flex flex-column">
          <input
            name="name"
            type="text"
            placeholder="ФИО"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          {formik.errors.name && <div>{formik.errors.name}</div>}
        </div>

        <div className="d-flex flex-column">
          <input
            name="email"
            type="text"
            placeholder="email"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          <div>{formik.errors.email}</div>
        </div>

        <div className="d-flex flex-column">
          <input
            name="password"
            type="text"
            placeholder="пароль"
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          <div>{formik.errors.password}</div>
        </div>

        <button type="submit">Зарегистрироваться</button>
      </form>

      <div className="login-href">
        <Link to="/login" className="login-href">
          Назад ко входу
        </Link>
      </div>
    </div>
  );
};

export default SignUp;
