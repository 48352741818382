import SkeletonLoading from "../../skeleton-loading/SkeletonLoading";
import { VehicleAddress } from "../Cars.styled";

const CarAddress = ({
  address = null,
}: {
  address: undefined | null | string | any;
  classAddress?: string;
}) => {
  if (address === null)
    return (
      <VehicleAddress>
        <SkeletonLoading height="10px" />
      </VehicleAddress>
    );

  return <VehicleAddress>{address}</VehicleAddress>;
};

export default CarAddress;
