import { baseTheme } from "assets/styles/Theme.styled";
import { mediaQuery } from "helpers/mediaQueries";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Vehicles = styled.div.attrs({
  "data-element": "vehicles",
})`
  padding: 15px 20px;
`;
export const Vehicle = styled.div.attrs({
  "data-element": "vehicle",
})`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  transition: all 0.1s ease;
  &:last-of-type {
    margin-bottom: 0;
  }
  &:hover {
    .open__icon {
      fill: ${baseTheme.colors.middleGrey};
    }
  }
`;

export const VehicleWrapper = styled.div.attrs({
  "data-element": "vehicle-wrapper",
})`
  padding: 10px 0 10px 12px;
  flex-grow: 1;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid ${baseTheme.colors.lightGrey};
  display: flex;
  position: relative;
  width: 100%;
  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 3px;
    background-color: transparent;
    top: 0;
    left: -1px;
    border-radius: 5px 0 0 5px;
  }
  ${Vehicle}:hover & {
    box-shadow: 5px 5px 10px -10px rgba(0, 0, 0, 0.5);
    &:after {
      background-color: ${baseTheme.colors.middleGrey};
      transition: all 0.2s;
    }
  }
  ${Vehicle}.active & {
    box-shadow: 5px 5px 10px -10px rgba(0, 0, 0, 0.5);
    &:after {
      background-color: ${baseTheme.colors.middleGrey};
      transition: all 0.2s;
    }
  }
  ${mediaQuery("md")(`
        width: 90%;
    `)}
`;

export const VehicleLink = styled(Link)`
  text-decoration: none;
  position: relative;
  color: #000;
  width: calc(100% - 28px);
  &:hover {
    color: inherit;
  }
`;

export const VehicleState = styled.div.attrs({
  "data-element": "vehicle-state",
})`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  .vehicle__icon {
    width: 30px;
  }
`;

export const VehicleInfo = styled.div.attrs({
  "data-element": "vehicle-info",
})`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;
  gap: 12px;
`;

export const VehicleFullNumber = styled.div.attrs({
  "data-element": "vehicle-full-number",
})`
  display: flex;
  align-items: center;
  height: 22px;
  border-radius: 5px;
  border: 1px solid ${baseTheme.colors.lightGrey};
  font-weight: 700;
  line-height: 1;
  background-color: #fff;
`;
export const VehicleNumber = styled.div.attrs({
  "data-element": "vehicle-number",
})`
  padding: 0 8px;
  position: relative;
  font-size: 12px;
  white-space: nowrap;
  color: #0b0c10;
  height: 12px;
  &::before {
    content: "";
    position: absolute;
    height: 20px;
    width: 1px;
    background-color: ${baseTheme.colors.lightGrey};
    top: -4px;
    right: 0;
  }
`;
export const VehicleRegion = styled.div.attrs({
  "data-element": "vehicle-region",
})`
  padding: 0 8px;
  font-size: 12px;
  color: #0b0c10;
`;

export const VehicleAddress = styled.p.attrs({
  "data-element": "vehicle-address",
})`
  font-size: 10px;
  line-height: 14px;
  margin-left: 6px;
  color: ${baseTheme.colors.darkGrey};
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
  flex: 1;
`;
