import { useRef, useState, memo } from "react";
import { useActions } from "hooks/useAction";
import useClickOutside from "hooks/useClickOutside";
import { ReactComponent as Cross } from "assets/images/cross.svg";

import {
  VehicleMenuContainer,
  VehicleMenu,
  VehicleMenuClose,
  VehicleMenuOpen,
} from "./CarMenu.styled";
import { Link } from "react-router-dom";
import { ICar } from "redux/car/car_types";

const CarMenu = ({
  car,
  icon,
  inner = false,
}: {
  car: ICar;
  icon: React.ReactElement;
  inner?: boolean;
}) => {
  const D = new Date();
  const date =
    D.getFullYear() +
    "-" +
    ("0" + (D.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + D.getDate()).slice(-2);

  const {
    toggleModal,
    getCarExtendedInfo,
    getNotifs,
    notificationResourcesLoading,
    getVehicleId,
    getLastLimits,
  } = useActions();

  const handlerChangeModalExtendedInfo = () => {
    getCarExtendedInfo(car.id);
    toggleModal({ toggle: true, modal: "modal_car_extended_info" });
    closeMenu();
  };

  const handlerChangeModalGeneralSettings = async () => {
    toggleModal({ toggle: true, modal: "modal_general_settings" });
    closeMenu();
    getVehicleId(car.id);
    getLastLimits({ vehicle_id: car.id, before: date });
  };

  const handlerChangeModalNotifications = () => {
    notificationResourcesLoading(true);
    getNotifs(car.id);
    toggleModal({ toggle: true, modal: "modal_notifications" });
    closeMenu();
  };

  const openMenu = () => {
    setIsOpened(true);
  };

  const closeMenu = () => {
    setIsOpened(false);
  };
  const [isOpened, setIsOpened] = useState(false);

  const menu = useRef<HTMLHeadingElement>(null);

  useClickOutside(menu, () => (isOpened ? setIsOpened(false) : {}));

  return (
    <VehicleMenuContainer>
      <VehicleMenuOpen onClick={openMenu}>{icon}</VehicleMenuOpen>
      {isOpened && (
        <VehicleMenu ref={menu} top={"12.5px"} right={"0px"}>
          <button className="vehicle-menu__button" onClick={() => handlerChangeModalExtendedInfo()}>
            Информация о ТС
          </button>
          <button
            className="vehicle-menu__button"
            onClick={() => handlerChangeModalGeneralSettings()}
          >
            Общие настройки
          </button>
          <button
            className="vehicle-menu__button"
            onClick={() => handlerChangeModalNotifications()}
          >
            Настройка уведомлений
          </button>

          {car.last_point && car.last_point.sensors.length > 0 && (
            <Link className="vehicle-menu__button" to={`/constructor/${car.id}`}>
              Конструктор
            </Link>
          )}

          <VehicleMenuClose onClick={closeMenu}>
            <Cross className="logo_exit" />
          </VehicleMenuClose>
        </VehicleMenu>
      )}
    </VehicleMenuContainer>
  );
};
export default memo(CarMenu);
