import { useTypedSelector } from "hooks/useTypedSelector";
import { useEffect, useState } from "react";
import { ModalsState } from "redux/modals/modals_types";
import { TyresState } from "redux/tyres/tyres_types";
import ModalWindow from "./ModalWindow";
import { useActions } from "hooks/useAction";
import Checkbox from "components/ui/Checkbox/Checkbox";
import ModalInput from "components/ui/Inputs/ModalInput";
import { useDispatch } from "react-redux";
import moment from "moment";
import { updateValue } from "redux/tyres/tyres_actions";

interface updateTyresType {
  used: boolean;
  model: string;
  brand: string;
  size: string;
  dot: string;
  date: string;
  tread: number;
  price: number;
  run: number;
}

const ModalUpdateTyre: React.FC = () => {
  const { tyre }: TyresState = useTypedSelector((state) => state.tyres);
  const modals: ModalsState = useTypedSelector((state) => state.modals);
  const [used, setUsed] = useState<boolean>(false);
  const [run, setRun] = useState<string>("");
  const [formUpdateTyres, setFormUpdateTyres] = useState<any>({
    model: tyre?.model,
    brand: tyre?.brand,
    size: tyre?.size,
    dot: tyre?.dot,
    date: tyre?.updated_at,
    tread_start: tyre?.tread_start,
    tread_start_by: tyre?.last_action?.tread,
    price: tyre?.price,
    run: run,
  });

  useEffect(() => {
    setFormUpdateTyres(tyre);
  }, [tyre]);

  const dispatch = useDispatch();

  const [errors, setErrors] = useState<any>({
    brand: [],
    model: [],
    type: [],
    run: [],
    tread: [],
    comment: [],
    reason: [],
    to: [],
    runLast: [],
  });

  const { toggleModal } = useActions();

  const handleInput = (e: any) => {
    setFormUpdateTyres({
      ...formUpdateTyres,
      [e.target.name]: (e.target as HTMLInputElement).value,
    });
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    try {
      dispatch(updateValue(formUpdateTyres));
    } catch (error) {
      console.log(setErrors(error));
    }
  };

  const handleClose = () => {
    toggleModal({ toggle: false, modal: "modal_update_tyre" });
  };
  const handleChangeRun = (e: React.FormEvent<HTMLInputElement>) => {
    setErrors({
      ...errors,
      run: [],
    });
    setRun((e.target as HTMLInputElement).value);
    if (
      tyre &&
      tyre.last_action &&
      tyre?.last_action?.run > +(e.target as HTMLInputElement).value
    ) {
      setErrors({
        ...errors,
        run: "Пробег не может быть меньше предыдущего",
      });
      return;
    }
  };

  return (
    <div>
      <ModalWindow
        isShow={modals.modal_update_tyre}
        title="Редактировать информацию о шине"
        dialogClassName="modal_box-big"
        handleClose={handleClose}
      >
        <form id="addTransport" className="modal-content__box" onSubmit={handleSubmit}>
          <div>
            <p className="model-title__bold">Метоположение шин</p>
          </div>
          <div>
            <p className="model-title__bold">Модель и размеры шин</p>
          </div>
          <div style={{ marginBottom: "5px" }}>
            <Checkbox
              onChange={() => setUsed((e: any) => !e)}
              checked={used}
              text="Б/У"
              titleClasses="modal-password_title"
            />
          </div>
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="Бренд"
              // length={7
              name="brand"
              value={formUpdateTyres?.brand}
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleInput(e)}
              errors={errors.brand}
            />
            <ModalInput
              titleClass="modal-password_title"
              title="Модель"
              min={1}
              // length={3}
              name="model"
              value={formUpdateTyres?.model}
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleInput(e)}
              errors={errors.model}
            />
          </div>
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="Размер"
              // length={3}
              name="size"
              value={formUpdateTyres?.size}
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleInput(e)}
              errors={errors.size}
            />
          </div>

          <div>
            <p className="model-title__bold">Характеристики производителя</p>
          </div>
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="Dot"
              // length={3}
              name="dot"
              value={formUpdateTyres?.dot}
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleInput(e)}
              errors={errors.dot}
            />
            <ModalInput
              titleClass="modal-password_title"
              title="Дата производства"
              // length={3}

              value={moment(formUpdateTyres?.date).format("DD.MM.YYYY")}
              name="date"
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleInput(e)}
              errors={errors.date}
            />
          </div>
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="Глубина протектора"
              // length={3}
              // placeholder={String(tyre?.last_move.tread)}
              value={formUpdateTyres?.tread_start}
              name="tread_start"
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleInput(e)}
              errors={errors.tread}
            />
            <ModalInput
              titleClass="modal-password_title"
              title="Стоимость"
              // length={3}
              // placeholder={`${String(tyre?.price)} ₽ `}
              value={formUpdateTyres?.price}
              name="price"
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleInput(e)}
              errors={errors.price}
            />
          </div>
          <div>
            <p className="model-title__bold">Начальные параметры для б/у шин</p>
            {/* /// надо доделать не изменяются значения */}
          </div>
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="Пробег"
              // length={3}
              // placeholder={`${String(tyre?.last_action?.run)} км`}
              value={tyre && tyre.last_action ? tyre.last_action.run : formUpdateTyres?.run}
              name="run"
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleChangeRun(e)}
              errors={errors.run}
            />
            <ModalInput
              titleClass="modal-password_title"
              title="Глубина протектора"
              value={
                tyre && tyre.last_action ? tyre.last_action.tread : formUpdateTyres?.tread_start_by
              }
              name="tread_start_by"
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleInput(e)}
              errors={errors.tread}
            />
          </div>
          <div className="modal-edit__button-box">
            <button form="addTransport" type="submit" className="modal__button">
              Сохранить
            </button>
          </div>
        </form>
      </ModalWindow>
    </div>
  );
};

export default ModalUpdateTyre;
