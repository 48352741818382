import React, { FC } from "react";

interface ISvg {
  className: string;
  width?: number;
  height?: number;
}

const PickerIcon: FC<ISvg> = ({ className, height, width }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.8 7.2H3.2V8.8H4.8V7.2ZM8 7.2H6.4C6.4 7.2 5.95817 8.35817 6.4 8.8C6.84183 9.24183 8 8.8 8 8.8V7.2ZM11.2 7.2H9.6V8.8H11.2V7.2ZM12.8 1.6H12V0H10.4V1.6H4V0H2.4V1.6H1.6C0.712 1.6 0.00799999 2.32 0.00799999 3.2L0 14.4C0 15.28 0.712 16 1.6 16H12.8C13.68 16 14.4 15.28 14.4 14.4V3.2C14.4 2.32 13.68 1.6 12.8 1.6ZM12.8 14.4H1.6V5.6H12.8V14.4Z"
        fill="#8E99AD"
      />
    </svg>
  );
};

export default PickerIcon;
