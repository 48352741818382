import React, { useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import { ModalsState } from "../../redux/modals/modals_types";
import ModalInput from "../ui/Inputs/ModalInput";
import SkeletonLoading from "../skeleton-loading/SkeletonLoading";
import ModalWindow from "./ModalWindow";
import toast from "react-hot-toast";
import Checkbox from "components/ui/Checkbox/Checkbox";
import CustomDatePicker from "components/datepicker/CustomDatePicker";
import moment from "moment";
import { TyresState } from "redux/tyres/tyres_types";
import { CarState } from "redux/car/car_types";

const ModalAddTyre = () => {
  const [model, setModel] = useState<string>("");
  const [brand, setBrand] = useState<string>("");
  const [size, setSize] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [tread_start, setTreadStart] = useState<string>("");
  const [tread, setTread] = useState<string>("");
  const [dot, setDot] = useState<string>("");
  const [production_date, setProdDate] = useState(moment().format("YYYY-MM-DD"));
  const [run_start, setRunStart] = useState<string>("");
  const [place, setPlace] = useState<number>(1);
  const [used, setUsed] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({
    title: [],
    region: [],
    car_number: [],
    comment: [],
    code: [],
  });

  const { toggleModal, addTyre, addActionAdding } = useActions();

  const [submiting, setSubmiting] = useState<boolean>(false);

  const modals: ModalsState = useTypedSelector((state) => state.modals);
  const { loading, car }: CarState = useTypedSelector((state) => state.car);

  const handleClose = () => {
    toggleModal({ toggle: false, modal: "modal_add_tyre" });
    setModel("");
    setBrand("");
    setSize("");
    setPrice("");
    setTreadStart("");
    setTread("");
    setDot("");
    setProdDate(moment().format("YYYY-MM-DD"));
    setPlace(1);
    setRunStart("");
    setUsed(false);
    setErrors({
      title: [],
      region: [],
      car_number: [],
      code: [],
    });
  };

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const data /*: Omit<TyresProps, "id" | "status" | "user_id">*/ = {
      brand,
      model,
      dot,
      size,
      production_date,
      price,
      tread_start,
      used,
      place,
      run_start: run_start !== "" ? run_start : 0,
      vehicle_id: car ? car.id : 0,
      type: "adding",
      run: run_start,
      tread: tread ? tread : tread_start,
    };

    try {
      setSubmiting(true);
      await addTyre(data);
      toast.success("Вы успешно добавили шину");

      handleClose();
    } catch (error: any) {
      console.log(error);

      if (error?.response?.data?.errors) {
        setErrors({
          ...errors,
          brand: error?.response?.data?.errors?.brand || [],
          model: error?.response?.data?.errors?.model || [],
          dot: error?.response?.data?.errors?.dot || [],
          size: error?.response?.data?.errors?.size || [],
          production_date: error?.response?.data?.errors?.production_date || [],
          price: error?.response?.data?.errors?.price || [],
          tread_start: error?.response?.data?.errors?.tread_start || [],
          place: error?.response?.data?.errors?.place || [],
          used: error?.response?.data?.errors?.used || [],
          run_start: error?.response?.data?.errors?.run_start || [],
        });
      } else if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
    setSubmiting(false);
  };

  if (submiting) {
    return (
      <ModalWindow
        isShow={modals.modal_add_tyre}
        title="Добавление шины"
        handleClose={handleClose}
        dialogClassName="modal__box-big"
      >
        <SkeletonLoading height="382px" />
      </ModalWindow>
    );
  }

  const onDateChoose = (from: string) => {
    setProdDate(from);
  };

  return (
    <ModalWindow
      isShow={modals.modal_add_tyre}
      title="Добавление шины"
      handleClose={handleClose}
      dialogClassName="modal__box-big"
    >
      <form id="addTransport" onSubmit={handleSave} className="modal-content__box">
        <div>
          <p className="model-title__bold">Местоположение шины</p>
        </div>
        <div>
          <p className="model-title__bold">Модель и размеры шин</p>
          <div style={{ marginBottom: "5px" }}>
            <Checkbox
              onChange={() => setUsed((used) => !used)}
              checked={used}
              text="Б/У"
              titleClasses="modal-password_title"
            />
          </div>
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="Бренд"
              // length={7}
              value={brand}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setBrand((e.target as HTMLInputElement).value)
              }
              errors={errors.brand}
            />
            <ModalInput
              titleClass="modal-password_title"
              title="Модель"
              // length={3}
              value={model}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setModel((e.target as HTMLInputElement).value)
              }
              errors={errors.model}
            />
          </div>
          <ModalInput
            titleClass="modal-password_title"
            classes={["modal-title__half"]}
            title="Размер"
            // length={3}
            value={size}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setSize((e.target as HTMLInputElement).value)
            }
            errors={errors.size}
          />
        </div>
        <div>
          <p className="model-title__bold">Характеристики производителя</p>
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="DOT"
              length={12}
              value={dot}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setDot((e.target as HTMLInputElement).value)
              }
              errors={errors.dot}
            />
            <div className="input-area input-area--border w100 m0">
              <label className="label">Дата производства</label>
              <CustomDatePicker
                onChange={onDateChoose}
                date={production_date}
                classes="react-datepicker_big"
              />
              {errors.production_date && typeof errors.production_date === "string" && (
                <div className="input-error-message">{errors.production_date}</div>
              )}
            </div>
          </div>
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="Глубина протектора"
              // length={32}
              value={tread_start}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setTreadStart((e.target as HTMLInputElement).value)
              }
              errors={errors.tread_start}
            />
            <ModalInput
              titleClass="modal-password_title"
              title="Стоимость"
              value={price}
              // length={16}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setPrice((e.target as HTMLInputElement).value)
              }
              errors={errors.price}
            />
          </div>
        </div>
        {used && (
          <div>
            <p className="model-title__bold">Начальные параметры для б/у шин</p>
            <div className="modal__inputs-box">
              <ModalInput
                titleClass="modal-password_title"
                title="Пробег"
                // length={32}
                value={run_start}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setRunStart((e.target as HTMLInputElement).value)
                }
                errors={errors.run_start}
              />
              <ModalInput
                titleClass="modal-password_title"
                title="Глубина протектора"
                value={tread}
                // length={16}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setTread((e.target as HTMLInputElement).value)
                }
                errors={errors.tread}
              />
            </div>
          </div>
        )}
      </form>

      <div className="modal-edit__button-box">
        <button form="addTransport" type="submit" className="modal__button">
          Сохранить
        </button>
      </div>
    </ModalWindow>
  );
};

export default ModalAddTyre;
