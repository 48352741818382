import { Outlet } from "react-router-dom";
import SideBarProfile from "../components/sideBar/SideBarProfile";
import DashboardWrapper from "./DashboardWrapper";

const ProfileLayout = () => {
  return (
    <DashboardWrapper>
      <SideBarProfile />
      <Outlet />
    </DashboardWrapper>
  );
};

export default ProfileLayout;
