import ActionLine from "components/actions/ActionLine";
import Loader from "components/loader/Loader";
import TyreHeader from "components/tyres/TyreHeader";
import TyreInfo from "components/tyres/TyreInfo";
import TyreTitle from "components/tyres/TyreTitle";
import { useActions } from "hooks/useAction";
import { useTypedSelector } from "hooks/useTypedSelector";
// import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ActionsState } from "redux/actions/actions_types";
// import { CarState } from "redux/car/car_types";

const TyresRepairPage = () => {
  const { vehicle_id } = useParams<{ vehicle_id?: string }>();
  const titles = [
    "ДАТА",
    "СОБЫТИЕ",
    "ТРАНСПОРТ",
    "ШИНА",
    "МЕСТОПОЛОЖЕНИЕ",
    "ПРОБЕГ",
    "ГЛУБИНА ПРОТЕКТОРА",
    "",
  ];

  const { getActionsByVehicle, getCar, getCarTyres } = useActions();
  const [count, setCount] = useState<number>(0);
  const { actions, loading }: ActionsState = useTypedSelector((state) => state.actions);
  const init = async () => {
    if (!vehicle_id) return;
    getCar(+vehicle_id);
    getCarTyres(+vehicle_id);
    getActionsByVehicle(+vehicle_id, "repair");
  };

  useEffect(() => {
    init();
  }, [vehicle_id]);

  useEffect(() => {
    actions && actions.count ? setCount(actions.count) : setCount(0);
  }, [actions]);

  const handlClick = () => {
    console.log("click");
  };
  return (
    <div className="report_head">
      {loading ? (
        <Loader />
      ) : (
        <>
          <TyreTitle
            title="Ремонт"
            subtitle="шин"
            btnTitle="добавить событие"
            handleClick={handlClick}
          />
          <TyreInfo
            firstCount={{
              count: actions?.actions.length,
              title1: "количество",
              title2: "событий",
            }}
            secondCount={{ count: count, title1: "общий", title2: "пробег" }}
            showConstructor={false}
          />
          {actions && actions.actions.length > 0 ? (
            <>
              <TyreHeader titles={titles} classes="tyre_table_box-8" />
              {actions.actions.map((action) => (
                <ActionLine
                  key={action.id}
                  date={action.updated_at}
                  type={action.type}
                  car_title={
                    action.tyre && action.tyre.vehicle?.title
                      ? action.tyre.vehicle?.title
                      : "ТС не привязано"
                  }
                  tyre_model={action.tyre?.model}
                  tyre_brand={action.tyre?.brand}
                  run={action.run}
                  tread={action.tread}
                />
              ))}
            </>
          ) : (
            <div className="border-text">Ничего не найдено</div>
          )}
        </>
      )}
    </div>
  );
};

export default TyresRepairPage;
