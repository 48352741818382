import { ICar } from "../../../redux/car/car_types";
import Highlighter from "../../highlighter/Highlighter";
import CarIcon from "./CarIcon";
import { FC } from "react";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import CarAddress from "./CarAddress";
import { Declination, formatMinutes } from "../../../helpers";
import {
  VehicleState,
  VehicleInfo,
  VehicleFullNumber,
  VehicleNumber,
  VehicleRegion,
  VehicleAddress,
} from "../Cars.styled";

const CarInfo: FC<ICar> = (car) => {
  const cars = useTypedSelector((state) => state.cars);
  let address = <VehicleAddress>Сигнала от данного устройства пока не было</VehicleAddress>;

  if (car.last_point) {
    const { minutes, formattedDiff, words } = formatMinutes(car.last_point.device_updated_at);

    if (minutes > 30) {
      address = (
        <VehicleAddress>
          Сигнал отсутствует уже {formattedDiff} {Declination(formattedDiff, words)}
        </VehicleAddress>
      );
    } else {
      address = <CarAddress address={car.last_point.address} />;
    }
  }
  return (
    <>
      <VehicleInfo>
        <VehicleFullNumber>
          <VehicleNumber>
            <Highlighter text={car.car_number} filter={cars.filter} />
          </VehicleNumber>
          <VehicleRegion>{car.region}</VehicleRegion>
        </VehicleFullNumber>
        <p className="vehicle__name">
          <Highlighter text={car.title} filter={cars.filter} />
        </p>
      </VehicleInfo>
      <VehicleState>
        <CarIcon lastPoint={car.last_point} />
        {address}
      </VehicleState>
    </>
  );
};

export default CarInfo;
