import { TyreProps } from "redux/tyres/tyres_types";
import { ReactComponent as Arrow } from "../../assets/images/Arrow2.svg";
import moment from "moment";
import ModalAddTyre from "components/modals/ModalAddTyre";
import ModalAddDevice from "components/admin/ModalAddDevice";

const TyreLineWithReason = ({ model, brand, price, action, last_move }: TyreProps) => {
  let reason = "";
  action && action.utilization ? (reason = action.utilization.reason) : "";
  action && action.store ? (reason = action.store.reason) : "";

  const handlePoint = () => {
    console.log("sdfsdf");
  };

  return (
    <div className="tyre_line tyre_table_box">
      <div className="tyre_line_part bold">{`${model} ${brand}`}</div>
      <div className="tyre_line_part">{`${moment(last_move.updated_at).format(
        "YYYY-MM-DD"
      )}/${moment(action.updated_at).format("YYYY-MM-DD")}`}</div>
      <div className="tyre_line_part tyre_line_part--white-space">{reason}</div>
      <div className="tyre_line_part">{action.run}</div>
      <div className="tyre_line_part">{price}</div>
      <button className="report_time-more" onClick={() => handlePoint()}>
        <span className="time-more_text">Подробнее</span>
        <Arrow className="arrow" />
      </button>
    </div>
  );
};

export default TyreLineWithReason;
