import * as LoginActionCreators from "./login/login_actions";
import * as UserActionCreators from "./user/user_actions";
import * as CarsActionCreators from "./cars/cars_actions";
import * as CarActionCreators from "./car/car_actions";
import * as ModalsActionCreators from "./modals/modals_actions";
import * as SettingsActionCreators from "./settings/settings_actions";
import * as NotificationsActionCreators from "./notifications/notifications_actions";
import * as CarsModalActionCreators from "./cars_modal/cars_modal_actions";
import * as EventsActionCreators from "./events/events_actions";
import * as MapActionCreators from "./map/map_actions";
import * as PointsActionCreators from "./points/points_actions";
import * as ReportActionCreators from "./reports/reports_actions";
import * as ConstructorActionCreators from "./constructor/constructor_actions";
import * as DevicesActions from "./admin/devices/DevicesSlice";
import * as FolderActions from "./folder/FolderSlice";
import * as UsersActions from "./admin/users/UsersSlice";
import * as ProfilesActions from "./admin/profiles/ProfilesSlice";
import * as UserFilterActionCreators from "./users_filter/users_filter_actions";
import * as DeviceFilterActionCreators from "./device_filter/device_filter_actions";
import * as TyresActionCreators from "./tyres/tyres_actions";
import * as ActionsActionCreators from "./actions/actions_actions";

export default {
  ...UserActionCreators,
  ...LoginActionCreators,
  ...CarsActionCreators,
  ...CarActionCreators,
  ...ModalsActionCreators,
  ...SettingsActionCreators,
  ...NotificationsActionCreators,
  ...CarsModalActionCreators,
  ...EventsActionCreators,
  ...MapActionCreators,
  ...PointsActionCreators,
  ...ReportActionCreators,
  ...ConstructorActionCreators,
  ...DevicesActions.devicesActions,
  ...FolderActions.folderActions,
  ...UsersActions.usersActions,
  ...ProfilesActions.profilesActions,
  ...UserFilterActionCreators,
  ...DeviceFilterActionCreators,
  ...TyresActionCreators,
  ...ActionsActionCreators,
};
