import { useTypedSelector } from "hooks/useTypedSelector";
import { IFolder } from "redux/folder/FolderType";
import { ArrowEl, FolderCount, FolderInfo, FolderTitle } from "./Folder.styled";
import { ReactComponent as Arrow } from "assets/images/arrow.svg";

const FolderInfoEl = ({
  group,
  setOpenFolder,
  isOpenFolder,
}: {
  group: IFolder;
  setOpenFolder: (isOpenFolder: boolean) => void;
  isOpenFolder: boolean;
}) => {
  const handleToggleFolder = () => {
    setOpenFolder(!isOpenFolder);
  };
  const { filter } = useTypedSelector((state) => state.cars);
  return (
    <FolderInfo onClick={handleToggleFolder}>
      <ArrowEl active={isOpenFolder || !!filter}>
        <Arrow />
      </ArrowEl>
      <FolderTitle>
        {group.title}
        <FolderCount>{group.count}</FolderCount>
      </FolderTitle>
    </FolderInfo>
  );
};

export default FolderInfoEl;
