import Loader from "components/loader/Loader";
import { useEffect, useState } from "react";
import { useTypedSelector } from "hooks/useTypedSelector";
import { useActions } from "hooks/useAction";
import { ActionsState } from "redux/actions/actions_types";
import moment from "moment";
import ActionLine from "components/actions/ActionLine";
import TyreTitle from "components/tyres/TyreTitle";
import TyreInfo from "components/tyres/TyreInfo";
import TyreDate from "components/tyres/TyreDate";
import TyreHeader from "components/tyres/TyreHeader";
import MobileSwitch from "components/mobile-toggler/MobileSwitch";
import DashboardWrapper from "layouts/DashboardWrapper";
import SideBar from "components/sideBar/SideBar";
import TyresHeader from "components/tyres/TyresHeader";
import SideBarTyresAction from "components/sideBar/SideBarTyresAction";
import CustomScroll from "react-custom-scroll";

const JournalPage = () => {
  const titles = [
    "ДАТА",
    "СОБЫТИЕ",
    "ТРАНСПОРТ",
    "ШИНА",
    "МЕСТОПОЛОЖЕНИЕ",
    "ПРОБЕГ",
    "ГЛУБИНА ПРОТЕКТОРА",
    "",
  ];

  const { getActionsPeriod } = useActions();
  const [count, setCount] = useState<number>(0);
  const { actions, loading }: ActionsState = useTypedSelector((state) => state.actions);
  const today = moment(new Date()).format("YYYY-MM-DD");

  const init = async () => {
    await getActionsPeriod(today, today);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    let sum = 0;
    if (actions) {
      for (let index = 0; index < actions.actions.length; index++) {
        sum = sum + actions.actions[index].run;
      }
    }
    setCount(sum);
  }, [actions]);
  const handlClick = () => {
    console.log("click");
  };

  return (
    <DashboardWrapper>
      <SideBar>
        <SideBarTyresAction />
      </SideBar>

      <div className="dashboard__view">
        <MobileSwitch />
        <TyresHeader />
        <div className="tyre_container">
          <div className="report">
            <CustomScroll
              allowOuterScroll={false}
              heightRelativeToParent="100%"
              className="rcs-custom-scroll--center"
            >
              <div className="report_box">
                <div>
                  <div className="report_head">
                    {loading ? (
                      <Loader />
                    ) : (
                      <>
                        <TyreTitle
                          title="Журнал"
                          subtitle="событий"
                          btnTitle="добавить событие"
                          handleClick={handlClick}
                        />
                        <TyreInfo
                          firstCount={{
                            count: actions?.actions.length,
                            title1: "количество",
                            title2: "событий",
                          }}
                          secondCount={{ count: count, title1: "общий", title2: "пробег" }}
                          showConstructor={false}
                        />
                        <TyreDate
                          from={
                            actions && actions.from
                              ? actions.from
                              : moment(new Date()).format("YYYY-MM-DD")
                          }
                          to={actions?.to}
                        />
                        {actions && actions.actions.length > 0 ? (
                          <>
                            <TyreHeader titles={titles} classes="tyre_table_box-8" />
                            {actions.actions.map((action) => (
                              <ActionLine
                                key={action.id}
                                date={action.updated_at}
                                type={action.type}
                                car_title={
                                  action.tyre && action.tyre.vehicle?.title
                                    ? action.tyre.vehicle?.title
                                    : "ТС не привязано"
                                }
                                tyre_model={action.tyre?.model}
                                tyre_brand={action.tyre?.brand}
                                run={action.run}
                                tread={action.tread}
                              />
                            ))}
                          </>
                        ) : (
                          <div className="border-text">Ничего не найдено</div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </CustomScroll>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default JournalPage;
