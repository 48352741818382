import { SettingsActionTypes, SettingsActions, SettingsState, ISettings } from "./settings_types";

const initialState: SettingsState = {
  vehicle_id: 0,
  id: 0,
  settings: {} as ISettings,
  loading: true,
  settingsLast: {} as ISettings,
  limits: [],
  isCharts: false,
};

const SettingsReducer = (state = initialState, action: SettingsActions) => {
  switch (action.type) {
    case SettingsActionTypes.GET_LIMITS:
      return {
        ...state,
        loading: false,
      };

    case SettingsActionTypes.LOADING_GET_LAST_SETTINGS:
      return {
        ...state,
        loading: action.payload,
      };

    case SettingsActionTypes.CHANGE_INPUT_LAST_SETTINGS:
      return {
        ...state,
        settingsLast: {
          ...state.settingsLast,
          [action.payload.key]: action.payload.value,
        },
      };

    case SettingsActionTypes.GET_VEHICLE_ID:
      return {
        ...state,
        vehicle_id: action.payload,
      };

    case SettingsActionTypes.GET_LAST_LIMITS:
      return {
        ...state,
        settingsLast: action.payload,
        loading: false,
      };
    case SettingsActionTypes.GET_PERIOD_LIMITS:
      return {
        ...state,
        limits: action.payload,
        loading: false,
      };
    case SettingsActionTypes.CHARTS_IS:
      return {
        ...state,
        isCharts: action.payload,
      };
    default:
      return state;
  }
};
export default SettingsReducer;
