import NoData from "components/no-data/NoData";
import SensorTabs from "components/vehicle-view/sensors/SensorsTabs";
import { defaultSettingsCar } from "helpers";
import { IDesign } from "helpers/constructorTypes";
import { useState } from "react";
import { ICar } from "redux/car/car_types";
import { IPoint } from "redux/map/map_types";
import { ISettings } from "redux/settings/settings_types";
import { TabIds, TabModes } from "types/other";
import SensorsWModel from "./SensorsWModel";
import SensorsNaked from "./SensorsNaked";
import { ReactComponent as Exclaim } from "../../../assets/images/exclaim.svg";
import moment from "moment";

const Sensors = ({
  car,
  point,
  tabMode = TabModes.NOTAB,
}: {
  car: ICar;
  point: IPoint;
  tabMode?: TabModes;
}) => {
  const [tab, setTab] = useState<string>(TabIds.Bar);
  let design: null | IDesign = null;

  const limit: ISettings = {
    bar_min: car.last_limit?.bar_min || defaultSettingsCar.notification.min_bar,
    bar_max: car.last_limit?.bar_max || defaultSettingsCar.notification.max_bar,
    temp_max: car.last_limit?.temp_max || defaultSettingsCar.notification.max_temp,
    speed_max: car.last_limit?.speed_max || defaultSettingsCar.notification.max_speed,
  };

  if (car.design)
    design = {
      modelId: car.design.model_id,
      trailerId: car.design.trailer_id,
      positions: car.design.positions,
    };

  if (point.sensors.length === 0)
    return <NoData text={false} title="Сигнал от датчиков отсутствует" />;

  return (
    <div className="sensors">
      {point.sensors_from && (
        <div className="sensors-from">
          <Exclaim />
          <span className="sensors-from__text">показания датчиков от</span>
          <span className="sensors-from__date">
            {moment(point.sensors_from).format("HH:mm DD.MM.YYYY")}
          </span>
        </div>
      )}

      {!!design && (
        <div style={{ marginBottom: "1rem" }}>
          <SensorsWModel design={design} sensors={point.sensors} limit={limit} tab={tab} />
        </div>
      )}

      {!design && (
        <div style={{ marginBottom: "1rem" }}>
          <SensorsNaked sensors={point.sensors} limit={limit} tab={tab} />
        </div>
      )}

      {tabMode !== TabModes.NOTAB && <SensorTabs onTabClick={setTab} tab={tab} tabMode={tabMode} />}
    </div>
  );
};

export default Sensors;
