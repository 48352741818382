import { ReactComponent as BackArrowMobile } from "../../assets/images/back-arrow-mobile.svg";
import { useContext } from "react";
import { MobileContext } from "context/MobileContext";

const MobileSwitch = () => {
  const { toggled, setToggled } = useContext(MobileContext);

  return (
    <div
      className="mobile_car_state"
      onClick={() => {
        setToggled(false);
      }}
    >
      <BackArrowMobile />
    </div>
  );
};

export default MobileSwitch;
