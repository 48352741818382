import { ReactNode } from "react";

const UserVehicleWrapper = ({ leftPart, rightPart, bottomPart }: UserVehicleWrapperProps) => {
  return (
    <div className="userVehicle">
      <div className="userVehicle__name">
        {leftPart}
        <div className="userVehicle__statistic">{rightPart}</div>
      </div>
      <div className="userVehicle__buttons">{bottomPart}</div>
    </div>
  );
};

export default UserVehicleWrapper;

interface UserVehicleWrapperProps {
  leftPart: ReactNode;
  rightPart: ReactNode;
  bottomPart: ReactNode;
}
