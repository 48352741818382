import { ICar } from "../../redux/car/car_types";
import { ReactComponent as Point } from "../../assets/images/point.svg";
import { IPoint } from "../../redux/map/map_types";
import ViewStatusTimestamp from "./ViewStatusTimestamp";
import VehicleMovement from "./VehicleMovement";
import { defaultSettingsCar } from "helpers";
import Sensors from "./sensors/Sensors";
import { TabModes } from "types/other";
import { Link } from "react-router-dom";

const PointStatus = ({
  car,
  point,
  minified = false,
  tabMode = TabModes.NOTAB,
}: {
  car: ICar;
  point: IPoint;
  minified?: boolean;
  tabMode?: TabModes;
}) => {
  const { lat, long, voltage, ignition, device_created_at, device_updated_at, address } = point;
  // console.log(point);

  const speedLimit = car.last_limit?.speed_max || defaultSettingsCar.notification.max_speed;

  return (
    <div className={`view-status${minified ? " view-status--no-margin" : ""}`}>
      <ViewStatusTimestamp dateFrom={device_created_at} dateTo={device_updated_at} />

      <Sensors car={car} point={point} tabMode={tabMode} />

      {!car.design && !minified && point.sensors.length !== 0 && (
        <Link to={`/constructor/${car.id}`} className="gotoconstructor">
          Выбрать модель тс
        </Link>
      )}

      <div className="view-status__table">
        <VehicleMovement point={point} speedLimit={speedLimit} dontShowLostSignal={minified} />

        <div className="view-status__column">
          <div className="view-status__table-title">Зажигание</div>
          <div className="view-status__table-value">{ignition ? "ВКЛ" : "ВЫКЛ"}</div>
        </div>
        <div className="view-status__column">
          <div className="view-status__table-title">Напряжение</div>
          <div className="view-status__table-value">{voltage} В</div>
        </div>
      </div>

      <div className="view-status__address-container">
        <Point className="view-status__address-img" />

        <span className="view-status__coords">
          {lat}, {long}
        </span>

        <span className="view-status__address">{address}</span>
      </div>
    </div>
  );
};

export default PointStatus;
