import { PacketItemTypes } from "../Ping";
import MainParams from "./MainParams";
import Tyres from "./Tyres";
import WheelList from "./WheelList";

const Item = ({ item, showHex }: ItemTypes) => {
  return (
    <div className="console__item" key={`${item.date}-${item.id}`}>
      <div className="console__item-header">
        <div className="console__item-date">{item.date}</div>

        <div className="console__item-type">
          {item.text} {item.id ? <strong>{item.id}</strong> : ""}
        </div>
      </div>

      {item.pack && <MainParams {...item.pack} />}

      {showHex && item.pack && item.pack.hex && (
        <div className="console__item-hex">{item.pack.hex}</div>
      )}

      {item.pack && <WheelList tires={item.pack.tires} />}
      {item.pack && item.pack.hex && item.pack.hex.length === 364 && <Tyres hex={item.pack.hex} />}
    </div>
  );
};

export default Item;

interface ItemTypes {
  item: PacketItemTypes;
  showHex: boolean;
}
