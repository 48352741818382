import EventsTime from "./EventsTime";
import EventDayHead from "./EventDayHead";

const EventDay = ({ day, date }: { day: any; date: string }) => {
  if (!!day.events && day.events.length > 0) {
    return (
      <div className="report_day">
        <EventDayHead day={day} date={date} />
        {day.events.map((event: any, index: any) => (
          <EventsTime key={index} event={event} />
        ))}
      </div>
    );
  }

  return (
    <div className="report_day">
      <EventDayHead day={day} date={date} />
      <div className="report_day">
        <div className="report_event--not">Отклонений от заданных параметров нет</div>
      </div>
    </div>
  );
};

export default EventDay;
