import { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useActions } from "../hooks/useAction";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { Outlet } from "react-router-dom";
import SideBarCarNavigation from "../components/sideBar/SideBarCarNavigation";
import { ReactComponent as Settings } from "../assets/images/settings.svg";
import SkeletonLoading from "../components/skeleton-loading/SkeletonLoading";
import VehicleHeader from "../components/vehicle-view/VehicleHeader";
import moment from "moment";
import { CarState } from "redux/car/car_types";
import CarMenu from "components/cars/car/CarMenu";
import { MobileContext } from "context/MobileContext";
import useWindowSize from "hooks/useWindowSize";
import { MEDIA } from "constants/media";
import GoBackBtn from "components/ui/Buttons/GoBack";

const ViewCarLayout = () => {
  const { vehicle_id } = useParams<{ vehicle_id?: string }>();
  const { getCar, resetCar, getCalendar } = useActions();

  const { loading, car }: CarState = useTypedSelector((state) => state.car);

  const yearAgo = moment().subtract(1, "months").format("YYYY-MM-DD");

  const init = async () => {
    if (!vehicle_id) return;

    getCar(+vehicle_id);
  };

  useEffect(() => {
    init();
  }, [vehicle_id]);

  useEffect(() => {
    return () => {
      resetCar();
    };
  }, []);

  useEffect(() => {
    if (car?.device_id) {
      getCalendar(car.device_id, yearAgo);
    }
  }, [car]);

  const { toggled, setToggled } = useContext(MobileContext);
  const size = useWindowSize();

  const handlerMobileView = () => {
    if (size.width < MEDIA.mobile) {
      setToggled(true);
    }
  };
  return (
    <div className="view-container">
      <div className="view__header">
        <div className="view-goback">
          <GoBackBtn text="Назад" to="/" />
          <div
            id="mobile_btn_map"
            className=" goback"
            onClick={() => {
              handlerMobileView();
            }}
          >
            <span>Карта</span>
          </div>

          {car && (
            <CarMenu
              car={car}
              icon={
                <div className="view-settings">
                  <Settings />
                </div>
              }
              inner={true}
            />
          )}
        </div>

        {(!car || loading) && <SkeletonLoading height="220px" />}

        {car !== null && !loading && (
          <>
            <VehicleHeader car={car} />

            <SideBarCarNavigation />
          </>
        )}
      </div>

      <div className="view__content">{car?.id && !loading && <Outlet />}</div>
    </div>
  );
};

export default ViewCarLayout;
