import { Outlet, useNavigate } from "react-router-dom";
import HomePageHeader from "../components/header/HomePageHeader";

// layout для страниц входа и регистрации
const PublicLayout = () => {
  const navigate = useNavigate();

  return (
    <div className="layout layout--public">
      {/* <HomePageHeader /> */}
      <Outlet />
    </div>
  );
};

export default PublicLayout;
