import { lazy, Suspense, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useActions } from "../hooks/useAction";
import { useParams } from "react-router-dom";
import Loader from "components/loader/Loader";
import DashboardWrapper from "./DashboardWrapper";
const SideBarConstructor = lazy(() => import("../components/sideBar/SideBarConstructor"));

const ConstructorLayout = () => {
  const { vehicle_id } = useParams<{ vehicle_id?: string }>();
  const { getCar, resetConstructor } = useActions();

  const init = async () => {
    if (vehicle_id) {
      getCar(+vehicle_id);
    }
  };

  useEffect(() => {
    init();
  }, [vehicle_id]);

  useEffect(() => {
    return () => {
      resetConstructor();
    };
  }, []);

  return (
    <DashboardWrapper>
      <Suspense fallback={<Loader />}>
        <SideBarConstructor />
        <Outlet />
      </Suspense>
    </DashboardWrapper>
  );
};

export default ConstructorLayout;
