import RSC from "react-scrollbars-custom";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { ReportState } from "../../../redux/reports/reports_types";
import Sensor from "./Sensor";

const SensorsData = () => {
  const { report }: ReportState = useTypedSelector((state) => state.reports);

  return (
    <div className="report_sensors">
      <div className="report_title ">Последние показания датчиков</div>
      <div className="report_sensors-box">
        <div className="report_sensors-info">
          <div className="sensors_row">
            Давление <span className="report-thin">(BAR)</span>
          </div>
          <div className="sensors_row">
            Температура <span className="report-thin">(°C)</span>
          </div>
        </div>
        <RSC
          id="RSC-Example"
          style={{ width: "100%", height: "100%", display: "flex" }}
          noScrollY={true}
          thumbXProps={{
            renderer: (props) => {
              const { style, elementRef, ...restProps } = props;
              return (
                <div
                  {...restProps}
                  ref={elementRef}
                  className="ThUmBX"
                  style={{ ...style, background: "#4F6288" }}
                />
              );
            },
          }}
          trackXProps={{
            renderer: (props) => {
              const { style, elementRef, ...restProps } = props;
              return (
                <div
                  {...restProps}
                  ref={elementRef}
                  className="TrackX"
                  style={{ ...style, height: "5px" }}
                />
              );
            },
          }}
        >
          <div className="report_sensors-date">
            {report.last_point?.sensors &&
              report.last_point?.sensors.map((sensor: any, index: any) => {
                return <Sensor sensor={sensor} key={index} index={index} />;
              })}
          </div>
        </RSC>
      </div>
    </div>
  );
};

export default SensorsData;
