import { ReactNode } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import SocketListener from "components/socket/SocketListener";
import { MobileContextProvider } from "context/MobileContext";
import { Toaster } from "react-hot-toast";

const Providers = ({ children }: { children: ReactNode }) => {
  return (
    <Provider store={store}>
      <Toaster />
      <MobileContextProvider>
        <SocketListener />
        <BrowserRouter>{children}</BrowserRouter>
      </MobileContextProvider>
    </Provider>
  );
};

export default Providers;
