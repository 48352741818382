// import { combineReducers } from "redux";
import { createBrowserHistory } from "history";
import { configureStore, combineReducers } from "@reduxjs/toolkit";

import UserReducer from "./user/user_reducer";
import LoginReducer from "./login/login_reducer";
import CarsReducer from "./cars/cars_reducer";
import CarReducer from "./car/car_reducer";
import ModalsReducer from "./modals/modals_reducer";
import SettingsReducer from "./settings/settings_reducer";
import NotificationsReducer from "./notifications/notifications_reducer";
import CarsModalReducer from "./cars_modal/cars_modal_reducer";
import { devicesReducer } from "./admin/devices/DevicesSlice";
import MapReducer from "./map/map_reducer";
import EventsReducer from "./events/events_reducer";
import PointsReducer from "./points/points_reducer";
import ReportsReducer from "./reports/reports_reducer";
import ConstructorReducer from "./constructor/constructor_reducer";
// import {DevicesApi} from "./admin/devices/DevicesApi";
// import {UsersApi} from './admin/users/UsersApi';
import { GlobalApi } from "../api/GlobalApi";
import { folderReducer } from "./folder/FolderSlice";
import { usersReducer } from "./admin/users/UsersSlice";
import { profilesReducer } from "./admin/profiles/ProfilesSlice";

import { userReducer } from "./user/UserSlice";
import UsersFilterReducer from "./users_filter/users_filter_reducer";
import DevicesFilterReducer from "./device_filter/device_filter_reducer";
import TyresReducer from "./tyres/tyres_reducer";
import ActionReducer from "./actions/actions_reducer";

export const history = createBrowserHistory();

const combineReducer = combineReducers({
  user: UserReducer,
  userMe: userReducer,
  login: LoginReducer,
  cars: CarsReducer,
  car: CarReducer,
  map: MapReducer,
  modals: ModalsReducer,
  settings: SettingsReducer,
  notifications: NotificationsReducer,
  carsModal: CarsModalReducer,
  folder: folderReducer,
  devices: devicesReducer,
  users: usersReducer,
  profiles: profilesReducer,
  [GlobalApi.reducerPath]: GlobalApi.reducer,
  // [DevicesApi.reducerPath] : DevicesApi.reducer,
  // [UsersApi.reducerPath] : UsersApi.reducer,
  events: EventsReducer,
  points: PointsReducer,
  reports: ReportsReducer,
  design: ConstructorReducer,
  userFilter: UsersFilterReducer,
  deviceFilter: DevicesFilterReducer,
  tyres: TyresReducer,
  actions: ActionReducer,
});

const store = configureStore({
  reducer: combineReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(GlobalApi.middleware),
});

export type RootState = ReturnType<typeof combineReducer>;
export type AppDispatch = typeof store.dispatch;
export default store;
