import api from "api";
import { AxiosResponse } from "axios";
import Loader from "components/loader/Loader";
import { useActions } from "hooks/useAction";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const UserVehiclePoinstCount = ({ device_id }: { device_id: number }) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isShow, setisShow] = useState(false);

  const { toggleModalConfirm } = useActions();

  const getCount = async (device_id: number) => {
    await api
      .getPointsCount(device_id)
      .then((res: AxiosResponse) => {
        setCount(res.data);
        setLoading(false);
      })
      .catch((error: any) => {
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  const openConfirm = () => {
    toggleModalConfirm({
      toggle: true,
      title: "Подтвердите действие",
      content: `Вы уверены, что хотите удалить записи в количестве ${count}?`,
      onConfirm: confirmDelete,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onCancel: () => {},
    });
  };

  const confirmDelete = (): void => {
    handleDelete();
  };

  const handleDelete = async () => {
    if (count === 0) {
      toast.error("Записи не обнаружены");
      return;
    }
    await api
      .deletePoints(device_id)
      .then((response: AxiosResponse) => {
        toast.success(response.data.message);
        setLoading(true);
        getCount(device_id);
      })
      .catch((e) => {
        toast.error(e.data.message);
      });
  };

  const show = () => {
    setisShow(true);
    getCount(device_id);
  };

  return (
    <>
      {isShow ? (
        <div className="flex b-grey items-center">
          <span>Кол-во записей: {!loading ? count : <Loader />}</span>
          <button
            className="button button--middle-grey button--margin"
            disabled={count === 0 ? true : false}
            onClick={openConfirm}
          >
            Очистить
          </button>
        </div>
      ) : (
        <button className="button button--middle-grey button--margin" onClick={show}>
          Показать кол-во записей/очистить
        </button>
      )}
    </>
  );
};

export default UserVehiclePoinstCount;
