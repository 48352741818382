import { memo } from "react";

const Highlighter = ({ text, filter }: { text: string; filter: string }) => {
  const indexStart = text.toUpperCase().indexOf(filter.toUpperCase());
  const indexEnd = indexStart + filter.length;

  if (!filter || indexStart < 0) return <>{text}</>;

  const before = indexStart > 0 ? text.substring(0, indexStart) : "";
  const filtered = text.substring(indexStart, indexEnd);
  const after = text.substring(indexEnd, text.length);

  return (
    <>
      {before}
      <span className="highlighter">{filtered}</span>
      {after}
    </>
  );
};

export default memo(Highlighter);
