import { STEPS } from "../../helpers/constructorTypes";

export interface ConstructorState {
  step: STEPS;
  categoryId: number;
  modelId: null | number;
  trailerId: null | number;
  positions: number[];
}

export interface IConstructor {
  id: number;
  vehicle_id: number;
  model_id: number;
  trailer_id: null | number;
  positions: number[];
}

export enum ConstructorActionTypes {
  CHANGE_STEP = "CHANGE_STEP",
  CHANGE_CATEGORY = "CHANGE_CATEGORY",
  CHANGE_MODEL = "CHANGE_MODEL",
  CHANGE_TRAILER = "CHANGE_TRAILER",
  RESET_CONSTRUCTOR = "RESET_CONSTRUCTOR",
  CHANGE_POSITION = "CHANGE_POSITION",
  LOAD_CONSTRUCTOR = "LOAD_CONSTRUCTOR",
}

interface ChangeStep {
  type: ConstructorActionTypes.CHANGE_STEP;
  payload: string;
}

interface ChangeCategory {
  type: ConstructorActionTypes.CHANGE_CATEGORY;
  payload: number;
}

interface ChangeModel {
  type: ConstructorActionTypes.CHANGE_MODEL;
  payload: null | number;
}

interface ChangeTrailer {
  type: ConstructorActionTypes.CHANGE_TRAILER;
  payload: null | number;
}

interface ResetConstructor {
  type: ConstructorActionTypes.RESET_CONSTRUCTOR;
}

interface ChangePosition {
  type: ConstructorActionTypes.CHANGE_POSITION;
  payload: number[];
}

interface LoadConstructor {
  type: ConstructorActionTypes.LOAD_CONSTRUCTOR;
  payload: {
    modelId: number;
    trailerId: null | number;
    positions: number[];
  } | null;
}

export type ConstructorActions =
  | ChangeStep
  | ChangeCategory
  | ChangeModel
  | ChangeTrailer
  | ResetConstructor
  | ChangePosition
  | LoadConstructor;
