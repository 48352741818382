import api from "api";
import moment from "moment";
import { useState } from "react";
import { IEvent } from "redux/events/events_types";
import { IPoint } from "redux/map/map_types";
import PointMove from "./components/PointMove";
import PointStop from "./components/PointStop";
import { time } from "./pointsHelper";

const Point = (stop: PointMoveType | PointStopType) => {
  let start = "";
  let end = "";
  let period = "";
  let isDate = true;
  const [address, setAddress] = useState<string | null>(null);
  const [pointId, setPointId] = useState<any>();
  const [coordinate, setCoordinat] = useState<any>([]);
  const [adressNot, setAdressNot] = useState<any>();
  const fetchAddresses = async (point: IPoint) => {
    let result: any;

    try {
      if (point.lat !== 0 && point.long !== 0) {
        result = await api.getAddress({ lat: point.lat, long: point.long });

        setPointId(point.id);
        setCoordinat([point.lat, point.long]);
        setAddress(result.data.address);
        setAdressNot(point);
        // console.log(point);
      }
    } catch (error: any) {
      console.log(error.response.status);
      setAddress("Не определен");
    }

    if (!result) {
      setCoordinat([point.lat, point.long]);
      setAddress(`Не определен`);
    }
  };

  let date = "";

  if (stop.type == "stop" && stop.data) {
    start = moment(stop.data.device_created_at).utcOffset("+03:00").format("HH:mm");
    end = moment(stop.data.device_updated_at).utcOffset("+03:00").format("HH:mm");
    period = time(
      Date.parse(stop.data.device_updated_at) - Date.parse(stop.data.device_created_at)
    );

    isDate = stop.data.device_created_at.slice(0, 10) == stop.data.device_updated_at.slice(0, 10);

    if (address == null) {
      fetchAddresses(stop.data);
    }

    date = stop.data.device_created_at.slice(0, 10) + " " + start;
  }

  // console.log("coordinate>>", coordinate, "adressNot>>", adressNot);
  if (stop.type === "stop") {
    return (
      <PointStop
        date={date}
        start={start}
        end={end}
        isDate={isDate}
        address={address}
        pointId={pointId}
        coordinate={coordinate}
        period={period}
      />
    );
  }

  if (stop.type === "move") return <PointMove {...stop} />;

  return <></>;
};

export default Point;

export interface PointMoveType {
  data: PointMoveData;
  type: "move";
  events: IEvent[];
}

export interface PointStopType {
  data: IPoint;
  type: "stop";
}

export interface PointMoveData {
  id: number | null;
  time_finish: string;
  time_start: string;
  run_start: number;
  run_finish: number;
  run_total: number;
}
