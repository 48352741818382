import CustomScroll from "react-custom-scroll";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { PropsEvent, ReportState } from "../../redux/reports/reports_types";
import ReportHeader from "./report-components/ReportHeader";
import ReportEventsTotal from "./report-components/ReportEventsTotal";
import { useLocation } from "react-router-dom";
import { useActions } from "../../hooks/useAction";
import { useEffect } from "react";

const Report = () => {
  const { report, loading }: ReportState = useTypedSelector((state) => state.reports);
  const location = useLocation();
  const { toggleIsReport } = useActions();
  useEffect(() => {
    if (location.pathname.slice(-6) != "report") toggleIsReport(false);
  }, [location]);
  // события сразу за все дни (чтобы посчитать)
  const eventsTotal: PropsEvent[] = [];
  if (report.days != undefined) {
    Object.keys(report.days).forEach((date: any) => {
      report.days[date].events.forEach((event: PropsEvent) => eventsTotal.push(event));
    });
  }

  // if (loading) {
  //   return (
  //     <div className="aside">
  //       <div className="report">
  //         <SkeletonLoading height="100vh" width="100vw" theme={true} />
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="aside">
      <div className="report">
        <CustomScroll
          allowOuterScroll={false}
          heightRelativeToParent="100%"
          className="rcs-custom-scroll--center"
        >
          <div className="report_box">
            <ReportHeader report={report} eventsCount={eventsTotal.length} />
            <ReportEventsTotal report={report} events={eventsTotal} />
          </div>
        </CustomScroll>
      </div>
    </div>
  );
};

export default Report;
