import { ISettings } from "redux/settings/settings_types";
import { IConstructor } from "../constructor/constructor_types";
import { IPoint } from "../map/map_types";

export interface CarState {
  car: ICar | null;
  loading: boolean;
  car_extended: ICar | null;
  loading_extended: boolean;
  last_point: null | IPoint;
  last_limit?: null | ISettings;
  loading_point: boolean;
  calendarData: null | CalendarType;
}

export interface CalendarType {
  [key: string]: {
    point: boolean;
    event: boolean;
  };
}

export interface ICar extends Record<string, any> {
  id: number;
  code: string;
  car_number: string;
  region: string;
  title: string;
  comment: string;
  device_id: number;
  folder_id: number;
  user_id: number;
  archived: string;
  last_point?: null | IPoint;
  design?: null | IConstructor;
  last_limit?: null | ISettings;
  calendarData: CalendarType;
  is_sensors_ignored?: boolean;
}

export enum CarActionTypes {
  GET_CAR = "GET_CAR",
  CAR_LOADING = "CAR_LOADING",
  GET_CAR_EXTENDED = "GET_CAR_EXTENDED",
  LOADING_EXTENDED = "LOADING_EXTENDED",
  CHANGE_INPUT_CAR_EXTENDED = "CHANGE_INPUT_CAR_EXTENDED",
  GET_LAST_POINT = "GET_LAST_POINT",
  RESET_CAR_EXTENDED = "RESET_CAR_EXTENDED",
  RESET_CAR = "RESET_CAR",
  CAR_UPDATE_LAST_POINT_ADDRESS = "CAR_UPDATE_LAST_POINT_ADDRESS",
  CAR_CHANGE_CONSTRUCTOR = "CAR_CHANGE_CONSTRUCTOR",
  CAR_CHANGE_LIMIT = "CAR_CHANGE_LIMIT",
  GET_CALENDAR = "GET_CALENDAR",
}

interface GetCar {
  type: CarActionTypes.GET_CAR;
  payload: ICar;
}

interface CarLoading {
  type: CarActionTypes.CAR_LOADING;
  payload: boolean;
}

interface GetCarExtended {
  type: CarActionTypes.GET_CAR_EXTENDED;
  payload: ICar;
}

interface LoadingExtended {
  type: CarActionTypes.LOADING_EXTENDED;
  payload: boolean;
}

interface ChangeInputCarExtended {
  type: CarActionTypes.CHANGE_INPUT_CAR_EXTENDED;
  payload: { key: string; value: string };
}

interface GetLastPointServer {
  type: CarActionTypes.GET_LAST_POINT;
  payload: IPoint;
}

interface ResetCarExtended {
  type: CarActionTypes.RESET_CAR_EXTENDED;
}

interface ResetCar {
  type: CarActionTypes.RESET_CAR;
}

interface CarUpdateLastPointAddress {
  type: CarActionTypes.CAR_UPDATE_LAST_POINT_ADDRESS;
  payload: string;
}

interface ChangeCarConstructor {
  type: CarActionTypes.CAR_CHANGE_CONSTRUCTOR;
  payload: IConstructor;
}

interface ChangeCarLimit {
  type: CarActionTypes.CAR_CHANGE_LIMIT;
  payload: ISettings;
}

interface GetCalendar {
  type: CarActionTypes.GET_CALENDAR;
  // eslint-disable-next-line @typescript-eslint/ban-types
  payload: {};
}

export type CarActions =
  | GetCar
  | CarLoading
  | GetCarExtended
  | LoadingExtended
  | ChangeInputCarExtended
  | GetLastPointServer
  | ResetCarExtended
  | ResetCar
  | CarUpdateLastPointAddress
  | ChangeCarConstructor
  | GetCalendar
  | ChangeCarLimit;
