import { IPoint } from "../../redux/map/map_types";
import { Declination, formatMinutes } from "../../helpers";
import { ReactComponent as CarDrive } from "../../assets/images/car_drive--bordered.svg";
import { ReactComponent as CarPurple } from "../../assets/images/car_purple--bordered.svg";
import { ReactComponent as CarSleep } from "../../assets/images/car_sleep--bordered.svg";
import { ReactComponent as CarError } from "../../assets/images/car_error--bordered.svg";

const VehicleMovement = ({
  point,
  dontShowLostSignal = false,
  speedLimit,
}: {
  point: IPoint;
  dontShowLostSignal?: boolean;
  speedLimit: number;
}) => {
  const { speed, device_created_at, device_updated_at } = point;
  let overspeed: boolean = speed > speedLimit;
  // по умолчанию считаем, что машина едет
  let value = speed || 3;
  let units = "км/ч";
  let text = "В ДВИЖЕНИИ";
  let icon = <CarDrive className="view-status__img" />;

  // остановка/стоянка
  if (device_created_at !== device_updated_at && speed <= 3) {
    const { formattedDiff, words, minutes } = formatMinutes(device_created_at, device_updated_at);

    if (minutes < 1) {
      text = "ОСТАНОВКА";
    } else {
      text = "СТОЯНКА";
    }

    value = formattedDiff;
    units = Declination(formattedDiff, words);
    icon = <CarSleep className="view-status__img" />;
    overspeed = false;
  }

  // отсутствие связи (на карте не показываем)
  if (!dontShowLostSignal) {
    const { formattedDiff, words, minutes } = formatMinutes(device_updated_at);

    if (minutes > 30) {
      value = formattedDiff;
      units = Declination(formattedDiff, words);
      text = "НЕТ СВЯЗИ С ТС";
      icon = <CarError className="view-status__img" />;
      overspeed = false;
    }
  }

  const speedClasses = ["view-status__table-speed"];
  if (overspeed) {
    icon = <CarPurple className="view-status__img" />;
  }

  return (
    <div className="view-status__column view-status__column--with-img">
      <div className="view-status__img-container">{icon}</div>
      <div className="view-status__info">
        <div className={speedClasses.join(" ")}>
          {value} <span>{units}</span>
        </div>
        <div className="view-status__table-value">{text}</div>
      </div>
    </div>
  );
};

export default VehicleMovement;
