import Modal from "react-bootstrap/Modal";

interface IModalRemove {
  title: string;
  callbackYes: () => any;
  callbackNo: () => any;
  show: boolean;
}

const ModalRemove = ({ title, callbackYes, callbackNo, show }: IModalRemove) => {
  const handleClose = () => {
    callbackNo();
  };

  return (
    <Modal centered onHide={handleClose} show={show} className="modal-device">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <button onClick={callbackNo} className="btn a-device__btn a-device-btn-delete">
          Отмена
        </button>
        <button onClick={callbackYes} className="btn a-device__btn a-device-btn-edit" type="button">
          Да
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalRemove;
