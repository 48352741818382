import React, { useEffect, useState } from "react";
import { useActions } from "../../hooks/useAction";
import SelectInput from "components/select-input/SelectInput";
import DateTemplates from "components/date-templates/DateTemplate";
import CustomDatePicker from "components/datepicker/CustomDatePicker";
import moment from "moment";
import InputBlock from "./components/InputsBlock";
import toast from "react-hot-toast";

const SideBarTyresActionWithReason = () => {
  const typeAction = location.pathname.split("/").reverse()[0];

  const [from, setFrom] = useState(moment().format("YYYY-MM-DD"));
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));

  const [fromError, setFromError] = useState("");
  const [toError, setToError] = useState("");

  const clearErrors = () => {
    setFromError("");
    setToError("");
  };

  useEffect(() => {
    setFrom(moment().format("YYYY-MM-DD"));
    setTo(moment().format("YYYY-MM-DD"));
  }, [typeAction]);

  const onDatesChange = (from: string, to: string) => {
    setFrom(from);
    setTo(to);
  };

  const { postTyresByPeriod } = useActions();

  const handlerReport = async () => {
    try {
      await postTyresByPeriod(from, to, typeAction);
    } catch (error: any) {
      console.log(error);

      if (error?.response?.data?.errors) {
        if (error?.response?.data?.errors?.from) {
          setFromError(error.response.data.errors.from[0]);
        }
        if (error?.response?.data?.errors?.to) {
          setToError(error.response.data.errors.to[0]);
        }
        if (error?.response?.data?.errors?.vehicles) {
          toast.error(error.response.data.errors.vehicles[0]);
        }
      } else if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <div className="view-container sidebar-grey">
      <div className="view__inner sidebar-white">
        {/* { type && (<><h4 className="point__title">Причина</h4>
        <SelectInput
          value={type}
          options={actionTypes}
          onChange={onChangeType}
          className="react-select--mini react-select--margin "
          placeholder=""
        /></>)} */}
        <h4 className="point__title">Выбор периода</h4>
        <div className="reports reports--charts reports--charts--border margin-bottom-20">
          <DateTemplates onDatesChange={onDatesChange} from={from} to={to} />

          <div className="input-and-button input-and-button--border-top">
            <div className="input-area w50-5 m0">
              <label className="label">Начало периода</label>
              <CustomDatePicker
                onChange={(date) => {
                  clearErrors();
                  setFrom(date);
                }}
                date={from}
                isError={!!fromError}
              />
              {!!fromError && <div className="input-error-message">{fromError}</div>}
            </div>

            <div className="input-area w50-5 m0">
              <label className="label">Конец периода</label>
              <CustomDatePicker
                onChange={(date) => {
                  clearErrors();
                  setTo(date);
                }}
                date={to}
                isError={!!toError}
              />
              {!!toError && <div className="input-error-message">{toError}</div>}
            </div>
            <button
              className="button button--mint button--with-label w100 m0"
              onClick={handlerReport}
            >
              Показать
            </button>
          </div>
        </div>
        {/* {!loading && actions && actions.actions.length > 0 && <InputBlock/>} */}
      </div>
    </div>
  );
};

export default SideBarTyresActionWithReason;
