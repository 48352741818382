const Wheel = ({ pressure, temperature, index }: WheelTypes) => {
  return (
    <span className="console__item-wheel" key={index}>
      <strong>{index + 1}</strong>
      {pressure.toFixed(2)} bar, {temperature} °C
    </span>
  );
};

export default Wheel;

interface WheelTypes {
  pressure: number;
  temperature: number;
  index: number;
}
