export const FooterEdit = ({
  openConfirm,
  handleSave,
}: {
  openConfirm: () => void;
  handleSave: () => void;
}) => (
  <div className="modal-folder__button-box">
    <button
      className="modal__button modal__button--white modal__button--red"
      onClick={() => openConfirm()}
    >
      Удалить список
    </button>
    <button type="button" className="modal__button " onClick={() => handleSave()}>
      Применить
    </button>
  </div>
);

export const FooterNew = ({ save }: { save: () => void }) => (
  <div className="modal-folder__button-box--end">
    <button type="button" className="modal__button " onClick={() => save()}>
      Сохранить
    </button>
  </div>
);

export const InputEdit = ({
  title,
  errors,
  setTitle,
  save,
}: {
  title: string;
  errors: string[];
  setTitle: (title: string) => void;
  save: () => void;
}) => (
  <div className="modal-content__body">
    <input
      type="text"
      name="title"
      value={title}
      onChange={(e) => setTitle(e.target.value)}
      className={
        errors.length == 0 ? "modal-input__grow" : "modal-input__grow modal-input__grow--error"
      }
    />
    <button type="button" className="modal__button " onClick={() => save()}>
      Сохранить
    </button>
  </div>
);

export const catchErrors = (error: any, setError: any, toast: any) => {
  if (error?.data?.errors) {
    if (error?.data?.errors?.title) {
      setError(error?.data?.errors?.title);
    }
    if (error?.data?.errors?.vehicles) {
      toast.error(error?.data.errors.vehicles[0]);
    }
  } else if (error?.data?.message) {
    toast.error(error.data.message);
  } else {
    toast.error(error.message);
  }
};
