import { ActionProps, AddingProps, MovingProps } from "redux/actions/actions_types";
import { ICar } from "redux/car/car_types";

export interface TyresState {
  tyres: TyreProps[] | null;
  tyres_by_car: Omit<TyreProps, "actions | last_move">[] | null;
  last_tyre: TyreProps | null;
  tyre_edit: TyreProps[] | null;
  loading_tyres: boolean;
  tyre: TyreProps | null;
  tyres_by_period: TyrePropsByPeriod | null;
}

export interface TyreProps {
  id: number;
  vehicle_id: number;
  user_id: number;
  brand: string;
  model: string;
  size: string;
  price: number;
  tread_start: number;
  dot: string;
  production_date: string;
  used: boolean;
  place: number;
  status: boolean;
  run_start: number;
  updated_at: string;
  action: ActionProps;
  last_move: LastMoveProps;
  vehicle?: ICar;
  last_action?: ActionProps;
}

export interface TyrePropsByPeriod {
  tyres: TyreProps[];
  from: string;
  to: string;
}

export interface LastMoveProps {
  id: number;
  tyre_id: number;
  type: "adding" | "moving";
  run: number;
  tread: number;
  updated_at: string;
  creates_at: string;
  adding: AddingProps | null;
  moving: MovingProps | null;
}

export enum TyresActionTypes {
  GET_TYRES = "GET_TYRES",
  GET_CAR_TYRES = "GET_CAR_TYRES",
  TYRES_LOADING = "TYRES_LOADING",
  TYRES_LAST = "TYRES_LAST",
  TYRES_RESET = "TYRES_RESET",
  TYRE_EDIT = "TYRE_EDIT",
  GET_TYRE = "GET_TYRE",
  GET_LAST_ACTION = "GET_LAST_ACTION",
  GET_TYRES_BY_PERIOD = "GET_TYRES_BY_PERIOD",
}

interface GetTyres {
  type: TyresActionTypes.GET_TYRES;
  payload: TyreProps[] | Omit<TyreProps, "actions, last_move">;
}

interface GetCarTyres {
  type: TyresActionTypes.GET_CAR_TYRES;
  payload: TyreProps[];
}

interface TyresLoading {
  type: TyresActionTypes.TYRES_LOADING;
  payload: boolean;
}

interface TyresLast {
  type: TyresActionTypes.TYRES_LAST;
  payload: boolean;
}

interface TyresReset {
  type: TyresActionTypes.TYRES_RESET;
}

interface GetTyre {
  type: TyresActionTypes.GET_TYRE;
  payload: TyreProps;
}

interface GetTyresByPeriod {
  type: TyresActionTypes.GET_TYRES_BY_PERIOD;
  payload: TyrePropsByPeriod;
}

interface TyresEdit {
  type: TyresActionTypes.TYRE_EDIT;
  payload: TyreProps;
}

export type TyresActions =
  | GetTyres
  | GetCarTyres
  | TyresLoading
  | TyresLast
  | TyresReset
  | GetTyre
  | TyresEdit
  | GetTyresByPeriod;
