import { ICarsModalState, CarsModalActionTypes, CarsModalActions } from "./cars_modal_types";

const initialState: ICarsModalState = {
  data: [],
  filter: "",
};

const CarsModalReducer = (state = initialState, action: CarsModalActions) => {
  switch (action.type) {
    case CarsModalActionTypes.GET_CARS_MODAL:
      return {
        ...state,
        data: action.payload,
      };
    case CarsModalActionTypes.CARS_MODAL_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state;
  }
};

export default CarsModalReducer;
