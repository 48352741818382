import { useEffect, useState } from "react";
import SelectInput from "../../components/select-input/SelectInput";
import CustomScroll from "react-custom-scroll";
import ReportsCharts from "../../components/reports/ReportsCharts";
import ReportsRoute from "../../components/reports/ReportsRoute";
import ReportsReport from "../../components/reports/ReportsReport";
import { PointCheckProvider } from "components/reports/context/PointCheckContext";
import { CarState } from "redux/car/car_types";
import { useTypedSelector } from "hooks/useTypedSelector";
import { IPoint } from "redux/map/map_types";
import NoData from "components/no-data/NoData";
import SkeletonLoading from "components/skeleton-loading/SkeletonLoading";
import { useParams } from "react-router-dom";
import { useActions } from "hooks/useAction";
import toast from "react-hot-toast";
import useWindowSize from "hooks/useWindowSize";
import { MEDIA } from "constants/media";

const ViewCarReportPage = () => {
  const [type, setType] = useState("ROUTE");
  const { last_point, car, loading_point }: CarState = useTypedSelector((state) => state.car);
  const point: null | IPoint = last_point;

  const { vehicle_id } = useParams<{ vehicle_id?: string }>();
  const { getLastPointServer } = useActions();
  const [options, setOptions] = useState<Array<any>>([]);
  const size = useWindowSize();

  useEffect(() => {
    if (size.width > MEDIA.desctop) {
      setOptions([
        { value: "ROUTE", label: "Маршрут и стоянки" },
        { value: "REPORT", label: "Отчет" },
        { value: "CHARTS", label: "Графики" },
      ]);
    } else {
      setOptions([
        { value: "ROUTE", label: "Маршрут и стоянки" },
        { value: "REPORT", label: "Отчет" },
      ]);
    }
    if (size.width < MEDIA.desctop && type === "CHARTS") {
      setType("ROUTE");
    }
  }, [size.width]);

  const onChangeType = (value: string) => {
    setType(value);
  };

  useEffect(() => {
    if (!car?.device_id || !vehicle_id || car?.id !== +vehicle_id) return;
    init();
  }, [car?.device_id]);

  const init = async () => {
    if (!car) return;

    const { status, data }: any = await getLastPointServer(car.device_id, false);

    if (status !== 200 && status !== 201) toast.error(data.message);
  };

  if (loading_point || car === null)
    return (
      <div className="view__inner">
        <SkeletonLoading height="200px" />
      </div>
    );

  if (!point) return <NoData />;
  return (
    <CustomScroll allowOuterScroll={true} heightRelativeToParent="calc(100%)">
      <div className="view__inner">
        <h4 className="point__title">Вид</h4>
        <SelectInput
          value={type}
          options={options}
          onChange={onChangeType}
          className="react-select--mini react-select--margin"
        />

        {type == "ROUTE" && (
          <PointCheckProvider>
            <ReportsRoute />
          </PointCheckProvider>
        )}
        {type == "REPORT" && <ReportsReport />}
        {type == "CHARTS" && <ReportsCharts />}
      </div>
    </CustomScroll>
  );
};

export default ViewCarReportPage;
