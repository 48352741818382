import { FC } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { renderWeekDay } from "../../helpers";
import PickerIcon from "../../assets/svgElements/PickerIcon";
import { useTypedSelector } from "hooks/useTypedSelector";
import { CarState } from "redux/car/car_types";

interface ICustomDatePicker {
  date: string;
  isError?: boolean;
  onChange?: (date: string) => void;
  classes?: string;
}

const CustomDatePicker: FC<ICustomDatePicker> = ({ date, isError = false, onChange, classes }) => {
  const onChangeDate = (date: Date) => {
    if (onChange) onChange(moment(date).format("YYYY-MM-DD"));
  };

  const { calendarData }: CarState = useTypedSelector((state) => state.car);

  const dayClass = (date: Date) => {
    const dateFormat = moment(date).format("YYYY-MM-DD");
    if (calendarData) {
      const keys = Object.keys(calendarData);
      if (keys.includes(dateFormat)) {
        if (calendarData[dateFormat].event) {
          return "have_event have_point";
        } else return "have_point";
      } else return "";
    } else return "";
  };

  return (
    <div className={classes ? `${classes} custom-datepicker` : "custom-datepicker"}>
      <DatePicker
        dateFormat="dd MMM yyyy"
        selected={new Date(date)}
        weekDayClassName={renderWeekDay}
        onChange={onChangeDate}
        className={isError ? "input--error" : ""}
        placeholderText="Выберите дату"
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        dayClassName={dayClass}
      />

      <PickerIcon className="picker-icon" height={16} width={15} />
    </div>
  );
};

export default CustomDatePicker;
