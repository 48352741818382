import { IDevice } from "redux/admin/devices/DevicesTypes";

export interface IDevicesFilterState {
  devicesFiltered: IDevice[];
  typeFilterDevice: string;
  filterDevice: string;
}

export enum DevicesFilterActionTypes {
  GET_DEVICES_FILTER = "GET_DEVICES_FILTER",
  DEVICES_TYPE_SEARCH = "DEVICES_TYPE_SEARCH",
  DEVICES_FILTER_SEARCH = "DEVICES_FILTER_SEARCH",
}

interface GetDevicesFilter {
  type: DevicesFilterActionTypes.GET_DEVICES_FILTER;
  payload: IDevice[];
}

interface DevicesFilterSearch {
  type: DevicesFilterActionTypes.DEVICES_FILTER_SEARCH;
  payload: string;
}

interface DevicesTypeSearch {
  type: DevicesFilterActionTypes.DEVICES_TYPE_SEARCH;
  payload: string;
}

export type DevicesFilterActions = GetDevicesFilter | DevicesFilterSearch | DevicesTypeSearch;
