const TyreHeader = ({ titles, classes }: { titles: Array<string>; classes?: string }) => {
  return (
    <div
      className={
        classes ? `tyre_table_box tyre_header_box ${classes}` : "tyre_table_box tyre_header_box"
      }
    >
      {titles.map((title, index) => (
        <div key={index} className="tyre_header_title">
          {title}
        </div>
      ))}
    </div>
  );
};

export default TyreHeader;
