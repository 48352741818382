import { TyreProps } from "redux/tyres/tyres_types";
import { ReactComponent as Arrow } from "../../assets/images/Arrow2.svg";
import moment from "moment";
import { Link } from "react-router-dom";
import { CarState } from "redux/car/car_types";
import { useTypedSelector } from "hooks/useTypedSelector";

const TyreLine = (tyre: Omit<TyreProps, "actions | last_move">) => {
  const { car }: CarState = useTypedSelector((state) => state.car);

  return (
    <div className="tyre_line tyre_table_box">
      <div className="tyre_line_part bold">{tyre.model}</div>
      <div className="tyre_line_part">{moment(tyre.production_date).format("YYYY-MM-DD")}</div>
      <div className="tyre_line_part tyre_line_part--white-space">{tyre.dot}</div>
      <div className="tyre_line_part">
        {tyre.last_action?.run ? tyre.last_action?.run : tyre.run_start}
      </div>
      <div className="tyre_line_part">{tyre.brand}</div>
      {car && (
        <Link className="report_time-more" to={`/tyres/${car.id}/${tyre.id}`}>
          <span className="time-more_text">Подробнее</span>
          <Arrow className="arrow" />
        </Link>
      )}
    </div>
  );
};

export default TyreLine;
