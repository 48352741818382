import { MEDIA } from "constants/media";
import useWindowSize from "hooks/useWindowSize";
import { ReactComponent as BackArrow } from "assets/images/back-arrow.svg";
import { ReactComponent as BackArrowMobile } from "assets/images/back-arrow-mobile.svg";
import { GoBack } from "./SideBarGoBack.styled";

const GoBackBtn = ({ text, to }: GoBtnProps) => {
  const size = useWindowSize();
  return (
    <GoBack to={to}>
      {size.width > MEDIA.mobile ? (
        <>
          <BackArrow /> <span>{text}</span>
        </>
      ) : (
        <BackArrowMobile />
      )}
    </GoBack>
  );
};

export default GoBackBtn;

interface GoBtnProps {
  text: string;
  to: string;
}
