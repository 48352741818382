import { Dispatch } from "redux";
import api from "../../api";
import { EventsActions, EventsActionsTypes } from "./events_types";

export const getEvents = () => {
  return async (dispatch: Dispatch<EventsActions>) => {
    try {
      // throw Error('ошибка получения данных о событиях')
      await dispatch({ type: EventsActionsTypes.EVENTS_LOADING, payload: true });
      const response: any = await api.getEvents();
      dispatch({
        type: EventsActionsTypes.GET_EVENTS,
        payload: response.data.events,
      });

      return response;
    } catch (error: any) {
      console.log("e", error.message);
      return error;
    }
  };
};

export const getEventsPeriod = (from: string, to: string, vehicles: number[], page = 1) => {
  return async (dispatch: Dispatch<EventsActions>) => {
    dispatch({ type: EventsActionsTypes.EVENTS_LOADING, payload: true });
    const response: any = await api.getEventsPeriod(from, to, vehicles, page);
    dispatch({
      type: EventsActionsTypes.EVENT_PERIOD,
      payload: response.data.events,
    });
    dispatch({
      type: EventsActionsTypes.TOTAL_PAGE,
      payload: response.data.meta.last_page,
    });
    dispatch({
      type: EventsActionsTypes.TOTAL_EVENTS,
      payload: response.data.meta.total,
    });
  };
};

export const getEventsLast = (vehicle_id: number) => {
  return async (dispatch: Dispatch<EventsActions>) => {
    try {
      // throw Error('ошибка получения данных о событиях')
      await dispatch({ type: EventsActionsTypes.EVENTS_LOADING, payload: true });
      const response: any = await api.getEventsLast(vehicle_id);
      dispatch({
        type: EventsActionsTypes.EVENT_LAST,
        payload: response.data.events,
      });
      await dispatch({ type: EventsActionsTypes.EVENTS_LOADING, payload: false });
    } catch (error: any) {
      console.log("e", error.message);
      return error;
    }
  };
};

export const getEvent = (id: number) => {
  return async (dispatch: Dispatch<EventsActions>) => {
    try {
      // throw Error('ошибка получения данных о событиях')
      await dispatch({ type: EventsActionsTypes.EVENTS_LOADING, payload: true });
      const response: any = await api.getEvent(id);
      dispatch({
        type: EventsActionsTypes.GET_EVENT,
        payload: response.data.event,
      });
    } catch (error: any) {
      console.log("e", error.message);
      return error;
    }
  };
};

export const deleteEvent = (id: number) => {
  return async (dispatch: Dispatch<EventsActions>) => {
    try {
      // throw Error('ошибка получения данных о событиях')
      await dispatch({ type: EventsActionsTypes.EVENTS_LOADING, payload: true });
      const response: any = await api.deleteEvent(id);
      dispatch({
        type: EventsActionsTypes.DELETE_EVENT,
        payload: response.data.events,
      });
    } catch (error: any) {
      console.log("e", error.message);
      return error;
    }
  };
};

export const cleanTotalPage = () => {
  return async (dispatch: Dispatch<EventsActions>) => {
    dispatch({
      type: EventsActionsTypes.TOTAL_PAGE,
      payload: 0,
    });
  };
};
