import { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import { UserState } from "../../redux/user/user_types";
import { ModalsState } from "../../redux/modals/modals_types";
import SkeletonLoading from "../skeleton-loading/SkeletonLoading";
import ModalWindow from "./ModalWindow";
import toast from "react-hot-toast";

const ModalEditUserData = () => {
  const modals: ModalsState = useTypedSelector((state) => state.modals);
  const user: UserState = useTypedSelector((state) => state.user);

  const { toggleModal, putUser } = useActions();

  const [full_name, setFullName] = useState<string>("");

  const [errors, setErrors] = useState<any>({
    name: [],
  });

  const [submiting, setSubmiting] = useState<boolean>(false);

  useEffect(() => {
    setFullName(user.data.name);
  }, [user.data]);

  useEffect(() => {
    setErrors({
      name: [],
    });
  }, [modals.modal_edit_user_data]);

  const handleClose = () => {
    toggleModal({ toggle: false, modal: "modal_edit_user_data" });
    setFullName(user.data.name);
    setSubmiting(false);
  };

  const handleSave = async () => {
    setErrors({
      name: [],
    });
    try {
      setSubmiting(true);
      await putUser(full_name.trim());
      toast.success("Имя сохранено");
    } catch (error: any) {
      if (error?.response?.data?.errors) {
        setErrors(error?.response.data.errors);
      } else if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
    setSubmiting(false);
  };

  if (submiting) {
    return (
      <ModalWindow
        isShow={modals.modal_edit_user_data}
        title="Редактирование личных данных"
        handleClose={handleClose}
      >
        <SkeletonLoading height="125px" />
      </ModalWindow>
    );
  }

  return (
    <ModalWindow
      isShow={modals.modal_edit_user_data}
      title="Редактирование личных данных"
      handleClose={handleClose}
    >
      <div className="form-group profile-edit__form">
        <label className="profile-edit__label">ФИО</label>
        <input
          className={
            errors.name.length == 0
              ? "modal-input__grow"
              : "modal-input__grow modal-input__grow--error"
          }
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setFullName((e.target as HTMLInputElement).value)
          }
          value={full_name}
          name="full_name"
        />
        {errors?.name?.map((error: any, index: number) => {
          return (
            <div className="input-error-message" key={index}>
              {error}
            </div>
          );
        })}
        <div className="modal-edit__button-box ">
          <button
            className="modal__button modal__button--white modal__button--grey-hover"
            onClick={handleClose}
          >
            Отменить
          </button>
          <button className="modal__button modal__button__grey-hover" onClick={() => handleSave()}>
            Сохранить
          </button>
        </div>
      </div>
    </ModalWindow>
  );
};

export default ModalEditUserData;
