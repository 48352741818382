import { HeaderEl, HeaderContent, LogoType, HeaderInner, HeaderContainer } from "./Header.styled";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import HeaderNavs from "./HeaderNavs";

const Header = () => {
  return (
    <HeaderEl variant="Cabinet">
      <HeaderInner>
        <HeaderContainer variant="Cabinet">
          <HeaderContent>
            <LogoType to="/">
              <Logo className="header-logo" />
            </LogoType>
            <HeaderNavs />
          </HeaderContent>
        </HeaderContainer>
      </HeaderInner>
    </HeaderEl>
  );
};

export default Header;
