type CheckboxProps = {
  checked?: boolean;
  onChange?: () => void;
  readOnly?: boolean;
  classes?: "checkbox__box--white" | string;
  text?: string;
  disabled?: boolean;
  classesLabel?: string;
  titleClasses?: "checkbox-text-container__text" | string;
};

const Checkbox = ({
  readOnly = false,
  onChange,
  checked,
  classes,
  text,
  disabled = false,
  classesLabel,
  titleClasses,
}: CheckboxProps) => {
  return (
    <label className={["checkbox-container", classesLabel].join(" ")}>
      <div className="checkbox">
        <input
          className="checkbox__input"
          type="checkbox"
          disabled={disabled}
          readOnly={readOnly}
          onChange={onChange}
          checked={checked}
        />
        <div className={["checkbox__box", classes].join(" ")} />
      </div>
      {text && (
        <div className="checkbox-text-container">
          <span className={titleClasses}>{text}</span>
        </div>
      )}
    </label>
  );
};

export default Checkbox;
