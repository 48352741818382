import Modal from "react-bootstrap/Modal";
import { ReactNode } from "react";

const ModalWindow = ({
  isShow,
  title,
  children,
  dialogClassName = "",
  handleClose,
  isHeader = true,
  classBody = "modal-body",
}: PropsModalWindow) => {
  return (
    <Modal dialogClassName={dialogClassName} show={isShow} onHide={handleClose}>
      {isHeader && (
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body bsPrefix={classBody}>{children}</Modal.Body>
      <button className="button-exit" onClick={handleClose}></button>
    </Modal>
  );
};

export default ModalWindow;

interface PropsModalWindow {
  isShow: boolean;
  title?: string;
  children: ReactNode;
  dialogClassName?: string;
  isHeader?: boolean;
  classBody?: string;
  handleClose: () => void;
}
