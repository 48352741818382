// @ts-ignore
import io from "socket.io-client";
import { useEffect, useRef, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { MapState } from "../../redux/map/map_types";
import { useActions } from "../../hooks/useAction";
import { sockUrl } from "helpers/config";

const SocketListener = () => {
  const { point }: MapState = useTypedSelector((state) => state.map);
  const socket = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  const { getLastPointServer, carsUpdateLastPoint } = useActions();
  const cars = useTypedSelector((state) => state.cars);

  useEffect(() => {
    // следим за подключением
    if (!isConnected) {
      // @ts-ignore
      if (socket.current) socket.current.removeAllListeners();
      return;
    }
  }, [isConnected]);

  useEffect(() => {
    // обновление конкретной точки
    // @ts-ignore
    if (socket.current) socket.current.removeAllListeners();
    if (!point || !isConnected) return;

    // @ts-ignore
    socket.current.on(`updated ${point.device_id}`, (_) => {
      // console.log(`updated ${point.device_id}`);
      getLastPointServer(point.device_id, true);
    });
  }, [isConnected, point]);

  useEffect(() => {
    // обновление точек в списке машин
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (socket.current) socket.current.removeAllListeners();
    if (!cars.cars || !isConnected) return;

    cars.cars.forEach((car) => {
      // @ts-ignore
      socket.current.on(`updated ${car.device_id}`, (_) => {
        // console.log(`updated [${car.device_id}] ${car.car_number} ${car.title}`);
        carsUpdateLastPoint(car.id, car.device_id);
      });
    });
  }, [cars.cars]);

  useEffect(() => {
    init();

    return () => {
      reset();
    };
  }, []);

  const init = () => {
    // @ts-ignore
    socket.current = io(`${sockUrl}:443`);

    // @ts-ignore
    socket.current.on("connect", () => {
      console.log("Connected to sockets...");
      setIsConnected(true);
    });

    // @ts-ignore
    socket.current.on("disconnect", () => {
      console.log("Disconnected from sockets");
      reset();
    });

    // @ts-ignore
    socket.current.on("error", (error) => {
      console.log("Socket error", error);
      reset();
    });

    // @ts-ignore
    socket.current.on("connect_error", (error) => {
      console.log("Connect error", error);
      setIsConnected(false);
    });
  };

  const reset = () => {
    // @ts-ignore
    socket.current.removeAllListeners();
    // @ts-ignore
    socket.current.disconnect();
    setIsConnected(false);
  };

  return <></>;
};

export default SocketListener;
