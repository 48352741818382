import { useLocation } from "react-router-dom";
import { IGeometry, IGeoObject, Map } from "yandex-maps";
import { MapState } from "../../redux/map/map_types";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useEffect, useRef, useState } from "react";
import { makeMultiplePoint } from "./mapHelper";
import Checkbox from "components/ui/Checkbox/Checkbox";
import { ICar } from "redux/car/car_types";

const ymaps = window.ymaps;

const HandleMultiplePoints = ({ myMap }: { myMap: Map }) => {
  const map: MapState = useTypedSelector((state) => state.map);
  const { cars } = useTypedSelector((state) => state.cars);

  const [drawed, setDrawed] = useState<Boolean>(false);
  const [hidden, setHidden] = useState<Boolean>(false);
  const [points, setPoints] = useState<number[]>([]);
  const [track, setTrack] = useState<boolean>(true);
  const trackRef = useRef<boolean>(true);

  // таймер на перерисовку точек
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const selectedCars = useRef<ICar[]>([]);

  const location = useLocation();

  const reset = () => {
    myMap.geoObjects.removeAll();

    setDrawed(false);
    setHidden(false);
    setTrack(true);
    setPoints([]);

    trackRef.current = true;
    if (timer.current !== null) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  };

  useEffect(() => {
    if (!track) {
      trackRef.current = false;
      return;
    }

    trackRef.current = true;
    if (drawed) center();
  }, [track]);

  const center = () => {
    const bounds = myMap.geoObjects.getBounds();
    if (bounds) myMap.setBounds(bounds);

    const length = cars.filter(
      (localCar) => map.multiSelected.includes(localCar.id) && localCar.last_point !== null
    ).length;

    if (length > 1) {
      // @ts-ignore
      myMap.setZoom(myMap.action.getCurrentState().zoom - 0.5, { duration: 1 });
    } else if (length === 1) {
      myMap.setZoom(16, { duration: 1 });
    }
  };

  const draw = () => {
    const newPoints: number[] = [];

    const placemarks: IGeoObject<IGeometry>[] = [];

    selectedCars.current.forEach((localCar) => {
      if (localCar.last_point) {
        placemarks.push(makeMultiplePoint(localCar.last_point, localCar, null));
        newPoints.push(localCar.last_point.id);
      }
    });

    myMap.geoObjects.removeAll();

    placemarks.forEach((placemark) => {
      myMap.geoObjects.add(placemark);
    });

    if (trackRef.current) center();

    setHidden(false);
    setDrawed(true);
    setPoints(newPoints);
  };

  useEffect(() => {
    if (!myMap) return;
    if (map.multiSelected.length === 0) {
      selectedCars.current = [];

      reset();
      return;
    }

    selectedCars.current = cars.filter(
      (car) => map.multiSelected.includes(car.id) && car.last_point !== null
    );

    draw();
  }, [map.multiSelected]);

  useEffect(() => {
    if (!myMap) return;

    if (!map.isMultiple && drawed) reset();
    if (map.isMultiple && !drawed && map.multiSelected.length > 0) draw();
  }, [map.isMultiple]);

  useEffect(() => {
    if (!myMap || !map.isMultiple || !drawed || !cars || !map.multiSelected.length) return;

    let needToRedraw = false;
    // console.log("машины обновились");

    selectedCars.current = cars.filter(
      (car) => map.multiSelected.indexOf(car.id) >= 0 && car.last_point !== null
    );

    selectedCars.current.forEach((car) => {
      if (car.last_point && points.indexOf(car.last_point.id) < 0) {
        needToRedraw = true;
        return;
      }
    });

    // console.log(needToRedraw ? "надо" : "не надо");

    if (needToRedraw) debounce(handleCheck, 5000);
  }, [cars]);

  const debounce = (handler: () => void, time: number) => {
    if (timer.current !== null) return;

    timer.current = setTimeout(handler, time);
  };

  const handleCheck = () => {
    if (timer.current !== null) {
      clearTimeout(timer.current);
      timer.current = null;
    }

    draw();
  };

  useEffect(() => {
    if (!myMap || !drawed) return;

    // @ts-ignore
    const elements = ymaps.geoQuery(myMap.geoObjects).search("properties.pointType = 'multiple'");

    if (!hidden && location.pathname !== "/") {
      elements.setOptions("visible", false);
      setHidden(true);
      return;
    }

    if (hidden && location.pathname === "/") {
      elements.setOptions("visible", true);
      setHidden(false);
      if (trackRef.current) center();
    }
  }, [location.pathname]);

  if (drawed && !hidden)
    return (
      <div className="map__controls">
        <Checkbox
          checked={track}
          text="Двигать карту за точками"
          onChange={() => setTrack(!track)}
        />
      </div>
    );

  return <></>;
};

export default HandleMultiplePoints;
