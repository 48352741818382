import { ICar } from "../car/car_types";
import { IPoint } from "../map/map_types";

export interface ReportState {
  report: IReport;
  loading: boolean;
  isReport: boolean;
  isShow: boolean;
}

export enum ReportActionsTypes {
  GET_REPORT = "GET_REPORT",
  REPORT_LOADING = "REPORT_LOADING",
  REPORT_IS = "REPORT_IS",
  SHOW_IS = "SHOW_IS",
}

interface GetReportType {
  type: ReportActionsTypes.GET_REPORT;
  payload: Array<IReport>;
}

interface ReportLoading {
  type: ReportActionsTypes.REPORT_LOADING;
  payload: boolean;
}

interface ReportIs {
  type: ReportActionsTypes.REPORT_IS;
  payload: boolean;
}

interface ShowIs {
  type: ReportActionsTypes.SHOW_IS;
  payload: boolean;
}

export type ReportActions = GetReportType | ReportLoading | ReportIs | ShowIs;

export interface IReport {
  days: any | undefined;
  events: Array<PropsEvent>;
  from: string;
  last_point: IPoint | undefined;
  run: IRun | undefined;
  to: string;
  vehicle: ICar | undefined;
}

export interface PropsEvent {
  date: string;
  id: number;
  code: number;
  limit: {
    id: number;
    bar_min: number;
    bar_max: number;
    speed_max: number;
    temp_max: number;
    created_at: string;
  };
  type: string;
  violation: Array<string>;
  violation_text: string | boolean;
  limit_text: string;
  point: IPoint;
}
interface IRun {
  run_finish: number;
  run_start: number;
  run_total: number;
}

export interface IDay {
  ignition_off: number;
  ignition_on: number;
  ignition_total: number;
  no_signal: number;
  run_finish: number;
  run_start: number;
  run_total: number;
  events: Array<PropsEvent>;
}
