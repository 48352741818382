import { Dispatch } from "redux";
import { IPoint, MapActions, MapActionTypes } from "./map_types";

export const toggleMultiple = (payload: boolean) => async (dispatch: Dispatch<MapActions>) => {
  dispatch({ type: MapActionTypes.TOGGLE_MULTIPLE, payload });
};

export const multiToggle = (payload: number) => async (dispatch: Dispatch<MapActions>) => {
  dispatch({ type: MapActionTypes.MULTI_TOGGLE, payload });
};

export const toggleLoading = (payload: boolean) => async (dispatch: Dispatch<MapActions>) => {
  dispatch({ type: MapActionTypes.TOGGLE_LOADING, payload });
};

export const multiSelect =
  (payload: { ids: number[]; toggle: boolean }) => async (dispatch: Dispatch<MapActions>) => {
    dispatch({ type: MapActionTypes.MULTI_SELECT, payload });
  };

export const changePoint = (payload: null | IPoint) => async (dispatch: Dispatch<MapActions>) => {
  dispatch({ type: MapActionTypes.CHANGE_POINT, payload });
};

export const changeRoute = (payload: [] | IPoint[]) => async (dispatch: Dispatch<MapActions>) => {
  dispatch({ type: MapActionTypes.CHANGE_ROUTE, payload });
};

export const mapGlobal = (value: any) => {
  return {
    type: MapActionTypes.GLOBAL_MAP,
    payload: value,
  };
};

export const clearMap = () => {
  return {
    type: MapActionTypes.CLEAR_MAP_INSTANCE,
  };
};
