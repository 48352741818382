import moment from "moment";
import EventRow from "./EventRow";
import { forHourMin, mToKm } from "../../helpers";
import EventColumn from "./EventColumn";

const EventDayHead = ({ day, date }: { day: any; date: string }) => {
  return (
    <div className="report_total">
      <div className="report_date report_bold">
        <div className="report_date-title">{moment(date).format("DD.MM.YYYY")}</div>
      </div>

      <EventRow
        part1={{
          title: "Пробег",
          data: {
            item1: { title: "начало пробега", data: mToKm(day.run_start), smallTitle: " (КМ)" },
            item2: { title: "конец пробега", data: mToKm(day.run_finish), smallTitle: " (КМ)" },
            item3: { title: "итого", data: mToKm(day.run_total), smallTitle: " (КМ)" },
          },
        }}
        part2={{
          title: "Зажигание",
          data: {
            item1: { title: "включено", data: day.ignition_on, smallTitle: " (ч:мин)" },
            item2: { title: "выключено", data: day.ignition_off, smallTitle: " (ч:мин)" },
            item3: { title: "общее время", data: day.ignition_total },
          },
        }}
        classes="report_data-border"
      />

      <div className="report_data report_data-border">
        <div className="report_data-col ">
          <div className="report_title report_info-title">Пробег</div>
          <div className="report_info report_info-three">
            <EventColumn
              classColumn="report_column_day"
              title="начало пробега"
              data={mToKm(day.run_start)}
              smallTitle=" (КМ)"
            />
            <EventColumn
              classColumn="report_column_day"
              title="конец пробега"
              data={mToKm(day.run_finish)}
              smallTitle=" (КМ)"
            />
            <EventColumn
              classColumn="report_column_day"
              title="итого"
              data={mToKm(day.run_total)}
              smallTitle=" (КМ)"
            />
          </div>
        </div>
        <div className="report_data-col ">
          <div className="report_title report_info-title">Зажигание</div>
          <div className="report_info report_info-three">
            <EventColumn
              classColumn="report_column_day"
              title="включено"
              data={forHourMin(day.ignition_on)}
              smallTitle=" (ч:мин)"
            />
            <EventColumn
              classColumn="report_column_day"
              title="выключено"
              data={forHourMin(day.ignition_off)}
              smallTitle=" (ч:мин)"
            />
            <EventColumn
              classColumn="report_column_day"
              title="общее время"
              data={forHourMin(day.ignition_total)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDayHead;
