import ReactDOM from "react-dom/client";
import App from "./App";
import { YMaps } from "@pbe/react-yandex-maps";
import ru from "date-fns/locale/ru";
import "moment/locale/ru";
import Providers from "./Providers";
import { setDefaultLocale, registerLocale } from "react-datepicker";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./assets/styles/index.sass";

registerLocale("ru", ru);
setDefaultLocale("ru");

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <YMaps query={{ lang: "en_RU" }}>
    <Providers>
      <App />
    </Providers>
  </YMaps>
);
