import ReactPaginate from "react-paginate";

const PaginatedItems = ({ totalPage, handleShow }: PaginatedItemsProps) => {
  const pageCount = totalPage;

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel=" Далее"
      onPageChange={(event) => handleShow(event)}
      pageRangeDisplayed={2}
      marginPagesDisplayed={2}
      pageCount={pageCount}
      previousLabel={null}
      renderOnZeroPageCount={null}
      pageClassName="pagination_item pagination_li"
      className="pagination"
      breakClassName="pagination_item"
      previousClassName="pagination_item pagination_a--prev"
      nextClassName="pagination_item pagination_a"
      activeClassName="pagination_active"
    />
  );
};

export default PaginatedItems;

interface PaginatedItemsProps {
  totalPage: number;
  handleShow: (event: { selected: number | undefined }) => void;
}
