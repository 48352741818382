import { baseTheme } from "assets/styles/Theme.styled";
import styled from "styled-components";

export const VehicleMenuContainer = styled.div.attrs({
  "data-element": "vehicle-menu-container",
})`
  width: 25px;
  height: 46px;
  font-size: 0;
  cursor: pointer;
`;

interface MenuProps {
  top?: string;
  right?: string;
  left?: string;
  bottom?: string;
}

export const VehicleMenu = styled.div.attrs({
  "data-element": "vehicle-menu",
})<MenuProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${(props) => (props.top ? props.top : "auto")};
  right: ${(props) => (props.right ? props.right : "auto")};
  bottom: ${(props) => (props.bottom ? props.bottom : "auto")};
  left: ${(props) => (props.left ? props.left : "auto")};

  padding: 10px 26px 10px 10px;
  margin: 0;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 2px 2px 10px -1px rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

export const VehicleMenuOpen = styled.div.attrs({
  "data-element": "vehicle-menu-open",
})`
  border: none;
  background-color: transparent;
  height: 100%;
  width: 100%;
  .open__icon {
    top: 14px;
    position: relative;
    left: 6px;
    fill: ${baseTheme.colors.grey};
  }
`;

export const VehicleMenuClose = styled.button.attrs({
  "data-element": "vehicle-menu-close",
})`
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background-color: transparent;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  &:hover {
    background-color: #edeff4;
  }
`;
