import { IReportCount } from "./eventInterfaces";

const ReportCount = ({ count, title1, title2 }: IReportCount) => {
  return (
    <div className="report_count">
      <p className="report_count-p">
        <span className="report_bold report_blue report_padding">{count}</span>
        {title1}
      </p>
      <p className="report_count-p">{title2}</p>
    </div>
  );
};

export default ReportCount;
