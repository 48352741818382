import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "assets/styles/Buttons";
import { baseTheme } from "../../../assets/styles/Theme.styled";

export const ViewGoBack = styled.div.attrs({
  "data-element": "sidebar_goBack",
})`
  display: flex;
  border-bottom: 4px solid #edeff4;
  margin-bottom: 16px;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
`;
export const GoBack = styled(Link)`
  ${Button};
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  color: ${baseTheme.colors.main};
  background-color: ${baseTheme.colors.bg};
  border: none;
  margin-left: -10px;
  padding: 6px 10px;
  transition: all 0.1s ease;
  border-radius: 5px;
  span {
    margin-left: 10px;
  }
  &:hover {
    background-color: #edeff4;
    cursor: pointer;
    color: ${baseTheme.colors.main};
  }
  &:active {
    transform: translateY(1px);
  }
`;
