import { FC } from "react";
import { Routes, Route } from "react-router-dom";
import { useTypedSelector } from "./hooks/useTypedSelector";
import { UserState } from "./redux/user/user_types";
import PublicRoutes from "./routes/PublicRoutes";
import PrivateRoutes from "./routes/PrivateRoutes";

const MainRouter: FC = () => {
  const user: UserState = useTypedSelector((state) => state.user);

  return (
    <Routes>
      {!user.auth && <Route path="*" element={<PublicRoutes />} />}
      {user.auth && <Route path="*" element={<PrivateRoutes />} />}
    </Routes>
  );
};

export default MainRouter;
