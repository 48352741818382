const ADDING = "Установка на транспортное средство";
const MOVING = "Перемещение на транспортное средство";
const STORE = "Перемещение на склад";
const REPAIR = "Ремонт";
const UTILIZATION = "Утилизация";

export const ACTION_TYPES: ActionTypesConstProps = {
  adding: ADDING,
  moving: MOVING,
  store: STORE,
  repair: REPAIR,
  utilization: UTILIZATION,
};

interface ActionTypesConstProps {
  [key: string]: string;
}
