import { useEffect, useState } from "react";
import moment from "moment";
import CustomDatePicker from "../../components/datepicker/CustomDatePicker";
import DateTemplates from "../../components/date-templates/DateTemplate";
import { useActions } from "../../hooks/useAction";
import { CarState } from "../../redux/car/car_types";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { toast } from "react-hot-toast";

const ReportsCharts = () => {
  const [from, setFrom] = useState(moment().format("YYYY-MM-DD"));
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const { car }: CarState = useTypedSelector((state) => state.car);
  console.log(car);

  const { toggleIsCharts, getPointsPeriod, getLastLimits, getPeriodLimits, toggleIsShow } =
    useActions();

  const [errors, setErrors] = useState<any>({
    from: [],
    to: [],
  });

  const onDatesChange = (from: string, to: string) => {
    setFrom(from);
    setTo(to);
  };

  const handleClick = async () => {
    toggleIsShow(true);
    setErrors({
      from: [],
      to: [],
    });
    try {
      toggleIsCharts(false);
      if (car) {
        await getPointsPeriod(car.device_id, from, to);
        await getLastLimits({ vehicle_id: car.id, before: from });
        await getPeriodLimits({ vehicle_id: car.id, from, to });
      }
      toggleIsCharts(true);
    } catch (error: any) {
      if (error?.response?.data?.errors) {
        setErrors({
          ...errors,
          from: error?.response?.data?.errors?.from || [],
          to: error?.response?.data?.errors?.to || [],
        });
        console.log(errors);
      } else if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    return () => {
      toggleIsShow(false);
      toggleIsCharts(false);
    };
  }, []);

  return (
    <>
      <h4 className="point__title">Выбор периода</h4>
      <div className="reports reports--charts reports--charts--border">
        <DateTemplates onDatesChange={onDatesChange} from={from} to={to} />
        <div className="input-and-button input-and-button--border-top">
          <div className="input-area w50-5 m0">
            <label className="label">Начало периода</label>
            <CustomDatePicker onChange={(date) => setFrom(date)} date={from} />
            {errors.from?.length > 0 && <div className="input-error-message">{errors.from}</div>}
          </div>
          <div className="input-area w50-5 m0">
            <label className="label">Конец периода</label>
            <CustomDatePicker onChange={(date) => setTo(date)} date={to} />
            {errors?.length > 0 && <div className="input-error-message">{errors.to}</div>}
          </div>
          <button className="button button--mint button--with-label w100 m0" onClick={handleClick}>
            Показать
          </button>
        </div>
      </div>
    </>
  );
};

export default ReportsCharts;
