import { Dispatch } from "redux";
import { CarsModalActions, CarsModalActionTypes } from "./cars_modal_types";
import { ICar } from "../car/car_types";

export const getCarsModal = (cars: ICar[]) => {
  return async (dispatch: Dispatch<CarsModalActions>) => {
    try {
      await dispatch({
        type: CarsModalActionTypes.GET_CARS_MODAL,
        payload: cars,
      });
    } catch (e: any) {}
  };
};

export const changeFilterModalCar = (payload: string) => {
  return (dispatch: Dispatch<CarsModalActions>) => {
    dispatch({ type: CarsModalActionTypes.CARS_MODAL_FILTER, payload });
  };
};
