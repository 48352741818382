export interface IUser {
  id: number;
  comment_for_admin: string;
  email: string;
  email_confirmed: string;
  name: string;
  phone: string;
  pressure_units: string;
  profile_id: number;
  profile: IProfile;
  role: string;
  is_verified: boolean;
  token?: string;
}

export interface IProfile {
  id: number;
  title: string;
  inn: string;
  kpp: string;
  ogrn: string;
  full_title: string;
}

export interface UserState {
  data: IUser;
  loading: boolean;
  error: string;
  auth: boolean;
  users: IUser[];
}

export enum UserActionTypes {
  GET_USER = "GET_USER",
  USER_AUTH = "USER_AUTH",
  USER_LOADING = "USER_LOADING",
  GET_USERS = "GET_USERS",
}

interface GetUser {
  type: UserActionTypes.GET_USER;
  payload: IUser;
}

interface UserAuth {
  type: UserActionTypes.USER_AUTH;
  payload: boolean;
}

interface UserLoading {
  type: UserActionTypes.USER_LOADING;
  payload: boolean;
}

interface GetUsers {
  type: UserActionTypes.GET_USERS;
  payload: IUser[];
}

export type UserActions = GetUser | UserAuth | UserLoading | GetUsers;
