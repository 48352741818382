import { useState } from "react";
import { useActions } from "../../hooks/useAction";
import { INotification } from "../../redux/notifications/notifications_types";
import SkeletonLoading from "../skeleton-loading/SkeletonLoading";
import Checkbox from "../ui/Checkbox/Checkbox";
import ModalInput from "../ui/Inputs/ModalInput";
import { toast } from "react-hot-toast";

const Notification = ({ notif, vehicle_id }: { notif: INotification; vehicle_id: number }) => {
  const { putMachineResourceAlerts, deleteNotif } = useActions();

  const handleChangeInstant = async (toggle: boolean, notification_id: number) => {
    try {
      setIsChecked(true);
      await putMachineResourceAlerts(toggle, notification_id, vehicle_id);
    } catch (error: any) {
      toast.error(error);
    }

    setIsChecked(false);
  };

  const handleDeleteNotif = (notification_id: number) => {
    try {
      deleteNotif(notification_id, vehicle_id);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const [isChecked, setIsChecked] = useState<boolean>(false);

  return (
    <>
      {isChecked ? (
        <div className="skelet__container">
          <SkeletonLoading height="38px" />
        </div>
      ) : (
        <div className="modal-notification__container">
          <ModalInput readOnly={true} value={notif.value} />
          <div className="modal-notification__box">
            <Checkbox
              onChange={() => handleChangeInstant(!notif.is_instant, notif.id)}
              checked={notif.is_instant}
              text="Уведомлять мгновенно"
            />
            <button
              className="modal__button modal__button--white modal__button--red"
              type="button"
              onClick={() => handleDeleteNotif(notif.id)}
            >
              Удалить
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Notification;
