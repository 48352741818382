import { createContext, useState } from "react";

export const PointCheckProvider = ({ children }) => {
  const [isMoves, setIsMoves] = useState(true);
  const [isStops, setIsStops] = useState(true);

  const changeMoves = () => {
    setIsMoves(!isMoves);
  };
  const changeStops = () => {
    setIsStops(!isStops);
  };

  return (
    <PointCheckContext.Provider value={{ isMoves, isStops, changeMoves, changeStops }}>
      {children}
    </PointCheckContext.Provider>
  );
};

export const PointCheckContext = createContext();
