import EventRowPart, { EventRowPartProps } from "./EventRowPart";

const EventRow = ({
  part1,
  part2,
  classes,
}: {
  part1: EventRowPartProps;
  part2: EventRowPartProps;
  classes?: string;
}) => {
  return (
    <div className={classes ? `report_data ${classes}` : "report_data"}>
      <EventRowPart
        title={part1.title}
        data={{
          item1: {
            title: part1.data.item1.title,
            data: part1.data.item1.data,
            smallTitle: part1.data.item1.smallTitle,
          },
          item2: {
            title: part1.data.item2.title,
            data: part1.data.item2.data,
            smallTitle: part1.data.item2.smallTitle,
          },
          item3: {
            title: part1.data.item3.title,
            data: part1.data.item3.data,
            smallTitle: part1.data.item3.smallTitle,
          },
        }}
      />
      <EventRowPart
        title={part2.title}
        data={{
          item1: {
            title: part2.data.item1.title,
            data: part2.data.item1.data,
            smallTitle: part2.data.item1.smallTitle,
          },
          item2: {
            title: part2.data.item2.title,
            data: part2.data.item2.data,
            smallTitle: part2.data.item2.smallTitle,
          },
          item3: {
            title: part2.data.item3.title,
            data: part2.data.item3.data,
            smallTitle: part2.data.item3.smallTitle,
          },
        }}
      />
    </div>
  );
};

export default EventRow;
