export const CHART_COLORS = [
  "rgb(127,13,201)",
  "rgb(73,184,105)",
  "rgb(254,199,58)",
  "rgb(54,110,195)",
  "rgb(225,52,52)",
  "rgb(33,176,185)",
  "rgb(245,118,0)",
  "rgb(136,33,185)",
  "rgb(54,195,161)",
  "rgb(99,54,195)",
  "rgb(195,54,113)",
  "rgb(17,129,176)",
  "rgb(108,195,54)",
  "rgb(108,135,231)",
  "rgb(163,33,182)",
  "rgb(148,179,24)",
  "rgb(230,206,0)",
  "rgb(219,88,37)",
];

export const limitTypes: { [key: string]: string } = {
  bar_min: "Нижний предел",
  bar_max: "Верхний предел",
  temp_max: "Верхний предел",
};
