import { createContext, useState } from "react";
import { hasAll } from "../helpers";

export const CarsModalProvider = ({ children }) => {
  const [vehiclesId, setVehiclesId] = useState([]);

  const selectVehicle = (vehicle_id) => {
    const _selectedVehicles = [...vehiclesId];
    const indexPosition = _selectedVehicles.indexOf(vehicle_id);

    if (indexPosition !== -1) {
      _selectedVehicles.splice(indexPosition, 1);
    } else {
      _selectedVehicles.push(vehicle_id);
    }

    setVehiclesId(_selectedVehicles);
  };

  const handleAllSelect = (cars, filterCars, filter) => {
    const _selectedIds = [];

    // выбраны все
    if (filter == "") {
      if (cars.length === vehiclesId.length) {
        setVehiclesId([]);
      } else {
        cars.forEach((car) => {
          _selectedIds.push(car.id);
        });
        setVehiclesId(_selectedIds);
      }
    } else {
      const filterId = [];
      filterCars.forEach((car) => filterId.push(car.id));
      const isAdded = hasAll(filterId, vehiclesId);

      if (isAdded) {
        const filterNew = vehiclesId.filter((id) => filterId.indexOf(id) === -1);
        filterNew.forEach((car) => {
          _selectedIds.push(car);
        });
        setVehiclesId(_selectedIds);
      } else {
        filterId.forEach((car) => {
          _selectedIds.push(car);
        });
        setVehiclesId([...vehiclesId, ..._selectedIds]);
      }
    }
  };

  const resetCarsModalContext = () => {
    setVehiclesId([]);
  };

  return (
    <CarsModalContext.Provider
      value={{
        vehiclesId,
        setVehiclesId,
        selectVehicle,
        handleAllSelect,
        resetCarsModalContext,
      }}
    >
      {children}
    </CarsModalContext.Provider>
  );
};

export const CarsModalContext = createContext();
