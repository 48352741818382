import LoginContactUs from "../components/contact-us/LoginContactUs";
import Login from "../components/login/Login";

const LoginPage = () => {
  return (
    <div className="login-page">
      <Login />
      <LoginContactUs />
    </div>
  );
};

export default LoginPage;
