import { GlobalApi } from "api/GlobalApi";
import api from "api/index";

export const UsersApi = GlobalApi.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<any, void>({
      query: () => ({
        url: api.getUsers(),
      }),
      providesTags: ["Users"],
    }),
    createNewUser: build.mutation<
      any,
      { name: string; email: string; password: string; profile_id: number }
    >({
      query: (body) => ({
        url: api.createNewUser(),
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error) => {
        if (error) return [];
        return ["Users"];
      },
    }),
    changeUser: build.mutation<any, { id: number; name: string; role: string; profile_id: number }>(
      {
        query: (body) => ({
          url: api.changeUser(body.id),
          method: "PUT",
          body,
        }),
        invalidatesTags: (result, error) => {
          if (error) return [];
          return ["Users"];
        },
      }
    ),
  }),
});

export const { useGetUsersQuery, useCreateNewUserMutation, useChangeUserMutation } = UsersApi;
