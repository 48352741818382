import { IUser } from "redux/user/user_types";

export interface IUsersFilterState {
  usersFiltered: IUser[];
  typeFilterUser: string;
  filterUser: string;
}

export enum UsersFilterActionTypes {
  GET_USER_FILTER = "GET_USER_FILTER",
  USER_TYPE_SEARCH = "USER_TYPE_SEARCH",
  USER_FILTER_SEARCH = "USER_FILTER_SEARCH",
}

interface GetUsersFilter {
  type: UsersFilterActionTypes.GET_USER_FILTER;
  payload: IUser[];
}

interface UsersFilterSearch {
  type: UsersFilterActionTypes.USER_FILTER_SEARCH;
  payload: string;
}

interface UsersTypeSearch {
  type: UsersFilterActionTypes.USER_TYPE_SEARCH;
  payload: string;
}

export type UsersFilterActions = GetUsersFilter | UsersFilterSearch | UsersTypeSearch;
