const Tyres = ({ hex }: { hex: string }) => {
  const array = [];
  let index = 1;

  for (let i = 0; i < 46; i++) {
    const tireHEX = hex.substr(84 + i * 6, 6);

    if (!!tireHEX && tireHEX !== "ffffff" && tireHEX !== "000000") {
      array.push({
        index,
        tireHEX: tireHEX.toUpperCase(),
      });
    }
    index++;
  }

  if (array.length > 0)
    return (
      <div className="console__item-wheels">
        Шины HEX:{" "}
        {array.map((item) => (
          <span className="console__item-wheel" key={item.index}>
            <strong>{item.index}</strong> {item.tireHEX.toUpperCase()}
          </span>
        ))}
      </div>
    );

  return <></>;
};

export default Tyres;
