import moment from "moment";
import { parseEvent } from "../../helpers/events";
import ViolationPart from "./ViolationPart";
import { useActions } from "../../hooks/useAction";
import { shortTime } from "helpers";

const Vioalation = ({ event, showDate = false }: { event: any; showDate?: boolean }) => {
  let parsed_events: any[] = [];
  if (event.point) {
    parsed_events = parseEvent(event.point, event.limit);
  }

  const { getPointDetailed, toggleModal } = useActions();
  const handlePoint = () => {
    toggleModal({ toggle: true, modal: "modal_point" });
    getPointDetailed(event.point.id);
  };

  const date = moment(event.created_at);

  return (
    <>
      {showDate && (
        <div className="report_date report_bold report_date--violation">
          <div className="report_date-title">
            {shortTime(date.fromNow())} - {date.format("DD.MM.YYYY")}
          </div>
        </div>
      )}

      <div className="violation" tabIndex={0} role="button" onClick={handlePoint}>
        <span className="violation__date ">{date.format("HH:mm")}</span>
        <div className="violation__container">
          {parsed_events.map((event, index) => (
            <ViolationPart key={index} {...event}></ViolationPart>
          ))}
        </div>
      </div>
    </>
  );
};

export default Vioalation;
