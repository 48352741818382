import React, { useEffect } from "react";
import Cars from "../cars/Cars";
import Folders from "../folders/Folders";
import { useActions } from "../../hooks/useAction";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import DashboardSearch from "./DashboardSearch";
import CustomScroll from "react-custom-scroll";
import DashboardButtons from "./DashboarButtons";
import DashboardMultiple from "./DashboardMultiple";
import {
  SideBarBottom,
  SideBarContent,
  SideBarHeader,
  SideBarTop,
} from "../admin/sideBar/SideBar.styled";
import { useGetFoldersQuery } from "redux/folder/FolderApi";
import SkeletonLoading from "components/skeleton-loading/SkeletonLoading";

const Dashboard = () => {
  const { getCars, resetCars } = useActions();

  const cars = useTypedSelector((state) => state.cars);
  const { data, isLoading } = useGetFoldersQuery();

  useEffect(() => {
    getCars();

    return () => {
      resetCars();
    };
  }, []);

  const dashboardContent = (
    <>
      {cars.loading && isLoading ? (
        <SkeletonLoading height="42px" />
      ) : (
        <Folders cars={cars.cars} data={data} />
      )}
      <Cars />
    </>
  );

  return (
    <>
      {!cars.loading && (
        <SideBarHeader>
          <SideBarTop>
            <DashboardButtons />
          </SideBarTop>
          {cars.cars.length > 0 && (
            <SideBarBottom>
              <DashboardMultiple />
              <DashboardSearch />
            </SideBarBottom>
          )}
        </SideBarHeader>
      )}

      <SideBarContent>
        {/* прячем скролл пока загрузка */}
        {cars.loading && !isLoading && dashboardContent}

        {/* показываем скролл после загрузки */}
        {!cars.loading && !isLoading && (
          <CustomScroll allowOuterScroll={true} heightRelativeToParent="100%">
            {dashboardContent}
          </CustomScroll>
        )}
      </SideBarContent>
    </>
  );
};

export default Dashboard;
