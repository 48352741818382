import { useTypedSelector } from "hooks/useTypedSelector";
import React, { useEffect, useState } from "react";

export default function MapComponent({ onMapReady }) {
  const { map_global } = useTypedSelector((state) => state.map);
  const [myMap, setMyMap] = useState(null);

  useEffect(() => {
    if (map_global) {
      setMyMap(map_global);
      onMapReady(map_global);
    }
  }, [map_global, onMapReady]);

  return <></>;
}
