import { renderToString } from "react-dom/server";
import moment from "moment";
import { defaultSettingsCar } from "../../helpers";
import { ICar } from "../../redux/car/car_types";
import { IPoint } from "../../redux/map/map_types";
import { ISettings } from "../../redux/settings/settings_types";
import Balloon from "./Balloon";
import { IGeometry, IGeoObject, Map } from "yandex-maps";
import {
  ARROW_HEIGHT,
  ARROW_WIDTH,
  ARROW_ZOOM,
  BALLOON_MAX_WIDTH,
  CLUSTER_SIZE,
  getDistanceBetween,
  IGetStages,
  IMakeLine,
  IPointWStatus,
  ISortedPoints,
  IStageCoord,
  POINT_SIZE,
  POINT_SIZE_MINI,
  scanSensors,
  SPEED_DIFF,
  switchColor,
  switchLineWidth,
} from "./mapTypes";
import { VehicleFullNumber, VehicleNumber, VehicleRegion } from "components/cars/Cars.styled";

const ymaps = window.ymaps;

export const makeClusterWithPoints = (
  points: IPointWStatus[],
  car: ICar
): {
  clusters: unknown[];
  start: null | IGeoObject<IGeometry>;
  finish: null | IGeoObject<IGeometry>;
} => {
  const sortedPoints: ISortedPoints = { G: [], Y: [], P: [], R: [] };
  let startPlacemark: null | IGeoObject<IGeometry> = null;
  let finishPlacemark: null | IGeoObject<IGeometry> = null;

  const pointsLength = points.length;
  const clusters: unknown[] = [];

  if (pointsLength > 0) {
    let firstIsleft = true;

    const firstPoint = points[0];
    const lastPoint = pointsLength > 1 ? points[pointsLength - 1] : null;

    if (lastPoint !== null) {
      if (firstPoint.long > lastPoint.long) firstIsleft = false;

      finishPlacemark = makePoint(points[pointsLength - 1], car, {
        status: points[pointsLength - 1].status,
        zIndex: 18,
        extra: firstIsleft ? "FINISH" : "FINISH ON LEFT",
      });
    }

    startPlacemark = makePoint(points[0], car, {
      status: points[0].status,
      zIndex: 17,
      extra: firstIsleft ? "START" : "START ON RIGHT",
    });
  }

  for (let i = 1; i < pointsLength - 1; i++) {
    const placemark = makePoint(points[i], car, { status: points[i].status });
    const status = points[i].status as string;
    sortedPoints[status as keyof typeof sortedPoints].push(placemark);
  }

  Object.keys(sortedPoints).forEach((key) => {
    const status = key as string;

    const layout = `<div class="map__cluster map__cluster--${status}">{{ properties.geoObjects.length }}</div>`;

    const cl = ymaps.templateLayoutFactory.createClass(layout, {
      build: function () {
        // @ts-ignore
        cl.superclass.build.call(this);
        // @ts-ignore
        // зона, на которую можно навести курсор
        this.getData().options.set("shape", {
          type: "Circle",
          coordinates: [CLUSTER_SIZE / 2, CLUSTER_SIZE / 2],
          radius: CLUSTER_SIZE / 2,
        });

        const map__cluster =
          // @ts-ignore
          this.getParentElement().getElementsByClassName("map__cluster")[0];

        // @ts-ignore
        this.getData().geoObject.events.add("mouseenter", () => {
          map__cluster.classList.add("map__cluster--active");
        });

        // @ts-ignore
        this.getData().geoObject.events.add("mouseleave", () => {
          map__cluster.classList.remove("map__cluster--active");
        });
      },
    });

    const cluster = new ymaps.Clusterer({
      // @ts-ignore
      clusterIconLayout: cl,
      hasBalloon: false,
      hasHint: false,
      maxZoom: 13,
      zIndex: 15,
      iconOffset: [-CLUSTER_SIZE / 2, -CLUSTER_SIZE / 2],
    });

    cluster.add(sortedPoints[status as keyof typeof sortedPoints]);
    clusters.push(cluster);
  });

  return { clusters, start: startPlacemark, finish: finishPlacemark };
};

export const makeLine = (stages: IStageCoord[][]): IMakeLine => {
  let coordCount = 0;
  const polylines: IGeoObject<IGeometry>[] = [];

  // рисуем линию
  if (stages.length > 0)
    stages.forEach((stage) => {
      const coordinates: [number, number][] = [];
      stage.forEach((coord) => {
        if (coord.point) {
          coordinates.push([coord.point.lat, coord.point.long]);
          coordCount++;
        }
      });

      const polyline = new ymaps.Polyline(
        coordinates,
        {},
        {
          strokeColor: switchColor(stage[0].status),
          strokeWidth: switchLineWidth(stage[0].status),
          zIndex: 5,
        }
      );

      polylines.push(polyline);
    });

  return { coordCount, polylines };
};

export const getStagesAndPoints = (data: IPoint[], limit: ISettings): IGetStages => {
  const points: IPointWStatus[] = []; // точки маршрута
  const arrowPoints: IPointWStatus[] = []; // точки между которых будут рисоваться стрелки

  const stages = generateStages(data, limit);

  if (stages.length === 0) return { stages: [], points: [], arrowPoints: [] };
  let addingNext = false;
  // stages.forEach((stage, stageIndex) => {
  stages.forEach((stage) => {
    // добавляем точки на маршрут
    const stageLength = stage.length;

    // const isLastStage = () => stageIndex === stages.length - 1;
    // const isFirstStage = () => stageIndex === 0;
    // сколько точек нужно надо нарисовать на отрезке маршрута
    // const pointCount = Math.floor(stageLength / POINTS_PER_STAGE);

    for (let pointIndex = 0; pointIndex < stageLength; pointIndex++) {
      if (!stage[pointIndex].point) continue;

      if (stage[pointIndex].invisible) {
        addingNext = true;
        continue;
      }
      const { point, status } = stage[pointIndex];
      arrowPoints.push({ ...point, status });

      let adding = false;
      if (addingNext) {
        adding = true;
        addingNext = false;
      }

      if (!adding) adding = needToAdd(pointIndex, stageLength, point.speed);
      if (!adding && points.length > 0) {
        adding = Math.abs(points[points.length - 1].speed - point.speed) > SPEED_DIFF;
      }
      if (!adding) continue;

      points.push({ ...point, status });
    }
  });

  return { stages, points, arrowPoints };
};

const needToAdd = (pointIndex: number, stageLength: number, speed: number): boolean => {
  if (pointIndex === 0 || pointIndex === stageLength - 1) return true;
  if (speed < 4) return true;

  return false;
};

const generateStages = (data: IPoint[], limit: ISettings): IStageCoord[][] => {
  const nonNullData = data.filter(({ long, lat }) => long > 0 && lat > 0);

  if (nonNullData.length === 0) return [];

  const settingsParams: ISettings = {
    bar_min: limit.bar_min || defaultSettingsCar.notification.min_bar,
    bar_max: limit.bar_max || defaultSettingsCar.notification.max_bar,
    temp_max: limit.temp_max || defaultSettingsCar.notification.max_temp,
    speed_max: limit.speed_max || defaultSettingsCar.notification.max_speed,
  };

  let status = "G";
  let lastStageLastPoint: IPoint | null = null;

  const stages: IStageCoord[][] = [[]];

  // let pointLimit = 16;

  for (let index = 0; index < nonNullData.length; index++) {
    const point = nonNullData[index];

    const tireStatus = scanSensors(settingsParams, point.sensors);
    let newStatus = status;

    const lastStage: IStageCoord[] = stages[stages.length > 0 ? stages.length - 1 : 0]; // последний отрезок
    let away = false; // флаг нужно ли разделить отрезок

    if (lastStage.length > 0 && lastStage[lastStage.length - 1].point) {
      const a = {
        x: point.lat,
        y: point.long,
      };

      const prevPoint = lastStage[lastStage.length - 1].point;

      const b = {
        x: prevPoint.lat,
        y: prevPoint.long,
      };

      const dist = getDistanceBetween(a, b);

      const slittingDistance = point.speed > 50 ? (point.speed > 80 ? 1700 : 1000) : 500; // какое расстояние считать разрывом (м)

      if (dist > slittingDistance) away = true;

      if (dist > 10000) {
        const timeFrom = moment(point.device_updated_at).diff(prevPoint.device_updated_at) / 1000;
        if (timeFrom < 180) continue;
      }
    }

    let switching = false;
    let isOk = true;

    if (point.speed > settingsParams.speed_max) {
      // превышение скорости
      if (status !== "P") {
        switching = true;
        newStatus = "P";
      }

      isOk = false;
    }

    if (tireStatus === "MAX_T") {
      // нарушение температуры
      if (status !== "Y") {
        switching = true;
        newStatus = "Y";
      }

      isOk = false;
    }

    if (tireStatus === "MIN" || tireStatus === "MAX") {
      // если давление
      if (status !== "R") {
        switching = true;
        newStatus = "R";
      }

      isOk = false;
    }

    if (isOk) {
      // если никаких нарушений
      if (status !== "G") {
        switching = true;
        newStatus = "G";
      }
    }

    if (!away) {
      if (switching) {
        if (lastStage.length > 0) {
          // точка в конце
          // lastStage.push({ status, point });
          const newStage: IStageCoord[] = [];
          if (lastStageLastPoint !== null) {
            const pointFromLastStage: IStageCoord = {
              invisible: true,
              status: newStatus,
              point: lastStageLastPoint,
            };
            newStage.push(pointFromLastStage);
          }

          status = newStatus;
          const newItem: IStageCoord = { status, point };
          lastStageLastPoint = { ...point };
          newStage.push(newItem);
          stages.push(newStage);
        } else {
          // продолжаем последний c новым статусом
          status = newStatus;
          lastStage.push({ status, point });
          lastStageLastPoint = { ...point };
        }
      } else {
        // продолжаем последний
        lastStage.push({ status, point });
        lastStageLastPoint = { ...point };
      }
    } else {
      // создаем новый отрезок
      stages.push([{ status, point }]);
      lastStageLastPoint = null;
    }
  }

  return stages;
};

export const makeArrows = (points: IPointWStatus[], map: Map): IGeoObject<IGeometry>[] => {
  const arrows: IGeoObject<IGeometry>[] = [];
  let lastArrowIndex = -99;

  for (let i = 0; i < points.length; i++) {
    if (i + 1 >= points.length || i < lastArrowIndex + 3) continue;

    const current = map.converter.globalToPage([points[i].lat, points[i].long]);
    const next = map.converter.globalToPage([points[i + 1].lat, points[i + 1].long]);

    const middleGlobal = map.converter.pageToGlobal([
      (next[0] + current[0]) / 2,
      (next[1] + current[1]) / 2,
    ]);

    // @ts-ignore
    const geoProblem = ymaps.coordSystem.geo.solveInverseProblem(
      [points[i].lat, points[i].long],
      [points[i + 1].lat, points[i + 1].long]
    );

    const angle =
      (Math.atan2(geoProblem.endDirection[1], geoProblem.endDirection[0]) * 180) / Math.PI;

    lastArrowIndex = i;

    const arrowPlacemark = makeArrow(
      middleGlobal[0],
      middleGlobal[1],
      angle,
      points[i + 1].status,
      map
    );

    arrows.push(arrowPlacemark);
  }

  return arrows;
};

export const makeArrow = (lat: number, long: number, angle: number, status: string, map: Map) => {
  const layout = renderToString(
    <div
      className={`map__arrow map__arrow--${status}`}
      style={{ rotate: `${angle + 270}deg` }}
    ></div>
  );

  const iconLayout = ymaps.templateLayoutFactory.createClass(layout, {
    build: function () {
      // @ts-ignore
      iconLayout.superclass.build.call(this);
      const initialZoom = map.getZoom();
      const map__arrowClassList =
        // @ts-ignore
        this.getParentElement().getElementsByClassName("map__arrow")[0].classList;
      if (initialZoom < ARROW_ZOOM) {
        map__arrowClassList.add("map__arrow--hidden");
      } else {
        map__arrowClassList.remove("map__arrow--hidden");
      }

      map.events.add(
        "boundschange",
        function () {
          const currentZoom = map.getZoom();

          if (currentZoom < ARROW_ZOOM) {
            map__arrowClassList.add("map__arrow--hidden");
          } else {
            map__arrowClassList.remove("map__arrow--hidden");
          }
        },
        this
      );
    },
  });

  const placemark = new ymaps.Placemark(
    [lat, long],
    {},
    {
      hideIconOnBalloonOpen: false,
      openEmptyBalloon: true,
      iconOffset: [-ARROW_WIDTH / 2, -ARROW_HEIGHT / 2],
      iconLayout,
      zIndex: 5,
    }
  );

  return placemark;
};

export const makeMultiplePoint = (
  point: IPoint,
  car: ICar,
  status: null | string = null
): IGeoObject<IGeometry> => {
  const layout = renderToString(
    <div className="map__point">
      <div className="map__hover map__number">
        <VehicleFullNumber>
          <VehicleNumber>{car.car_number}</VehicleNumber>
          <VehicleRegion>{car.region}</VehicleRegion>
        </VehicleFullNumber>
      </div>
    </div>
  );

  const iconLayout = ymaps.templateLayoutFactory.createClass(layout, {
    build: function () {
      // @ts-ignore
      iconLayout.superclass.build.call(this);
      // @ts-ignore
      // зона, на которую можно навести курсор
      this.getData().options.set("shape", {
        type: "Circle",
        coordinates: [POINT_SIZE / 2, POINT_SIZE / 2],
        radius: POINT_SIZE / 2,
      });

      const map__point =
        // @ts-ignore
        this.getParentElement().getElementsByClassName("map__point")[0];

      // @ts-ignore
      this.getData().geoObject.events.add("mouseenter", () => {
        map__point.classList.add("map__point--active");
      });

      // @ts-ignore
      this.getData().geoObject.events.add("mouseleave", () => {
        map__point.classList.remove("map__point--active");
      });
    },
  });

  const placemark = new ymaps.Placemark(
    [point.lat, point.long],
    {
      pointId: 123,
      pointType: "multiple",
    },
    {
      hideIconOnBalloonOpen: false,
      openEmptyBalloon: true,
      iconOffset: [-POINT_SIZE / 2, -POINT_SIZE / 2],
      balloonOffset: [0, -POINT_SIZE / 1.5],
      iconLayout,
      zIndex: 10,
    }
  );

  placemark.options.set("balloonMaxWidth", BALLOON_MAX_WIDTH);
  placemark.properties.set(
    "balloonContent",
    renderToString(
      <div className="map__balloon-content" id={`${point.id}`}>
        <Balloon point={point} car={car} />
      </div>
    )
  );

  return placemark;
};

interface IMakePointOptions {
  status?: null | string;
  zIndex?: null | number;
  extra?: null | string;
}

export const makePoint = (
  point: IPoint,
  car: ICar,
  options?: IMakePointOptions
): IGeoObject<IGeometry> => {
  const pointClasses = ["map__point"];
  const timeClasses = ["map__hover"];
  let SIZE = POINT_SIZE;

  const status = options?.status ?? null;
  const zIndex = options?.zIndex ?? 10;
  const extra = options?.extra ?? null;

  if (status !== null) {
    pointClasses.push("map__point--" + status);

    if (point.device_created_at !== point.device_updated_at) {
      SIZE = POINT_SIZE_MINI;
      pointClasses.push("map__point--slow");
    }
  }

  let hoverContent = "";

  hoverContent = moment(point.device_updated_at).format("HH:mm");
  if (point.device_created_at !== point.device_updated_at) {
    hoverContent = moment(point.device_created_at).format("HH:mm") + " - " + hoverContent;
    timeClasses.push("map__hover--wide");
  }

  const markerClasses = ["map__marker"];

  if (extra !== null) {
    if (extra === "START ON RIGHT" || extra === "FINISH") markerClasses.push("map__marker--right");
  }

  const layout = renderToString(
    <div className={pointClasses.join(" ")}>
      <div className={timeClasses.join(" ")}>{hoverContent}</div>
      {extra !== null && (
        <div className={markerClasses.join(" ")}>
          {extra === "START" || extra === "START ON RIGHT" ? "Старт" : "Финиш"}
        </div>
      )}
    </div>
  );

  const iconLayout = ymaps.templateLayoutFactory.createClass(layout, {
    build: function () {
      // @ts-ignore
      iconLayout.superclass.build.call(this);
      // @ts-ignore
      // зона, на которую можно навести курсор
      this.getData().options.set("shape", {
        type: "Circle",
        coordinates: [SIZE / 2, SIZE / 2],
        radius: SIZE / 2,
      });

      const map__point =
        // @ts-ignore
        this.getParentElement().getElementsByClassName("map__point")[0];

      // @ts-ignore
      this.getData().geoObject.events.add("mouseenter", () => {
        map__point.classList.add("map__point--active");
      });

      // @ts-ignore
      this.getData().geoObject.events.add("mouseleave", () => {
        map__point.classList.remove("map__point--active");
      });
    },
  });

  const placemark = new ymaps.Placemark(
    [point.lat, point.long],
    {},
    {
      hideIconOnBalloonOpen: false,
      openEmptyBalloon: true,
      iconOffset: [-SIZE / 2, -SIZE / 2],
      balloonOffset: [0, -SIZE / 1.5],
      iconLayout,
      zIndex,
    }
  );

  placemark.options.set("balloonMaxWidth", BALLOON_MAX_WIDTH);
  placemark.properties.set(
    "balloonContent",
    renderToString(
      <div className="map__balloon-content" id={`${point.id}`}>
        <Balloon point={point} car={car} />
      </div>
    )
  );

  return placemark;
};
