import { createContext, useState } from "react";

export const ModalUserCarProvider = ({ children }) => {
  const [carUser, setCarUser] = useState({});
  const [userCurrent, setUserCurrent] = useState(null);
  const [userCarList, setuserCarList] = useState(null);

  return (
    <ModalUserCarContext.Provider
      value={{ carUser, setCarUser, userCurrent, setUserCurrent, userCarList, setuserCarList }}
    >
      {children}
    </ModalUserCarContext.Provider>
  );
};

export const ModalUserCarContext = createContext();
