import { createSlice } from "@reduxjs/toolkit";
import { IAdminProfile } from "redux/admin/profiles/ProfilesTypes";

export interface IProfilesState {
  select_profile: IAdminProfile;
}

const initialState: IProfilesState = {
  select_profile: {} as IAdminProfile,
};

export const profilesSlice = createSlice({
  name: "profiles",
  initialState,
  reducers: {
    selectProfiles: (state, action) => {
      state.select_profile = action.payload;
    },
  },
});

export const profilesReducer = profilesSlice.reducer;
export const profilesActions = profilesSlice.actions;
