import { useState } from "react";
import api from "../../api";
import Loader from "../../components/loader/Loader";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";

const EmailConfirm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [doneMessage, setDoneMessage] = useState("");

  const showModal = () => {
    setError("");
    setDoneMessage("");
    setIsLoading(false);
    setIsOpen(true);
  };

  const submitStep1 = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsLoading(true);
    setError("");

    try {
      const result = await api.sendConfirmation();
      if (result?.data?.message) {
        setDoneMessage(result.data.message);
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setError(error.response.data.message);
      } else {
        toast.error(error.message);
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      <button className="modal__button modal__button--small" onClick={() => showModal()}>
        Подтвердить
      </button>

      <Modal show={isOpen} onHide={() => setIsOpen(false)}>
        <Modal.Header>
          <button className="button-exit" onClick={() => setIsOpen(false)}></button>
          <Modal.Title>Подтверждение почты</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!doneMessage && (
            <form onSubmit={submitStep1}>
              <div className="modal-confirm__info">
                <p className="p--modal">
                  На указанный email будет отправлено письмо. Перейдите по ссылке в письме чтобы
                  подтвердить свою почту.
                </p>

                {error && <div className="input-error-message">{error}</div>}
              </div>
              <div className="modal-confirm__center">
                {!isLoading && (
                  <button className="modal__button" type="submit">
                    Отправить
                  </button>
                )}

                {isLoading && (
                  <div className="modal-button-loader">
                    <Loader />
                  </div>
                )}
              </div>
            </form>
          )}

          {doneMessage && (
            <>
              <div className="modal-confirm__info">
                <p className="p--modal">{doneMessage}</p>
              </div>
              <div className="modal-confirm__center">
                <button className="modal__button" type="button" onClick={() => setIsOpen(false)}>
                  Понятно
                </button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmailConfirm;
