import { ReactNode } from "react";
import { models } from "./constructorModels";

export interface ICategory {
  id: number;
  title: string;
  image: string;
}

export interface IModels {
  [key: number]: ICatalogModel;
}

export interface ITrailers {
  [key: number]: ICatalogTrailer;
}

interface ICatalog {
  title: string;
  wheelCount: number;
  flat: ReactNode;
  color: ReactNode;
  preview: string;
  globalPadding: number;
  axes: IAxis[];
}

interface ICatalogModel extends ICatalog {
  category: number;
}

interface ICatalogTrailer extends ICatalog {
  type: number;
}

interface IAxis {
  offset: number;
  wheels: number[];
  mini?: boolean;
}

export interface IDesign {
  modelId: number;
  trailerId: null | number;
  positions: number[];
}

export interface IDesignParams {
  vehicle_id: number;
  model_id: number;
  positions: number[];
  trailer_id?: number;
}

export enum STEPS {
  CATEGORIES = "CATEGORIES",
  MODEL = "MODEL",
  TRAILER = "TRAILER",
  SENSORS = "SENSORS",
  PREVIEW = "PREVIEW",
}

export const categoryCount = (id: number) =>
  Object.keys(models).filter((modelId) => models[+modelId].category === id).length;
