import Wheel from "./Wheel";

const WheelList = ({ tires }: WheelListTypes) => {
  const res = JSON.parse(tires);
  return (
    <div className="console__item-wheels">
      {res.map((item: TiresTypes, index: number) => {
        return <Wheel key={index} index={index} {...item} />;
      })}
    </div>
  );
};

export default WheelList;

interface WheelListTypes {
  tires: string;
}

interface TiresTypes {
  pressure: number;
  temperature: number;
}
