import moment from "moment";
import { ISettings } from "redux/settings/settings_types";

export const pattern = [];

for (let i = 1; i <= 20; i++) {
  const wheel = {
    id: i,
    value: 0,
    replay: false,
  };
  // @ts-ignore
  pattern.push(wheel);
}

export const defaultSettingsCar = {
  notification: {
    max_speed: 100,
    min_bar: 1,
    max_bar: 12,
    min_temp: -50,
    max_temp: 90,
  },
  transport: {
    car_type: "C",
    time: "0",
  },
  notifications_while_driving: 30,
  notifications_while_stop: 60,
  pattern,
};

export const defaultLimits: ISettings = {
  bar_min: defaultSettingsCar.notification.min_bar,
  bar_max: defaultSettingsCar.notification.max_bar,
  temp_max: defaultSettingsCar.notification.max_temp,
  speed_max: defaultSettingsCar.notification.max_speed,
};

export const Codes: { [key: string]: any } = {
  200: "Превышение скорости",
  315: "Давление меньше допустимого",
  335: "Давление больше допустимого",
  375: "Превышение температуры",
};

interface RolesType {
  [key: string]: number;
}

interface KeyStringObject {
  [key: string]: string;
}

export const ROLES: RolesType = {
  user: 1,
  moderator: 3,
  developer: 5,
  admin: 7,
};

export const TEXT_ROLES: KeyStringObject = {
  user: "Пользователь",
  moderator: "Модератор",
  developer: "Разработчик",
  admin: "Админ",
};

export const hasRole = (userRole: string, minimalRole: string): boolean => {
  return ROLES[userRole] >= ROLES[minimalRole];
};

export const allowedRoles = (minimalRole: string): string[] => {
  const result: string[] = [];

  Object.keys(ROLES).forEach((role) => {
    if (ROLES[role] >= ROLES[minimalRole]) result.push(role);
  });

  return result;
};

export const renderWeekDay = (date: Date) => {
  if (
    moment(date).year() === moment().year() &&
    moment(date).month() === moment().month() &&
    moment(date).day() === moment().day()
  )
    return "react-datepicker__day-name--active";

  return "";
};

export const like = (a: string, b: string) => {
  return a.toUpperCase().indexOf(b.toUpperCase()) >= 0;
};

export const hasAll = (a: any[], b: any[]) => {
  const hash = b.reduce(function (acc, i) {
    acc[i] = true;
    return acc;
  }, {});
  return a.every(function (i) {
    return i in hash;
  });
};

// склонение слов
export const Declination = (number: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

export const mToKm = (meters: number | undefined) => {
  if (meters) return (meters / 1000).toFixed(2);

  return 0;
};

export const wordsForSeconds = ["секунда", "секунды", "секунд"];
export const wordsForMinutes = ["минута", "минуты", "минут"];
export const wordsForHours = ["час", "часа", "часов"];
export const wordsForDays = ["день", "дня", "дней"];

const shortCuts: KeyStringObject = {
  месяцев: "мес.",
  месяца: "мес.",
  месяц: "мес.",
  "несколько секунд назад": "только что",
};

export const shortTime = (string: string) => {
  let result = string;

  Object.keys(shortCuts).forEach((key) => {
    result = result.replace(key, shortCuts[key]);
  });

  return result;
};

export const formatMinutes = (dateA: string, dateB?: string) => {
  const minutes = moment.duration(moment(dateB).diff(dateA)).asMinutes();

  let words = wordsForMinutes;
  let formattedDiff = Math.round(minutes);

  if (minutes < 1) {
    formattedDiff = Math.round(minutes * 60);
    words = wordsForSeconds;
  } else if (minutes >= 60 && minutes < 1440) {
    formattedDiff = Math.round(minutes / 60);
    words = wordsForHours;
  } else if (minutes >= 1440) {
    words = wordsForDays;
    formattedDiff = Math.round(minutes / 1440);
  }

  return { formattedDiff, words, minutes };
};

export const genCode = () => {
  const length = 16;
  const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let res = "";
  for (let i = 0; i < length; i++) {
    res += charset.charAt(Math.floor(Math.random() * length));
  }
  return res;
};

export const round = (value: number): string => (Math.round(value * 10) / 10).toString();

export const forHourMin = (seconds: number) => {
  const hours = Math.floor(seconds / 60 / 60);
  const minutes = Math.ceil(seconds / 60) - hours * 60;
  return hours + ":" + minutes;
};
