import { IModels } from "./constructorTypes";

/////////////////////////////////////

// ТРАКТОРЫ
import { ReactComponent as A_1_2_3_25_27_FLAT } from "../assets/images/constructor/models/A/FLAT_A--1-2-3-25-27.svg";
import A_1_PREW from "../assets/images/constructor/models/A/PREW_A--1.png";
import A_2_PREW from "../assets/images/constructor/models/A/PREW_A--2.png";
import A_3_PREW from "../assets/images/constructor/models/A/PREW_A--3.png";
import A_25_PREW from "../assets/images/constructor/models/A/PREW_A--25.png";
import A_27_PREW from "../assets/images/constructor/models/A/PREW_A--27.png";
import { ReactComponent as A_24_26_28_FLAT } from "../assets/images/constructor/models/A/FLAT_A--24-26-28.svg";
import A_24_PREW from "../assets/images/constructor/models/A/PREW_A--24.png";
import A_26_PREW from "../assets/images/constructor/models/A/PREW_A--26.png";
import A_28_PREW from "../assets/images/constructor/models/A/PREW_A--28.png";

// КОМБАЙНЫ
import { ReactComponent as A_6_29_FLAT } from "../assets/images/constructor/models/A/FLAT_A--6-29.svg";
import A_6_PREW from "../assets/images/constructor/models/A/PREW_A--6.png";
import A_29_PREW from "../assets/images/constructor/models/A/PREW_A--29.png";

// АВТОБУСЫ
import { ReactComponent as D_4_31_32_FLAT } from "../assets/images/constructor/models/D/FLAT_D--4-31-32.svg";
import D_4_PREW from "../assets/images/constructor/models/D/PREW_D--4.png";
import D_31_PREW from "../assets/images/constructor/models/D/PREW_D--31.png";
import D_32_PREW from "../assets/images/constructor/models/D/PREW_D--32.png";
import { ReactComponent as D_5_30_FLAT } from "../assets/images/constructor/models/D/FLAT_D--5-30.svg";
import D_5_PREW from "../assets/images/constructor/models/D/PREW_D--5.png";
import D_30_PREW from "../assets/images/constructor/models/D/PREW_D--30.png";

// АВТОМОБИЛЬ
import { ReactComponent as D_15_16_FLAT } from "../assets/images/constructor/models/D/FLAT_D--15-16.svg";
import D_15_PREW from "../assets/images/constructor/models/D/PREW_D--15.png";
import D_16_PREW from "../assets/images/constructor/models/D/PREW_D--16.png";
import { ReactComponent as D_17_FLAT } from "../assets/images/constructor/models/D/FLAT_D--17.svg";
import D_17_PREW from "../assets/images/constructor/models/D/PREW_D--17.png";
import { ReactComponent as D_18_19_FLAT } from "../assets/images/constructor/models/D/FLAT_D--18-19.svg";
import D_18_PREW from "../assets/images/constructor/models/D/PREW_D--18.png";
import D_19_PREW from "../assets/images/constructor/models/D/PREW_D--19.png";
import { ReactComponent as D_20_FLAT } from "../assets/images/constructor/models/D/FLAT_D--20.svg";
import D_20_PREW from "../assets/images/constructor/models/D/PREW_D--20.png";

// ТЯГАЧИ
import { ReactComponent as D_21_22_23_FLAT } from "../assets/images/constructor/models/D/FLAT_D--21-22-23.svg";
import D_21_PREW from "../assets/images/constructor/models/D/PREW_D--21.png";
import D_22_PREW from "../assets/images/constructor/models/D/PREW_D--22.png";
import D_23_PREW from "../assets/images/constructor/models/D/PREW_D--23.png";

// ФОРВАДЕРЫ
import { ReactComponent as B_12_FLAT } from "../assets/images/constructor/models/B/FLAT_B--12.svg";
import B_12_PREW from "../assets/images/constructor/models/B/PREW_B--12.png";
import { ReactComponent as B_13_FLAT } from "../assets/images/constructor/models/B/FLAT_B--13.svg";
import B_13_PREW from "../assets/images/constructor/models/B/PREW_B--13.png";
import { ReactComponent as B_14_FLAT } from "../assets/images/constructor/models/B/FLAT_B--14.svg";
import B_14_PREW from "../assets/images/constructor/models/B/PREW_B--14.png";

// СОЧЛЕНЕННЫЙ САМОСВАЛ
import { ReactComponent as B_7_FLAT } from "../assets/images/constructor/models/B/FLAT_B--7.svg";
import B_7_PREW from "../assets/images/constructor/models/B/PREW_B--7.png";
import { ReactComponent as B_8_FLAT } from "../assets/images/constructor/models/B/FLAT_B--8.svg";
import B_8_PREW from "../assets/images/constructor/models/B/PREW_B--8.png";

// КАРЬЕРНЫЙ САМОСВАЛ
import { ReactComponent as B_9_FLAT } from "../assets/images/constructor/models/B/FLAT_B--9.svg";
import B_9_PREW from "../assets/images/constructor/models/B/PREW_B--9.png";

// ПОГРУЗЧИКИ
import { ReactComponent as B_10_11_FLAT } from "../assets/images/constructor/models/B/FLAT_B--10-11.svg";
import B_10_PREW from "../assets/images/constructor/models/B/PREW_B--10.png";
import B_11_PREW from "../assets/images/constructor/models/B/PREW_B--11.png";

// САМОСВАЛЫ
import { ReactComponent as B_33_34_FLAT } from "../assets/images/constructor/models/B/FLAT_B--33-34.svg";
import B_33_PREW from "../assets/images/constructor/models/B/PREW_B--33.png";
import B_34_PREW from "../assets/images/constructor/models/B/PREW_B--34.png";
import { ReactComponent as B_35_FLAT } from "../assets/images/constructor/models/B/FLAT_B--35.svg";
import B_35_PREW from "../assets/images/constructor/models/B/PREW_B--35.png";
import { ReactComponent as B_36_37_FLAT } from "../assets/images/constructor/models/B/FLAT_B--36-37.svg";
import B_36_PREW from "../assets/images/constructor/models/B/PREW_B--36.png";
import B_37_PREW from "../assets/images/constructor/models/B/PREW_B--37.png";
import { ReactComponent as B_38_39_FLAT } from "../assets/images/constructor/models/B/FLAT_B--38-39.svg";
import B_38_PREW from "../assets/images/constructor/models/B/PREW_B--38.png";
import B_39_PREW from "../assets/images/constructor/models/B/PREW_B--39.png";

export const models: IModels = {
  1: {
    title: "Трактор с 2 осями (4+6)",
    wheelCount: 10,
    category: 7,
    flat: <A_1_2_3_25_27_FLAT />,
    color: <A_1_2_3_25_27_FLAT />,
    preview: A_1_PREW,
    globalPadding: 40,
    axes: [
      {
        offset: -18,
        wheels: [-55, -50, -45, 45, 50, 55],
      },
      {
        offset: 4,
        wheels: [-29, -24, 24, 29],
        mini: true,
      },
    ],
  },
  2: {
    title: "Трактор с 2 осями (2+2)",
    wheelCount: 4,
    category: 7,
    flat: <A_1_2_3_25_27_FLAT />,
    color: <A_1_2_3_25_27_FLAT />,
    preview: A_2_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -18,
        wheels: [-46, 46],
      },
      {
        offset: 4,
        wheels: [-24, 24],
        mini: true,
      },
    ],
  },
  3: {
    title: "Трактор с 2 осями (4+6)",
    wheelCount: 10,
    category: 7,
    flat: <A_1_2_3_25_27_FLAT />,
    color: <A_1_2_3_25_27_FLAT />,
    preview: A_3_PREW,
    globalPadding: 40,
    axes: [
      {
        offset: -18,
        wheels: [-55, -50, -45, 45, 50, 55],
      },
      {
        offset: 4,
        wheels: [-29, -24, 24, 29],
      },
    ],
  },

  4: {
    title: "Автобус с 3 осями (2+4+4)",
    wheelCount: 10,
    category: 10,
    flat: <D_4_31_32_FLAT />,
    color: <D_4_31_32_FLAT />,
    preview: D_4_PREW,
    globalPadding: 40,
    axes: [
      {
        offset: -34,
        wheels: [-46, -40, 40, 46],
      },
      {
        offset: -22,
        wheels: [-46, -40, 40, 46],
      },
      {
        offset: 22,
        wheels: [-40, 40],
      },
    ],
  },
  5: {
    title: "Автобус с 2 осями (2+4)",
    wheelCount: 6,
    category: 10,
    flat: <D_5_30_FLAT />,
    color: <D_5_30_FLAT />,
    preview: D_5_PREW,
    globalPadding: 40,
    axes: [
      {
        offset: -48,
        wheels: [-46, -40, 40, 46],
      },
      {
        offset: 34,
        wheels: [-40, 40],
      },
    ],
  },

  6: {
    title: "Комбайн с 2 осями (4+2)",
    wheelCount: 6,
    category: 8,
    flat: <A_6_29_FLAT />,
    color: <A_6_29_FLAT />,
    preview: A_6_PREW,
    globalPadding: 40,
    axes: [
      {
        offset: -20,
        wheels: [-44, 44],
        mini: true,
      },
      {
        offset: -20,
        wheels: [-49, -44, 44, 49],
      },
    ],
  },

  7: {
    title: "Самосвал с 2 осями (2+2)",
    wheelCount: 4,
    category: 1,
    flat: <B_7_FLAT />,
    color: <B_7_FLAT />,
    preview: B_7_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -20,
        wheels: [-40, 40],
      },
      {
        offset: 30,
        wheels: [-40, 40],
      },
    ],
  },
  8: {
    title: "Самосвал с 3 осями (2+2+2)",
    wheelCount: 6,
    category: 1,
    flat: <B_8_FLAT />,
    color: <B_8_FLAT />,
    preview: B_8_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -18,
        wheels: [-40, 40],
      },
      {
        offset: -12,
        wheels: [-40, 40],
      },
      {
        offset: 0,
        wheels: [-40, 40],
      },
    ],
  },
  9: {
    title: "Самосвал с 2 осями (2+4)",
    wheelCount: 6,
    category: 1,
    flat: <B_9_FLAT />,
    color: <B_9_FLAT />,
    preview: B_9_PREW,
    globalPadding: 43,
    axes: [
      {
        offset: -22,
        wheels: [-46, -40, 40, 46],
      },
      {
        offset: 14,
        wheels: [-40, 40],
      },
    ],
  },

  10: {
    title: "Погрузчик с 2 осями (2+2)",
    wheelCount: 4,
    category: 5,
    flat: <B_10_11_FLAT />,
    color: <B_10_11_FLAT />,
    preview: B_10_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -32,
        wheels: [-42, 42],
      },
      {
        offset: 8,
        wheels: [-42, 42],
      },
    ],
  },
  11: {
    title: "Погрузчик с 2 осями (2+2)",
    wheelCount: 4,
    category: 5,
    flat: <B_10_11_FLAT />,
    color: <B_10_11_FLAT />,
    preview: B_11_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -32,
        wheels: [-42, 42],
      },
      {
        offset: 8,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },

  12: {
    title: "Харвестер с 4 осями (2+2+2+2)",
    wheelCount: 8,
    category: 6,
    flat: <B_12_FLAT />,
    color: <B_12_FLAT />,
    preview: B_12_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -6,
        wheels: [-46, 46],
      },
      {
        offset: -2,
        wheels: [-46, 46],
      },
      {
        offset: 2,
        wheels: [-46, 46],
      },
      {
        offset: 6,
        wheels: [-46, 46],
      },
    ],
  },
  13: {
    title: "Форвардер с 3 осями (2+2+2)",
    wheelCount: 6,
    category: 11,
    flat: <B_13_FLAT />,
    color: <B_13_FLAT />,
    preview: B_13_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -26,
        wheels: [-46, 46],
        mini: true,
      },
      {
        offset: -36,
        wheels: [-46, 46],
        mini: true,
      },
      {
        offset: 8,
        wheels: [-46, 46],
      },
    ],
  },
  14: {
    title: "Форвардер с 4 осями (2+2+2+2)",
    wheelCount: 8,
    category: 11,
    flat: <B_14_FLAT />,
    color: <B_14_FLAT />,
    preview: B_14_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: 8,
        wheels: [-46, 46],
        mini: true,
      },
      {
        offset: 2,
        wheels: [-46, 46],
        mini: true,
      },
      {
        offset: -6,
        wheels: [-46, 46],
        mini: true,
      },
      {
        offset: -16,
        wheels: [-46, 46],
        mini: true,
      },
    ],
  },
  15: {
    title: "Грузовик с 2 осями (2+2)",
    wheelCount: 4,
    category: 4,
    flat: <D_15_16_FLAT />,
    color: <D_15_16_FLAT />,
    preview: D_15_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -32,
        wheels: [-42, 42],
      },
      {
        offset: 30,
        wheels: [-42, 42],
      },
    ],
  },
  16: {
    title: "Грузовик с 2 осями (2+4)",
    wheelCount: 6,
    category: 4,
    flat: <D_15_16_FLAT />,
    color: <D_15_16_FLAT />,
    preview: D_16_PREW,
    globalPadding: 43,
    axes: [
      {
        offset: -32,
        wheels: [-48, -42, 42, 48],
      },
      {
        offset: 30,
        wheels: [-42, 42],
      },
    ],
  },
  17: {
    title: "Грузовик с 3 осями (2+4+4)",
    wheelCount: 10,
    category: 4,
    flat: <D_17_FLAT />,
    color: <D_17_FLAT />,
    preview: D_17_PREW,
    globalPadding: 43,
    axes: [
      {
        offset: -20,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: -28,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: 29,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
  18: {
    title: "Грузовик с 4 осями (2+4+4+4)",
    wheelCount: 14,
    category: 4,
    flat: <D_18_19_FLAT />,
    color: <D_18_19_FLAT />,
    preview: D_18_PREW,
    globalPadding: 43,
    axes: [
      {
        offset: -16,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: -24,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: -32,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: 6,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
  19: {
    title: "Грузовик с 4 осями (2+2+4+4)",
    wheelCount: 12,
    category: 4,
    flat: <D_18_19_FLAT />,
    color: <D_18_19_FLAT />,
    preview: D_19_PREW,
    globalPadding: 43,
    axes: [
      {
        offset: -16,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: -24,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: -32,
        wheels: [-42, 42],
        mini: true,
      },
      {
        offset: 6,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
  20: {
    title: "Грузовик с 5 осями (2+2+2+4+4)",
    wheelCount: 14,
    category: 4,
    flat: <D_20_FLAT />,
    color: <D_20_FLAT />,
    preview: D_20_PREW,
    globalPadding: 43,
    axes: [
      {
        offset: 4,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: -8,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: -20,
        wheels: [-42, 42],
        mini: true,
      },
      {
        offset: -34,
        wheels: [-42, 42],
        mini: true,
      },
      {
        offset: -14,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },

  21: {
    title: "Тягач с 3 осями (2+2+2)",
    wheelCount: 6,
    category: 9,
    flat: <D_21_22_23_FLAT />,
    color: <D_21_22_23_FLAT />,
    preview: D_21_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -12,
        wheels: [-42, 42],
        mini: true,
      },
      {
        offset: -22,
        wheels: [-42, 42],
        mini: true,
      },
      {
        offset: 0,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
  22: {
    title: "Тягач с 3 осями (2+4+4)",
    wheelCount: 10,
    category: 9,
    flat: <D_21_22_23_FLAT />,
    color: <D_21_22_23_FLAT />,
    preview: D_22_PREW,
    globalPadding: 43,
    axes: [
      {
        offset: -12,
        wheels: [-46, -42, 42, 46],
        mini: true,
      },
      {
        offset: -22,
        wheels: [-46, -42, 42, 46],
        mini: true,
      },
      {
        offset: 0,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
  23: {
    title: "Тягач с 3 осями (4+4+4)",
    wheelCount: 12,
    category: 9,
    flat: <D_21_22_23_FLAT />,
    color: <D_21_22_23_FLAT />,
    preview: D_23_PREW,
    globalPadding: 43,
    axes: [
      {
        offset: -12,
        wheels: [-46, -42, 42, 46],
        mini: true,
      },
      {
        offset: -22,
        wheels: [-46, -42, 42, 46],
        mini: true,
      },
      {
        offset: 0,
        wheels: [-46, -42, 42, 46],
        mini: true,
      },
    ],
  },

  24: {
    title: "Трактор с 2 осями (4+4)",
    wheelCount: 8,
    category: 7,
    flat: <A_24_26_28_FLAT />,
    color: <A_24_26_28_FLAT />,
    preview: A_24_PREW,
    globalPadding: 43,
    axes: [
      {
        offset: -46,
        wheels: [-28, -24, 24, 28],
      },
      {
        offset: 28,
        wheels: [-28, -24, 24, 28],
      },
    ],
  },
  25: {
    title: "Трактор с 2 осями (4+4)",
    wheelCount: 8,
    category: 7,
    flat: <A_1_2_3_25_27_FLAT />,
    color: <A_1_2_3_25_27_FLAT />,
    preview: A_25_PREW,
    globalPadding: 43,
    axes: [
      {
        offset: -18,
        wheels: [-49, -45, 45, 49],
      },
      {
        offset: 4,
        wheels: [-28, -24, 24, 28],
        mini: true,
      },
    ],
  },
  26: {
    title: "Трактор с 2 осями (2+4)",
    wheelCount: 6,
    category: 7,
    flat: <A_24_26_28_FLAT />,
    color: <A_24_26_28_FLAT />,
    preview: A_26_PREW,
    globalPadding: 43,
    axes: [
      {
        offset: -46,
        wheels: [-28, -24, 24, 28],
      },
      {
        offset: 28,
        wheels: [-24, 24],
      },
    ],
  },
  27: {
    title: "Трактор с 2 осями (2+4)",
    wheelCount: 6,
    category: 7,
    flat: <A_1_2_3_25_27_FLAT />,
    color: <A_1_2_3_25_27_FLAT />,
    preview: A_27_PREW,
    globalPadding: 43,
    axes: [
      {
        offset: -18,
        wheels: [-49, -45, 45, 49],
      },
      {
        offset: 4,
        wheels: [-24, 24],
        mini: true,
      },
    ],
  },
  28: {
    title: "Трактор с 2 осями (2+2)",
    wheelCount: 4,
    category: 7,
    flat: <A_24_26_28_FLAT />,
    color: <A_24_26_28_FLAT />,
    preview: A_28_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -46,
        wheels: [-24, 24],
      },
      {
        offset: 28,
        wheels: [-24, 24],
      },
    ],
  },

  29: {
    title: "Комбайн с 2 осями (2+2)",
    wheelCount: 4,
    category: 8,
    flat: <A_6_29_FLAT />,
    color: <A_6_29_FLAT />,
    preview: A_29_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -20,
        wheels: [-44, 44],
        mini: true,
      },
      {
        offset: -20,
        wheels: [-44, 44],
      },
    ],
  },
  30: {
    title: "Автобус с 2 осями (2+2)",
    wheelCount: 4,
    category: 10,
    flat: <D_5_30_FLAT />,
    color: <D_5_30_FLAT />,
    preview: D_30_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -48,
        wheels: [-40, 40],
      },
      {
        offset: 34,
        wheels: [-40, 40],
      },
    ],
  },
  31: {
    title: "Автобус с 3 осями (2+2+4)",
    wheelCount: 8,
    category: 10,
    flat: <D_4_31_32_FLAT />,
    color: <D_4_31_32_FLAT />,
    preview: D_31_PREW,
    globalPadding: 43,
    axes: [
      {
        offset: -34,
        wheels: [-46, -40, 40, 46],
      },
      {
        offset: -22,
        wheels: [-40, 40],
      },
      {
        offset: 22,
        wheels: [-40, 40],
      },
    ],
  },
  32: {
    title: "Автобус с 3 осями (2+2+2)",
    wheelCount: 6,
    category: 10,
    flat: <D_4_31_32_FLAT />,
    color: <D_4_31_32_FLAT />,
    preview: D_32_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -34,
        wheels: [-40, 40],
      },
      {
        offset: -22,
        wheels: [-40, 40],
      },
      {
        offset: 22,
        wheels: [-40, 40],
      },
    ],
  },

  33: {
    title: "Самосвал с 2 осями (2+2)",
    wheelCount: 4,
    category: 1,
    flat: <B_33_34_FLAT />,
    color: <B_33_34_FLAT />,
    preview: B_33_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -36,
        wheels: [-40, 40],
      },
      {
        offset: 26,
        wheels: [-40, 40],
      },
    ],
  },
  34: {
    title: "Самосвал с 2 осями (2+4)",
    wheelCount: 6,
    category: 1,
    flat: <B_33_34_FLAT />,
    color: <B_33_34_FLAT />,
    preview: B_34_PREW,
    globalPadding: 43,
    axes: [
      {
        offset: -36,
        wheels: [-44, -40, 40, 44],
      },
      {
        offset: 26,
        wheels: [-40, 40],
      },
    ],
  },
  35: {
    title: "Самосвал с 3 осями (2+4+4)",
    wheelCount: 10,
    category: 1,
    flat: <B_35_FLAT />,
    color: <B_35_FLAT />,
    preview: B_35_PREW,
    globalPadding: 43,
    axes: [
      {
        offset: -24,
        wheels: [-46, -42, 42, 46],
        mini: true,
      },
      {
        offset: -30,
        wheels: [-46, -42, 42, 46],
        mini: true,
      },
      {
        offset: 24,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
  36: {
    title: "Самосвал с 4 осями (2+4+4+4)",
    wheelCount: 14,
    category: 1,
    flat: <B_36_37_FLAT />,
    color: <B_36_37_FLAT />,
    preview: B_36_PREW,
    globalPadding: 43,
    axes: [
      {
        offset: -20,
        wheels: [-46, -42, 42, 46],
        mini: true,
      },
      {
        offset: -28,
        wheels: [-46, -42, 42, 46],
        mini: true,
      },
      {
        offset: -34,
        wheels: [-46, -42, 42, 46],
        mini: true,
      },
      {
        offset: 2,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
  37: {
    title: "Самосвал с 4 осями (2+2+4+4)",
    wheelCount: 12,
    category: 1,
    flat: <B_36_37_FLAT />,
    color: <B_36_37_FLAT />,
    preview: B_37_PREW,
    globalPadding: 43,
    axes: [
      {
        offset: -20,
        wheels: [-46, -42, 42, 46],
        mini: true,
      },
      {
        offset: -28,
        wheels: [-46, -42, 42, 46],
        mini: true,
      },
      {
        offset: -34,
        wheels: [-42, 42],
        mini: true,
      },
      {
        offset: 2,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
  38: {
    title: "Самосвал с 5 осями (2+2+2+4+4)",
    wheelCount: 14,
    category: 1,
    flat: <B_38_39_FLAT />,
    color: <B_38_39_FLAT />,
    preview: B_38_PREW,
    globalPadding: 43,
    axes: [
      {
        offset: 0,
        wheels: [-46, -42, 42, 46],
        mini: true,
      },
      {
        offset: -12,
        wheels: [-46, -42, 42, 46],
        mini: true,
      },
      {
        offset: -24,
        wheels: [-42, 42],
        mini: true,
      },
      {
        offset: -38,
        wheels: [-42, 42],
        mini: true,
      },
      {
        offset: -18,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
  39: {
    title: "Самосвал с 5 осями (2+2+4+4+4)",
    wheelCount: 16,
    category: 1,
    flat: <B_38_39_FLAT />,
    color: <B_38_39_FLAT />,
    preview: B_39_PREW,
    globalPadding: 43,
    axes: [
      {
        offset: 0,
        wheels: [-46, -42, 42, 46],
        mini: true,
      },
      {
        offset: -12,
        wheels: [-46, -42, 42, 46],
        mini: true,
      },
      {
        offset: -24,
        wheels: [-46, -42, 42, 46],
        mini: true,
      },
      {
        offset: -38,
        wheels: [-42, 42],
        mini: true,
      },
      {
        offset: -18,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
};
