import { ReactComponent as List } from "../../assets/images/list.svg";
import { ReactComponent as Car } from "../../assets/images/car_logo.svg";
import { useActions } from "../../hooks/useAction";
import { useContext } from "react";
import { ModalUserCarContext } from "components/user/context/ModalUserCarContext";
import { useTypedSelector } from "hooks/useTypedSelector";

const DashboardButtons = () => {
  const { toggleModal } = useActions();
  const { setuserCarList } = useContext(ModalUserCarContext);
  const cars = useTypedSelector((state) => state.cars);
  const handleClickFolder = () => {
    toggleModal({ toggle: true, modal: "modal_group" });
    setuserCarList(cars.cars);
  };

  return (
    <>
      <button
        className="side-bar__btn"
        onClick={() => toggleModal({ toggle: true, modal: "modal_add_transport" })}
      >
        <Car className="side-bar__icon" />
        Добавить ТС
      </button>

      <button className="side-bar__btn" onClick={handleClickFolder}>
        <List className="side-bar__icon" />
        Добавить Список
      </button>
    </>
  );
};

export default DashboardButtons;
