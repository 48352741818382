import { Outlet } from "react-router-dom";
import ModalConfirm from "../components/modals/ModalConfirm";
import HomePageHeader from "../components/header/HomePageHeader";

// layout для главной страницы и магазина
const HomeLayout = () => {
  return (
    <div className="layout layout--home">
      <HomePageHeader />
      <div>
        <Outlet />
      </div>

      {/* ПУБЛИЧНЫЕ МОДАЛКИ */}
      <ModalConfirm />
    </div>
  );
};

export default HomeLayout;
