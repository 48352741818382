import { Outlet } from "react-router-dom";
import SideBar from "components/admin/sideBar/SideBar";
import { AdminArea } from "../assets/styles/Admin.styled";
import { Suspense } from "react";
import Loader from "components/loader/Loader";
import DashboardWrapper from "./DashboardWrapper";
import MobileSwitch from "components/mobile-toggler/MobileSwitch";

const AdminLayout = () => {
  return (
    <DashboardWrapper>
      <SideBar />
      <AdminArea>
        <MobileSwitch />
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </AdminArea>
    </DashboardWrapper>
  );
};

export default AdminLayout;
