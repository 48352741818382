const TyreTitle = ({ title, subtitle, btnTitle, handleClick, reverse = false }: TyreTitleProps) => {
  return (
    <div className="report_title">
      <span className="report_bold">{reverse ? subtitle : title}</span> {reverse ? title : subtitle}
      {btnTitle && (
        <button className="report_download" onClick={handleClick}>
          {btnTitle}
        </button>
      )}
    </div>
  );
};

export default TyreTitle;

interface TyreTitleProps {
  title: string;
  subtitle?: string;
  reverse?: boolean;
  btnTitle?: string;
  handleClick?: () => void;
}
