import { IPoint } from "../redux/map/map_types";
import { ISettings } from "../redux/settings/settings_types";

export const formatDate = (date: string) => {
  return date.slice(8) + date.slice(4, 7) + date.slice(7, 8) + date.slice(0, 4);
};

export const dayTimes = (day: any) => {
  const events = Object.entries(day).reduce((acc: any, item: any) => {
    const date = item[1].date;
    if (acc[date]) {
      acc[date].push(item[1]);
    } else {
      acc[date] = [item[1]];
    }
    return acc;
  }, {});
  return events;
};

export const parseEvent = (point: IPoint, limit: ISettings) => {
  const result: any[] = [];

  if (point.sensors)
    point.sensors.forEach((sensor, index) => {
      const press = sensor[0];
      const temp = sensor[1];

      if (press > 0.45 && press < limit.bar_min) {
        result.push({
          index,
          type: "bar_min",
          value: press.toFixed(1),
          limit: limit.bar_min,
          units: "bar",
          point_id: point.id,
        });
      }
      if (press > limit.bar_max) {
        result.push({
          index,
          type: "bar_max",
          value: press.toFixed(1),
          limit: limit.bar_max,
          units: "bar",
          point_id: point.id,
        });
      }
      if (temp > limit.temp_max) {
        result.push({
          index,
          type: "temp_max",
          value: temp,
          limit: limit.temp_max,
          units: "°С",
          point_id: point.id,
        });
      }
    });

  if (point.speed > limit.speed_max) {
    result.push({
      index: 0,
      type: "speed_max",
      value: point.speed,
      limit: limit.speed_max,
      units: "км/ч",
      point_id: point.id,
    });
  }

  if (result.length === 0) {
    result.push({
      index: 0,
      type: "unknown",
      value: 0,
      limit: 0,
      units: "",
      point_id: point.id,
    });
  }

  return result;
};

const eventTypes: { [key: string]: string } = {
  bar_min: "Давление меньше допустимого",
  bar_max: "Давление больше допустимого",
  temp_max: "Превышение температуры",
  speed_max: "Превышение скорости",
};

export const typeToText = (type: string) => {
  if (type in eventTypes) return eventTypes[type];

  return "Неопознанное нарушение";
};
