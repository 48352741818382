import { useLayoutEffect } from "react";
import { CHART_COLORS } from "../helpers/charts";
import am4lang_ru_RU from "@amcharts/amcharts5/locales/ru_RU";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";

const Amcharts = ({ data, type }) => {
  useLayoutEffect(() => {
    let root = am5.Root.new(type);
    root.locale = am4lang_ru_RU;

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
        maxTooltipDistance: 0,
      })
    );

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: {
          timeUnit: "minute",
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        valueXField: "date",
      })
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          inversed: false,
        }),
      })
    );
    let scrollbar = chart.set(
      "scrollbarX",
      am5xy.XYChartScrollbar.new(root, {
        orientation: "horizontal",
        height: 50,
      })
    );

    chart.set(
      "scrollbarY",
      am5xy.XYChartScrollbar.new(root, {
        orientation: "vertical",
      })
    );

    let sbDateAxis = scrollbar.chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: {
          timeUnit: "minute",
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
      })
    );

    let sbValueAxis = scrollbar.chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    let sbSeries;

    let series;
    Object.entries(data).forEach((item, i) => {
      let tooltip1 = am5.Tooltip.new(root, {
        pointerOrientation: "horizontal",
        labelText: "{valueY}",
        getFillFromSprite: false,
      });

      tooltip1.get("background").setAll({
        fill: am5.color(CHART_COLORS[i]),
        pointerOrientation: "horizontal",
      });
      series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: item[0],
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: item[0],
          valueXField: "date",
          legendValueText: "{valueY}",
          tooltip: tooltip1,
        })
      );

      sbSeries = scrollbar.chart.series.push(
        am5xy.LineSeries.new(root, {
          valueYField: item[0],
          valueXField: "date",
          xAxis: sbDateAxis,
          yAxis: sbValueAxis,
        })
      );

      series.set("stroke", am5.color(CHART_COLORS[i]));
      series.data.setAll(item[1]);
      sbSeries.data.setAll(item[1]);

      if (item[0].match(/предел/g)) {
        series.strokes.template.setAll({
          strokeDasharray: [5, 10],
        });
      }
    });

    chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );

    let legend = chart.rightAxesContainer.children.push(
      am5.Legend.new(root, {
        width: 220,
        paddingLeft: 15,
        height: am5.percent(100),
        verticalScrollbar: am5.Scrollbar.new(root, {
          orientation: "vertical",
        }),
      })
    );

    legend.itemContainers.template.events.on("pointerover", function (e) {
      let itemContainer = e.target;
      let series = itemContainer.dataItem.dataContext;

      chart.series.each(function (chartSeries, i) {
        if (chartSeries != series) {
          chartSeries.strokes.template.setAll({
            strokeOpacity: 0.15,
            stroke: am5.color(CHART_COLORS[i]),
          });
        } else {
          chartSeries.strokes.template.setAll({
            strokeWidth: 3,
          });
        }
      });
    });

    legend.itemContainers.template.events.on("pointerout", function (e) {
      let itemContainer = e.target;
      let series = itemContainer.dataItem.dataContext;
      chart.series.each(function (chartSeries, i) {
        chartSeries.strokes.template.setAll({
          strokeOpacity: 1,
          strokeWidth: 1,
          stroke: chartSeries.get("stroke", am5.color(CHART_COLORS[i])),
        });
      });
    });

    legend.itemContainers.template.set("width", am5.p100);
    legend.valueLabels.template.setAll({
      width: am5.p100,
      textAlign: "right",
    });

    legend.data.setAll(chart.series.values);

    return () => {
      {
        root.dispose();
        legend.dispose();
      }
    };
  }, [data]);

  return <div className="chart_field" id={type} style={{ width: "90%", height: "400px" }}></div>;
};

export default Amcharts;
