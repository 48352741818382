import { useTypedSelector } from "hooks/useTypedSelector";
import { UserState } from "redux/user/user_types";
import { LinkMe } from "./Header.styled";
import { useContext, useRef, useState } from "react";
import { ReactComponent as Cross } from "assets/images/cross.svg";
import useClickOutside from "hooks/useClickOutside";
import { MobileContext } from "context/MobileContext";

const HeaderNavs = () => {
  const user: UserState = useTypedSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const menu = useRef<HTMLHeadingElement>(null);

  useClickOutside(menu, () => (isOpen ? setIsOpen(false) : {}));
  const { toggled, setToggled } = useContext(MobileContext);

  const handlerOpen = () => {
    setIsOpen(false);
    setToggled(false);
  };

  return (
    <div className="header-navs">
      <button role="menu" className="header-navs__toggle" onClick={() => setIsOpen(true)}>
        Меню
      </button>

      <div ref={menu} className={`header-navs__menu${isOpen ? " header-navs__menu--active" : ""}`}>
        {(user.data.role === "admin" || user.data.role === "developer") && (
          <LinkMe to="/admin/devices" title="Перейти в админку" onClick={() => handlerOpen()}>
            Админка
          </LinkMe>
        )}
        <LinkMe
          to="/profile"
          title="Перейти в настройки пользователя"
          onClick={() => handlerOpen()}
        >
          {user?.data?.name}
        </LinkMe>

        <button role="banner" className="header-navs__close" onClick={() => setIsOpen(false)}>
          <Cross className="logo_exit" />
        </button>
      </div>
    </div>
  );
};

export default HeaderNavs;
