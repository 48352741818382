export interface LoginState {
  error_email: string;
  error_password: string;
}
export enum LoginActionTypes {
  LOGIN_EMAIL_ERROR = "LOGIN_EMAIL_ERROR",
}

interface LoginEmailError {
  type: LoginActionTypes.LOGIN_EMAIL_ERROR;
  payload: string;
}

export type LoginActions = LoginEmailError;
