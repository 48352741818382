import EventRow from "components/events/EventRow";
import moment from "moment";
import { TyreProps } from "redux/tyres/tyres_types";

const TyreHead = (tyre: TyreProps) => {
  return (
    <div className="margin-bottom-40">
      <div className="report_total">
        <div className="report_date report_bold">
          <div className="report_date-title">{}</div>
        </div>
        <EventRow
          part1={{
            title: "Название и размер шины",
            data: {
              item1: { title: "бренд", data: tyre.brand },
              item2: { title: "модель", data: tyre.model },
              item3: { title: "размер", data: tyre.size },
            },
          }}
          part2={{
            title: "Данные о шине",
            data: {
              item1: { title: "DOT", data: tyre.dot },
              item2: {
                title: "глубина протектора",
                data: tyre.last_action?.tread ? tyre.last_action?.tread : tyre.tread_start,
                smallTitle: " (мм)",
              },
              item3: { title: "стоимость", data: tyre.price, smallTitle: " руб" },
            },
          }}
        />
        <EventRow
          part1={{
            title: "Данные о пробеге",
            data: {
              item1: {
                title: "пробег",
                data: tyre.last_action?.run ? tyre.last_action?.run : tyre.run_start,
                smallTitle: " км/ч",
              },
              item2: { title: "стоимость", data: tyre.price },
              item3: { title: "", data: "" },
            },
          }}
          part2={{
            title: "Дата производства и установки",
            data: {
              item1: {
                title: "дата производства",
                data: moment(tyre.production_date).format("DD.MM.YYYY"),
              },
              item2: {
                title: "дата установки",
                data: moment(tyre.last_move?.updated_at).format("DD.MM.YYYY"),
              },
              item3: { title: "", data: "", smallTitle: "" },
            },
          }}
        />
      </div>
    </div>
  );
};

export default TyreHead;
