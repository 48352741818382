import { MobileContext } from "context/MobileContext";
import { ReactNode, useContext } from "react";

const DashboardWrapper = ({ children }: { children: ReactNode }) => {
  const { toggled } = useContext(MobileContext);

  return (
    <div className="dashboard">
      <div className={`dashboard__content${toggled ? " dashboard__content--active" : ""}`}>
        {children}
      </div>
    </div>
  );
};

export default DashboardWrapper;
