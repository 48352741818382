import { ReactComponent as NoDataImg } from "../../assets/images/no-data.svg";

const NoData = ({ title, text = true }: { title?: string; text?: boolean }) => {
  const titlePrint: string = title ?? "Сигнала от данного устройства пока не было";

  return (
    <div className="no-data">
      <div className="no-data__img-container">
        <NoDataImg />
      </div>
      <div className="no-data__title">{titlePrint}</div>

      {text && (
        <div className="no-data__text">
          Убедитесь, что устройство корректно установлено и работает, тогда данные скоро поступят
        </div>
      )}
    </div>
  );
};

export default NoData;
