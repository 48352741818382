import ReportCount from "components/reports/report-components/ReportCount";
import { TyreInfoProps } from "./interfaces";

const TyreInfo = ({ firstCount, secondCount, showConstructor }: TyreInfoProps) => {
  return (
    <div className="tyre_data">
      <ReportCount count={firstCount.count} title1={firstCount.title1} title2={firstCount.title2} />
      <div></div>
      <ReportCount
        count={secondCount.count}
        title1={secondCount.title1}
        title2={secondCount.title2}
      />
    </div>
  );
};

export default TyreInfo;
