const Sensor = ({ sensor, index }: any) => {
  return (
    <div className="sensor">
      <div className="sensor_number">
        №<span className="report_bold">{index + 1}</span>{" "}
      </div>
      <div className="sensor_params">
        <div className="sensor_pressure-max">{sensor[0]}</div>
        <div className="sensor_temp">{sensor[1]}</div>
      </div>
    </div>
  );
};

export default Sensor;
