import { ReactComponent as Arrow } from "../../assets/images/Arrow2.svg";
import { ReactComponent as Speed } from "../../assets/images/speed_error.svg";
import { ReactComponent as Pressure } from "../../assets/images/pressure_error.svg";
import { ReactComponent as Temp } from "../../assets/images/temp_error.svg";
import { typeToText } from "../../helpers/events";
import { useActions } from "hooks/useAction";

const EventTime = ({ event }: { event: any }) => {
  let logo;
  let reportTimeLimit;

  if (event.type !== "speed_max") {
    reportTimeLimit = (
      <div className="report_time-limit">
        датчик № <span className="report_bold">{event.index + 1}</span>
      </div>
    );

    if (event.type === "temp_max") {
      logo = <Temp />;
    }

    if (event.type === "bar_min" || event.type === "bar_max") {
      logo = <Pressure />;
    }
  }

  if (event.type == "speed_max") {
    logo = <Speed />;

    reportTimeLimit = (
      <div className="report_time-limit">
        на <span className="report_bold">{event.value - event.limit}</span> км/ч
      </div>
    );
  }

  const { getPoint, toggleModal } = useActions();
  const handlePoint = () => {
    toggleModal({ toggle: true, modal: "modal_point" });
    getPoint(event.point_id);
  };

  return (
    <div className="report_time-event">
      <div className="report_time-violation">{typeToText(event.type)}</div>
      {reportTimeLimit}

      <div className="report_time-param">
        {logo}
        <p>
          <span className="report_time-param--bold">{event.value}</span>
          {event.units}
        </p>
      </div>

      <button className="report_time-more" onClick={() => handlePoint()}>
        <span className="time-more_text">Подробнее</span>
        <Arrow className="arrow" />
      </button>
    </div>
  );
};

export default EventTime;
