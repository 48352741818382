import React, { useContext, useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import CarsModal from "../cars/CarsModal";
import { CarsModalContext } from "../../context/CarsModalContext";
import SkeletonLoading from "../skeleton-loading/SkeletonLoading";
import ModalWindow from "./ModalWindow";
import ModalSearch from "./modal-components/ModalSearch";
import { ModalsState } from "../../redux/modals/modals_types";
import { catchErrors, FooterEdit, FooterNew } from "./modal-components/foldersHelper";
import ModalInput from "../ui/Inputs/ModalInput";
import {
  useAddFolderMutation,
  useDeleteFolderMutation,
  usePutFolderMutation,
} from "redux/folder/FolderApi";
import { IFolder } from "redux/folder/FolderType";
import { toast } from "react-hot-toast";
import { ModalUserCarContext } from "components/user/context/ModalUserCarContext";
import { ICarsModalState } from "redux/cars_modal/cars_modal_types";
import api from "api";

const ModalFolders = () => {
  const { modal_group }: ModalsState = useTypedSelector((state) => state.modals);
  const { data }: ICarsModalState = useTypedSelector((state) => state.carsModal);
  const folderFromReducer: IFolder | null = useTypedSelector((state) => state.folder.folder);
  const { resetCarsModalContext, setVehiclesId, vehiclesId } = useContext(CarsModalContext);
  const { userCarList, userCurrent, setuserCarList } = useContext(ModalUserCarContext);

  const [submiting, setSubmiting] = useState<boolean>(false);
  const [titleError, setTitleError] = useState<string[]>([]);
  const [folderTitle, setFolderTitle] = useState<string>("");
  const [currentFolder, setCurrentFolder] = useState<IFolder | null>(null);

  useEffect(() => {
    if (folderFromReducer !== null) setCurrentFolder(folderFromReducer);
  }, [folderFromReducer]);

  const {
    unselectFolder,
    changeTitleFolder,
    getCarsModal,
    toggleModal,
    toggleModalConfirm,
    changeFilterModalCar,
    getCars,
    selectFolder,
  } = useActions();

  const [addFolder] = useAddFolderMutation();
  const [deleteFolder] = useDeleteFolderMutation();
  const [putFolder, {}] = usePutFolderMutation();

  useEffect(() => {
    if (!modal_group) {
      handleResetStates();
      return;
    }

    getCarsModal(userCarList);
  }, [modal_group]);

  useEffect(() => {
    if (currentFolder)
      setVehiclesId(
        data
          .filter(({ folder_id }: { folder_id: number }) => currentFolder.id === folder_id)
          .map(({ id }: { id: number }) => id)
      );
  }, [currentFolder]);

  const openConfirm = () => {
    toggleModal({ toggle: false, modal: "modal_group" });
    toggleModalConfirm({
      toggle: true,
      title: "Подтвердите действие",
      content: "Вы уверены, что хотите удалить список?",
      onConfirm: confirmDelete,
      onCancel: cancelDelete,
    });
  };

  const confirmDelete = (): void => {
    handleDelete();
    handleClose();
  };

  const cancelDelete = (): void => {
    if (currentFolder) selectFolder(currentFolder);
    toggleModal({ toggle: true, modal: "modal_group" });
  };

  const handleResetStates = () => {
    setFolderTitle("");
    setSubmiting(false);
    setTitleError([]);
    resetCarsModalContext();
    changeFilterModalCar("");
    unselectFolder({});
  };

  const handleClose = () => {
    handleResetStates();
    toggleModal({ toggle: false, modal: "modal_group" });
    setCurrentFolder(null);
  };

  const handleDelete = async () => {
    if (!currentFolder?.id) return;

    await deleteFolder(currentFolder.id)
      .unwrap()
      .then((response) => {
        toast.success(response.message);
        getCars();
      })
      .catch((e) => {
        toast.error(e.data.message);
      });
  };

  const handleSave = async () => {
    setSubmiting(true);
    if (currentFolder?.id != null && currentFolder?.title != null) {
      await putFolder({ id: currentFolder.id, title: currentFolder.title, vehicles: vehiclesId })
        .unwrap()
        .then(async () => {
          toast("Данные изменены");
          handleClose();
          getCars();
          if (userCurrent) {
            await api.getUserCars(userCurrent).then((res) => {
              setuserCarList(res.vehicles);
            });
          }
        })
        .catch((e) => {
          catchErrors(e, setTitleError, toast);
        });
    } else {
      await addFolder({ title: folderTitle, vehicles: vehiclesId })
        .unwrap()
        .then(() => {
          handleResetStates();
          toast.success("Вы добавили список");
          handleClose();
          getCars();
        })
        .catch((e) => {
          catchErrors(e, setTitleError, toast);
        });
    }
    setSubmiting(false);
  };

  let modalTitle = "Добавить список";

  let inputTitle = (
    <ModalInput
      onChange={(e: React.FormEvent<HTMLInputElement>) =>
        setFolderTitle((e.target as HTMLInputElement).value)
      }
      title={"Название списка"}
      placeholder="Введите название"
      value={folderTitle}
      errors={titleError}
      length={30}
    />
  );

  let footer = <FooterNew save={handleSave} />;

  if (currentFolder) {
    modalTitle = "Редактирование списка";

    inputTitle = (
      <ModalInput
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          changeTitleFolder((e.target as HTMLInputElement).value)
        }
        title={"Название списка"}
        placeholder="Введите название"
        value={currentFolder.title}
        errors={titleError}
        length={30}
      />
    );

    footer = <FooterEdit openConfirm={openConfirm} handleSave={handleSave} />;
  }

  let content = (
    <>
      <div className="modal-content__box">
        {inputTitle}
        {currentFolder && <div className="d-flex align-items-center h-100"></div>}
      </div>
      <ModalSearch />
      <CarsModal />
      {footer}
    </>
  );

  if (submiting) content = <SkeletonLoading height="480px" />;

  return (
    <ModalWindow
      isShow={modal_group}
      title={modalTitle}
      dialogClassName="modal__box-big"
      handleClose={handleClose}
    >
      {content}
    </ModalWindow>
  );
};

export default ModalFolders;
