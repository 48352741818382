import EventColumn from "./EventColumn";

const EventRowPart = ({ title, data }: EventRowPartProps) => {
  return (
    <div className="report_data-col ">
      <div className="report_title report_info-title">{title}</div>
      <div className="report_info report_info-three">
        <EventColumn
          classColumn="report_column_day"
          title={data.item1.title}
          data={data.item1.data}
          smallTitle={data.item1.smallTitle}
        />
        <EventColumn
          classColumn="report_column_day"
          title={data.item2.title}
          data={data.item2.data}
          smallTitle={data.item2.smallTitle}
        />
        <EventColumn
          classColumn="report_column_day"
          title={data.item3.title}
          data={data.item3.data}
          smallTitle={data.item3.smallTitle}
        />
      </div>
    </div>
  );
};

export default EventRowPart;

interface EventColumnProps {
  title: string;
  data: string | number;
  smallTitle?: string;
}

export interface EventRowPartProps {
  title: string;
  data: {
    item1: EventColumnProps;
    item2: EventColumnProps;
    item3: EventColumnProps;
  };
}
