import { useActions } from "../../hooks/useAction";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { hasAll, like } from "../../helpers";
import Checkbox from "../ui/Checkbox/Checkbox";
import React from "react";

const DashboardMultiple = () => {
  const { toggleMultiple, multiSelect } = useActions();
  const map = useTypedSelector((state) => state.map);
  const cars = useTypedSelector((state) => state.cars);

  if (!map.isMultiple)
    return (
      <button type="button" className="side-bar__btn-choose" onClick={() => toggleMultiple(true)}>
        Выбрать несколько ТС
      </button>
    );

  const filteredCars = cars.cars.filter(
    (car) => !cars.filter || like(car.car_number, cars.filter) || like(car.title, cars.filter)
  );

  const isAllSelected = () => {
    if (!filteredCars.length || !map.multiSelected.length) return false;

    return hasAll(
      filteredCars.map((car) => car.id),
      map.multiSelected
    );
  };

  const handleMultiSelect = () => {
    multiSelect({ ids: filteredCars.map((car) => car.id), toggle: !isAllSelected() });
  };

  return (
    <div className="side-bar__choose">
      <Checkbox
        classesLabel="side-bar__btn-choose side-bar__btn-choose--label"
        onChange={() => handleMultiSelect()}
        checked={isAllSelected()}
        disabled={!filteredCars.length}
        text="Выбрать все"
      />
      <button
        type="button"
        className="side-bar__btn-choose side-bar__btn-choose--cancel"
        onClick={() => toggleMultiple(false)}
      >
        Отмена
      </button>
    </div>
  );
};

export default DashboardMultiple;
