import { ICar } from "redux/car/car_types";
import UserVehicleView from "./UserVehicleView";
import UserVehicleWrapper from "./UserVehicleWrapper";
import UserVehicleStatistic from "./UserVehicleStatistic";
import UserVehicleButtons from "./UserVehicleButtons";
import UserVehicleIgnored from "./UserVehicleIgnored";
import UserVehiclePoinstCount from "./UserVehiclePoinstCount";

const UserVehicle = ({ car, userId }: { car: ICar; userId: number }) => {
  return (
    <UserVehicleWrapper
      leftPart={<UserVehicleView car={car} />}
      rightPart={
        <div>
          <UserVehicleStatistic car={car} />
          <UserVehicleIgnored car={car} />
        </div>
      }
      bottomPart={
        <div className="flex wrap">
          <UserVehicleButtons car={car} userId={userId} />
          <UserVehiclePoinstCount {...car} />
        </div>
      }
    />
  );
};

export default UserVehicle;
