import { FC, useContext } from "react";
import { ICar } from "../../../redux/car/car_types";
import CarMenu from "./CarMenu";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useAction";
import Checkbox from "../../ui/Checkbox/Checkbox";
import {
  Vehicle,
  VehicleWrapper,
  VehicleLink,
  VehicleState,
  VehicleInfo,
  VehicleFullNumber,
  VehicleNumber,
  VehicleRegion,
  VehicleAddress,
} from "../Cars.styled";
import { ReactComponent as Dots } from "assets/images/dots.svg";

import CarInfo from "./CarInfo";
import { Declination, formatMinutes } from "helpers";
import { MEDIA } from "constants/media";
import CarAddress from "./CarAddress";
import { MobileContext } from "context/MobileContext";
import useWindowSize from "hooks/useWindowSize";

const Car: FC<ICar> = (car) => {
  const cars = useTypedSelector((state) => state.cars);
  const map = useTypedSelector((state) => state.map);
  const { multiToggle, rememberCar } = useActions();

  const isMultiSelected = map.multiSelected.indexOf(car.id) >= 0;

  function handleMultiToggle() {
    multiToggle(car.id);
  }

  const findCar = (id: number): ICar | null => {
    const filtered = cars.cars.filter((localCar) => localCar.id === id);

    return filtered.length === 0 ? null : filtered[0];
  };

  const handleVehicleClick = (carId: number) => {
    const localCar = findCar(carId);

    if (localCar) rememberCar(localCar);
    if (size.width < MEDIA.mobile) {
      setToggled(false);
    }
  };

  let address = <VehicleAddress>Сигнала от данного устройства пока не было</VehicleAddress>;

  if (car.last_point) {
    const { minutes, formattedDiff, words } = formatMinutes(car.last_point.device_updated_at);

    if (minutes > 30) {
      address = (
        <VehicleAddress>
          Сигнал отсутствует уже {formattedDiff} {Declination(formattedDiff, words)}
        </VehicleAddress>
      );
    } else {
      address = <CarAddress address={car.last_point.address} />;
    }
  }

  const { toggled, setToggled } = useContext(MobileContext);
  const size = useWindowSize();

  return (
    <Vehicle>
      {map.isMultiple && <Checkbox onChange={handleMultiToggle} checked={isMultiSelected} />}

      <VehicleWrapper>
        <VehicleLink to={`/view/${car.id}`} onClick={() => handleVehicleClick(car.id)}>
          <CarInfo {...car} />
        </VehicleLink>

        {!map.isMultiple && <CarMenu car={car} icon={<Dots className="open__icon" />} />}
      </VehicleWrapper>
    </Vehicle>
  );
};

export default Car;
