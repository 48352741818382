import {
  IDevicesFilterState,
  DevicesFilterActionTypes,
  DevicesFilterActions,
} from "./device_filter_types";

const initialState: IDevicesFilterState = {
  devicesFiltered: [],
  typeFilterDevice: "",
  filterDevice: "",
};

const DevicesFilterReducer = (state = initialState, action: DevicesFilterActions) => {
  switch (action.type) {
    case DevicesFilterActionTypes.GET_DEVICES_FILTER:
      return {
        ...state,
        devicesFiltered: action.payload,
      };
    case DevicesFilterActionTypes.DEVICES_FILTER_SEARCH:
      return {
        ...state,
        filterDevice: action.payload,
      };
    case DevicesFilterActionTypes.DEVICES_TYPE_SEARCH:
      return {
        ...state,
        typeFilterDevice: action.payload,
      };
    default:
      return state;
  }
};

export default DevicesFilterReducer;
