const DEFAULT_BAR_MIN = 1;
const DEFAULT_BAR_MAX = 12;
const DEFAULT_TEMP_MAX = 90;
const DEFAULT_SPEED_MAX = 150;

export const DEFAULT_LIMIT_PAYLOAD = {
  bar_min: DEFAULT_BAR_MIN,
  bar_max: DEFAULT_BAR_MAX,
  temp_max: DEFAULT_TEMP_MAX,
  speed_max: DEFAULT_SPEED_MAX,
};
