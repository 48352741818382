import { ItemPackTypes } from "../Ping";
// import WheelList from "./WheelList";

const MainParams = ({ date, code, ignition, speed, lat, long, mileage, tires }: ItemPackTypes) => {
  return (
    <div>
      <div>
        Дата пакета: <strong>{date}</strong>, код: <strong>{code}</strong>, зажигание:{" "}
        <strong>{ignition ? "Вкл" : "Выкл"}</strong>, cкорость: <strong>{speed}</strong>,
        координаты:{" "}
        <strong>
          [{long}, {lat}]
        </strong>
      </div>
      <div>
        Пробег: <strong>{mileage.toLocaleString()}</strong>
      </div>
    </div>
  );
};

export default MainParams;
