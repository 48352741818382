import { createSlice } from "@reduxjs/toolkit";

export interface ISelectUser {
  email: string;
  name: string;
  role: string;
  profile_id: number;
  id: number;
}

export interface IUsersSlice {
  select_user: ISelectUser;
}

const initialState: IUsersSlice = {
  select_user: {} as ISelectUser,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    selectUser: (state, action) => {
      state.select_user = action.payload;
    },
  },
});

export const usersReducer = usersSlice.reducer;
export const usersActions = usersSlice.actions;
