import styled, { css } from "styled-components";
import { ButtonReset, Button } from "assets/styles/Buttons";
import { baseTheme } from "../../../assets/styles/Theme.styled";
import { ReactComponent as Settings } from "assets/images/settings.svg";

export const FolderEl = styled.div.attrs({
  "data-element": "folder",
})`
  display: flex;
  flex-direction: column;
  padding: 0;
  transition: all 0.1s ease;
  &:last-child {
    border-bottom: none;
  }
`;
export const FolderWrapper = styled.div.attrs({
  "data-element": "folder-wrapper",
})`
  display: flex;
  align-items: center;
  flex-grow: 1;
  transition: all 0.1s ease;
  border-radius: 5px;
  padding-right: 16px;
  &:hover {
    cursor: pointer;
    background-color: #edeff4;
  }
`;
export const FolderInfo = styled.button.attrs({
  "data-element": "button",
})`
  ${ButtonReset};
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 10px;
`;

export const FolderTitle = styled.div.attrs({
  "data-element": "folder-title",
})`
  flex-grow: 1;
  font-weight: 700;
  font-size: 11px;
  text-align: left;
  color: ${baseTheme.colors.darkGrey};
  text-transform: uppercase;
`;
export const FolderCount = styled.span`
  font-weight: 400;
  padding-left: 12px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 6px;
    width: 1px;
    height: 100%;
    background-color: grey;
  }
`;

export const FolderCars = styled.div.attrs({
  "data-element": "folder-cars",
})`
  padding-left: 15px;
  margin-top: 10px;
  padding-bottom: 1rem;
  padding-right: 16px;
`;
export const ArrowEl = styled.div<{ active?: boolean }>`
  transition: all 0.1s ease;
  width: 30px;
  ${({ active }) =>
    active &&
    css`
      transform: rotate(90deg);
    `}
`;
export const FolderMenuButton = styled.button.attrs({
  type: "button",
})`
  ${ButtonReset};
  ${Button};
  padding: 0 5px;
`;

export const FolderSettingsIcon = styled(Settings).attrs({
  "data-element": "folder-settings-icon",
})`
  fill: #8e99ad;
  transition: all 0.1s ease;
  ${FolderMenuButton}:hover & {
    fill: #4f6288;
  }
`;
