import { Dispatch } from "redux";
import {
  CarsActions,
  CarsActionTypes,
  CarsUpdateAddressPayload,
  CarsUpdateFieldPayload,
} from "./cars_types";
import api from "../../api";

export const getCars = () => {
  return async (dispatch: Dispatch<CarsActions>) => {
    try {
      dispatch({ type: CarsActionTypes.CARS_LOADING, payload: true });

      const response = await api.getCars();
      dispatch({
        type: CarsActionTypes.GET_CARS,
        payload: response.vehicles,
      });
      dispatch({ type: CarsActionTypes.CARS_LOADING, payload: false });
    } catch (e: any) {
      return e;
    }
  };
};

export const carsUpdateAddress = (payload: CarsUpdateAddressPayload) => {
  return async (dispatch: Dispatch<CarsActions>) => {
    dispatch({ type: CarsActionTypes.CARS_UPDATE_ADDRESS, payload });
  };
};

export const changeFilter = (payload: string) => {
  return (dispatch: Dispatch<CarsActions>) => {
    dispatch({ type: CarsActionTypes.CARS_FILTER, payload });
  };
};

export const resetCars = () => {
  return (dispatch: Dispatch<CarsActions>) => {
    dispatch({ type: CarsActionTypes.CARS_RESET });
  };
};

export const carsUpdateLastPoint = (carId: number, deviceId: number) => {
  return async (dispatch: Dispatch<CarsActions>) => {
    const response = await api.getLastPoint(deviceId);

    if (!response?.data?.point) return;

    const payload = {
      id: carId,
      field: "last_point",
      value: response.data.point,
    } as CarsUpdateFieldPayload;

    dispatch({ type: CarsActionTypes.CARS_UPDATE_FIELD, payload });
  };
};
