import Checkbox from "components/ui/Checkbox/Checkbox";

const PingHead = ({ setFilter, filter, showHex, handleHex, isPause, setPause }: PingHeadTypes) => {
  const handleRemember = () => {
    if (filter) localStorage.setItem("__filter", filter);
    if (!filter) localStorage.removeItem("__filter");
  };

  return (
    <div
      style={{
        margin: "10px 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ marginRight: "auto", display: "flex" }}>
        <button
          style={{ marginRight: "1rem" }}
          className="button button--mint"
          onClick={() => setPause(!isPause)}
        >
          {!isPause ? "Пауза" : "Продолжить"}
        </button>
        <Checkbox checked={showHex} text="Показать HEX" onChange={() => handleHex()} />
      </div>

      <input
        className="profile__input"
        type="text"
        placeholder="фильтр по ID"
        value={filter}
        onChange={(e) => setFilter(e.target.value.trim())}
        style={{ width: "200px" }}
      />

      <button
        style={{ marginLeft: "1rem" }}
        className="button button--mint"
        onClick={() => handleRemember()}
      >
        Запомнить
      </button>
    </div>
  );
};

export default PingHead;

interface PingHeadTypes {
  setFilter: (filter: string) => void;
  filter: string;
  showHex: boolean;
  handleHex: () => void;
  isPause: boolean;
  setPause: (isPause: boolean) => void;
}
