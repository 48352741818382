import { memo, useContext } from "react";
import { SideBarEl, SidebarMenu, SidebarMenuItem, SidebarMenuLink } from "./SideBar.styled";
import { ViewContainer, ViewHeader } from "assets/styles/VehicleView.styled";
import SideBarGoBack from "components/ui/Buttons/SideBarGoBack";
import { useTypedSelector } from "hooks/useTypedSelector";
import { UserState } from "redux/user/user_types";
import { MobileContext } from "context/MobileContext";
import useWindowSize from "hooks/useWindowSize";

const links = [
  {
    link: "/admin/devices",
    text: "Устройства",
  },
  {
    link: "/admin/users",
    text: "Пользователи",
    onlyForAdmin: true,
  },
  {
    link: "/admin/profiles",
    text: "Профили",
    onlyForAdmin: true,
  },
  {
    link: "/admin/ping",
    text: "Пинг",
  },
];

const SideBar = () => {
  const user: UserState = useTypedSelector((state) => state.user);

  const { toggled, setToggled } = useContext(MobileContext);
  const size = useWindowSize();

  const handlerMobileView = () => {
    if (size.width < 767.9) {
      setToggled(true);
    }
  };

  return (
    <SideBarEl>
      <ViewContainer>
        <ViewHeader>
          <SideBarGoBack to="/" text="Назад" />
        </ViewHeader>

        <SidebarMenu>
          {links
            .filter(
              (link) =>
                user.data.role === "admin" || (user.data.role === "developer" && !link.onlyForAdmin)
            )
            .map((link, index) => {
              return (
                <SidebarMenuItem key={index} onClick={handlerMobileView}>
                  <SidebarMenuLink to={link.link}>
                    <strong>{link.text}</strong>
                  </SidebarMenuLink>
                </SidebarMenuItem>
              );
            })}
        </SidebarMenu>
      </ViewContainer>
    </SideBarEl>
  );
};

export default memo(SideBar);
