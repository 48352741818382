export const baseTheme = {
  fonts: {
    OpenSans: "Open Sans, sans-serif",
  },
  colors: {
    lightLightGrey: "#EDEFF4",
    main: "#2F4267",
    bg: "#fff",
    darkGrey: "#2F4267",
    lightGrey: "#C5CBD9",
    middleGrey: "#4F6288",
    grey: "#8E99AD",
  },
  sizes: {
    headerHeight: "60px",
    sideBarWidth: "452px",
  },
};
