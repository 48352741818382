import { useContext, useEffect, useState } from "react";
import { useActions } from "hooks/useAction";
import { useTypedSelector } from "hooks/useTypedSelector";
import { IFolder } from "redux/folder/FolderType";
import Car from "components/cars/car/Car";
import { hasAll, like } from "helpers";
import Checkbox from "components/ui/Checkbox/Checkbox";
import {
  ArrowEl,
  FolderCars,
  FolderCount,
  FolderEl,
  FolderInfo,
  FolderMenuButton,
  FolderSettingsIcon,
  FolderTitle,
  FolderWrapper,
} from "./Folder.styled";

import { ReactComponent as Arrow } from "assets/images/arrow.svg";
import { ModalUserCarContext } from "components/user/context/ModalUserCarContext";
import { ICar } from "redux/car/car_types";
import UserVehicle from "components/user/components/UserVehicle";
import FolderInfoEl from "./FolderInfoEl";
import CarTyre from "components/cars/car/CarTyre";
import { useParams } from "react-router-dom";

const Folder = ({
  cars,
  group,
  userId,
  tire = false,
}: {
  cars: ICar[];
  group: IFolder;
  userId?: number;
  tire?: boolean;
}) => {
  const handleShow = () => toggleModal({ toggle: true, modal: "modal_group" });
  const { selectFolder, toggleModal, multiSelect } = useActions();
  const { filter } = useTypedSelector((state) => state.cars);
  const map = useTypedSelector((state) => state.map);
  const [isOpenFolder, setOpenFolder] = useState(false);
  const { setuserCarList, setUserCurrent } = useContext(ModalUserCarContext);

  const { vehicle_id } = useParams<{ vehicle_id?: string }>();

  const handleModal = (folder: IFolder): void => {
    if (userId) setUserCurrent(userId);
    selectFolder(folder);
    setuserCarList(cars);
    handleShow();
  };

  const filteredCars = cars
    .filter((car) => !filter || like(car.car_number, filter) || like(car.title, filter))
    .filter((car) => car.folder_id === group.id);

  useEffect(() => {
    if (vehicle_id && filteredCars.filter((car) => car.id == +vehicle_id).length > 0) {
      setOpenFolder(true);
    }
  }, [filteredCars]);

  let folderCars = <></>;

  if (!filteredCars.length && !filter)
    folderCars = <div className="message-warning">Список пуст</div>;

  if (filteredCars.length)
    folderCars = (
      <>
        {filteredCars.map((car) =>
          userId ? (
            <UserVehicle key={car.id} car={car} userId={userId} />
          ) : !tire ? (
            <Car key={car.id} {...car} />
          ) : (
            <CarTyre key={car.id} {...car} />
          )
        )}
      </>
    );

  const isAllSelected = () => {
    if (!filteredCars.length || !map.multiSelected.length) return false;

    return hasAll(
      filteredCars.map((car) => car.id),
      map.multiSelected
    );
  };

  const handleMultiSelect = () => {
    multiSelect({ ids: filteredCars.map((car) => car.id), toggle: !isAllSelected() });
  };

  return (
    <FolderEl>
      <FolderWrapper>
        {map.isMultiple && (
          <Checkbox
            classesLabel="checkbox--folder"
            onChange={() => handleMultiSelect()}
            checked={isAllSelected()}
            disabled={!group.count}
          />
        )}
        <FolderInfoEl group={group} setOpenFolder={setOpenFolder} isOpenFolder={isOpenFolder} />
        {!tire && (
          <FolderMenuButton onClick={() => handleModal(group)}>
            <FolderSettingsIcon />
          </FolderMenuButton>
        )}
      </FolderWrapper>
      {((isOpenFolder && !filter) || (filter && !!filteredCars.length)) && (
        <FolderCars>{folderCars}</FolderCars>
      )}
    </FolderEl>
  );
};

export default Folder;
