import { memo } from "react";
import Folder from "./folder/Folder";
import { FoldersEl } from "./Folders.styled";
import { IFolder } from "redux/folder/FolderType";
import { ICar } from "redux/car/car_types";

const Folders = ({
  cars,
  data,
  userId,
  tire = false,
}: {
  cars: ICar[];
  data: IFolder[] | undefined;
  userId?: number;
  tire?: boolean;
}) => {
  if (!data || data.length === 0) return <></>;

  return (
    <FoldersEl>
      {data?.map((group) => {
        return <Folder key={group.id} group={group} cars={cars} userId={userId} tire={tire} />;
      })}
    </FoldersEl>
  );
};

export default memo(Folders);
