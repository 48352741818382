import { IPoint } from "../../../redux/map/map_types";
import { ReactComponent as CarDrive } from "../../../assets/images/car_drive.svg";
import { ReactComponent as CarSleep } from "../../../assets/images/car_sleep.svg";
import { ReactComponent as CarError } from "../../../assets/images/car_error.svg";
import { formatMinutes } from "../../../helpers";

const CarIcon = ({ lastPoint }: { lastPoint?: IPoint | null }) => {
  if (!lastPoint) return <CarError className="vehicle__icon" />;

  const { device_created_at, device_updated_at } = lastPoint;
  const { minutes } = formatMinutes(device_updated_at);

  if (minutes > 30) {
    return <CarError className="vehicle__icon" />;
  }

  if (device_created_at === device_updated_at) return <CarDrive className="vehicle__icon" />;
  return <CarSleep className="vehicle__icon" />;
};

export default CarIcon;
