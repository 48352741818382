import { useEffect, useState } from "react";
import { useActions } from "../../hooks/useAction";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IDevice } from "../../redux/admin/devices/DevicesTypes";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import ModalRemove from "../../components/admin/ModalRemove";
import { useGetDevicesQuery, useDeleteDeviceMutation } from "redux/admin/devices/DevicesApi";
import Device from "components/admin/device/Device";
import { Col } from "react-bootstrap";
import toast from "react-hot-toast";
import SearchAdmin from "components/admin/search/SearchAdmin";
import { IDevicesFilterState } from "redux/device_filter/device_filter_types";

const DevicesAdminPage = () => {
  const [removeDeviceId, setRemoveDeviceId] = useState<number>(0);

  const { modal_remove } = useTypedSelector((state) => state.modals);

  const { toggleModal, selectDevice, getDevicesFilter } = useActions();

  const { data, isLoading } = useGetDevicesQuery();

  const [deleteDevice] = useDeleteDeviceMutation();

  const handleOpenModalRemoveDevice = (id: number) => {
    setRemoveDeviceId(id);
    toggleModal({ toggle: true, modal: "modal_remove" });
  };

  const handleRemoveDevice = () => {
    deleteDevice(removeDeviceId)
      .then((res: any) => {
        toast.success(res.data.message);
        handleCancelRemoveDevice();
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  const handleCancelRemoveDevice = () => {
    toggleModal({ toggle: false, modal: "modal_remove" });
  };

  const handleModelAddDevice = () => {
    toggleModal({ toggle: true, modal: "modal_add_device" });
  };

  const handleOpenModalEdit = (device: IDevice) => {
    selectDevice(device);
    toggleModal({ toggle: true, modal: "modal_add_device" });
  };
  const { devicesFiltered, filterDevice, typeFilterDevice }: IDevicesFilterState = useTypedSelector(
    (state) => state.deviceFilter
  );

  useEffect(() => {
    if (!!data && data.length > 0) {
      getDevicesFilter(data);
    }
    if (!!data && data.length > 0 && filterDevice != "") {
      getDevicesFilter(data, typeFilterDevice, filterDevice);
    }
  }, [data]);

  if (isLoading) return <>Loading....</>;

  return (
    <>
      <button onClick={handleModelAddDevice} className="btn btn-device-add">
        Добавить устройство <IoIosAddCircleOutline />
      </button>
      <br />
      <SearchAdmin
        filterType="device"
        fieldes={["device_id", "phone", "profile_id", "car_number"]}
      />
      <br />
      <br />
      <div className="row">
        {devicesFiltered.map((device: IDevice) => {
          return (
            <Col lg={12} md={12} sm={12} xs={12} className="col-device" key={device.id}>
              <Device
                device={device}
                handleOpenModalEdit={handleOpenModalEdit}
                handleOpenModalRemoveDevice={handleOpenModalRemoveDevice}
              />
            </Col>
          );
        })}
      </div>

      <ModalRemove
        title="Удалить устройство?"
        show={modal_remove}
        callbackYes={() => handleRemoveDevice()}
        callbackNo={() => handleCancelRemoveDevice()}
      />
    </>
  );
};

export default DevicesAdminPage;
