import { LoginActions } from "./login_types";
import { UserActions, UserActionTypes, IUser } from "../user/user_types";
import { Dispatch } from "redux";
import api from "../../api";
import { IServerResponse } from "../../types/other";
import axios from "axios";
import { GlobalApi } from "api/GlobalApi";

export const login = (email: string, password: string) => {
  return async (dispatch: Dispatch<UserActions | LoginActions>) => {
    try {
      const result = await api.login(email, password);

      localStorage.setItem("token", result?.data?.user?.token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + result?.data?.user?.token;

      dispatch({ type: UserActionTypes.GET_USER, payload: result?.data?.user });
      dispatch({ type: UserActionTypes.USER_AUTH, payload: true });

      return result;
    } catch (e: any) {
      return e.response;
    }
  };
};

export const logout = () => {
  return async (dispatch: Dispatch<UserActions | LoginActions>) => {
    const empty: IUser = {} as IUser;
    localStorage.removeItem("token");
    // dispatch(GlobalApi.util.resetApiState());
    dispatch({ type: UserActionTypes.GET_USER, payload: empty });
    dispatch({ type: UserActionTypes.USER_AUTH, payload: false });
  };
};

export const signIn = (name: string, email: string, password: string) => {
  return async () => {
    try {
      return await api.signUp(name, email, password);
    } catch (e) {
      return e as IServerResponse;
    }
  };
};
