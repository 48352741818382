import Image1 from "../assets/images/eye.svg";
import Image2 from "../assets/images/eye-blue.svg";
import Battery from "../assets/images/battery.svg";
import Truck from "../assets/images/parallax/truck.png";
import HeavyTruck from "../assets/images/parallax/heavytruck.png";
import Fendt from "../assets/images/parallax/fendt.png";
import KustLeft from "../assets/images/parallax/kust-left.png";
import TreeLeft from "../assets/images/parallax/tree-left.png";
import ShadowLeft from "../assets/images/parallax/ten-left.png";
import KustRight from "../assets/images/parallax/kust-right.png";
import TreeRight from "../assets/images/parallax/tree-right.png";
import ShadowRight from "../assets/images/parallax/ten-right.png";

export const IMAGES = {
  Image1,
  Image2,
  Battery,
  Truck,
  HeavyTruck,
  Fendt,
  KustLeft,
  TreeLeft,
  ShadowLeft,
  KustRight,
  TreeRight,
  ShadowRight,
};
