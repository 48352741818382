import moment from "moment";

const DateTemplates = ({
  onDatesChange,
  from,
  to,
  short = false,
}: {
  onDatesChange: (from: string, to: string) => void;
  from: string;
  to: string;
  short?: boolean;
}) => {
  const SERVER_DATE = "YYYY-MM-DD";
  const TODAY = moment().format(SERVER_DATE);
  const YESTERDAY = moment().subtract(1, "day").format(SERVER_DATE);
  const WEEK_AGO = moment().subtract(1, "week").format(SERVER_DATE);
  const MONTH_AGO = moment().subtract(1, "month").format(SERVER_DATE);

  const handleToday = () => {
    onDatesChange(moment().format(SERVER_DATE), moment().format(SERVER_DATE));
  };

  const handleYesterday = () => {
    onDatesChange(YESTERDAY, YESTERDAY);
  };

  const handleWeek = () => {
    onDatesChange(WEEK_AGO, TODAY);
  };

  const handleMonth = () => {
    onDatesChange(MONTH_AGO, TODAY);
  };

  const isToday = from === TODAY && to === TODAY;

  const isYesterday = from === YESTERDAY && to === YESTERDAY;

  const isWeek = from === WEEK_AGO && to === TODAY;

  const isMonth = from === MONTH_AGO && to === TODAY;

  return (
    <div className="date-templates">
      <button
        type="button"
        className={`date-template${isToday ? " date-template--active" : ""}`}
        onClick={() => handleToday()}
      >
        Сегодня
      </button>

      <button
        type="button"
        className={`date-template${isYesterday ? " date-template--active" : ""}`}
        onClick={() => handleYesterday()}
      >
        Вчера
      </button>

      {!short && (
        <button
          type="button"
          className={`date-template${isWeek ? " date-template--active" : ""}`}
          onClick={() => handleWeek()}
        >
          Неделя
        </button>
      )}

      {!short && (
        <button
          type="button"
          className={`date-template${isMonth ? " date-template--active" : ""}`}
          onClick={() => handleMonth()}
        >
          Месяц
        </button>
      )}
    </div>
  );
};

export default DateTemplates;
