import MainRouter from "./MainRouter";
import { withErrorBoundary } from "react-error-boundary";
import { useEffect } from "react";
import { useActions } from "./hooks/useAction";
import axios from "axios";
import { UserState } from "./redux/user/user_types";
import { useTypedSelector } from "./hooks/useTypedSelector";
import Loader from "./components/loader/Loader";
import { baseUrl } from "./helpers/config";
import Fallback from "./pages/Fallback";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

function App() {
  const { userMe, loadingUser, logout } = useActions();
  const navigation = useNavigate();
  const user: UserState = useTypedSelector((state) => state.user);

  const handleLogout = () => {
    logout();
    navigation("/login");
  };

  useEffect(() => {
    if (!user.auth) init();
  }, [user.auth]);

  /////////////////////////
  const init = async () => {
    axios.defaults.baseURL = baseUrl;

    const token: string | null = localStorage.getItem("token");

    if (!token) {
      loadingUser(false);
      return;
    }

    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    const me: any = await userMe();

    if (me.status === 200 || me.status === 429) {
      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response && error.response.status === 401) {
            toast.error(error.response.data.message);

            handleLogout();
          }
          return Promise.reject(error);
        }
      );

      return;
    }

    toast.error(me.data.message);

    if (me.status === 401) handleLogout();
  };
  /////////////////////////

  if (user.loading) return <Loader />;
  return <MainRouter />;
}

export default withErrorBoundary(App, {
  fallback: <Fallback />,
});
