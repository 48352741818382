import { ReportActions, ReportActionsTypes, ReportState } from "./reports_types";

const initialState: ReportState = {
  report: {
    days: undefined,
    events: [],
    from: "",
    last_point: undefined,
    run: undefined,
    to: "",
    vehicle: undefined,
    // limit: {},
    // limit_text: '',
    // point: {}
  },
  loading: true,
  isReport: false,
  isShow: false,
};

const ReportsReducer = (state = initialState, action: ReportActions) => {
  switch (action.type) {
    case ReportActionsTypes.GET_REPORT:
      return {
        ...state,
        report: action.payload,
        loading: false,
      };
    case ReportActionsTypes.REPORT_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ReportActionsTypes.REPORT_IS:
      return {
        ...state,
        isReport: action.payload,
      };
    case ReportActionsTypes.SHOW_IS:
      return {
        ...state,
        isShow: action.payload,
      };
    default:
      return state;
  }
};

export default ReportsReducer;
