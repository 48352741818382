import { ModalsActions, ModalsActionTypes, ModalsState } from "./modals_types";

const initialState: ModalsState = {
  modal_group: false,
  modal_group_add: false,
  modal_car_extended_info: false,
  modal_general_settings: false,
  modal_add_transport: false,
  modal_edit_user_data: false,
  modal_set_new_password: false,
  modal_notifications: false,
  modal_add_device: false,
  modal_remove: false,
  modal_create_new_user: false,
  modal_loading: false,
  modal_point: false,
  modal_point_data: null,
  modal_confirm: false,
  modal_confirm__title: "",
  modal_confirm__content: null,
  modal_confirm__onConfirm: null,
  modal_confirm__onCancel: null,
  modal_new_password_user_by_moderator: false,
  modal_user_car_change: false,
  modal_user_car_add: false,
  modal_profile_change: false,
  modal_update_user: false,

  modal_add_tyre: false,
  modal_update_tyre: false,
  modal_add_action: false,

  modal_change_folder: false,
};

const ModalsReducer = (state = initialState, action: ModalsActions) => {
  switch (action.type) {
    case ModalsActionTypes.TOGGLE_MODAL:
      return {
        ...state,
        [action.payload.modal]: action.payload.toggle,
      };
    case ModalsActionTypes.TOGGLE_MODAL_CONFIRM:
      return {
        ...state,
        modal_confirm: action.payload.toggle,
        modal_confirm__title: action.payload.title,
        modal_confirm__content: action.payload.content,
        modal_confirm__onConfirm: action.payload.onConfirm,
        modal_confirm__onCancel: action.payload.onCancel,
      };
    case ModalsActionTypes.POINT_MODAL:
      return {
        ...state,
        modal_point_data: action.payload,
        modal_loading: false,
      };
    case ModalsActionTypes.MODAL_LOADING:
      return {
        ...state,
        modal_loading: action.payload,
      };
    default:
      return state;
  }
};

export default ModalsReducer;
