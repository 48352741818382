import React, { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import { ModalsState } from "../../redux/modals/modals_types";
import ModalInput from "../ui/Inputs/ModalInput";
import SkeletonLoading from "../skeleton-loading/SkeletonLoading";
import ModalWindow from "./ModalWindow";
import { useChangeUserMutation } from "redux/admin/users/UsersApi";
import toast from "react-hot-toast";
import { IUsersSlice } from "redux/admin/users/UsersSlice";
import { TEXT_ROLES } from "helpers";
import SelectInput from "components/select-input/SelectInput";
import { useGetProfilesQuery } from "redux/admin/profiles/ProfilesApi";

const ModalUpdateUser = () => {
  const users: IUsersSlice = useTypedSelector((state) => state.users);
  const [name, setName] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [profileId, setProfileId] = useState<any>(null);
  const [errors, setErrors] = useState<any>({
    name: [],
    role: [],
    profile_id: [],
  });
  const { toggleModal } = useActions();

  const { data } = useGetProfilesQuery();

  const optionsProfile: any = [];

  if (data) {
    data.profiles.forEach((profile: any) => {
      optionsProfile.push({ value: profile.id, label: profile.title });
    });
  }

  const [changeUser, { isLoading }] = useChangeUserMutation();

  useEffect(() => {
    if (users.select_user.name) {
      setName(users.select_user.name);
    }
    if (users.select_user.role) {
      setRole(users.select_user.role);
    }
    if (users.select_user.profile_id) {
      setProfileId(users.select_user.profile_id);
    }
  }, [users.select_user, data]);

  const modals: ModalsState = useTypedSelector((state) => state.modals);

  const handleClose = () => {
    console.log("update");

    toggleModal({ toggle: false, modal: "modal_update_user" });
    setName("");
    setRole("");
    setProfileId(null);
    setErrors({
      name: [],
      role: [],
      profile_id: [],
    });
  };

  const handleSave = async () => {
    await changeUser({ id: users.select_user.id, name, profile_id: +profileId, role })
      .unwrap()
      .then(() => {
        toast.success("Профиль изменен");
        handleClose();
      })
      .catch((error) => {
        if (error?.data?.errors) {
          setErrors({
            ...errors,
            name: error?.data?.errors?.name || [],
            role: error?.data?.errors?.role || [],
            profile_id: error?.data?.errors?.profile_id || [],
          });
        } else if (error?.data?.message) {
          toast.error(error.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  if (isLoading) {
    return (
      <ModalWindow
        isShow={modals.modal_update_user}
        title="Редактирование пользователя"
        handleClose={handleClose}
      >
        <SkeletonLoading height="402px" />
      </ModalWindow>
    );
  }
  const onChangeRole = (value: any) => {
    setRole(value);
  };

  const onChangeProfile = (value: any) => {
    setProfileId(value);
  };

  const options: any = [];

  Object.keys(TEXT_ROLES).forEach((key) => {
    options.push({ value: key, label: TEXT_ROLES[key] });
  });

  return (
    <ModalWindow
      isShow={modals.modal_update_user}
      title="Редактирование пользователя"
      handleClose={handleClose}
    >
      <div className="modal-content__box">
        <ModalInput
          title="Имя"
          value={name}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setName((e.target as HTMLInputElement).value)
          }
          errors={errors.name}
        />
        <div className="profile__item">
          <p className="company__role">Роль в системе:</p>
          <div>
            <SelectInput onChange={(e) => onChangeRole(e)} options={options} value={role} />
          </div>
        </div>
        <div className="profile__item">
          <p className="company__role">
            ID компании: <span className="company__current-role"> {profileId}</span>
          </p>
          <div>
            <SelectInput
              onChange={(e) => onChangeProfile(e)}
              options={optionsProfile}
              value={profileId}
            />
          </div>
        </div>
      </div>
      <div className="modal-edit__button-box">
        <button className="modal__button " onClick={(e) => handleSave()}>
          Сохранить
        </button>
      </div>
    </ModalWindow>
  );
};

export default ModalUpdateUser;
