import api from "api";
import SkeletonLoading from "components/skeleton-loading/SkeletonLoading";
import { useEffect, useState } from "react";
import { ICar } from "redux/car/car_types";

const UserVehicleIgnored = ({ car }: { car: ICar }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [localCar, setLocalCar] = useState<ICar | null>(null);

  useEffect(() => {
    if (localCar === null) setLocalCar({ ...car });
  }, []);

  const changeIgnored = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);

    if (localCar) {
      const result = await api.putCar(localCar.id, { is_sensors_ignored: e.target.checked });

      if (result?.data?.vehicle?.is_sensors_ignored === 1)
        setLocalCar({ ...localCar, is_sensors_ignored: true });
      if (result?.data?.vehicle?.is_sensors_ignored === 0)
        setLocalCar({ ...localCar, is_sensors_ignored: false });
      setIsLoading(false);
    }
  };

  if (isLoading || localCar === null)
    return (
      <div className="userVehicle__ignored">
        <SkeletonLoading height="1.5rem" />
      </div>
    );

  return (
    <div className="userVehicle__ignored">
      <label>
        <input
          type="checkbox"
          name="is_sensors_ignored"
          checked={localCar?.is_sensors_ignored}
          onChange={changeIgnored}
        />
        <span style={{ marginLeft: "0.4rem", fontSize: "13px", textTransform: "uppercase" }}>
          Игнорировать датчики (будут пустые)
        </span>
      </label>
    </div>
  );
};

export default UserVehicleIgnored;
