import api from "api";
import { Dispatch } from "react";
import { ActionActionTypes, ActionsActions } from "./actions_types";
import { getTyre } from "redux/tyres/tyres_actions";

export const addActionAdding = (data: any) => async () => {
  await api.addActionAdding(data);
};

export const getActionsPeriod = (
  from: string,
  to: string,
  type: string | undefined = undefined
) => {
  return async (dispatch: Dispatch<ActionsActions>) => {
    try {
      dispatch({ type: ActionActionTypes.ACTION_LOADING, payload: true });
      const response = await api.getActionsPeriod(from, to, type);

      dispatch({
        type: ActionActionTypes.GET_ACTIONS,
        payload: response,
      });
      dispatch({ type: ActionActionTypes.ACTION_LOADING, payload: false });
    } catch (e: any) {
      return e;
    }
  };
};

export const changeActionFilter = (payload: { [key: string]: string }) => {
  return (dispatch: Dispatch<ActionsActions>) => {
    dispatch({ type: ActionActionTypes.ACTIONS_FILTER, payload });
  };
};

export const postActionsTyreByPeriod = (from: string, to: string, id: number) => {
  return async (dispatch: Dispatch<ActionsActions>) => {
    try {
      dispatch({ type: ActionActionTypes.ACTION_LOADING, payload: true });
      const response = await api.postActionsTyreByPeriod(from, to, id);

      dispatch({
        type: ActionActionTypes.GET_ACTIONS,
        payload: response,
      });
      dispatch({ type: ActionActionTypes.ACTION_LOADING, payload: false });
    } catch (e: any) {
      return e;
    }
  };
};

export const addAction =
  (data: any, type: string) => async (dispatch: Dispatch<ActionsActions | any>) => {
    await api.postAction(data, type);
    dispatch(getTyre(data.tyre_id));
  };

export const getActionsByVehicle = (vehicle_id: number, type: string | undefined = undefined) => {
  if (!type) {
    return async (dispatch: Dispatch<ActionsActions>) => {
      try {
        dispatch({ type: ActionActionTypes.ACTION_LOADING, payload: true });
        const response = await api.getActionsByVehicle(vehicle_id);

        dispatch({
          type: ActionActionTypes.GET_ACTIONS,
          payload: response,
        });
        dispatch({ type: ActionActionTypes.ACTION_LOADING, payload: false });
      } catch (e: any) {
        return e;
      }
    };
  } else {
    return async (dispatch: Dispatch<ActionsActions>) => {
      try {
        dispatch({ type: ActionActionTypes.ACTION_LOADING, payload: true });
        const response = await api.getActionsByVehicleWithType(vehicle_id, type);

        dispatch({
          type: ActionActionTypes.GET_ACTIONS,
          payload: response,
        });
        dispatch({ type: ActionActionTypes.ACTION_LOADING, payload: false });
      } catch (e: any) {
        return e;
      }
    };
  }
};
