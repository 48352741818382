import { IPoint } from "../../redux/map/map_types";
import { IGeometry, IGeoObject } from "yandex-maps";
import { ISettings } from "../../redux/settings/settings_types";

export const POINT_SIZE = 24;
export const POINT_SIZE_MINI = 18;
export const ARROW_WIDTH = 20;
export const ARROW_HEIGHT = 14;
export const ARROW_ZOOM = 13; // при каком зуме показывать стрелки
export const CLUSTER_SIZE = 32;
export const BALLOON_MAX_WIDTH = 452;
export const SPEED_DIFF = 10; // достаточная разница в скорости для рисования точки

export interface ICoord {
  x: number;
  y: number;
}

export interface IPointWStatus extends IPoint {
  status: string;
}

export interface IStageCoord {
  status: string;
  point: IPoint;
  invisible?: boolean;
}

export interface IMakeLine {
  coordCount: number;
  polylines: IGeoObject<IGeometry>[];
}

export interface IGetStages {
  points: IPointWStatus[];
  arrowPoints: IPointWStatus[];
  stages: IStageCoord[][];
}

export interface ISortedPoints {
  G: IGeoObject<IGeometry>[];
  Y: IGeoObject<IGeometry>[];
  P: IGeoObject<IGeometry>[];
  R: IGeoObject<IGeometry>[];
}

export const switchColor = (status: string) => {
  if (status === "G") return "#49B869";
  if (status === "P") return "#7E48B3";
  if (status === "Y") return "#F18101";
  if (status === "R") return "#e12c2c";

  return "#49B869";
};

export const switchLineWidth = (status: string) => {
  if (status === "G") return 3;
  if (status === "P") return 5;
  if (status === "Y") return 6;
  if (status === "R") return 6;

  return 3;
};

export const getDistanceBetween = (a: ICoord, b: ICoord): number => {
  const A = {
    x: (a.x * Math.PI) / 180,
    y: (a.y * Math.PI) / 180,
  };

  const B = {
    x: (b.x * Math.PI) / 180,
    y: (b.y * Math.PI) / 180,
  };

  const R = 6371000;

  const H = Math.pow(Math.sin((B.x - A.x) / 2), 2);
  const U = Math.cos(A.x) * Math.cos(B.x) * Math.pow(Math.sin((B.y - A.y) / 2), 2);

  const d = 2 * R * Math.asin(Math.sqrt(H + U));

  return Math.round(d);
};

export const scanSensors = (limit: ISettings, sensors: [] | [[number, number]]): string => {
  let result = "OK";
  if (!sensors) return result;

  sensors.forEach((sensor) => {
    if (sensor[0] > 0.45 && sensor[0] < limit.bar_min) result = "MIN";
    if (sensor[0] > limit.bar_max) result = "MAX";

    if (sensor[1] > limit.temp_max) result = "MAX_T";
  });

  return result;
};

export const mapSettings = {
  zoom: 12,
  controls: ["rulerControl", "typeSelector"],
  // controls: ["rulerControl", "searchControl", "zoomControl", "typeSelector"],
};

export const defaultCenter = [52.970371, 36.063837];
