import {
  IUsersFilterState,
  UsersFilterActionTypes,
  UsersFilterActions,
} from "./users_filter_types";

const initialState: IUsersFilterState = {
  usersFiltered: [],
  typeFilterUser: "",
  filterUser: "",
};

const UsersFilterReducer = (state = initialState, action: UsersFilterActions) => {
  switch (action.type) {
    case UsersFilterActionTypes.GET_USER_FILTER:
      return {
        ...state,
        usersFiltered: action.payload,
      };
    case UsersFilterActionTypes.USER_FILTER_SEARCH:
      return {
        ...state,
        filterUser: action.payload,
      };
    case UsersFilterActionTypes.USER_TYPE_SEARCH:
      return {
        ...state,
        typeFilterUser: action.payload,
      };
    default:
      return state;
  }
};

export default UsersFilterReducer;
