import { parseEvent } from "../../helpers/events";
import EventTime from "./EventTime";

const EventsTime = ({ event }: any) => {
  // в одном событии может быть больше одной проблемы
  const parsedEvents = parseEvent(event.point, event.limit);

  return (
    <div className="report_time">
      <div className="report_time-date">{event.date.slice(11, 16)}</div>
      <div className="report_time-events">
        {parsedEvents.map((parsedEvent, index) => (
          <EventTime key={index} event={parsedEvent} />
        ))}
      </div>
    </div>
  );
};

export default EventsTime;
