import { useState } from "react";
import ModalWindow from "./ModalWindow";
import { ModalsState } from "../../redux/modals/modals_types";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import ModalInput from "../ui/Inputs/ModalInput";
import { useActions } from "../../hooks/useAction";
import { ReactComponent as Loupe } from "../../assets/images/loupe.svg";
import { IUsersSlice } from "../../redux/admin/users/UsersSlice";
import { useChangePasswordUserByModeratorMutation } from "redux/user/UserApi";
import Loader from "../loader/Loader";
import toast from "react-hot-toast";

const ModalNewPasswordUserByModerator = () => {
  const { toggleModal, selectUser } = useActions();
  const [changePasswordUserByModerator, { isLoading }] = useChangePasswordUserByModeratorMutation();
  const modals: ModalsState = useTypedSelector((state) => state.modals);
  const users: IUsersSlice = useTypedSelector((state) => state.users);
  const [password, setPassword] = useState<string>("");

  const handleClose = () => {
    toggleModal({ toggle: false, modal: "modal_new_password_user_by_moderator" });
    selectUser({});
  };

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await changePasswordUserByModerator({ email: users.select_user.email, password })
      .unwrap()
      .then(() => {
        handleClose();
        toast.error("Данные изменены");
      })
      .catch((e) => {
        toast.error(e.data.message);
      });
  };

  return (
    <ModalWindow
      isShow={modals.modal_new_password_user_by_moderator}
      title="Измененин пароля пользователя"
      handleClose={handleClose}
    >
      <form onSubmit={handleSave}>
        <div className="modal-content__box">
          <ModalInput readOnly title="Email" value={users.select_user.email} />
          <ModalInput
            autoComplete
            title="Пароль"
            type="password"
            value={password}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setPassword((e.target as HTMLInputElement).value)
            }
            Icon={Loupe}
          />
        </div>
        <div className="modal-edit__button-box">
          <button className="modal__button " type="submit">
            {isLoading ? <Loader /> : "Сохранить"}
          </button>
        </div>
      </form>
    </ModalWindow>
  );
};

export default ModalNewPasswordUserByModerator;
