import { PacketItemTypes } from "../Ping";
import Item from "./Item";

const ItemList = ({ items, showHex, isScroll }: ItemListTypes) => (
  <div className={isScroll ? "console--scroll" : "console"}>
    {items.map((item: PacketItemTypes, index: number) => (
      <Item key={index} showHex={showHex} item={item} />
    ))}
  </div>
);

export default ItemList;

interface ItemListTypes {
  items: PacketItemTypes[];
  showHex: boolean;
  isScroll?: boolean;
}
