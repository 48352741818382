import { useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { ReactComponent as Loupe } from "../../assets/images/loupe.svg";
import { useActions } from "../../hooks/useAction";

const DashboardSearch = () => {
  // const [isFocus, setIsFocus] = useState(false);
  const cars = useTypedSelector((state) => state.cars);
  const { changeFilter } = useActions();

  // const handleFocus = () => {
  //   setIsFocus(true);
  // };

  // const handleBlur = () => {
  //   setIsFocus(false);
  // };

  const handleChange = (value: string) => {
    changeFilter(value);
  };

  return (
    <div className="side-bar__search">
      <Loupe className="side-bar__img" />
      <input
        className="side-bar__input"
        placeholder="ПОИСК ТС"
        value={cars.filter}
        onChange={(e) => handleChange(e.target.value)}
      />
    </div>
  );
};

export default DashboardSearch;
