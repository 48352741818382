import React, { useState } from "react";
import api from "../api";
import ModalInput from "../components/ui/Inputs/ModalInput";
import { toast } from "react-hot-toast";
import Loader from "components/loader/Loader";

const defaultErrors = { email: [] };

const ResetPasswordPage = () => {
  const [email, setEmail] = useState<string>("");
  const [emailErrors, setEmailErrors] = useState<any>({ ...defaultErrors });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submitResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setEmailErrors({ ...defaultErrors });
    setIsLoading(true);
    await api
      .userResetPassword(email)
      .then((response) => {
        console.log("response", response);
        toast.success(response.data.message);
      })
      .catch((e: any) => {
        setEmailErrors(e.response.data.errors);
        toast.error(e.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h4 className="h4">Восстановление пароля</h4>
        <form onSubmit={submitResetPassword}>
          <ModalInput
            type="text"
            name="email_reset"
            placeholder="Введите email"
            value={email}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setEmail((e.target as HTMLInputElement).value)
            }
            errors={emailErrors?.email}
          />

          <div className="login__buttons">
            {!isLoading ? (
              <div className="login__buttons">
                <button type="submit" className="button button--login">
                  Сбросить пароль
                </button>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
