import { TyreProps } from "redux/tyres/tyres_types";

export interface ActionsState {
  actions: ActionPeriodProps | null;
  filter: { [key: string]: string } | null;
  loading: boolean;
  countRun: number;
}

export interface AddingProps {
  id: number;
  action_id: number;
  vehicle_id: number;
  updated_at: string;
  creates_at: string;
}

export interface MovingProps {
  id: number;
  action_id: number;
  from: number;
  to: number;
  updated_at: string;
  creates_at: string;
}

export interface ActionProps {
  id: number;
  tyre_id: number;
  type: string;
  run: number;
  tread: number;
  updated_at: string;
  creates_at: string;
  tyre: TyreProps;
  utilization?: UtilizationActionProps;
  store?: StoreActionProps;
  moving?: MovingProps;
  adding?: AddingProps;
}

export interface ActionPeriodProps {
  actions: ActionProps[];
  from?: string;
  to?: string;
  count?: number;
}

export interface UtilizationActionProps {
  id: number;
  action_id: number;
  reason: string;
  comment: string;
  updated_at: string;
  creates_at: string;
}

export interface StoreActionProps {
  id: number;
  action_id: number;
  vehicle_id: string;
  reason: string;
  updated_at: string;
  creates_at: string;
}

export enum ActionActionTypes {
  GET_ACTIONS = "GET_ACTIONS",
  ACTION_LOADING = "ACTION_LOADING",
  ACTIONS_FILTER = "ACTIONS_FILTER",
  SET_SHOW = "SET_SHOW",
}

interface GetActions {
  type: ActionActionTypes.GET_ACTIONS;
  payload: ActionPeriodProps;
}

interface ActionLoading {
  type: ActionActionTypes.ACTION_LOADING;
  payload: boolean;
}

interface ActionShow {
  type: ActionActionTypes.SET_SHOW;
  payload: boolean;
}

interface ActionFilter {
  type: ActionActionTypes.ACTIONS_FILTER;
  payload: { [key: string]: string };
}

interface ActionFilter {
  type: ActionActionTypes.ACTIONS_FILTER;
  payload: { [key: string]: string };
}

export type ActionsActions = GetActions | ActionLoading | ActionFilter | ActionShow;
