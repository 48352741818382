export interface IPaginate {
  limit: number;
  offset: number;
  total: number;
}

interface IUser {
  name: string;
  age: number;
}

interface IPost {
  title: string;
}

export interface IServerResponse {
  response: {
    status: number;
    data: {
      message: string;
    };
  };
}

export enum IToastAppearance {
  success = "success",
  error = "error",
  warning = "warning",
  info = "info",
}

export enum TabIds {
  Bar = "bar",
  BarLimits = "bar_limits",
  Temp = "temp",
  TempLimit = "temp_limit",
  Battery = "battery",
  IdSensor = "id_sensor",
}

export enum TabModes {
  NOTAB = "NOTAB",
  MAIN = "MAIN",
  ALL = "ALL",
}

import { ReactComponent as Tab1 } from "../assets/images/tab-1.svg";
import { ReactComponent as Tab2 } from "../assets/images/tab-2.svg";
import { ReactComponent as Tab3 } from "../assets/images/tab-3.svg";
import { ReactComponent as Tab4 } from "../assets/images/tab-4.svg";
// import { ReactComponent as Tab5 } from "../assets/images/tab-5.svg";
// import { ReactComponent as Tab6 } from "../assets/images/tab-6.svg";

export interface ISensorTabs {
  id: TabIds;
  title: string;
  img: React.ReactElement;
}

export const SENSOR_TABS: ISensorTabs[] = [
  {
    id: TabIds.Bar,
    title: "Давление",
    img: <Tab1 className="sensor-tab__img" />,
  },
  {
    id: TabIds.BarLimits,
    title: "Пределы давления",
    img: <Tab2 className="sensor-tab__img" />,
  },
  {
    id: TabIds.Temp,
    title: "Температура",
    img: <Tab3 className="sensor-tab__img" />,
  },
  {
    id: TabIds.TempLimit,
    title: "Максимальная температура",
    img: <Tab4 className="sensor-tab__img" />,
  },
  // {
  //   id: TabIds.Battery,
  //   title: "Заряд",
  //   img: <Tab5 className="sensor-tab__img" />,
  // },
  // {
  //   id: TabIds.IdSensor,
  //   title: "Id датчиков",
  //   img: <Tab6 className="sensor-tab__img" />,
  // }
];
