import EventMainInfo from "./EventMainInfo";
import SensorsData from "./SensorsData";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useAction";
import moment from "moment";
import { IReport } from "../../../redux/reports/reports_types";
import { CarState } from "redux/car/car_types";
import { toast } from "react-hot-toast";

const ReportHeader = ({ report, eventsCount }: { report: IReport; eventsCount: number }) => {
  const { car }: CarState = useTypedSelector((state) => state.car);
  const { getReportPDF } = useActions();

  const handleDownload = async () => {
    try {
      if (!!car) await getReportPDF(report.from, report.to, car.id, "pdf");
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <div className="report_body">
      <div className="report_head">
        <div className="report_title">
          <span className="report_bold">Отчет </span> за период
          <button className="report_download" onClick={handleDownload}>
            Скачать отчет
          </button>
        </div>
        <div className="report_date report_bold">
          <div className="report_date-title">
            {moment(report.from).format("DD.MM.YYYY")} - {moment(report.to).format("DD.MM.YYYY")}
          </div>
        </div>
      </div>
      <EventMainInfo eventsCount={eventsCount} />
      <SensorsData />
    </div>
  );
};

export default ReportHeader;
