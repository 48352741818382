import { ReactComponent as Speed } from "../../assets/images/speed_error.svg";
import { ReactComponent as Pressure } from "../../assets/images/pressure_error.svg";
import { ReactComponent as Temp } from "../../assets/images/temp_error.svg";

const ViolationPart = (event: any) => {
  let icon;
  let text = "";
  let sensor = "";

  switch (event.type) {
    case "bar_max":
      icon = <Pressure className="violation__icon" />;
      text = "Давление больше допустимого";
      sensor = `датчик №${event.index + 1}`;
      break;
    case "bar_min":
      icon = <Pressure className="violation__icon" />;
      text = "Давление меньше допустимого";
      sensor = `датчик №${event.index + 1}`;
      break;
    case "speed_max":
      icon = <Speed className="violation__icon" />;
      text = "Превышение скорости";
      sensor = `на ${event.value - event.limit} км/ч`;
      break;
    case "temp_max":
      icon = <Temp className="violation__icon" />;
      text = "Нарушение температуры";
      sensor = `датчик №${event.index + 1}`;
      break;
    default:
      text = "Неопознанное нарушение";
      sensor = "Неизвестный датчик";
  }

  return (
    <div className="violation__part">
      {icon}
      <div className="violation__info">
        <p className="violation__name">{text}</p>
        <div className="violation__more">
          <p className="violation__sensor">{sensor}</p>
          <p className="violation__value">
            {event.value} {event.units}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ViolationPart;
