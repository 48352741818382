import { mToKm } from "../../../helpers";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { ReportState } from "../../../redux/reports/reports_types";
import EventColumn from "../../events/EventColumn";
import ReportCount from "./ReportCount";

const EventMainInfo = ({ eventsCount }: { eventsCount: number }) => {
  const { report }: ReportState = useTypedSelector((state) => state.reports);
  const fullNamber = report.vehicle?.car_number + " " + report.vehicle?.region;

  return (
    <>
      <div className="report_data count_events">
        <ReportCount count={eventsCount} title1="количество" title2="событий" />
        <ReportCount
          count={mToKm(report.last_point?.run_total)}
          title1="общий"
          title2="пробег (км)"
        />
      </div>
      <div className="report_data report_main">
        <div className="report_info report_info_comment">
          <div className="report_title report_info-title">Пробег</div>
          <EventColumn title="ГОС НОМЕР" data={fullNamber} classColumn="report_column--border" />
          <EventColumn
            title="МАРКА ТРАНСПОРТНОГО СРЕДСТВА"
            data={report.vehicle?.title}
            classColumn="report_column--border"
          />
          <EventColumn
            title="КОММЕНТАРИЙ"
            data={report.vehicle?.comment}
            classComment="report_comment "
            classColumn="report_column--big"
          />
        </div>
        <div className="report_info">
          <div className="report_title report_info-title">Номер и название транспорта</div>
          <EventColumn
            title="ОБЩИЙ ПРОБЕГ"
            data={mToKm(report.last_point?.run_total)}
            classColumn="report_column--border"
            smallTitle=" (КМ)"
          />
          <EventColumn
            title="ПРОБЕГ ЗА ПЕРИОД"
            data={mToKm(report.run?.run_total)}
            classColumn="report_column--border"
            smallTitle=" (КМ)"
          />
          <EventColumn
            title="ПРОБЕГ НА НАЧАЛО"
            data={mToKm(report.run?.run_start)}
            smallTitle=" (КМ)"
          />
          <EventColumn
            title="ПРОБЕГ НА КОНЕЦ ПЕРИОДА"
            data={mToKm(report.run?.run_finish)}
            smallTitle=" (КМ)"
          />
        </div>
      </div>
    </>
  );
};

export default EventMainInfo;
