import { useEffect, useState } from "react";
import moment from "moment";
import CustomDatePicker from "../datepicker/CustomDatePicker";
import DateTemplates from "../date-templates/DateTemplate";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import { CarState } from "redux/car/car_types";
import { toast } from "react-hot-toast";

const ReportsReport = () => {
  const [from, setFrom] = useState(moment().format("YYYY-MM-DD"));
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const { car }: CarState = useTypedSelector((state) => state.car);

  const onDatesChange = (from: string, to: string) => {
    setFrom(from);
    setTo(to);
  };
  const { getReportJSON, toggleIsReport, toggleIsShow } = useActions();

  useEffect(() => {
    return () => {
      toggleIsShow(false);
      toggleIsReport(false);
    };
  }, []);

  const handlerReport = async () => {
    toggleIsShow(true);
    try {
      toggleIsReport(false);
      if (car) await getReportJSON(from, to, car.id, "json");
      toggleIsReport(true);
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <h4 className="point__title">Выбор периода</h4>
      <div className="reports reports--charts reports--charts--border">
        <DateTemplates onDatesChange={onDatesChange} from={from} to={to} />

        <div className="input-and-button input-and-button--border-top">
          <div className="input-area w50-5 m0">
            <label className="label">Начало периода</label>
            <CustomDatePicker onChange={(date) => setFrom(date)} date={from} />
          </div>

          <div className="input-area w50-5 m0">
            <label className="label">Конец периода</label>
            <CustomDatePicker onChange={(date) => setTo(date)} date={to} />
          </div>

          <button
            className="button button--mint button--with-label w100 m0"
            onClick={handlerReport}
          >
            Показать
          </button>
        </div>
      </div>
    </>
  );
};

export default ReportsReport;
