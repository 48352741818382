import { useContext, useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import { ModalsState } from "../../redux/modals/modals_types";
import ModalWindow from "./ModalWindow";
import { ModalUserCarContext } from "components/user/context/ModalUserCarContext";
import api from "api";
import SkeletonLoading from "components/skeleton-loading/SkeletonLoading";
import { toast } from "react-hot-toast";
import { useGetDevicesQuery } from "../../redux/admin/devices/DevicesApi";
import SelectInput from "components/select-input/SelectInput";
import { useGetFoldersUserQuery, usePutFolderMutation } from "redux/folder/FolderApi";
import { IFolder } from "redux/folder/FolderType";

const ModalChangeFolder = () => {
  const { toggleModal, getCars } = useActions();

  const modals: ModalsState = useTypedSelector((state) => state.modals);
  const { userCurrent, setuserCarList, carUser } = useContext(ModalUserCarContext);
  const [submiting, setSubmiting] = useState<boolean>(false);
  const [folderId, setFolderId] = useState<any>(null);
  const [newFolder, setNewFolder] = useState<any>(null);
  const [folder, setFolder] = useState<any>(null);
  const { data, isLoading } = useGetFoldersUserQuery(userCurrent);

  const [putFolder, {}] = usePutFolderMutation();

  const handleClose = () => {
    toggleModal({ toggle: false, modal: "modal_change_folder" });
    setSubmiting(false);
  };

  const handleSave = async () => {
    setSubmiting(true);
    if (userCurrent) {
      await api.getUserCars(userCurrent).then(async (res) => {
        let carsByUser: number[] = [];
        let folderData: { id: number; title: string; vehicles: number[] } = {
          id: 0,
          title: "",
          vehicles: [],
        };
        if (folderId == 0) {
          res.vehicles.forEach((car) => {
            if (car.folder_id === folder.id && car.id !== carUser.id) carsByUser.push(car.id);
          });
          folderData = {
            id: folder.id,
            title: folder.title,
            vehicles: carsByUser,
          };
        } else {
          carsByUser = [carUser.id];
          res.vehicles.forEach((car) => {
            if (car.folder_id === folderId) carsByUser.push(car.id);
          });
          folderData = {
            id: newFolder.id,
            title: newFolder.title,
            vehicles: carsByUser,
          };
        }
        console.log(folderData);

        await putFolder(folderData)
          .unwrap()
          .then(async () => {
            toast("Данные изменены");
            handleClose();
            getCars();
            if (userCurrent) {
              await api.getUserCars(userCurrent).then((res) => {
                setuserCarList(res.vehicles);
              });
            }
          });
      });
    }
  };

  const onFolder = (value: any) => {
    setFolderId(value);
    if (data) setNewFolder(data.filter((folder) => folder.id == value)[0]);
  };

  const optionsFolders: any = [];
  optionsFolders.push({ value: 0, label: "без папки" });

  if (data) {
    data.forEach((folder: any) => {
      optionsFolders.push({ value: folder.id, label: folder.title });
    });
  }

  useEffect(() => {
    setFolderId(carUser.folder_id);
    if (data) setFolder(data.filter((folder) => folder.id == carUser.folder_id)[0]);
  }, [carUser]);

  return (
    <ModalWindow
      isShow={modals.modal_change_folder}
      title="Перемещение ТС"
      handleClose={handleClose}
    >
      {submiting ? (
        <SkeletonLoading height="388px" />
      ) : (
        <>
          <div className="modal-content__box">
            <SelectInput
              onChange={(e) => onFolder(e)}
              options={optionsFolders}
              value={folderId}
              placeholder="Выберите профиль"
            />
          </div>
          <div className="modal-folder__button-box">
            <button
              className="modal__button modal__button--white modal__button--red"
              onClick={() => handleClose()}
            >
              Отменить
            </button>
            <button className="modal__button " onClick={(e) => handleSave()}>
              Сохранить
            </button>
          </div>
        </>
      )}
    </ModalWindow>
  );
};

export default ModalChangeFolder;
