import React, { useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import { ModalsState } from "../../redux/modals/modals_types";
import SkeletonLoading from "../skeleton-loading/SkeletonLoading";
import ModalInput from "../ui/Inputs/ModalInput";
import ModalWindow from "./ModalWindow";
import { CarState } from "redux/car/car_types";
import { toast } from "react-hot-toast";
import ModalTextarea from "components/ui/Textarea/ModalTextarea";

const ModalCarExtendedInfo = () => {
  const {
    toggleModal,
    putCarExtendedInfo,
    ChangeInputCarExtendedInfo,
    deleteCar,
    toggleModalConfirm,
  } = useActions();

  const modals: ModalsState = useTypedSelector((state) => state.modals);
  const car: CarState = useTypedSelector((state: { car: any }) => state.car);

  const [submiting, setSubmiting] = useState<boolean>(false);

  const [errors, setErrors] = useState<any>({
    title: [],
    region: [],
    car_number: [],
    comment: [],
  });

  const handleClose = () => {
    toggleModal({ toggle: false, modal: "modal_car_extended_info" });
    setSubmiting(false);

    setErrors({
      title: [],
      region: [],
      car_number: [],
      code: [],
    });
  };

  const handleChangeInput = (key: string, value: string) => {
    ChangeInputCarExtendedInfo(key, value);
  };

  const handleSave = async () => {
    try {
      setSubmiting(true);
      if (car.car_extended) await putCarExtendedInfo(car.car_extended.id, car.car_extended);
      toast.success("Данные изменены");
      handleClose();
    } catch (error: any) {
      if (error?.response?.data?.errors) {
        setErrors({
          ...errors,
          title: error?.response?.data?.errors?.title || [],
          car_number: error?.response?.data?.errors?.car_number || [],
          region: error?.response?.data?.errors?.region || [],
        });
      } else if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
    setSubmiting(false);
  };

  const сancel = (): void => {
    console.log("Отмена, возвращаемся в модалку");
    toggleModal({ toggle: true, modal: "modal_car_extended_info" });
  };

  const open = () => {
    toggleModal({ toggle: false, modal: "modal_car_extended_info" });
    toggleModalConfirm({
      toggle: true,
      title: "Подтвердите действие",
      content: "Вы уверены, что хотите удалить ТС?",
      onConfirm: сonfirm,
      onCancel: сancel,
    });
  };

  const сonfirm = (): void => {
    console.log("Тут удаляем список и закрываем модалку");
    handleDelete();
    handleClose();
  };

  const handleDelete = () => {
    try {
      if (car.car_extended) deleteCar(car.car_extended.id);
      toast.success("ТС удалено");
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  if (car.loading_extended || submiting || !car.car_extended) {
    return (
      <ModalWindow
        isShow={modals.modal_car_extended_info}
        title="Редактирование транспортного средства"
        handleClose={handleClose}
      >
        <SkeletonLoading height="402px" />
      </ModalWindow>
    );
  }

  return (
    <ModalWindow
      isShow={modals.modal_car_extended_info}
      title="Редактирование транспортного средства"
      handleClose={handleClose}
    >
      <div className="modal-content__box">
        <ModalInput
          titleClass="modal-password_title"
          title="Гос номер"
          length={7}
          value={car.car_extended.car_number}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            handleChangeInput("car_number", (e.target as HTMLInputElement).value);
          }}
          errors={errors.car_number}
        />
        <ModalInput
          titleClass="modal-password_title"
          title="Регион"
          length={3}
          value={car.car_extended.region}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            handleChangeInput("region", (e.target as HTMLInputElement).value);
          }}
          errors={errors.region}
        />
        <ModalInput
          titleClass="modal-password_title"
          title="Марка ТС"
          length={32}
          value={car.car_extended.title}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            handleChangeInput("title", (e.target as HTMLInputElement).value);
          }}
          errors={errors.title}
        />
        <ModalTextarea
          titleClass="modal-password_title"
          title="Комментарий"
          length={64}
          rowsLength={2}
          value={car.car_extended.comment}
          onChange={(e: React.FormEvent<HTMLTextAreaElement>) => {
            handleChangeInput("comment", (e.target as HTMLTextAreaElement).value);
          }}
          errors={errors.comment}
        />
      </div>
      <div className="modal-folder__button-box">
        <button
          className="modal__button modal__button--white modal__button--red"
          onClick={() => open()}
        >
          Удалить
        </button>
        <button className="modal__button " onClick={(e) => handleSave()}>
          Сохранить
        </button>
      </div>
    </ModalWindow>
  );
};

export default ModalCarExtendedInfo;
