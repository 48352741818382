import { DevicesState, IDevice } from "./DevicesTypes";
import { createSlice } from "@reduxjs/toolkit";

const initialState: DevicesState = {
  device: {} as IDevice,
};

export const devicesSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    selectDevice: (state, action) => {
      state.device = action.payload;
    },
    unselectDevice: (state) => {
      state.device = {} as IDevice;
    },
  },
});

export const devicesReducer = devicesSlice.reducer;
export const devicesActions = devicesSlice.actions;
