import { IMAGES } from "../../constants/images";

const ModalInputPassword = ({
  value,
  onChange,
  errors,
  formikErrors,
  onClick,
  title,
  visible,
  name,
}: IModalInputPassword) => {
  return (
    <div className="form-group">
      <label className="modal-password_title">{title}</label>
      <div className="modal-password__input-box">
        <input
          onChange={onChange}
          value={value}
          type={visible ? "text" : "password"}
          name={name}
          className={
            errors || formikErrors ? "profile__input profile__input--error" : "profile__input"
          }
        />
      </div>
      {!!errors && <div className="input-error-message">{errors}</div>}
      <div className="input-error-message">{formikErrors}</div>
    </div>
  );
};

export default ModalInputPassword;

interface IModalInputPassword {
  title: string;
  value: string;
  name: string;
  errors?: [];
  formikErrors: any;
  visible: boolean;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onClick: () => void;
}
