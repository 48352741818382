import { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useActions } from "../../hooks/useAction";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { GlobalApi } from "../../api/GlobalApi";
import { MobileContext } from "context/MobileContext";
import useWindowSize from "hooks/useWindowSize";
import { MEDIA } from "constants/media";
import GoBackBtn from "components/ui/Buttons/GoBack";

const SideBarProfile = () => {
  const { logout } = useActions();
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    logout();
    localStorage.removeItem("@center");
    navigation("/");
    dispatch(GlobalApi.util.resetApiState());
  };

  const { toggled, setToggled } = useContext(MobileContext);
  const size = useWindowSize();

  const handlerMobileView = () => {
    if (size.width < MEDIA.mobile) {
      setToggled(true);
    }
  };

  return (
    <div className="side-bar">
      <div className="view-container">
        <div className="view__header">
          <div className="view-goback">
            <GoBackBtn text="Назад" to="/" />
          </div>
        </div>

        <ul className="sidebar__menu">
          <li className="sidebar__menu-item">
            <NavLink to="/profile" className="sidebar__menu-link" onClick={handlerMobileView}>
              <strong>Настройки</strong> пользователя
            </NavLink>
          </li>
          {/* <li className="sidebar__menu-item">
            <NavLink to="/profile" className="sidebar__menu-link" onClick={handlerMobileView}>
              <strong>Настройки</strong> компании [если модератор]
            </NavLink>
          </li> */}
          <li className="sidebar__menu-item">
            <button
              title="Выход"
              className="sidebar__menu-link sidebar__menu-link--logout"
              onClick={() => handleLogout()}
            >
              <strong>Выйти из профиля</strong>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideBarProfile;
