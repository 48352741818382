import { IPoint } from "../map/map_types";
import { CarActions, CarActionTypes, CarState, ICar } from "./car_types";

const initialState: CarState = {
  car: null,
  loading: true,
  car_extended: null,
  loading_extended: true,
  last_point: null,
  loading_point: true,
  calendarData: null,
};

const CarReducer = (state = { ...initialState }, action: CarActions) => {
  switch (action.type) {
    case CarActionTypes.GET_CAR:
      return {
        ...state,
        car: action.payload,
        loading: false,
      };
    case CarActionTypes.CAR_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case CarActionTypes.GET_CAR_EXTENDED:
      return {
        ...state,
        car_extended: action.payload,
        loading_extended: false,
      };

    case CarActionTypes.LOADING_EXTENDED:
      return {
        ...state,
        loading_extended: action.payload,
      };

    case CarActionTypes.CHANGE_INPUT_CAR_EXTENDED: {
      const _car_extended = { ...state.car_extended };
      _car_extended[action.payload.key] = action.payload.value;

      return {
        ...state,
        car_extended: _car_extended,
      };
    }
    case CarActionTypes.GET_LAST_POINT:
      return {
        ...state,
        last_point: action.payload,
        loading_point: false,
      };
    case CarActionTypes.RESET_CAR_EXTENDED:
      return {
        ...state,
        car: {} as ICar,
        loading_extended: true,
      };
    case CarActionTypes.RESET_CAR:
      return {
        ...initialState,
      };
    case CarActionTypes.CAR_UPDATE_LAST_POINT_ADDRESS:
      const __last_point = { ...state.last_point, address: action.payload } as IPoint;

      return {
        ...state,
        last_point: __last_point,
      };
    case CarActionTypes.CAR_CHANGE_CONSTRUCTOR: {
      const newCar = { ...state.car };
      newCar.design = action.payload;

      return {
        ...state,
        car: newCar,
      };
    }
    case CarActionTypes.CAR_CHANGE_LIMIT: {
      const newCar = { ...state.car };
      newCar.last_limit = action.payload;

      return {
        ...state,
        car: newCar,
      };
    }
    case CarActionTypes.GET_CALENDAR:
      return {
        ...state,
        calendarData: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default CarReducer;
