import { useState } from "react";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import {
  HeaderContent,
  HeaderEl,
  LinkMe,
  LogoType,
  HeaderContainer,
  HeaderInner,
} from "./Header.styled";

const HomePageHeader = () => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  return (
    <HeaderEl>
      <HeaderInner>
        <HeaderContainer>
          <HeaderContent>
            <button
              onClick={() => setOpenMenu(!openMenu)}
              type="button"
              className={"button hamburger-lines " + (openMenu ? "active" : "")}
            >
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </button>

            <LogoType to="/">
              <Logo className="header-logo" />
            </LogoType>

            <div className="header-nav__links d-flex">
              <div className="header-nav__item">
                <span>О продукции</span>
              </div>
              <div className="header-nav__item">
                <span>Выгода</span>
              </div>
              <div className="header-nav__item">
                <span>Контакты</span>
              </div>
              <div className="header-nav__item">
                <span>Каталог</span>
              </div>
            </div>

            <LinkMe to="/login">
              <span>Вход/Регистрация</span>
            </LinkMe>
          </HeaderContent>
        </HeaderContainer>
      </HeaderInner>

      <div className={"under-menu " + (openMenu ? "under-menu--active" : "")}>
        <div className="container">
          <div className="under-menu__links">
            <div className="under-menu__item ">
              <span>ЛИЧНЫЙ КАБИНЕТ</span>
            </div>
            <div className="under-menu__item ">
              <span>ОСОБЕННОСТИ КОМПЛЕКСА</span>
            </div>
            <div className="under-menu__item ">
              <span>Установка и работа терминала</span>
            </div>
          </div>
        </div>
      </div>
    </HeaderEl>
  );
};

export default HomePageHeader;
