import { IGetLastDeviceSettingsResponse } from "../../types/responses";

export interface SettingsState extends Omit<IGetLastDeviceSettingsResponse, "settings"> {
  vehicle_id: number;
  id: number;
  settings: ISettings;
  loading: boolean;
  settingsLast: ISettings;
  limits: ISettings[];
  isCharts: boolean;
}

export interface ISettings extends Record<string, any> {
  bar_max: number;
  speed_max: number;
  temp_max: number;
  bar_min: number;
  created_at?: string;
}

export interface PostTransportSettings extends ISettings {
  vehicle_id: number;
}

export interface GetLastSettingsData {
  vehicle_id: number;
  before: string;
}

export interface GetPeriodSettingsData {
  vehicle_id: number;
  from: string;
  to: string;
}

export enum SettingsActionTypes {
  GET_LIMITS = "GET_LIMITS",
  LOADING_GET_LAST_SETTINGS = "LOADING_GET_LAST_SETTINGS",
  CHANGE_INPUT_LAST_SETTINGS = "CHANGE_INPUT_LAST_SETTINGS",
  GET_VEHICLE_ID = "GET_VEHICLE_ID",
  GET_LAST_LIMITS = "GET_LAST_LIMITS",
  GET_PERIOD_LIMITS = "GET_PERIOD_LIMITS",
  CHARTS_IS = "CHARTS_IS",
}

interface GetLimits {
  type: SettingsActionTypes.GET_LIMITS;
  payload: IGetLastDeviceSettingsResponse;
}

interface LoadingGetLastSettings {
  type: SettingsActionTypes.LOADING_GET_LAST_SETTINGS;
  payload: boolean;
}

interface ChangeInputLastSettings {
  type: SettingsActionTypes.CHANGE_INPUT_LAST_SETTINGS;
  payload: { key: string; value: number };
}
interface GetVehicleId {
  type: SettingsActionTypes.GET_VEHICLE_ID;
  payload: number;
}

interface GetLastSettings {
  type: SettingsActionTypes.GET_LAST_LIMITS;
  payload: ISettings;
}

interface GetPeriodSettings {
  type: SettingsActionTypes.GET_PERIOD_LIMITS;
  payload: GetPeriodSettingsData;
}

interface ChartsIs {
  type: SettingsActionTypes.CHARTS_IS;
  payload: boolean;
}

export type SettingsActions =
  | GetLimits
  | LoadingGetLastSettings
  | ChangeInputLastSettings
  | GetVehicleId
  | GetLastSettings
  | GetPeriodSettings
  | ChartsIs;
