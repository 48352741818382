import { SetStateAction, useEffect, useState } from "react";
import moment from "moment";
import CustomDatePicker from "../../components/datepicker/CustomDatePicker";
import Vioalations from "../../components/violations/Violations";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import DateTemplates from "../../components/date-templates/DateTemplate";
import CustomScroll from "react-custom-scroll";
import { CarState } from "redux/car/car_types";
import { useParams } from "react-router-dom";
import { IPoint } from "redux/map/map_types";
import SkeletonLoading from "components/skeleton-loading/SkeletonLoading";
import NoData from "components/no-data/NoData";
import { toast } from "react-hot-toast";
import PaginatedItems from "../../components/pagination/Pagination";

const ViewCarEventsPage = () => {
  const [from, setFrom] = useState(moment().format("YYYY-MM-DD"));
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));

  const { events_period, totalPage, totalEvents } = useTypedSelector((state) => state.events);

  const [fromError, setFromError] = useState("");
  const [toError, setToError] = useState("");
  const [isReady, setIsReady] = useState(false);

  const { last_point, car, loading_point }: CarState = useTypedSelector((state) => state.car);
  const point: null | IPoint = last_point;
  const { vehicle_id } = useParams<{ vehicle_id?: string }>();

  const { getEventsPeriod, getLastPointServer, cleanTotalPage } = useActions();

  const handleShow = async (event: any) => {
    setToError("");
    setFromError("");
    let page = 1;
    if (event && event.selected) page = event.selected + 1;
    try {
      setIsReady(true);
      if (car) await getEventsPeriod(from, to, [car.id], page);
    } catch (error: any) {
      console.log(error);

      if (error?.response?.data?.errors) {
        if (error?.response?.data?.errors?.from) {
          setFromError(error.response.data.errors.from[0]);
        }
        if (error?.response?.data?.errors?.to) {
          setToError(error.response.data.errors.to[0]);
        }
        if (error?.response?.data?.errors?.vehicles) {
          toast.error(error.response.data.errors.vehicles[0]);
        }
      } else if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };
  const handleSetFrom = (date: SetStateAction<string>) => {
    setFrom(date);
    setIsReady(false);
    cleanTotalPage();
  };

  const handleSetTo = (date: SetStateAction<string>) => {
    setTo(date);
    setIsReady(false);
    cleanTotalPage();
  };

  const onDatesChange = (from: string, to: string) => {
    setFrom(from);
    setTo(to);
  };

  useEffect(() => {
    if (!car?.device_id || !vehicle_id || car?.id !== +vehicle_id) return;
    init();
  }, [car?.device_id]);

  const init = async () => {
    if (!car) return;

    const { status, data }: any = await getLastPointServer(car.device_id, false);

    if (status !== 200 && status !== 201) toast.error(data.message);
  };

  if (loading_point || car === null)
    return (
      <div className="view__inner">
        <SkeletonLoading height="200px" />
      </div>
    );

  if (!point) return <NoData />;

  return (
    <CustomScroll allowOuterScroll={true} heightRelativeToParent="calc(100%)">
      <div className="view__inner">
        <h4 className="point__title">Выбор периода</h4>
        <div className="reports reports--charts reports--charts--border">
          <DateTemplates onDatesChange={onDatesChange} from={from} to={to} />

          <div className="input-and-button input-and-button--border-top">
            <div className="input-area w50-5 m0">
              <label className="label">Начало периода</label>
              <CustomDatePicker
                isError={!!fromError}
                onChange={(date) => handleSetFrom(date)}
                date={from}
              />

              {!!fromError && <div className="input-error-message">{fromError}</div>}
            </div>

            <div className="input-area w50-5 m0">
              <label className="label">Конец периода</label>
              <CustomDatePicker
                isError={!!toError}
                onChange={(date) => handleSetTo(date)}
                date={to}
              />

              {!!toError && <div className="input-error-message">{toError}</div>}
            </div>

            <button
              type="button"
              className="button button--mint button--with-label w100 m0"
              onClick={(event) => handleShow(event)}
            >
              Показать
            </button>
          </div>
        </div>
        {isReady && (
          <>
            <Vioalations
              events={events_period}
              text={"Нарушения за период"}
              classPage={true}
              eventsLength={totalEvents}
            />
            <PaginatedItems totalPage={totalPage} handleShow={handleShow} />
          </>
        )}
      </div>
    </CustomScroll>
  );
};

export default ViewCarEventsPage;
