import { ICar } from "redux/car/car_types";
import {
  VehicleInfo,
  VehicleFullNumber,
  VehicleNumber,
  VehicleRegion,
} from "components/cars/Cars.styled";

const UserVehicleView = ({ car }: { car: ICar }) => {
  return (
    <div className="vehicle-view">
      <div className="vehicle-header">
        <VehicleInfo>
          <VehicleFullNumber>
            <VehicleNumber>{car.car_number}</VehicleNumber>
            <VehicleRegion>{car.region}</VehicleRegion>
          </VehicleFullNumber>

          <div className="vehicle__name">{car.title}</div>
        </VehicleInfo>
      </div>

      <div className="vehicle-comment">{car.comment || "Описание отсутствует"}</div>
    </div>
  );
};

export default UserVehicleView;
