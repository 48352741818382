import { Dispatch } from "redux";
import { STEPS } from "../../helpers/constructorTypes";
import { ConstructorActions, ConstructorActionTypes } from "./constructor_types";

export const changeStep = (payload: string) => {
  return async (dispatch: Dispatch<ConstructorActions>) => {
    dispatch({ type: ConstructorActionTypes.CHANGE_STEP, payload });
  };
};

export const goToCategories = () => {
  return async (dispatch: Dispatch<ConstructorActions>) => {
    dispatch({ type: ConstructorActionTypes.CHANGE_CATEGORY, payload: 0 });
    dispatch({ type: ConstructorActionTypes.CHANGE_STEP, payload: STEPS.CATEGORIES });
  };
};

export const changeCategory = (payload: number) => {
  return async (dispatch: Dispatch<ConstructorActions>) => {
    dispatch({ type: ConstructorActionTypes.CHANGE_CATEGORY, payload });
  };
};

export const changeModel = (payload: null | number) => {
  return async (dispatch: Dispatch<ConstructorActions>) => {
    dispatch({ type: ConstructorActionTypes.CHANGE_MODEL, payload });
  };
};

export const changeTrailer = (payload: null | number) => {
  return async (dispatch: Dispatch<ConstructorActions>) => {
    dispatch({ type: ConstructorActionTypes.CHANGE_TRAILER, payload });
  };
};

export const resetConstructor = () => {
  return async (dispatch: Dispatch<ConstructorActions>) => {
    dispatch({ type: ConstructorActionTypes.RESET_CONSTRUCTOR });
  };
};

export const changePosition = (payload: number[]) => {
  return async (dispatch: Dispatch<ConstructorActions>) => {
    dispatch({ type: ConstructorActionTypes.CHANGE_POSITION, payload });
  };
};
