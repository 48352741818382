import TyreTitle from "components/tyres/TyreTitle";
import { useTypedSelector } from "hooks/useTypedSelector";
import { CarsState } from "redux/cars/cars_types";
import { useGetFoldersQuery } from "redux/folder/FolderApi";

const TyresPageStart = () => {
  const { loading }: CarsState = useTypedSelector((state) => state.cars);
  const { isLoading } = useGetFoldersQuery();

  return (
    <>
      {!loading && !isLoading && (
        <div className="report_head">
          <TyreTitle title="Выбирете машину"></TyreTitle>
        </div>
      )}
    </>
  );
};

export default TyresPageStart;
