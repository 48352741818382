import styled, { css } from "styled-components";
import { baseTheme } from "assets/styles/Theme.styled";

export const DeviceEl = styled.div<{ type: boolean }>`
  position: relative;
  border-radius: 15px;
  background-color: ${baseTheme.colors.main};
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  color: white;
  ${({ type }) =>
    type &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}
`;

export const DeviceArchived = styled.div<{ type: boolean }>`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  z-index: 10;
  ${({ type }) =>
    type
      ? css`
          background-color: #bf3353;
        `
      : css`
          background-color: #51b56d;
        `}
`;

export const DeviceHeader = styled.div`
  padding: 10px 10px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  background-color: #333333;
  border-radius: 15px;
`;

export const DeviceHeaderCol = styled.div`
  display: flex;
  span {
    display: inline-block;
    font-weight: bold;
    margin-right: 5px;
  }
`;

export const DeviceBody = styled.div`
  padding: 0 10px;
`;

export const DevicePhone = styled.div`
  text-align: center;
  font: 400 14px/17px;
  margin-bottom: 10px;
`;
export const DeviceCode = styled.div`
  background-color: #333333;
  padding: 5px;
  border-radius: 10px;
  text-transform: uppercase;
  text-align: center;
  font: 500 15px/17px;
`;

export const DeviceFooter = styled.div`
  padding: 10px 5px 0 5px;
  justify-content: space-between;
  display: flex;
`;
