import { Dispatch } from "redux";
import { ReportActionsTypes, ReportActions, IReport } from "./reports_types";
import api from "../../api";

export const getReportJSON = (from: string, to: string, vehicle_id: number, format: string) => {
  return async (dispatch: Dispatch<ReportActions>) => {
    dispatch({ type: ReportActionsTypes.REPORT_LOADING, payload: true });
    const response: Array<IReport> = await api.getReportJSON(from, to, vehicle_id, format);
    dispatch({
      type: ReportActionsTypes.GET_REPORT,
      payload: response,
    });
  };
};

export const toggleIsReport = (bool: boolean) => {
  return async (dispatch: Dispatch<ReportActions>) => {
    dispatch({ type: ReportActionsTypes.REPORT_IS, payload: bool });
  };
};

export const toggleIsShow = (bool: boolean) => {
  return async (dispatch: Dispatch<ReportActions>) => {
    dispatch({ type: ReportActionsTypes.SHOW_IS, payload: bool });
  };
};

export const getReportPDF = (from: string, to: string, vehicle_id: number, format: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (_dispatch: Dispatch<ReportActions>) => {
    const response: any = await api.getReportPDF(from, to, vehicle_id, format);
    return response;
  };
};
