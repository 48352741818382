import { UserState, UserActions, UserActionTypes, IUser } from "./user_types";

const initialState: UserState = {
  data: {} as IUser,
  loading: true,
  error: "",
  auth: false,
  users: [],
};

const UserReducer = (state = initialState, action: UserActions) => {
  switch (action.type) {
    case UserActionTypes.GET_USER:
      return {
        ...state,
        data: action.payload,
      };
    case UserActionTypes.USER_AUTH:
      return {
        ...state,
        auth: action.payload,
      };
    case UserActionTypes.USER_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case UserActionTypes.GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    default:
      return state;
  }
};

export default UserReducer;
