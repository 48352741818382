import { useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import { INotification, INotificationsState } from "../../redux/notifications/notifications_types";
import { ModalsState } from "../../redux/modals/modals_types";
import SkeletonLoading from "../skeleton-loading/SkeletonLoading";
import ModalWindow from "./ModalWindow";
import Notification from "../notification/Notification";
import ModalInput from "../ui/Inputs/ModalInput";
import toast from "react-hot-toast";

const ModalNotifications = () => {
  const modals: ModalsState = useTypedSelector((state) => state.modals);

  const [email, setEmail] = useState<string>("");

  const [errors, setErrors] = useState<any>({
    value: [],
    vehicle_id: [],
  });

  const {
    notification_resources,
    notification_resources_loading,
    vehicle_id,
  }: INotificationsState = useTypedSelector((state) => state.notifications);

  const { toggleModal, postNotif } = useActions();

  const [submiting, setSubmiting] = useState<boolean>(false);

  const handleClose = () => {
    toggleModal({ toggle: false, modal: "modal_notifications" });
    setEmail("");
    setErrors({
      value: [],
      vehicle_id: [],
    });
    setSubmiting(false);
  };

  const handleSave = async () => {
    setErrors({
      value: [],
      vehicle_id: [],
    });
    const data: any = {
      vehicle_id,
      type: "email",
      value: email,
    };
    try {
      setSubmiting(true);
      await postNotif(data, vehicle_id);
      toast.success("Почта добавлена");
      setEmail("");
    } catch (error: any) {
      if (error?.response?.data?.errors) {
        setErrors({
          ...errors,
          value: error?.response?.data?.errors?.value || [],
          vehicle_id: error?.response?.data?.errors?.vehicle_id || [],
        });
      } else if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
    setSubmiting(false);
  };

  const notification = () => {
    return notification_resources.map((notif: INotification) => {
      if (notif.vehicle_id === vehicle_id) {
        return <Notification vehicle_id={vehicle_id} notif={notif} key={notif.id} />;
      }
    });
  };

  if (submiting) {
    return (
      <ModalWindow
        isShow={modals.modal_notifications}
        title="Настройка уведомлений"
        dialogClassName="modal__box-big"
        handleClose={handleClose}
      >
        <label className="profile-edit__label">Email адреса для отчетов</label>
        <br />
        {notification_resources_loading ? (
          <>
            <SkeletonLoading height="38px" />
            <br />
          </>
        ) : (
          notification()
        )}
        <SkeletonLoading height="38px" />
      </ModalWindow>
    );
  }

  return (
    <ModalWindow
      isShow={modals.modal_notifications}
      title="Настройка уведомлений"
      dialogClassName="modal__box-big"
      handleClose={handleClose}
    >
      <label className="modal-folder__title">Email адреса для отчетов</label>
      <br />
      {notification_resources_loading ? (
        <>
          <SkeletonLoading height="38px" />
          <br />
        </>
      ) : (
        notification()
      )}
      <div className="modal-content__body">
        <ModalInput
          value={email}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setEmail((e.target as HTMLInputElement).value)
          }
          errors={errors.value}
        />
        <button className="modal__button input-area--margin" onClick={(e) => handleSave()}>
          Добавить email
        </button>
      </div>
    </ModalWindow>
  );
};

export default ModalNotifications;
