import React, { FC } from "react";
import { ViewGoBack } from "./SideBarGoBack.styled";
import GoBackBtn from "./GoBack";

interface ISideBarGoBack {
  text: string;
  to: string;
}

const SideBarGoBack: FC<ISideBarGoBack> = ({ to, text }) => {
  return (
    <ViewGoBack>
      <GoBackBtn text={text} to={to} />
    </ViewGoBack>
  );
};

export default SideBarGoBack;
