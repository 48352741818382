import { ChangeEventHandler, memo, useState } from "react";
import { IMAGES } from "../../../constants/images";

const ModalInput = ({
  value,
  onChange,
  onChangeTextArea,
  errors = [],
  title,
  length = 64,
  placeholder,
  leftText,
  Icon,
  readOnly,
  type,
  min,
  name,
  rowsLength,
  classes = [],
  autoComplete = false,
  titleClass = "modal-folder__title",
  isInput = true,
}: IModalInput) => {
  const classList = ["input-area"].concat(classes);

  const [visible, setVisible] = useState(false);

  return (
    <div className={classList.join(" ")}>
      {title && <label className={titleClass}>{title}</label>}
      <div className="d-flex align-items-center">
        {leftText && <span className="modal-title--margin modal-title--first">{leftText}</span>}
        <div className="position-relative w-100">
          {isInput ? (
            <input
              autoComplete={autoComplete ? "new-password" : "on"}
              name={name}
              min={min}
              maxLength={length}
              readOnly={readOnly}
              onChange={onChange}
              value={value}
              placeholder={placeholder || ""}
              type={visible ? "text" : type}
              className={
                errors && errors.length == 0
                  ? "modal-input__grow"
                  : "modal-input__grow modal-input__grow--error"
              }
            />
          ) : (
            <textarea
              name={name}
              onChange={onChangeTextArea}
              maxLength={length}
              readOnly={readOnly}
              rows={rowsLength}
              value={value}
              placeholder={placeholder || ""}
              className={
                errors && errors.length == 0
                  ? "modal-input__grow textarea__resize"
                  : "modal-input__grow textarea__resize modal-input__grow--error"
              }
            />
          )}

          {type === "password" ? (
            <button
              type="button"
              className="modal-password_visible"
              style={{ top: 10 }}
              onClick={() => setVisible(!visible)}
            >
              <img src={!visible ? IMAGES.Image1 : IMAGES.Image2} alt="" />
            </button>
          ) : (
            <div className="input-icon">{Icon && <Icon />}</div>
          )}
        </div>
      </div>

      {errors &&
        Array.isArray(errors) &&
        errors.length > 0 &&
        errors.map((error: any, index: number) => (
          <div key={index} className="input-error-message">
            {error}
          </div>
        ))}
      {errors && typeof errors === "string" && <div className="input-error-message">{errors}</div>}
    </div>
  );
};

export default memo(ModalInput);

interface IModalInput {
  length?: number;
  title?: string;
  value?: string | number;
  errors?: string[] | null | string;
  placeholder?: string;
  leftText?: string;
  Icon?: any;
  readOnly?: boolean;
  type?: "number" | "text" | "password" | "date";
  min?: number;
  name?: string;
  classes?: string[];
  titleClass?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onChangeTextArea?: ChangeEventHandler<HTMLTextAreaElement>;
  autoComplete?: boolean | string;
  isInput?: boolean;
  rowsLength?: number;
}
