import { useActions } from "hooks/useAction";
import { useTypedSelector } from "hooks/useTypedSelector";
import { TyresState } from "redux/tyres/tyres_types";
import { useEffect, useState } from "react";
import Loader from "components/loader/Loader";
import TyreTitle from "components/tyres/TyreTitle";
import TyreInfo from "components/tyres/TyreInfo";
import moment from "moment";
import TyreList from "./components/TyreList";

const TyresStorePage = () => {
  const titles = [
    "ШИНА",
    "ДАТА УСТАНОВКИ / ПЕРЕМЕЩЕНИЯ",
    "ПРИЧИНА ПЕРЕМЕЩЕНИЯ",
    "ПРОБЕГ",
    "СТОИМОСТЬ (1 КМ ПРБЕГА)",
    "",
  ];

  const { postTyresByPeriod } = useActions();
  const [count, setCount] = useState<number>(0);
  const { tyres_by_period, loading_tyres }: TyresState = useTypedSelector((state) => state.tyres);
  const today = moment(new Date()).format("YYYY-MM-DD");

  const init = async () => {
    await postTyresByPeriod(today, today, "store");
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    let sum = 0;
    if (tyres_by_period && tyres_by_period.tyres) {
      for (let index = 0; index < tyres_by_period.tyres.length; index++) {
        sum = sum + tyres_by_period.tyres[index].action.run;
      }
    }
    setCount(sum);
  }, [tyres_by_period?.tyres]);

  const handlClick = () => {
    console.log("click");
  };

  return (
    <div>
      <div className="report_head">
        {loading_tyres ? (
          <Loader />
        ) : (
          <>
            <TyreTitle
              title="Склад"
              subtitle="б/у шин"
              btnTitle="переместить"
              handleClick={handlClick}
            />
            <TyreInfo
              firstCount={{
                count: tyres_by_period?.tyres.length,
                title1: "количество",
                title2: "шин",
              }}
              secondCount={{
                count: count,
                title1: "общий",
                title2: "пробег",
              }}
              showConstructor={false}
            />
            <TyreList
              from={
                tyres_by_period && tyres_by_period.from
                  ? tyres_by_period.from
                  : moment(new Date()).format("YYYY-MM-DD")
              }
              to={tyres_by_period?.to}
              titles={titles}
              tyres={tyres_by_period?.tyres ? tyres_by_period?.tyres : []}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default TyresStorePage;
