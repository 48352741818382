import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonLoading = ({
  height,
  theme,
  width,
}: {
  height: string;
  theme?: boolean;
  width?: string;
}) => {
  if (theme) {
    return (
      <SkeletonTheme baseColor="#fff" highlightColor="#EDEFF4">
        <Skeleton className={theme ? "skeleton-loader" : ""} height={height} width={width} />
      </SkeletonTheme>
    );
  }
  return (
    <SkeletonTheme baseColor="#EDEFF4" highlightColor="#fff">
      <Skeleton className={theme ? "skeleton-loader" : ""} height={height} width={width} />
    </SkeletonTheme>
  );
};

export default SkeletonLoading;
