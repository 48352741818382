import { useEffect } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IPoint } from "../../redux/map/map_types";
import { useActions } from "../../hooks/useAction";
import { TabModes } from "../../types/other";
import { useParams } from "react-router-dom";
import NoData from "../../components/no-data/NoData";
import SkeletonLoading from "../../components/skeleton-loading/SkeletonLoading";
import CustomScroll from "react-custom-scroll";
import Sensors from "components/vehicle-view/sensors/Sensors";
import ViewStatusTimestamp from "components/vehicle-view/ViewStatusTimestamp";
import { CarState } from "redux/car/car_types";
import toast from "react-hot-toast";

const ViewCarSensorsPage = () => {
  const { vehicle_id } = useParams<{ vehicle_id?: string }>();
  const { last_point, car, loading_point }: CarState = useTypedSelector((state) => state.car);
  const point: null | IPoint = last_point;
  const { getLastPointServer } = useActions();

  useEffect(() => {
    if (!car?.device_id || !vehicle_id || car?.id !== +vehicle_id) return;
    init();
  }, [car?.device_id]);

  const init = async () => {
    if (!car) return;

    const { status, data }: any = await getLastPointServer(car.device_id, false);

    if (status !== 200 && status !== 201) toast.error(data.message);
  };

  if (loading_point || car === null)
    return (
      <div className="view__inner">
        <SkeletonLoading height="200px" />
      </div>
    );

  if (!point) return <NoData />;

  return (
    <CustomScroll allowOuterScroll={true} heightRelativeToParent="calc(100%)">
      <div className="view__inner">
        <div className="view__inner-info">
          <ViewStatusTimestamp
            dateFrom={point.device_created_at}
            dateTo={point.device_updated_at}
          />

          <Sensors car={car} point={point} tabMode={TabModes.ALL} />
        </div>
      </div>
    </CustomScroll>
  );
};

export default ViewCarSensorsPage;
