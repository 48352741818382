import { useContext, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import { ModalsState } from "../../redux/modals/modals_types";
import ModalInput from "../ui/Inputs/ModalInput";
import ModalWindow from "./ModalWindow";
import { ModalUserCarContext } from "components/user/context/ModalUserCarContext";
import api from "api";
import SkeletonLoading from "components/skeleton-loading/SkeletonLoading";
import { CarState } from "redux/car/car_types";
import toast from "react-hot-toast";

const ModalUserCarAdd = () => {
  const { toggleModal } = useActions();

  const modals: ModalsState = useTypedSelector((state) => state.modals);
  const car: CarState = useTypedSelector((state) => state.car);
  const { userCurrent, setuserCarList, userCarList } = useContext(ModalUserCarContext);
  const [submiting, setSubmiting] = useState<boolean>(false);
  const [carId, setCarId] = useState<string>("");

  const handleClose = () => {
    toggleModal({ toggle: false, modal: "modal_user_car_add" });
    setSubmiting(false);
  };

  const handleSave = async () => {
    setSubmiting(true);

    try {
      await api.addUserCars({ user_id: userCurrent, vehicle_id: carId });

      await api.getUserCars(userCurrent).then((res) => {
        setuserCarList(res.vehicles);
      });

      toast.success("Данные изменены");
      handleClose();
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
    setSubmiting(false);
  };

  // if (!car.car_extended) return <></>

  return (
    <ModalWindow
      isShow={modals.modal_user_car_add}
      title="Изменение владельца ТС"
      handleClose={handleClose}
    >
      {submiting ? (
        <SkeletonLoading height="142px" />
      ) : (
        <>
          <div className="modal-content__box">
            <ModalInput
              title="ID транспортного средства"
              value={carId}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setCarId((e.target as HTMLInputElement).value);
              }}
              errors={[]}
            />
          </div>

          <div className="modal-folder__button-box">
            <button
              className="modal__button modal__button--white modal__button--red"
              onClick={() => handleClose()}
            >
              Отменить
            </button>
            <button className="modal__button " onClick={(e) => handleSave()}>
              Сохранить
            </button>
          </div>
        </>
      )}
    </ModalWindow>
  );
};

export default ModalUserCarAdd;
