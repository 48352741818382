import { IPoint } from "../../redux/map/map_types";
import { limitTypes } from "../../helpers/charts";
import { ISettings, SettingsState } from "../../redux/settings/settings_types";
import { useTypedSelector } from "hooks/useTypedSelector";
import simplify from "./simplify-js/simplify";

export const getDataCharts = (points: any[], type: string) => {
  let data: any = {};
  const { limits, settingsLast }: SettingsState = useTypedSelector((state) => state.settings);

  points.forEach((point) => {
    if (point.sensors.length != 0) {
      point.sensors.forEach((sensor: any, index: number) => {
        if (data["Датчик №" + (index + 1)] == undefined) {
          data["Датчик №" + (index + 1)] = [];
        }

        const sensorData = type == "pressure" ? sensor[0] : sensor[1];

        data["Датчик №" + (index + 1)].push({
          date: Date.parse(point.device_updated_at.slice(0, 19)),
          ["Датчик №" + (index + 1)]: sensorData,
        });
      });
    }
  });

  if (type === "pressure") {
    data = makeLimits(data, "bar_max", points, limits, settingsLast);
    data = makeLimits(data, "bar_min", points, limits, settingsLast);
  } else {
    data = makeLimits(data, "temp_max", points, limits, settingsLast);
  }

  const dataOpt: any = {};
  Object.entries(data).forEach((item: any) => {
    if (!dataOpt[item[0]]) {
      dataOpt[item[0]] = [];
    }
    const pointsOptimized = simplify(item[1], 0.2, true, item[0]);
    dataOpt[item[0]] = [...pointsOptimized];
  });

  return dataOpt;
};

export const makeLimits = (
  data: any,
  type: string,
  points: IPoint[],
  limits: ISettings[],
  settingsLast: ISettings
) => {
  let limitsSort: any[] = [];

  const limitType = limitTypes[type];

  if (data[limitType] == undefined) {
    data[limitType] = [];
  }

  if (Object.values(settingsLast).length != 0) limitsSort.push(settingsLast);

  limitsSort = [...limitsSort, ...limits];

  if (Object.values(settingsLast).length === 0) return data;

  limitsSort.forEach((item, index) => {
    if (index == 0) {
      data[limitType].push({
        date: Date.parse(points[0].device_updated_at.slice(0, 19)),
        [limitType]: settingsLast[type],
      });
    } else {
      const item_prev = data[limitType][data[limitType].length - 1];

      if (item_prev && item_prev[limitType] != item[type]) {
        data[limitType].push({
          date: Date.parse(item.created_at.slice(0, 19)),
          [limitType]: item_prev[limitType],
        });
      }

      if (
        !data[limitType][data[limitType].length - 1] ||
        !data[limitType][data[limitType].length - 1][limitType] == item[type]
      ) {
        data[limitType].push({
          date: Date.parse(item.created_at.slice(0, 19)),
          [limitType]: item[type],
        });
      }
    }
  });

  data[limitType].push({
    date: Date.parse(points[points.length - 1].device_updated_at.slice(0, 19)),
    [limitType]: data[limitType][data[limitType].length - 1][limitType],
  });

  return data;
};
