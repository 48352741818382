import { useContext } from "react";
import { ICar } from "redux/car/car_types";
import { ModalUserCarContext } from "../context/ModalUserCarContext";
import { useActions } from "hooks/useAction";
import { toast } from "react-hot-toast";
import api from "api";
import { useGetFoldersUserQuery } from "redux/folder/FolderApi";

const UserVehicleButtons = ({ car, userId }: { car: ICar; userId: number }) => {
  const D = new Date();
  const date =
    D.getFullYear() +
    "-" +
    ("0" + (D.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + D.getDate()).slice(-2);
  const { setUserCurrent, setuserCarList, setCarUser } = useContext(ModalUserCarContext);
  const { data, isLoading } = useGetFoldersUserQuery(userId);
  const {
    toggleModal,
    toggleModalConfirm,
    closeModalConfirm,
    getLastLimits,
    getVehicleId,
    getNotifs,
    notificationResourcesLoading,
  } = useActions();
  const handleOpen = () => {
    console.log("open");
    setCarUser(car);
    setUserCurrent(userId);
    toggleModal({ toggle: true, modal: "modal_user_car_change" });
  };

  const handlerGeneralSettings = () => {
    toggleModal({ toggle: true, modal: "modal_general_settings" });
    getVehicleId(car.id);
    getLastLimits({ vehicle_id: car.id, before: date });
  };

  const handlerChangeModalNotifications = () => {
    notificationResourcesLoading(true);
    getNotifs(car.id);
    toggleModal({ toggle: true, modal: "modal_notifications" });
  };

  const handleConfirm = () => {
    setUserCurrent(userId);
    toggleModalConfirm({
      toggle: true,
      title: "Подтвердите действие",
      content: "Вы уверены, что хотите удалить TC?",
      onConfirm: confirmDelete,
      onCancel: cancelDelete,
    });
  };

  const handleChangeFolder = () => {
    setCarUser(car);
    setUserCurrent(userId);
    toggleModal({ toggle: true, modal: "modal_change_folder" });
  };

  const confirmDelete = (): void => {
    handleDelete();
  };

  const cancelDelete = (): void => {
    closeModalConfirm();
  };

  const handleDelete = async () => {
    try {
      await api.deleteCar(car.id);
      await api.getUserCars(userId).then((res) => {
        setuserCarList(res.vehicles);
      });
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <button className="button button--middle-grey button--margin" onClick={() => handleOpen()}>
        Редактировать
      </button>
      {data && data.length > 0 && (
        <button
          className="button button--middle-grey button--margin"
          onClick={() => handleChangeFolder()}
        >
          Изменить папку
        </button>
      )}
      <button
        className="button button--middle-grey button--margin"
        onClick={handlerGeneralSettings}
      >
        Настройки
      </button>
      <button
        className="button button--middle-grey button--margin"
        onClick={handlerChangeModalNotifications}
      >
        Уведомления
      </button>
      <button className="button button--middle-grey button--margin" onClick={() => handleConfirm()}>
        Архивировать
      </button>
    </>
  );
};

export default UserVehicleButtons;
