import {
  GetLastSettingsData,
  GetPeriodSettingsData,
  PostTransportSettings,
  SettingsActions,
  SettingsActionTypes,
} from "./settings_types";
import { Dispatch } from "redux";
import api from "../../api";
import { toggleModal } from "../modals/modals_actions";
import { ModalsActions } from "../modals/modals_types";
import { DEFAULT_LIMIT_PAYLOAD } from "../../constants/limits";
import { CarsActionTypes } from "redux/cars/cars_types";
import { CarActionTypes } from "redux/car/car_types";

export const getLimits = () => {
  return async (dispatch: Dispatch<SettingsActions | any>) => {
    try {
      dispatch(loadingLimitSettings(true));
      const result: any = await api.getLimits();
      dispatch({
        type: SettingsActionTypes.GET_LIMITS,
        payload: result.limits,
      });
      return result;
    } catch (e: any) {
      console.log("e", e.message);
      return e.response;
    }
  };
};

export const loadingLimitSettings = (toggle: boolean) => {
  return async (dispatch: Dispatch<SettingsActions>) => {
    dispatch({
      type: SettingsActionTypes.LOADING_GET_LAST_SETTINGS,
      payload: toggle,
    });
  };
};

export const ChangeInputLastSettings = (key: string, value: number) => {
  return async (dispatch: Dispatch<SettingsActions>) => {
    dispatch({
      type: SettingsActionTypes.CHANGE_INPUT_LAST_SETTINGS,
      payload: { key, value },
    });
  };
};

export const getVehicleId = (id: number) => {
  return async (dispatch: Dispatch<SettingsActions>) => {
    dispatch({
      type: SettingsActionTypes.GET_VEHICLE_ID,
      payload: id,
    });
  };
};

export const postLimits = (data: PostTransportSettings) => {
  return async (dispatch: Dispatch<SettingsActions | ModalsActions | any>) => {
    await api.postLimits(data);

    dispatch({
      type: SettingsActionTypes.GET_LAST_LIMITS,
      payload: data,
    });

    dispatch({
      type: CarActionTypes.CAR_CHANGE_LIMIT,
      payload: data,
    });

    dispatch({
      type: CarsActionTypes.CARS_UPDATE_FIELD,
      payload: {
        id: data.vehicle_id,
        field: "last_limit",
        value: data,
      },
    });

    toggleModal({ toggle: false, modal: "modal_general_settings" });
  };
};

export const getLastLimits = ({ vehicle_id, before }: GetLastSettingsData) => {
  return async (dispatch: Dispatch<SettingsActions | any>) => {
    try {
      dispatch(loadingLimitSettings(true));
      const result: any = await api.getLastLimits({ vehicle_id, before });
      dispatch({
        type: SettingsActionTypes.GET_LAST_LIMITS,
        payload: result.data.limit,
      });
      return result;
    } catch (e: any) {
      console.log("e", e.message);
      if (e.response.status == 404) {
        dispatch({
          type: SettingsActionTypes.GET_LAST_LIMITS,
          payload: DEFAULT_LIMIT_PAYLOAD,
        });
      }
      return e.response;
    }
  };
};

export const getPeriodLimits = ({ vehicle_id, from, to }: GetPeriodSettingsData) => {
  return async (dispatch: Dispatch<SettingsActions | any>) => {
    // try {
    dispatch(loadingLimitSettings(true));
    const result: any = await api.getPeriodLimits({ vehicle_id, from, to });
    dispatch({
      type: SettingsActionTypes.GET_PERIOD_LIMITS,
      payload: result.data.limits,
    });
    return result;
    // } catch (e: any) {
    //   console.log("e", e.message);
    // }
  };
};

export const toggleIsCharts = (bool: boolean) => {
  return async (dispatch: Dispatch<SettingsActions>) => {
    dispatch({ type: SettingsActionTypes.CHARTS_IS, payload: bool });
  };
};
