import { createSlice } from "@reduxjs/toolkit";
// import api from "api";
// import axios from "axios";
import { UserState, IUser } from "./user_types";

// export const getUser = createAsyncThunk("user/getUser", async () => {
//     const response = await axios.post<any>(api.getUserMe())
//     return response.data.user
// });

const initialState: UserState = {
  data: {} as IUser,
  loading: true,
  error: "",
  auth: false,
  users: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  // extraReducers:{
  //   [getUser.fulfilled.type]: (state,action) => {
  //       state.data = action.payload
  //       state.auth = true
  //   }
  // }
});

export const userReducer = userSlice.reducer;
export const userActions = userSlice.actions;
