import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { baseUrl } from "../helpers/config";

/*
 *   build.query<То Что получаем, То что передаем в запрос>
 *   если void то в запросе не сможем использовать options
 *   если any то можем
 */

export const GlobalApi = createApi({
  tagTypes: ["Devices", "Users", "Folders", "Profiles"], //какие теги сушествуют
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    headers: {
      "Content-Type": "application/json",
    },
    prepareHeaders: (headers, { getState }) => {
      const token: string | null = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        return headers;
      }
    },
  }),
  endpoints: (build) => ({}),
});
