import { Navigate, Route, Routes } from "react-router-dom";
import HomeLayout from "../layouts/HomeLayout";
import PublicLayout from "../layouts/PublicLayout";
import LoginPage from "../pages/LoginPage";
import MainPage from "../pages/main/MainPage";
import NewPasswordPage from "../pages/NewPasswordPage";
import NotFound from "../pages/NotFound";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import SignupPage from "../pages/SignupPage";

const PublicRoutes = () => (
  <Routes>
    <Route element={<HomeLayout />}>
      {/* <Route path="/" element={<MainPage />} /> */}
      <Route path="/" element={<Navigate to="/login" replace={true} />} />
    </Route>
    <Route element={<PublicLayout />}>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/new_password/:token" element={<NewPasswordPage />} />
    </Route>
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default PublicRoutes;
