import React, { useEffect, useState } from "react";
import api from "../api";
import { useNavigate, useParams } from "react-router-dom";
import ModalInput from "../components/ui/Inputs/ModalInput";
import { toast } from "react-hot-toast";

const defaultErrors = {
  code: [],
  password: [],
};

const NewPasswordPage = () => {
  const params = useParams();
  const navigation = useNavigate();
  const [code, setCode] = useState<string>("");
  const [newPassword, setNewPassword] = useState<any>("");
  const [confirmErrors, setConfirmErrors] = useState<any>(defaultErrors);

  useEffect(() => {
    if (!params.token) return;
    setCode(params.token);
  }, []);

  const submitCode = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setConfirmErrors(defaultErrors);

    await api
      .userConfirmPassword(code, newPassword)
      .then((res) => {
        toast.success(res.data.message);
        setCode("");
        setNewPassword("");
        navigation("/login");
      })
      .catch((e: any) => {
        setConfirmErrors(e.response.data.errors);
        toast.error(e.response.data.message);
      });
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h4 className="h4">Введите новый пароль</h4>
        <form onSubmit={submitCode}>
          <input
            className="modal-input__grow"
            readOnly
            name="code"
            type="text"
            placeholder="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            style={{ display: "none" }}
          />

          <ModalInput
            type="password"
            name="password"
            placeholder="Новый пароль"
            value={newPassword}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setNewPassword((e.target as HTMLInputElement).value)
            }
            errors={confirmErrors?.password}
          />

          <div className="bg-warning">{confirmErrors?.code?.map((error: any) => error)}</div>

          <div className="login__buttons">
            <button className="button button--login" type="submit">
              Сохранить новый пароль
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewPasswordPage;
