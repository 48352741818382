import styled, { css } from "styled-components";

export const ButtonReset = css`
  background: transparent;
  border: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  padding: 11px 13px;
  transition: 0.1s all ease;
  text-decoration: none;
`;

export const Button = css`
  display: flex;
  justify-content: center;
  align-content: center;
  text-transform: uppercase;
`;
