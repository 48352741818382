import { Link } from "react-router-dom";

const NotFound = () => (
  <div className="not-found-page">
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <h1 className="h1 h1--404">404</h1>

          <p className="p p--404">Упс, такой страницы не существует</p>

          <Link to="/" className="button button--fallback">
            На главную
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default NotFound;
