export interface MapState {
  loading: boolean;
  point: null | IPoint;
  route: [] | IPoint[];
  isMultiple: boolean;
  multiSelected: number[];
  isMapLoading: boolean;
  map_global: null;
}

export interface IPoint {
  id: number;
  device_id: number;
  device_created_at: string;
  device_updated_at: string;
  is_history: number;
  lat: number;
  long: number;
  lat_address?: number;
  long_address?: number;
  ignition: number;
  speed: number;
  voltage: number;
  sensors: [] | [[number, number]];
  sensors_from?: null | string;
  run: number;
  run_total: number;
  created_at: string;
  updated_at: string;
  address?: string;
}

export enum MapActionTypes {
  CHANGE_POINT = "CHANGE_POINT",
  CHANGE_ROUTE = "CHANGE_ROUTE",
  TOGGLE_MULTIPLE = "TOGGLE_MULTIPLE",
  TOGGLE_LOADING = "TOGGLE_LOADING",
  MULTI_TOGGLE = "MULTI_TOGGLE",
  MULTI_SELECT = "MULTI_SELECT",
  GLOBAL_MAP = "GLOBAL_MAP",
  CLEAR_MAP_INSTANCE = "CLEAR_MAP_INSTANCE",
}

interface ChangePoint {
  type: MapActionTypes.CHANGE_POINT;
  payload: null | IPoint;
}

interface ToggleMultiple {
  type: MapActionTypes.TOGGLE_MULTIPLE;
  payload: boolean;
}

interface ToggleLoading {
  type: MapActionTypes.TOGGLE_LOADING;
  payload: boolean;
}

interface MultiToggle {
  type: MapActionTypes.MULTI_TOGGLE;
  payload: number;
}

interface MultiSelect {
  type: MapActionTypes.MULTI_SELECT;
  payload: {
    ids: number[];
    toggle: boolean;
  };
}

interface ChangeRoute {
  type: MapActionTypes.CHANGE_ROUTE;
  payload: IPoint[];
}

interface globalMaps {
  type: MapActionTypes.GLOBAL_MAP;
  payload: null;
}

interface clearMapInstans {
  type: MapActionTypes.CLEAR_MAP_INSTANCE;
}
export type MapActions =
  | clearMapInstans
  | globalMaps
  | ChangePoint
  | ChangeRoute
  | ToggleMultiple
  | MultiToggle
  | MultiSelect
  | ToggleLoading;
