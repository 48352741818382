import React, { useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import { ModalsState } from "../../redux/modals/modals_types";
import SkeletonLoading from "../skeleton-loading/SkeletonLoading";
import ModalWindow from "./ModalWindow";
import { SettingsState } from "../../redux/settings/settings_types";
import ModalInput from "../ui/Inputs/ModalInput";
import toast from "react-hot-toast";

const ModalGeneralSettings = () => {
  const { toggleModal, ChangeInputLastSettings, postLimits } = useActions();

  const [errors, setErrors] = useState({
    bar_max: [],
    bar_min: [],
    speed_max: [],
    temp_max: [],
  });

  const modals: ModalsState = useTypedSelector((state) => state.modals);
  const { settingsLast, loading, vehicle_id }: SettingsState = useTypedSelector(
    (state) => state.settings
  );

  const [submiting, setSubmiting] = useState<boolean>(false);

  const handleClose = () => {
    toggleModal({ toggle: false, modal: "modal_general_settings" });
    setErrors({
      bar_max: [],
      bar_min: [],
      speed_max: [],
      temp_max: [],
    });
  };

  const handleChangeInput = (key: string, value: any) => {
    ChangeInputLastSettings(key, value);
  };

  const handleSave = async () => {
    const data: any = {
      bar_min: +settingsLast.bar_min,
      bar_max: +settingsLast.bar_max,
      temp_max: +settingsLast.temp_max,
      speed_max: +settingsLast.speed_max,
      vehicle_id,
    };

    try {
      setSubmiting(true);
      await postLimits(data);
      handleClose();
      toast.success("Настройки сохранены");
    } catch (error: any) {
      if (error?.response?.data?.errors) {
        setErrors({
          ...errors,
          speed_max: error?.response?.data?.errors?.speed_max || [],
          bar_max: error?.response?.data?.errors?.bar_max || [],
          bar_min: error?.response?.data?.errors?.bar_min || [],
          temp_max: error?.response?.data?.errors?.temp_max || [],
        });
      } else if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }

    setSubmiting(false);
  };

  if (loading || submiting)
    return (
      <ModalWindow
        isShow={modals.modal_general_settings}
        title="Общие настройки"
        handleClose={handleClose}
      >
        <SkeletonLoading height="345px" />
      </ModalWindow>
    );

  return (
    <ModalWindow
      isShow={modals.modal_general_settings}
      title="Общие настройки"
      handleClose={handleClose}
    >
      <div className="modal-content__box">
        <div className="modal-folder__title">
          ДОПУСТИМОЕ ДАВЛЕНИЕ <span>(BAR)</span>
        </div>

        <div className="modal-content__body">
          <ModalInput
            leftText={"MIN"}
            value={settingsLast.bar_min}
            type={"number"}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              handleChangeInput("bar_min", (e.target as HTMLInputElement).value);
            }}
            min={0}
            errors={errors.bar_min}
          />
          <ModalInput
            leftText={"MAX"}
            value={settingsLast.bar_max}
            type={"number"}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              handleChangeInput("bar_max", (e.target as HTMLInputElement).value);
            }}
            min={0}
            errors={errors.bar_max}
          />
        </div>
      </div>

      <div className="modal-content__box">
        <div className="modal-folder__title">
          ДОПУСТИМАЯ ТЕМПЕРАТУРА <span>(°C)</span>
        </div>
        <ModalInput
          leftText={"MAX"}
          value={settingsLast.temp_max}
          type={"number"}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            handleChangeInput("temp_max", (e.target as HTMLInputElement).value);
          }}
          errors={errors.temp_max}
        />
      </div>

      <div className="modal-content__box">
        <div className="modal-folder__title">
          ДОПУСТИМАЯ СКОРОСТЬ <span>(КМ/Ч)</span>
        </div>
        <ModalInput
          leftText={"MAX"}
          value={settingsLast.speed_max}
          type={"number"}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            handleChangeInput("speed_max", (e.target as HTMLInputElement).value);
          }}
          errors={errors.speed_max}
        />
      </div>

      <div className="modal-edit__button-box">
        <button className="modal__button " onClick={() => handleSave()}>
          Сохранить
        </button>
      </div>
    </ModalWindow>
  );
};

export default ModalGeneralSettings;
