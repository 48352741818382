import { LoginActions, LoginActionTypes, LoginState } from "./login_types";

const initialState: LoginState = {
  error_email: "",
  error_password: "",
};

const LoginReducer = (state = initialState, action: LoginActions) => {
  switch (action.type) {
    case LoginActionTypes.LOGIN_EMAIL_ERROR:
      return {
        ...state,
        error_email: action.payload,
      };
    default:
      return state;
  }
};

export default LoginReducer;
