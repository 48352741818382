export const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1140,
};

export const mediaQuery = (key: keyof typeof breakpoints) => {
  return (style: TemplateStringsArray | string) =>
    `@media (max-width: ${breakpoints[key]}px) { ${style} }`;
};
