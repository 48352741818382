import { IPoint } from "redux/map/map_types";
import { IEvent } from "redux/events/events_types";
import { PointMoveData, PointMoveType, PointStopType } from "./Point";

export const pointsType = (points: IPoint[], stops: IPoint[], events_period: IEvent[]) => {
  let runStart = 0;
  let timeStart = "";
  let timeFinish = "";
  let runFinish = 0;
  let id: number | null = null;
  const stopsId: number[] = [];
  const data: Array<PointMoveType | PointStopType> = [];
  let pointEvents: IEvent[] = [];

  stops.forEach((stop) => {
    stopsId.push(stop.id);
  });

  points.forEach((point: IPoint, index: number) => {
    let pointMove: PointMoveData;

    if (point.is_history == 0) {
      if (stopsId.includes(point.id)) {
        if (runStart !== 0 && timeStart !== "") {
          pointMove = {
            id: id,
            time_finish: timeFinish,
            time_start: timeStart,
            run_start: runStart,
            run_finish: runFinish,
            run_total: runFinish - runStart,
          };

          pointEvents = chooseEvents(timeStart, timeFinish, events_period);

          data.push({
            type: "move",
            data: pointMove,
            events: pointEvents,
          });

          runStart = 0;
          timeStart = "";
          runFinish = 0;
          pointEvents = [];
        }

        const pointFind = stops.find((stop) => stop.id === point.id);

        if (pointFind) {
          data.push({
            type: "stop",
            data: pointFind,
          });
        }
      } else {
        if (runStart === 0 && timeStart === "") {
          runStart = point.run_total;
          timeStart = point.created_at;
          runFinish = point.run_total;
          timeFinish = point.created_at;
          id = point.id;
        } else {
          runFinish = point.run_total;
          timeFinish = point.created_at;
        }

        if (index == points.length - 1) {
          data.push({
            type: "move",
            data: {
              id: id,
              time_finish: timeFinish,
              time_start: timeStart,
              run_start: runStart,
              run_finish: runFinish,
              run_total: runFinish - runStart,
            },
            events: pointEvents,
          });
        }
      }
    }
  });

  return data;
};

const chooseEvents = (start: string, finish: string, events: IEvent[]) => {
  const eventsPoints: IEvent[] = [];

  events.forEach((e) => {
    if (
      Date.parse(e.created_at) >= Date.parse(start) &&
      Date.parse(e.created_at) <= Date.parse(finish)
    ) {
      eventsPoints.push(e);
    }
  });

  return eventsPoints;
};

export function time(ms: number, separator = "") {
  const sec = ms / 1000;
  const days = Math.floor(sec / 3600 / 24);
  const hours = (sec / 3600) % 24;
  const minutes = (sec / 60) % 60;
  const seconds = sec % 60;

  function numDays(val: number) {
    val = Math.floor(val);
    let ending = "";
    if (val % 10 == 1) {
      ending = "день";
    } else if (val % 10 > 1 && val % 10 < 5) {
      ending = "дня";
    } else {
      ending = "дней";
    }
    return val > 0 ? val + " " + ending : "";
  }

  function num(val: number) {
    val = Math.floor(val);
    return val < 10 ? "0" + val : val;
  }

  if (separator === ":") return num(hours) + ":" + num(minutes) + ":" + num(seconds);

  if (+num(hours) === 0 && +num(minutes) === 0) {
    if (+num(seconds) < 10) return String(num(seconds)).slice(1) + " сек";
    return num(seconds) + " сек";
  }

  if (+num(hours) === 0) {
    if (+num(minutes) < 10) return String(num(minutes)).slice(1) + " мин";
    return num(minutes) + " мин";
  }

  if (days > 0) {
    return numDays(days);
  } else return num(hours) + " ч " + num(minutes) + " мин";
}
