import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { baseTheme } from "assets/styles/Theme.styled";
import { mediaQuery } from "helpers/mediaQueries";

export const SideBarEl = styled.div.attrs({
  "data-element": "sidebar",
})`
  background-color: ${baseTheme.colors.lightLightGrey};
  overflow: hidden;
  box-shadow: 5px 5px 10px -10px rgb(0 0 0 / 50%);
  z-index: 1;
  width: ${baseTheme.sizes.sideBarWidth};
  display: flex;
  flex-direction: column;
  ${mediaQuery("md")(`width: 50%`)}
`;

export const SidebarMenu = styled.ul.attrs({
  "data-element": "sidebar-menu",
})`
  width: 100%;
  padding: 0 10px;
  text-align: center;
`;
export const SidebarMenuItem = styled.li`
  width: 100%;
`;
export const SidebarMenuLink = styled(NavLink)`
  font-weight: 400;
  font-size: 13px;
  color: ${baseTheme.colors.main};
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  background-color: ${baseTheme.colors.bg};
  transition: all 0.1s ease;
  border-radius: 5px;
  padding: 9px 10px;
  width: 100%;
  display: inline-block;
  text-align: left;
  strong {
    font-weight: 700;
  }
  &:hover {
    cursor: pointer;
    background-color: #edeff4;
    color: ${baseTheme.colors.main};
  }
`;
export const SideBarHeader = styled.div.attrs({
  "data-element": "sidebar-header",
})`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  z-index: 1;
`;

export const SideBarTop = styled.div.attrs({
  "data-element": "sidebar-top",
})`
  font-family: ${baseTheme.fonts.OpenSans};
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  @media (max-width: 767.9px) {
    flex-direction: column;
    height: auto;
    margin-top: 5px;
  }
`;

export const SideBarBottom = styled.div.attrs({
  "data-element": "sidebar-bottom",
})`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  min-height: 45px;
  @media (max-width: 767.9px) {
    flex-direction: column;
    height: auto;
    margin-top: 5px;
    align-items: start;
  }
`;

export const SideBarContent = styled.div.attrs({
  "data-element": "sidebar-content",
})`
  flex: 1;
  overflow: hidden;
  min-height: 0;
  min-width: 0;
  padding-bottom: 1rem;
  ${mediaQuery("md")(`
    padding-bottom: 2rem;
  `)}
`;
