import React, { FC } from "react";
import { SideBarEl } from "components/admin/sideBar/SideBar.styled";

interface ISideBar {
  children: React.ReactNode;
}

const SideBar: FC<ISideBar> = ({ children }) => {
  return <SideBarEl>{children}</SideBarEl>;
};

export default SideBar;
