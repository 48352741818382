import moment from "moment";
import { TyreDateProps } from "./interfaces";
// import { ActionsState } from "redux/actions/actions_types";
// import { useTypedSelector } from "hooks/useTypedSelector";

const TyreDate = ({ from, to }: TyreDateProps) => {
  return (
    <div className="report_date report_bold">
      <div className="report_date-title">
        {to
          ? `${moment(from).format("DD.MM.YYYY")} - ${moment(to).format("DD.MM.YYYY")}`
          : `${moment(from).format("DD.MM.YYYY")}`}
      </div>
    </div>
  );
};

export default TyreDate;
