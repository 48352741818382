export interface INotificationsState {
  notification_resources: INotification[];
  notification_resources_loading: boolean;
  // device_id: number,
  vehicle_id: number;
}

export interface INotification {
  id: number;
  is_instant: true;
  type: string;
  vehicle_id: number;
  value: string;
}

export interface IPutNotification {
  notification_id: number;
  is_instant: true;
  vehicle_id: number;
}

export enum NotificationsTypes {
  GET_NOTIFICATIONS = "GET_NOTIFICATIONS",
  PUT_MACHINE_RESOURCE_ALERTS = "PUT_MACHINE_RESOURCE_ALERTS",
  NOTIFICATIONS_RESOURCES_LOADING = "NOTIFICATIONS_RESOURCES_LOADING",
  // NOTIFICATIONS_DEVICE_ID = 'NOTIFICATIONS_DEVICE_ID',
  NOTIFICATIONS_TRANSPORT_VEHICLE_ID = "NOTIFICATIONS_TRANSPORT_VEHICLE_ID",
  PUT_INSTANT = "PUT_INSTANT",
}

interface notificationResourcesLoading {
  type: NotificationsTypes.NOTIFICATIONS_RESOURCES_LOADING;
  payload: boolean;
}

// interface notificationsDeviceId {
//   type: NotificationsTypes.NOTIFICATIONS_DEVICE_ID
//   payload: number
// }
interface notificationsTransportVehicleId {
  type: NotificationsTypes.NOTIFICATIONS_TRANSPORT_VEHICLE_ID;
  payload: number;
}
interface getNotifications {
  type: NotificationsTypes.GET_NOTIFICATIONS;
  payload: INotification[];
}

interface putMachineResourceAlerts {
  type: NotificationsTypes.PUT_MACHINE_RESOURCE_ALERTS;
  payload: INotification[];
}

interface putInstant {
  type: NotificationsTypes.PUT_INSTANT;
  payload: IPutNotification;
}

export type NotificationsActions =
  | getNotifications
  | putMachineResourceAlerts
  | notificationResourcesLoading
  // | notificationsDeviceId
  | notificationsTransportVehicleId
  | putInstant;
