import { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { MapState } from "../../redux/map/map_types";
import { defaultCenter, mapSettings } from "./mapTypes";
import { MapLoader } from "./MapLoader.styled";
import Loader from "components/loader/Loader";
import HandleMultiplePoints from "./HandleMultiplePoints";
import HandlePoint from "./HandlePoint";
import HandleRoute from "./HandleRoute";
import { useActions } from "hooks/useAction";

export const ymaps = window.ymaps;
type MapType = any;

const MapLayout = () => {
  const map: MapState = useTypedSelector((state) => state.map);
  const [myMap, setMyMap] = useState<null | MapType>(null);
  const { mapGlobal, clearMap } = useActions();

  useEffect(() => {
    // инициализация карты
    if (!!myMap || map.loading) return;

    const init = () => {
      const center: [number, number] =
        // @ts-ignore
        localStorage.getItem("@center") !== null
          ? JSON.parse(localStorage.getItem("@center") || "{}")
          : defaultCenter;

      const newMap = new ymaps.Map("Map", {
        ...mapSettings,
        center,
      });

      newMap.margin.setDefaultMargin([150, 150, 150, 150]);

      // следим за центром
      newMap.events.add("actionend", function (e: any) {
        localStorage.setItem("@center", JSON.stringify(e.originalEvent.map.getCenter()));
      });
      setMyMap(newMap);
      mapGlobal(newMap); // копирования в redux переполнение в памяти нужен другой вариант
    };
    ymaps.ready(init);
  }, [myMap, map.loading]);

  return (
    <div className="map" id="Map">
      <MapLoader isMapLoading={map.isMapLoading}>
        <Loader />
      </MapLoader>

      {myMap && <HandlePoint myMap={myMap} />}
      {myMap && <HandleRoute myMap={myMap} />}
      {myMap && <HandleMultiplePoints myMap={myMap} />}
    </div>
  );
};

export default MapLayout;
