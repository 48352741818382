import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import { ModalsState } from "../../redux/modals/modals_types";
import SkeletonLoading from "../skeleton-loading/SkeletonLoading";
import ModalWindow from "./ModalWindow";
import ModalInputPassword from "./ModalInputPassword";
import toast from "react-hot-toast";

const ModalSetNewPassword = () => {
  const modals: ModalsState = useTypedSelector((state) => state.modals);

  const { userChangePassword, toggleModal } = useActions();

  const [oldPasswordVisible, setOldPasswordVisible] = useState<boolean>(true);
  const [newPasswordVisible, setNewPasswordVisible] = useState<boolean>(true);
  const [newRepeatPasswordVisible, setNewRepeatPasswordVisible] = useState<boolean>(true);

  const [errors, setErrors] = useState<any>({});

  const [submiting, setSubmiting] = useState<boolean>(false);

  const handleClose = () => {
    toggleModal({ toggle: false, modal: "modal_set_new_password" });
    formik.resetForm();

    setOldPasswordVisible(false);
    setNewPasswordVisible(false);
    setNewRepeatPasswordVisible(false);
    setErrors({});
  };

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      new_repeat_password: "",
    },
    validationSchema: Yup.object().shape({
      old_password: Yup.string().required("Обязательное поле"),
      new_password: Yup.string().required("Обязательное поле"),
      new_repeat_password: Yup.string()
        .oneOf([Yup.ref("new_password"), null], "Пароли не совпадают ")
        .required("Обязательное поле"),
    }),
    validateOnMount: false,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: async (values) => {
      setErrors({});
      try {
        setSubmiting(true);
        const response: any = await userChangePassword(
          values.old_password.trim(),
          values.new_password.trim()
        );
        toast.success("Пароль изменен");
        handleClose();
      } catch (error: any) {
        if (error?.response?.data?.errors) {
          if (error?.response?.data?.errors) {
            setErrors(error.response.data.errors);
          }
        } else toast.error(error.response.data.message);
      }
      setSubmiting(false);
    },
  });

  const handleSave = async () => {
    setErrors({});
    formik.handleSubmit();
  };

  if (submiting) {
    return (
      <ModalWindow
        isShow={modals.modal_set_new_password}
        title="Изменить пароль"
        dialogClassName="modal__box-big"
        handleClose={handleClose}
      >
        <SkeletonLoading height="130px" />
      </ModalWindow>
    );
  }

  return (
    <ModalWindow
      isShow={modals.modal_set_new_password}
      title="Изменить пароль"
      dialogClassName="modal__box-big"
      handleClose={handleClose}
    >
      <div className="modal-password__container">
        <ModalInputPassword
          value={formik.values.old_password}
          onChange={formik.handleChange}
          onClick={() => setOldPasswordVisible(!oldPasswordVisible)}
          errors={errors.password}
          formikErrors={formik.errors.old_password}
          title="Старый Пароль"
          visible={oldPasswordVisible}
          name="old_password"
        />

        <ModalInputPassword
          value={formik.values.new_password}
          onChange={formik.handleChange}
          onClick={() => setNewPasswordVisible(!newPasswordVisible)}
          errors={errors.new_password}
          formikErrors={formik.errors.new_password}
          title="Новый Пароль"
          name="new_password"
          visible={newPasswordVisible}
        />

        <ModalInputPassword
          value={formik.values.new_repeat_password}
          onChange={formik.handleChange}
          onClick={() => setNewRepeatPasswordVisible(!newRepeatPasswordVisible)}
          // errors={[]}
          formikErrors={formik.errors.new_repeat_password}
          title="Повторите Пароль"
          name="new_repeat_password"
          visible={newRepeatPasswordVisible}
        />
      </div>
      <div className="modal-edit__button-box">
        <button className="modal__button modal__button--grey-hover" onClick={handleClose}>
          Отменить
        </button>
        <button
          type="submit"
          className="modal__button modal__button--white"
          onClick={() => handleSave()}
        >
          Сохранить
        </button>
      </div>
    </ModalWindow>
  );
};

export default ModalSetNewPassword;
