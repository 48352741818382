import { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { CarState, ICar } from "../../redux/car/car_types";
import { useActions } from "../../hooks/useAction";
import api from "../../api";
import { IPoint } from "../../redux/map/map_types";

interface CoordsObj {
  [key: string]: { lat: number; long: number };
}

interface AddressesObj {
  [key: string]: string;
}

const AddressLoader = () => {
  const carsState = useTypedSelector((state) => state.cars);
  const carState: CarState = useTypedSelector((state) => state.car);

  const [coords, setCoords] = useState<CoordsObj>({});
  const { carsUpdateAddress, carUpdateLastPointAddress } = useActions();
  const [addresses, setAddresses] = useState<AddressesObj>({});

  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    // следим за стейтом cars
    if (!carsState.cars || carsState.cars.length === 0) return;
    handleAddresses(carsState.cars);
  }, [carsState.cars]);

  useEffect(() => {
    // следим за стейтом car
    if (!carState.last_point || !!carState.last_point.address) return;
    handleLastPoint(carState.last_point);
  }, [carState.last_point]);

  // LISTENERS ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  const handleLastPoint = async (point: IPoint) => {
    const key = `${point.lat},${point.long}`;

    if (!(key in addresses)) {
      if (!(key in coords)) {
        // и если координата не в очереди запросов
        if (point.lat !== 0 && point.long !== 0) {
          const result = await api.getAddress({ lat: point.lat, long: point.long });
          carUpdateLastPointAddress(result.data.address);
          setAddresses((addresses) => {
            return { ...addresses, [key]: result.data.address };
          });
        } else {
          setAddresses((addresses) => {
            return { ...addresses, [key]: "Не определен" };
          });
        }
      }
    } else {
      // если адрес уже есть
      carUpdateLastPointAddress(addresses[key]);
    }
  };

  // определяем какие координаты запрашивать
  const handleAddresses = (cars: ICar[]) => {
    const __coords: CoordsObj = {};

    for (const car of cars) {
      if (!car.last_point || car.last_point.address) continue;

      const key = `${car.last_point.lat},${car.last_point.long}`;

      if (key in addresses) {
        // если адрес по координатам уже известен
        carsUpdateAddress({
          lat: car.last_point.lat,
          long: car.last_point.long,
          address: addresses[key],
        });
      }

      if (!(key in addresses) && !(key in coords)) {
        // если координаты не были ранее загружены
        // и если координаты не в очереди запросов
        __coords[key] = {
          lat: car.last_point.lat,
          long: car.last_point.long,
        };
      }
    }

    setCoords(__coords);
  };

  useEffect(() => {
    if (Object.keys(coords).length === 0 || isFetching) return;
    fetchAddresses();
  }, [coords]);

  const fetchAddresses = async () => {
    setIsFetching(true);

    for await (const key of Object.keys(coords)) {
      if (coords[key].lat === 0 || coords[key].long === 0) {
        setAddresses((addresses) => {
          return { ...addresses, [key]: "Адрес не определен" };
        });

        continue;
      }

      // console.log(`await ${coords[key].lat},${coords[key].long}`);

      let address = "Адрес не определен";

      try {
        const result = await api.getAddress({ lat: coords[key].lat, long: coords[key].long });
        if (result.status === 200) address = result.data.address;
      } catch (e: any) {
        // console.log("catch");
        address = "Адрес не определен";
      } finally {
        // console.log("finally");

        carsUpdateAddress({
          lat: coords[key].lat,
          long: coords[key].long,
          address,
        });

        setAddresses((addresses) => {
          return { ...addresses, [key]: address };
        });
      }
    }

    setIsFetching(false);
  };

  return <></>;
};

export default AddressLoader;
