import LoginContactUs from "../components/contact-us/LoginContactUs";
import SignIn from "../components/signIn/SignUp";

const SignupPage = () => {
  return (
    <div className="login-page">
      <SignIn />
      <LoginContactUs />
    </div>
  );
};

export default SignupPage;
