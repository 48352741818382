import { Dispatch } from "redux";
import { DevicesFilterActionTypes, DevicesFilterActions } from "./device_filter_types";
import { IDevice } from "redux/admin/devices/DevicesTypes";

export const getDevicesFilter = (devices: IDevice[], typeFilterDevice = "", filterDevice = "") => {
  let deviceFiltered: IDevice[];
  if (typeFilterDevice !== "" || filterDevice !== "") {
    deviceFiltered = devices.filter((device: IDevice) => {
      if (typeFilterDevice == "profile_id" && device.user) {
        return device.user[typeFilterDevice as keyof typeof device.user] == filterDevice;
      } else if (typeFilterDevice == "car_number" && device.vehicle) {
        return device.vehicle[typeFilterDevice] == filterDevice;
      } else {
        return device[typeFilterDevice as keyof typeof device.user] == filterDevice;
      }
    });
  } else deviceFiltered = devices;

  return (dispatch: Dispatch<DevicesFilterActions>) => {
    dispatch({
      type: DevicesFilterActionTypes.GET_DEVICES_FILTER,
      payload: deviceFiltered,
    });
  };
};

export const changeFilterDevicesSearch = (payload: string) => {
  return (dispatch: Dispatch<DevicesFilterActions>) => {
    dispatch({ type: DevicesFilterActionTypes.DEVICES_FILTER_SEARCH, payload });
  };
};

export const changeTypeDevicesSearch = (payload: string) => {
  return (dispatch: Dispatch<DevicesFilterActions>) => {
    dispatch({ type: DevicesFilterActionTypes.DEVICES_TYPE_SEARCH, payload });
  };
};
