import { createContext, ReactNode, useState } from "react";

interface IMobileContext {
  toggled: boolean;
  setToggled: (value: boolean) => void;
}

export const MobileContext = createContext<IMobileContext>({
  toggled: false,
  setToggled: () => {},
});

export const MobileContextProvider = ({ children }: { children: ReactNode }) => {
  const [toggled, setToggled] = useState<boolean>(false);

  return (
    <MobileContext.Provider
      value={{
        toggled,
        setToggled,
      }}
    >
      {children}
    </MobileContext.Provider>
  );
};
