import { ReactNode } from "react";
import { IPoint } from "../map/map_types";
import { ISettings } from "redux/settings/settings_types";
import { IConstructor } from "redux/constructor/constructor_types";

export interface ModalsState {
  modal_group: boolean;
  modal_group_add: boolean;
  modal_car_extended_info: boolean;
  modal_general_settings: boolean;
  modal_add_transport: boolean;
  modal_edit_user_data: boolean;
  modal_set_new_password: boolean;
  modal_notifications: boolean;
  modal_add_device: boolean;
  modal_remove: boolean;
  modal_create_new_user: boolean;
  modal_loading: boolean;
  modal_point: boolean;
  modal_point_data: IPointDetailed | null;
  modal_confirm: boolean;
  modal_confirm__title: string;
  modal_confirm__content: ReactNode | null;
  modal_confirm__onConfirm: null | (() => void);
  modal_confirm__onCancel: null | (() => void);
  modal_new_password_user_by_moderator: boolean;
  modal_user_car_change: boolean;
  modal_user_car_add: boolean;
  modal_profile_change: boolean;
  modal_update_user: boolean;
  modal_add_tyre: boolean;
  modal_update_tyre: boolean;
  modal_add_action: boolean;

  modal_change_folder: boolean;
}

interface IPointDetailed {
  point: IPoint;
  last_design: IConstructor;
  last_limit: ISettings;
}

export interface ConfirmationPayload {
  toggle: boolean;
  title: string;
  content: ReactNode | null;
  onConfirm: null | (() => void);
  onCancel: null | (() => void);
}

export interface ToggleModalPayload {
  toggle: boolean;
  modal: string;
}

export interface ModalPointPayload {
  data: IPoint | null;
}

export enum ModalsActionTypes {
  TOGGLE_MODAL_CONFIRM = "TOGGLE_MODAL_CONFIRM",
  TOGGLE_MODAL = "TOGGLE_MODAL",
  POINT_MODAL = "POINT_MODAL",
  UPDATE_MODAL = "UPDATE_MODAL",
  MODAL_LOADING = "MODAL_LOADING",
}

interface PointModal {
  type: ModalsActionTypes.POINT_MODAL;
  payload: ModalPointPayload;
}

interface ToggleModal {
  type: ModalsActionTypes.TOGGLE_MODAL;
  payload: ToggleModalPayload;
}

interface ToggleModalConfirm {
  type: ModalsActionTypes.TOGGLE_MODAL_CONFIRM;
  payload: ConfirmationPayload;
}

interface ModalsLoading {
  type: ModalsActionTypes.MODAL_LOADING;
  payload: boolean;
}

export type ModalsActions = ToggleModalConfirm | ToggleModal | PointModal | ModalsLoading;
