import React, { useCallback, useState } from "react";
import cls from "../style/PointAdress.module.scss";

export default function PointAdressButton({ coordinate, children, onShwBallon }) {
  const handleClick = useCallback(() => {
    onShwBallon(coordinate);
  }, [coordinate, onShwBallon]);
  return (
    <div>
      <button className={cls.button} onClick={handleClick}>
        {children}
      </button>
    </div>
  );
}
