import { IPointsState, PointsActions, PointsActionTypes } from "./points_types";

const initialState: IPointsState = {
  stops: [],
  points: [],
  loading: false,
  point: null,
};

const PointsReducer = (state = initialState, action: PointsActions) => {
  switch (action.type) {
    case PointsActionTypes.GET_POINT_STOPS:
      return {
        ...state,
        stops: action.payload,
        loading: false,
      };
    case PointsActionTypes.GET_POINTS:
      return {
        ...state,
        points: action.payload,
        loading: false,
      };
    case PointsActionTypes.POINTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case PointsActionTypes.GET_POINT:
      return {
        ...state,
        point: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default PointsReducer;
