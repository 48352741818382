import moment from "moment";
import { shortTime } from "../../helpers";

const ViewStatusTimestamp = ({ dateFrom, dateTo }: { dateFrom: string; dateTo: string }) => {
  if (dateFrom == dateTo)
    return (
      <div className="view-status__timestamp">
        <strong>{shortTime(moment(dateFrom).fromNow())}</strong>
        {" — в "}
        <strong>{moment(dateFrom).format("HH:mm ")}</strong>
        {moment(dateFrom).format("DD MMMM YYYY")}
      </div>
    );

  if (dateFrom.slice(0, 10) === dateTo.slice(0, 10)) {
    return (
      <div className="view-status__timestamp">
        <strong>{shortTime(moment(dateFrom).fromNow())}</strong>
        {" — с "}
        <strong>{moment(dateFrom).format("HH:mm ")}</strong>
        {" по "}
        <strong>{moment(dateTo).format("HH:mm ")}</strong>
        {moment(dateTo).format("DD MMM YYYY")}
      </div>
    );
  }

  return (
    <div className="view-status__timestamp">
      <strong>{shortTime(moment(dateFrom).fromNow())}</strong>
      {" — с "}
      <strong>
        {moment(dateFrom).format("HH:mm ")}
        {moment(dateFrom).format("DD MMM YYYY")}
      </strong>
      {" по "}
      <strong>
        {moment(dateTo).format("HH:mm ")}
        {moment(dateTo).format("DD MMM YYYY")}
      </strong>
    </div>
  );
};

export default ViewStatusTimestamp;
