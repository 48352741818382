import { baseTheme } from "assets/styles/Theme.styled";
import styled from "styled-components";

export const FoldersEl = styled.div.attrs({
  "data-element": "folders",
})`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 5px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    height: 4px;
    background-color: ${baseTheme.colors.lightLightGrey};
    top: 0px;
    left: 20px;
    right: 20px;
  }
`;
