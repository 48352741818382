import moment from "moment";
import { ActionActionTypes, ActionsActions, ActionsState } from "./actions_types";

const initialState: ActionsState = {
  actions: null,
  filter: null,
  loading: true,
  countRun: 0,
};

const ActionReducer = (state = { ...initialState }, action: ActionsActions) => {
  switch (action.type) {
    case ActionActionTypes.GET_ACTIONS:
      return {
        ...state,
        actions: action.payload,
      };
    case ActionActionTypes.ACTION_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case ActionActionTypes.ACTIONS_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state;
  }
};

export default ActionReducer;
