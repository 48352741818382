import { PropsEventColumn } from "../reports/report-components/eventInterfaces";

const EventColumn = ({ title, data, classColumn, classComment, smallTitle }: PropsEventColumn) => {
  return (
    <div className={`report_column ${classColumn ? classColumn : ""}`}>
      <div className="report_column-item ">
        {title}
        <span className="report-thin">{smallTitle}</span>
      </div>
      <div
        className={`report_column-item report_bold report_13 ${classComment ? classComment : ""}`}
      >
        {data}
      </div>
    </div>
  );
};

export default EventColumn;
