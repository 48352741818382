import { IPoint } from "../map/map_types";

export interface IPointsState {
  stops: IPoint[];
  points: IPoint[];
  loading: boolean;
  point: IPoint | null;
}

export enum PointsActionTypes {
  GET_POINT_STOPS = "GET_POINT_STOPS",
  GET_POINTS = "GET_POINTS",
  POINTS_LOADING = "POINTS_LOADING",
  GET_POINT = "GET_POINT",
}

interface GetPoints {
  type: PointsActionTypes.GET_POINT_STOPS | PointsActionTypes.GET_POINTS;
  payload: IPoint[];
}

interface PointLoading {
  type: PointsActionTypes.POINTS_LOADING;
  payload: boolean;
}

interface GetPoint {
  type: PointsActionTypes.GET_POINT;
  payload: IPoint[];
}

export type PointsActions = GetPoints | PointLoading | GetPoint;
