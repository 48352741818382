import React, { useContext, useState, useEffect } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import { ModalsState } from "../../redux/modals/modals_types";
import ModalInput from "../ui/Inputs/ModalInput";
import ModalWindow from "./ModalWindow";
import { IProfilesState } from "redux/admin/profiles/ProfilesSlice";
import { IAdminProfile } from "redux/admin/profiles/ProfilesTypes";
import {
  useChangeProfileMutation,
  useAddNewProfileMutation,
} from "redux/admin/profiles/ProfilesApi";
import { toast } from "react-hot-toast";

const ModalProfileChange = () => {
  const { toggleModal, selectProfiles } = useActions();

  const modals: ModalsState = useTypedSelector((state) => state.modals);

  const profiles: IProfilesState = useTypedSelector((state) => state.profiles);

  const [profile, setProfile] = useState<IAdminProfile | null>({
    id: 1,
    director: "Таранова Марина Васильевна",
    inn: 5406622900,
    kpp: 540401001,
    ogrn: 1165476184697,
    full_title: 'ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "АЙНАНЭ"',
    title: "OOO Айнанэ",
  });

  const [changeProfile, { isLoading, isSuccess }] = useChangeProfileMutation();
  const [addNewProfile, { isLoading: isLoadingAddNewProfile }] = useAddNewProfileMutation();

  const [errors, setErrors] = useState<Record<keyof Omit<IAdminProfile, "id">, string[]> | null>({
    director: [],
    full_title: [],
    inn: [],
    kpp: [],
    ogrn: [],
    title: [],
  });

  const edit = () => Object.keys(profiles.select_profile).length > 0;

  useEffect(() => {
    if (edit()) {
      setProfile(profiles.select_profile);
    }
  }, [profiles.select_profile]);

  const handleChangeInput = (key: string, value: string) => {
    const newProfile = { ...profile };
    //@ts-ignore
    newProfile[key] = value;
    //@ts-ignore
    setProfile(newProfile);
  };

  const handleClose = () => {
    toggleModal({ toggle: false, modal: "modal_profile_change" });
    setProfile(null);
    selectProfiles({});
    setErrors(null);
  };

  const handleSaveChangeProfile = async () => {
    if (!profile) return;
    changeProfile(profile)
      .unwrap()
      .then(() => {
        handleClose();
      })
      .catch((e) => {
        setErrors(e.data.errors);
        toast.error(e.data.message);
      });
    // .finally(() => setSubmiting(false))
  };

  const handleSaveNewProfile = () => {
    addNewProfile(profile)
      .unwrap()
      .then(() => {
        handleClose();
      })
      .catch((e) => {
        setErrors(e.data.errors);
        toast.error(e.data.message);
      });
  };

  return (
    <ModalWindow
      isShow={modals.modal_profile_change}
      title="Изменение профиля"
      handleClose={handleClose}
    >
      <>
        <div className="modal-content__box">
          <ModalInput
            title="Полное наименование компании"
            value={profile?.full_title || ""}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              handleChangeInput("full_title", (e.target as HTMLInputElement).value);
            }}
            errors={errors?.full_title}
          />
          <ModalInput
            title="Имя деректора"
            value={profile?.director || ""}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              handleChangeInput("director", (e.target as HTMLInputElement).value);
            }}
            errors={errors?.director}
          />
          <ModalInput
            title="ИНН"
            value={profile?.inn || ""}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              handleChangeInput("inn", (e.target as HTMLInputElement).value);
            }}
            errors={errors?.inn}
          />
          <ModalInput
            title="КПП"
            value={profile?.kpp || ""}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              handleChangeInput("kpp", (e.target as HTMLInputElement).value);
            }}
            errors={errors?.kpp}
          />
          <ModalInput
            title="ОГРН"
            value={profile?.ogrn || ""}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              handleChangeInput("ogrn", (e.target as HTMLInputElement).value);
            }}
            errors={errors?.ogrn}
          />
          <ModalInput
            title="Наименование компании"
            value={profile?.title || ""}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              handleChangeInput("title", (e.target as HTMLInputElement).value);
            }}
            errors={errors?.title}
          />
        </div>
        <div className="modal-folder__button-box">
          <button
            className="modal__button modal__button--white modal__button--red"
            onClick={() => handleClose()}
          >
            Отменить
          </button>
          <button
            disabled={isLoading || isLoadingAddNewProfile}
            className="modal__button "
            onClick={(e) => (edit() ? handleSaveChangeProfile() : handleSaveNewProfile())}
          >
            Сохранить
          </button>
        </div>
      </>
    </ModalWindow>
  );
};

export default ModalProfileChange;
