import { GlobalApi } from "api/GlobalApi";
import api from "api/index";
import { IAdminProfile } from "./ProfilesTypes";

export const ProfilesApi = GlobalApi.injectEndpoints({
  endpoints: (build) => ({
    getProfiles: build.query<any, void>({
      query: () => ({
        url: api.getProfiles(),
      }),
      providesTags: ["Profiles"],
    }),
    addNewProfile: build.mutation<any, any>({
      query: (body) => ({
        url: api.postAddProfile(),
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error) => {
        if (error) return [];
        return ["Profiles"];
      },
    }),
    removeProfile: build.mutation<any, number>({
      query: (id) => ({
        url: api.deleteProfile(id),
        method: "DELETE",
      }),
      invalidatesTags: (result, error) => {
        if (error) return [];
        return ["Profiles"];
      },
    }),
    changeProfile: build.mutation<any, IAdminProfile>({
      query: (body) => ({
        url: api.getChangeProfile(body.id),
        method: "PUT",
        body,
      }),
      // async onQueryStarted(body, { dispatch, queryFulfilled }) {
      //   try {
      //     const {data: dataQuery}  = await queryFulfilled
      //     dispatch(
      //       ProfilesApi.util.updateQueryData("getProfiles", undefined, (draft) => {
      //         console.log(dataQuery)
      //         if(dataQuery  && "profile" in dataQuery){
      //           console.log(dataQuery.profile)
      //           draft.push(dataQuery.profile)
      //         }
      //       })
      //     )
      //   }catch{
      //     console.log('catch')
      //   }
      // },
      invalidatesTags: (result, error) => {
        if (error) return [];
        return ["Profiles"];
      },
    }),
    // createNewUser: build.mutation<any, { name: string, email: string, password: string, profile_id: number }>({
    //   query: (body) => ({
    //     url: api.createNewUser(),
    //     method: "POST",
    //     body
    //   }),
    //   invalidatesTags: (result, error) => {
    //     if(error) return []
    //     return ["Users"]
    //   },
    // })
  }),
});

export const {
  useGetProfilesQuery,
  useChangeProfileMutation,
  useAddNewProfileMutation,
  useRemoveProfileMutation,
} = ProfilesApi;
