import React, { useEffect } from "react";
import Cars from "../cars/Cars";
import Folders from "../folders/Folders";
import { useActions } from "../../hooks/useAction";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import CustomScroll from "react-custom-scroll";
import { SideBarContent } from "../admin/sideBar/SideBar.styled";
import { useGetFoldersQuery } from "redux/folder/FolderApi";
import SkeletonLoading from "components/skeleton-loading/SkeletonLoading";
import { Vehicle, VehicleWrapper, Vehicles } from "components/cars/Cars.styled";

const SideBarTyres = () => {
  const { getCars, resetCars, resetCar } = useActions();

  const cars = useTypedSelector((state) => state.cars);
  const { data, isLoading } = useGetFoldersQuery();

  useEffect(() => {
    getCars();
  }, []);

  // useEffect(() => {
  //   return () => {
  //     resetCar();
  //   };
  // }, []);

  const dashboardContent = (
    <>
      {cars.loading && isLoading ? (
        <SkeletonLoading height="42px" />
      ) : (
        <>
          <Folders cars={cars.cars} data={data} tire={true} />
        </>
      )}
      <Cars tyre={true} />
    </>
  );
  return (
    <SideBarContent>
      {/* прячем скролл пока загрузка */}
      {cars.loading && !isLoading && dashboardContent}

      {/* показываем скролл после загрузки */}
      {!cars.loading && !isLoading && (
        <CustomScroll allowOuterScroll={true} heightRelativeToParent="100%">
          {dashboardContent}
        </CustomScroll>
      )}
    </SideBarContent>
  );
};

export default SideBarTyres;
