// import { GroupsActions, GroupsActionsTypes, GroupsState } from "./folders_types";

import { EventsActions, EventState, EventsActionsTypes } from "./events_types";

const initialState: EventState = {
  events: [],
  events_period: [],
  events_last: [],
  currentEvent: undefined,
  totalPage: 0,
  totalEvents: 0,
  loading: true,
  // error: null,
};

const EventsReducer = (state = initialState, action: EventsActions) => {
  switch (action.type) {
    case EventsActionsTypes.GET_EVENTS:
      return {
        ...state,
        events: action.payload,
        loading: false,
      };
    case EventsActionsTypes.EVENT_PERIOD:
      return {
        ...state,
        events_period: action.payload,
        loading: false,
      };
    case EventsActionsTypes.EVENT_LAST:
      return {
        ...state,
        events_last: action.payload,
        loading: false,
      };
    case EventsActionsTypes.EVENTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case EventsActionsTypes.GET_EVENT:
      return {
        ...state,
        currentEvent: state.events.find((event) => {
          return event.id == action.payload.id;
        }),
        loading: false,
      };
    case EventsActionsTypes.DELETE_EVENT:
      return {
        ...state,
        events: state.events.filter((event) => {
          return event.id !== action.payload;
        }),
        loading: false,
      };
    case EventsActionsTypes.TOTAL_PAGE:
      return {
        ...state,
        totalPage: action.payload,
      };
    case EventsActionsTypes.TOTAL_EVENTS:
      return {
        ...state,
        totalEvents: action.payload,
      };
    default:
      return state;
  }
};

export default EventsReducer;
