import { Dispatch } from "redux";
import api from "../../api";
import { NotificationsActions, NotificationsTypes } from "./notifications_types";

export const notificationResourcesLoading = (toggle: boolean) => {
  return async (dispatch: Dispatch<NotificationsActions | any>) => {
    dispatch({
      type: NotificationsTypes.NOTIFICATIONS_RESOURCES_LOADING,
      payload: toggle,
    });
  };
};

export const getNotifs = (vehicle_id?: number) => {
  return async (dispatch: Dispatch<NotificationsActions | any>) => {
    try {
      const response: any = await api.getNotifs();
      dispatch({
        type: NotificationsTypes.GET_NOTIFICATIONS,
        payload: response.data.notifications,
      });
      //храним device_id для изменения уведомления
      // dispatch({
      //     type: NotificationsTypes.NOTIFICATIONS_DEVICE_ID,
      //     payload: device_id,
      //   },
      // );
      // храним transport_vehicle_id для добавления нового уведомления
      dispatch({
        type: NotificationsTypes.NOTIFICATIONS_TRANSPORT_VEHICLE_ID,
        payload: vehicle_id,
      });
    } catch (e: any) {
      return e;
    }
  };
};

export const putMachineResourceAlerts = (
  is_instant: boolean,
  notification_id: number,
  vehicle_id: number
) => {
  return async (dispatch: Dispatch<NotificationsActions | any>) => {
    await api.putNotification(is_instant, notification_id);
    dispatch({
      type: NotificationsTypes.PUT_INSTANT,
      payload: { is_instant, notification_id, vehicle_id },
    });
  };
};

export const postNotif = (data: any, vehicle_id: number) => {
  return async (dispatch: Dispatch<NotificationsActions | any>) => {
    // try {
    const response: any = await api.postNotif(data);
    dispatch(getNotifs(vehicle_id));
    return response;
    // } catch (e: any) {
    //   return e.response
    // }
  };
};
export const deleteNotif = (notification_id: number, vehicle_id: number) => {
  return async (dispatch: Dispatch<NotificationsActions | any>) => {
    // try {
    dispatch(notificationResourcesLoading(true));
    await api.deleteNotif(notification_id);
    dispatch(getNotifs(vehicle_id));
    // } catch (e: any) {
    //   return e.response
    // }
  };
};
// export const findNotif = (email:string) => {
//   return async (dispatch: Dispatch<NotificationsActions | any>) => {
//     try {
//       return await api.findNotif(email);
//     } catch (e: any) {
//       return e;
//     }
//   };
// };
