import { ITrailers } from "./constructorTypes";

// ТРЕЙЛЕРЫ
import { ReactComponent as T1_2Flat } from "../assets/images/constructor/models/T/FLAT_T--1-2.svg";
import { ReactComponent as T3_4_5Flat } from "../assets/images/constructor/models/T/FLAT_T--3-4-5.svg";
import { ReactComponent as T6_7_19Flat } from "../assets/images/constructor/models/T/FLAT_T--6-7-19.svg";
import { ReactComponent as T8_9Flat } from "../assets/images/constructor/models/T/FLAT_T--8-9.svg";
import { ReactComponent as T10_11_12Flat } from "../assets/images/constructor/models/T/FLAT_T--10-11-12.svg";
import { ReactComponent as T13_14_21Flat } from "../assets/images/constructor/models/T/FLAT_T--13-14-21.svg";
import { ReactComponent as T15_16_17Flat } from "../assets/images/constructor/models/T/FLAT_T--15-16-17.svg";
import { ReactComponent as T18Flat } from "../assets/images/constructor/models/T/FLAT_T--18.svg";
import { ReactComponent as T20Flat } from "../assets/images/constructor/models/T/FLAT_T--20.svg";

import T_0_PREW from "../assets/images/constructor/models/T/PREW_T--0.png";
import T_1_PREW from "../assets/images/constructor/models/T/PREW_T--1.png";
import T_2_PREW from "../assets/images/constructor/models/T/PREW_T--2.png";
import T_3_PREW from "../assets/images/constructor/models/T/PREW_T--3.png";
import T_4_PREW from "../assets/images/constructor/models/T/PREW_T--4.png";
import T_5_PREW from "../assets/images/constructor/models/T/PREW_T--5.png";
import T_6_PREW from "../assets/images/constructor/models/T/PREW_T--6.png";
import T_7_PREW from "../assets/images/constructor/models/T/PREW_T--7.png";
import T_8_PREW from "../assets/images/constructor/models/T/PREW_T--8.png";
import T_9_PREW from "../assets/images/constructor/models/T/PREW_T--9.png";
import T_10_PREW from "../assets/images/constructor/models/T/PREW_T--10.png";
import T_11_PREW from "../assets/images/constructor/models/T/PREW_T--11.png";
import T_12_PREW from "../assets/images/constructor/models/T/PREW_T--12.png";
import T_13_PREW from "../assets/images/constructor/models/T/PREW_T--13.png";
import T_14_PREW from "../assets/images/constructor/models/T/PREW_T--14.png";
import T_15_PREW from "../assets/images/constructor/models/T/PREW_T--15.png";
import T_16_PREW from "../assets/images/constructor/models/T/PREW_T--16.png";
import T_17_PREW from "../assets/images/constructor/models/T/PREW_T--17.png";
import T_18_PREW from "../assets/images/constructor/models/T/PREW_T--18.png";
import T_19_PREW from "../assets/images/constructor/models/T/PREW_T--19.png";
import T_20_PREW from "../assets/images/constructor/models/T/PREW_T--20.png";
import T_21_PREW from "../assets/images/constructor/models/T/PREW_T--21.png";

export const trailers: ITrailers = {
  0: {
    type: 1,
    title: "Без прицепа",
    wheelCount: 2,
    flat: <T1_2Flat />,
    color: <T1_2Flat />,
    preview: T_0_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -29,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
  1: {
    type: 1,
    title: "Прицеп с 1 осью (2)",
    wheelCount: 2,
    flat: <T1_2Flat />,
    color: <T1_2Flat />,
    preview: T_1_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -29,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
  2: {
    type: 1,
    title: "Прицеп с 1 осью (4)",
    wheelCount: 4,
    flat: <T1_2Flat />,
    color: <T1_2Flat />,
    preview: T_2_PREW,
    globalPadding: 30,
    axes: [
      {
        offset: -29,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
    ],
  },
  3: {
    type: 1,
    title: "Прицеп с 2 осями (2+2)",
    wheelCount: 4,
    flat: <T3_4_5Flat />,
    color: <T3_4_5Flat />,
    preview: T_3_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -18,
        wheels: [-42, 42],
        mini: true,
      },
      {
        offset: -25,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
  4: {
    type: 1,
    title: "Прицеп с 2 осями (2+4)",
    wheelCount: 6,
    flat: <T3_4_5Flat />,
    color: <T3_4_5Flat />,
    preview: T_4_PREW,
    globalPadding: 30,
    axes: [
      {
        offset: -18,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: -25,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
  5: {
    type: 1,
    title: "Прицеп с 2 осями (4+4)",
    wheelCount: 8,
    flat: <T3_4_5Flat />,
    color: <T3_4_5Flat />,
    preview: T_5_PREW,
    globalPadding: 30,
    axes: [
      {
        offset: -18,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: -25,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
    ],
  },
  6: {
    type: 1,
    title: "Прицеп с 3 осями (4+4+4)",
    wheelCount: 12,
    flat: <T6_7_19Flat />,
    color: <T6_7_19Flat />,
    preview: T_6_PREW,
    globalPadding: 30,
    axes: [
      {
        offset: -13,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: -21,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: -29,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
    ],
  },
  7: {
    type: 1,
    title: "Прицеп с 3 осями (2+2+2)",
    wheelCount: 6,
    flat: <T6_7_19Flat />,
    color: <T6_7_19Flat />,
    preview: T_7_PREW,
    globalPadding: 30,
    axes: [
      {
        offset: -13,
        wheels: [-42, 42],
        mini: true,
      },
      {
        offset: -21,
        wheels: [-42, 42],
        mini: true,
      },
      {
        offset: -29,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
  8: {
    type: 2,
    title: "Прицеп с 1 осью (2)",
    wheelCount: 2,
    flat: <T8_9Flat />,
    color: <T8_9Flat />,
    preview: T_8_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -19,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
  9: {
    type: 2,
    title: "Прицеп с 1 осью (4)",
    wheelCount: 4,
    flat: <T8_9Flat />,
    color: <T8_9Flat />,
    preview: T_9_PREW,
    globalPadding: 30,
    axes: [
      {
        offset: -19,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
    ],
  },
  10: {
    type: 2,
    title: "Прицеп с 2 осями (2+2)",
    wheelCount: 4,
    flat: <T10_11_12Flat />,
    color: <T10_11_12Flat />,
    preview: T_10_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -18,
        wheels: [-42, 42],
        mini: true,
      },
      {
        offset: -25,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
  11: {
    type: 2,
    title: "Прицеп с 2 осями (2+4)",
    wheelCount: 6,
    flat: <T10_11_12Flat />,
    color: <T10_11_12Flat />,
    preview: T_11_PREW,
    globalPadding: 30,
    axes: [
      {
        offset: -18,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: -25,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
  12: {
    type: 2,
    title: "Прицеп с 2 осями (4+4)",
    wheelCount: 8,
    flat: <T10_11_12Flat />,
    color: <T10_11_12Flat />,
    preview: T_12_PREW,
    globalPadding: 30,
    axes: [
      {
        offset: -18,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: -25,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
    ],
  },
  13: {
    type: 2,
    title: "Прицеп с 3 осями (4+4+4)",
    wheelCount: 12,
    flat: <T13_14_21Flat />,
    color: <T13_14_21Flat />,
    preview: T_13_PREW,
    globalPadding: 30,
    axes: [
      {
        offset: -13,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: -21,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: -29,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
    ],
  },
  14: {
    type: 2,
    title: "Прицеп с 3 осями (2+2+2)",
    wheelCount: 6,
    flat: <T13_14_21Flat />,
    color: <T13_14_21Flat />,
    preview: T_14_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -13,
        wheels: [-42, 42],
        mini: true,
      },
      {
        offset: -21,
        wheels: [-42, 42],
        mini: true,
      },
      {
        offset: -29,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
  15: {
    type: 3,
    title: "Сеялка (4)",
    wheelCount: 4,
    flat: <T15_16_17Flat />,
    color: <T15_16_17Flat />,
    preview: T_15_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -12,
        wheels: [-44, -18, 18, 44],
        mini: true,
      },
    ],
  },
  16: {
    type: 3,
    title: "Сеялка (6)",
    wheelCount: 6,
    flat: <T15_16_17Flat />,
    color: <T15_16_17Flat />,
    preview: T_16_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -12,
        wheels: [-28, -19, -10, 10, 19, 28],
        mini: true,
      },
    ],
  },
  17: {
    type: 3,
    title: "Сеялка (8)",
    wheelCount: 8,
    flat: <T15_16_17Flat />,
    color: <T15_16_17Flat />,
    preview: T_17_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -12,
        wheels: [-12, -9, -6, -3, 3, 6, 9, 12],
        mini: true,
      },
    ],
  },
  18: {
    type: 4,
    title: "Плуг (1)",
    wheelCount: 1,
    flat: <T18Flat />,
    color: <T18Flat />,
    preview: T_18_PREW,
    globalPadding: 20,
    axes: [
      {
        offset: -101,
        wheels: [-1],
        mini: true,
      },
    ],
  },
  19: {
    type: 1,
    title: "Прицеп с 3 осями (2+4+4)",
    wheelCount: 10,
    flat: <T6_7_19Flat />,
    color: <T6_7_19Flat />,
    preview: T_19_PREW,
    globalPadding: 30,
    axes: [
      {
        offset: -13,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: -21,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: -29,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
  20: {
    type: 1,
    title: "Прицеп с 4 осями (2+2+4+4)",
    wheelCount: 12,
    flat: <T20Flat />,
    color: <T20Flat />,
    preview: T_20_PREW,
    globalPadding: 30,
    axes: [
      {
        offset: 6,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: -6,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: -18,
        wheels: [-42, 42],
        mini: true,
      },
      {
        offset: -31,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
  21: {
    type: 2,
    title: "Прицеп с 3 осями (2+4+4)",
    wheelCount: 10,
    flat: <T13_14_21Flat />,
    color: <T13_14_21Flat />,
    preview: T_21_PREW,
    globalPadding: 30,
    axes: [
      {
        offset: -13,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: -21,
        wheels: [-48, -42, 42, 48],
        mini: true,
      },
      {
        offset: -29,
        wheels: [-42, 42],
        mini: true,
      },
    ],
  },
};
