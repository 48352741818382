import { useActions } from "hooks/useAction";
import { useTypedSelector } from "hooks/useTypedSelector";
import { TyresState } from "redux/tyres/tyres_types";
import Loader from "components/loader/Loader";
import TyreTitle from "components/tyres/TyreTitle";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import TyreHead from "components/tyres/TyreHead";
import TyreDate from "components/tyres/TyreDate";
import moment from "moment";
import TyreHeader from "components/tyres/TyreHeader";
import ActionLine from "components/actions/ActionLine";
import { ActionsState } from "redux/actions/actions_types";
import { CarState } from "redux/car/car_types";

const TyrePage = () => {
  const { tyre_id } = useParams<{ tyre_id?: string }>();
  const { getTyre, toggleModal, getActionsPeriod } = useActions();
  const { loading_tyres, tyre }: TyresState = useTypedSelector((state) => state.tyres);
  const { actions, loading }: ActionsState = useTypedSelector((state) => state.actions);
  const { car }: CarState = useTypedSelector((state) => state.car);
  const today = moment(new Date()).format("YYYY-MM-DD");

  const titles = [
    "ДАТА",
    "СОБЫТИЕ",
    "ТРАНСПОРТ",
    "ШИНА",
    "МЕСТОПОЛОЖЕНИЕ",
    "ПРОБЕГ",
    "ГЛУБИНА ПРОТЕКТОРА",
    "",
  ];

  const init = async () => {
    if (!tyre_id) return;

    getTyre(+tyre_id);
    getActionsPeriod(today, today);
  };

  useEffect(() => {
    init();
  }, [tyre_id]);

  const handlClick = () => {
    toggleModal({ toggle: true, modal: "modal_update_tyre" });
  };

  const handlClickAction = () => {
    toggleModal({ toggle: true, modal: "modal_add_action" });
  };

  return (
    <>
      {loading_tyres ? (
        <Loader />
      ) : (
        <>
          <div className="report_head">
            <TyreTitle
              title="Шина"
              subtitle="информация"
              btnTitle="редактировать"
              handleClick={handlClick}
            />
            {/* {vehickeModel} */}
          </div>
          {tyre && <TyreHead {...tyre} />}

          <div className="report_head">
            <>
              {/* <div className="report_head"> */}
              <TyreTitle
                title="События"
                subtitle="за период"
                btnTitle="добавить событие"
                handleClick={handlClickAction}
              />
              {loading ? (
                <Loader />
              ) : (
                <>
                  <TyreDate
                    from={
                      actions && actions.from
                        ? actions.from
                        : moment(new Date()).format("YYYY-MM-DD")
                    }
                    to={actions?.to}
                  />
                  {actions && car && tyre && actions.actions.length > 0 ? (
                    <>
                      <TyreHeader titles={titles} classes="tyre_table_box-8" />
                      {actions.actions.map((action) => (
                        <ActionLine
                          key={action.id}
                          date={action.updated_at}
                          type={action.type}
                          car_title={car.title}
                          tyre_model={tyre.model}
                          tyre_brand={tyre.brand}
                          run={action.run}
                          tread={action.tread}
                        />
                      ))}
                    </>
                  ) : (
                    <div className="border-text">Ничего не найдено</div>
                  )}
                </>
              )}
            </>
          </div>
        </>
      )}
    </>
  );
};

export default TyrePage;
