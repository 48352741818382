import { MapState, MapActions, MapActionTypes } from "./map_types";

const initialState: MapState = {
  loading: false,
  point: null,
  route: [],
  isMultiple: false,
  multiSelected: [],
  isMapLoading: false,
  map_global: null,
};

const ModalsReducer = (state = initialState, action: MapActions) => {
  switch (action.type) {
    case MapActionTypes.CHANGE_POINT:
      return {
        ...state,
        point: action.payload,
      };
    case MapActionTypes.CHANGE_ROUTE:
      return {
        ...state,
        route: action.payload,
      };
    case MapActionTypes.TOGGLE_MULTIPLE:
      return {
        ...state,
        isMultiple: action.payload,
      };
    case MapActionTypes.TOGGLE_LOADING:
      return {
        ...state,
        isMapLoading: action.payload,
      };
    case MapActionTypes.MULTI_TOGGLE: {
      const id = action.payload;
      const _multiSelected = [...state.multiSelected];
      const index = _multiSelected.indexOf(id);

      if (index < 0) {
        _multiSelected.push(id);
      } else {
        _multiSelected.splice(index, 1);
      }

      return {
        ...state,
        multiSelected: _multiSelected,
      };
    }
    case MapActionTypes.MULTI_SELECT: {
      const _multiSelected = [...state.multiSelected];

      action.payload.ids.forEach((id) => {
        const index = _multiSelected.indexOf(id);

        if (action.payload.toggle) {
          if (index < 0) _multiSelected.push(id);
        } else {
          if (index >= 0) _multiSelected.splice(index, 1);
        }
      });

      return {
        ...state,
        multiSelected: _multiSelected,
      };
    }
    case MapActionTypes.GLOBAL_MAP: {
      return {
        ...state,
        map_global: action.payload,
      };
    }
    case MapActionTypes.CLEAR_MAP_INSTANCE: {
      return {
        ...state,
        map_global: null,
      };
    }

    default:
      return state;
  }
};

export default ModalsReducer;
