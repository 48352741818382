import { useActions } from "hooks/useAction";
import { useTypedSelector } from "hooks/useTypedSelector";
import { TyresState } from "redux/tyres/tyres_types";
import Loader from "components/loader/Loader";
import { CarState } from "redux/car/car_types";
import { IDesign } from "helpers/constructorTypes";
import VehicleTyre from "components/vehicle-view/VehicleTyre";
import TyreHeader from "components/tyres/TyreHeader";
import TyreTitle from "components/tyres/TyreTitle";
import TyreLine from "components/tyres/TyreLine";
import { CarsState } from "redux/cars/cars_types";
import SkeletonLoading from "components/skeleton-loading/SkeletonLoading";
import { useGetFoldersQuery } from "redux/folder/FolderApi";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const TyresPage = () => {
  const { toggleModal } = useActions();

  const { vehicle_id } = useParams<{ vehicle_id?: string }>();

  const { getCar, getCarTyres } = useActions();

  const init = () => {
    if (!vehicle_id) return;
    getCar(+vehicle_id);
    getCarTyres(+vehicle_id);
  };

  useEffect(() => {
    init();
  }, [vehicle_id]);

  const { tyres_by_car, loading_tyres }: TyresState = useTypedSelector((state) => state.tyres);

  const { loading, car }: CarState = useTypedSelector((state) => state.car);
  const cars: CarsState = useTypedSelector((state) => state.cars);
  const { isLoading } = useGetFoldersQuery();

  let design: null | IDesign = null;

  if (car && car.design)
    design = {
      modelId: car.design.model_id,
      trailerId: car.design.trailer_id,
      positions: car.design.positions,
    };

  let vehickeModel = <></>;

  if (design) {
    vehickeModel = <VehicleTyre design={design} />;
  }

  // TODO pleсes of tyres

  const titles = [
    "ШИНА",
    "ДАТА УСТАНОВКИ / УТИЛИЗАЦИИ",
    "DOT",
    "ПРОБЕГ",
    "СТОИМОСТЬ (1 КМ ПРОБЕГА)",
    "",
  ];

  const handlClick = () => {
    toggleModal({ toggle: true, modal: "modal_add_tyre" });
  };
  return (
    <>
      {cars.loading && isLoading ? (
        <SkeletonLoading height="100vh" />
      ) : (
        <div className="report_head">
          {loading_tyres ? (
            <Loader />
          ) : (
            <>
              <TyreTitle
                title="Общая"
                subtitle="информация"
                btnTitle="добавить шину"
                handleClick={handlClick}
              />
              {vehickeModel}
              <TyreHeader titles={titles} />
              {tyres_by_car && tyres_by_car.length > 0 ? (
                tyres_by_car.map((tyre) => <TyreLine key={tyre.id} {...tyre} />)
              ) : (
                <div className="not_found_text">Шины не найдены</div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default TyresPage;
