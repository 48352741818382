import { useContext, useEffect, useState } from "react";
import moment from "moment";
import CustomDatePicker from "../datepicker/CustomDatePicker";
import DateTemplates from "../date-templates/DateTemplate";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import Points from "../points/Points";
import { CarState } from "redux/car/car_types";
import Checkbox from "components/ui/Checkbox/Checkbox";
import { PointCheckContext } from "./context/PointCheckContext";
import { toast } from "react-hot-toast";

const ReportsRoute = () => {
  const [from, setFrom] = useState(moment().format("YYYY-MM-DD"));
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const [fromError, setFromError] = useState("");
  const [isReady, setIsReady] = useState(false);
  const { getPointStops, changeRoute, getPointsPeriod, toggleLoading, getEventsPeriod } =
    useActions();
  const { car }: CarState = useTypedSelector((state) => state.car);
  const { isMoves, isStops, changeMoves, changeStops } = useContext(PointCheckContext);
  const onDatesChange = (from: string, to: string) => {
    setFrom(from);
    setTo(to);
    setIsReady(false);
  };

  const onDateChoose = (from: string) => {
    setFrom(from);
    setTo(from);
    setIsReady(false);
  };

  useEffect(() => {
    return () => {
      changeRoute([]);
      toggleLoading(false);
    };
  }, []);

  const handleShow = async () => {
    toggleLoading(true);
    setFromError("");

    try {
      if (car) {
        await getPointStops(car.device_id, from, from);
        await getPointsPeriod(car.device_id, from, from, true);
        await getEventsPeriod(from, to, [car.id]);
      }
      setIsReady(true);
    } catch (error: any) {
      toggleLoading(false);
      console.log(error);
      if (error?.response?.data?.errors) {
        if (error?.response?.data?.errors?.from) {
          setFromError(error.response.data.errors.from[0]);
        }
        if (error?.response?.data?.errors?.vehicles) {
          toast.error(error.response.data.errors.vehicles[0]);
        }
      } else if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <h4 className="point__title">Выбор периода</h4>
      <div className="reports reports--charts  reports--charts--border">
        <DateTemplates onDatesChange={onDatesChange} from={from} to={to} short={true} />
        <div className="input-and-button input-and-button--border-top">
          <div className="input-area input-area--border w100 m0">
            <label className="label">Выберите день</label>
            <CustomDatePicker onChange={onDateChoose} date={from} />
            {!!fromError && <div className="input-error-message">{fromError}</div>}
          </div>
          <button className="button button--mint button--with-label w100 m0" onClick={handleShow}>
            Показать
          </button>
        </div>

        <div className="point__checkboxes">
          <label className="point__checkbox">
            <Checkbox onChange={changeMoves} checked={isMoves} />
            <div className="checkbox__text">События</div>
          </label>
          <label className="point__checkbox">
            <Checkbox onChange={changeStops} checked={isStops} />
            <div className="checkbox__text">Остановки</div>
          </label>
        </div>
      </div>
      <div>{isReady && <Points />}</div>
    </>
  );
};

export default ReportsRoute;
