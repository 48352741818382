import { GlobalApi } from "../../api/GlobalApi";
import api from "api";

export const UserApi = GlobalApi.injectEndpoints({
  endpoints: (build) => ({
    changePasswordUserByModerator: build.mutation<any, { email: string; password: string }>({
      query: (body) => ({
        url: api.postNewPasswordUserByModerator(),
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useChangePasswordUserByModeratorMutation } = UserApi;
