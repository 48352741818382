import { useActions } from "hooks/useAction";
import { useTypedSelector } from "hooks/useTypedSelector";
import { useState } from "react";
import Select, { SingleValue } from "react-select";
import { useGetDevicesQuery } from "redux/admin/devices/DevicesApi";
import { IDevice } from "redux/admin/devices/DevicesTypes";
import { useGetProfilesQuery } from "redux/admin/profiles/ProfilesApi";
import { useGetUsersQuery } from "redux/admin/users/UsersApi";
import { IDevicesFilterState } from "redux/device_filter/device_filter_types";
import { IProfile, IUser } from "redux/user/user_types";
import { IUsersFilterState } from "redux/users_filter/users_filter_types";

const SearchAdmin = ({ filterType, fieldes }: { filterType: string; fieldes: string[] }) => {
  const { typeFilterUser, filterUser, usersFiltered }: IUsersFilterState = useTypedSelector(
    (state) => state.userFilter
  );
  const { typeFilterDevice, filterDevice, devicesFiltered }: IDevicesFilterState = useTypedSelector(
    (state) => state.deviceFilter
  );
  const [firstSelect, setFirstSelect] = useState({ value: "", label: "" });
  const [secondSelect, setSecondSelect] = useState({ value: "", label: "" });
  const [text, setText] = useState("");
  const { data: profiles } = useGetProfilesQuery();
  const { data: users } = useGetUsersQuery();
  const { data: devices } = useGetDevicesQuery();
  const {
    getUsersFilter,
    changeFilterUsersSearch,
    changeTypeUsersSearch,
    getDevicesFilter,
    changeFilterDevicesSearch,
    changeTypeDevicesSearch,
  } = useActions();

  const options: { [key: string]: { value: string; label: string }[] } = {};

  fieldes.forEach((field) => (options[field] = []));

  const [second, setSecond] = useState<{ value: string; label: string }[]>([]);

  if (profiles && options.hasOwnProperty("profile_id")) {
    profiles.profiles.forEach((profile: IProfile) => {
      options.profile_id.push({ value: profile.id.toString(), label: profile.title });
    });
  }
  if (users && options.hasOwnProperty("id")) {
    users.users.forEach((user: IUser) => {
      options.id.push({ value: user.id.toString(), label: user.id.toString() });
    });
  }
  if (users && options.hasOwnProperty("email")) {
    users.users.forEach((user: IUser) => {
      options.email.push({ value: user.email, label: user.email });
    });
  }
  if (devices && options.hasOwnProperty("device_id")) {
    devices.forEach((device: IDevice) => {
      options.device_id.push({
        value: device.device_id.toString(),
        label: device.device_id.toString(),
      });
    });
  }
  if (devices && options.hasOwnProperty("phone")) {
    devices.forEach((device: IDevice) => {
      options.phone.push({ value: device.phone, label: device.phone });
    });
  }
  if (devices && options.hasOwnProperty("car_number")) {
    devices.forEach((device: IDevice) => {
      if (device.vehicle) {
        options.car_number.push({
          value: device.vehicle.car_number,
          label: device.vehicle.car_number,
        });
      }
    });
  }

  const className = "react-select--mini react-select--margin select-admin";
  const optionsType: { value: string; label: string }[] = [];

  fieldes.forEach((field) => {
    optionsType.push({ value: field, label: field });
  });

  const onChangeType = (item: SingleValue<{ value: string; label: string }>) => {
    setText("");
    setSecond([]);
    if (item) {
      setFirstSelect(item);
      setSecond(options[item.value]);
      if (filterType == "user") {
        changeTypeUsersSearch(item.value);
        changeFilterUsersSearch("");
        setSecondSelect({ value: "", label: "" });
      }
      if (filterType == "device") {
        changeTypeDevicesSearch(item.value);
        changeFilterDevicesSearch("");
        setSecondSelect({ value: "", label: "" });
      }
    }
  };

  const onChangeTypeSecond = (item: SingleValue<{ value: string; label: string }>) => {
    setText("");
    if (item) {
      if (typeFilterUser != "") {
        changeFilterUsersSearch(item.value);
        setSecondSelect(item);
      }
      if (typeFilterDevice != "") {
        changeFilterDevicesSearch(item.value);
        setSecondSelect(item);
      }
    }
  };

  const handleClick = () => {
    if (secondSelect.value == "") {
      setText("Выбирете параметр");
      return;
    }
    if (filterType == "user") {
      getUsersFilter(
        users.users.filter((user: IUser) => user[typeFilterUser as keyof typeof user] == filterUser)
      );
      if (usersFiltered.length == 0) setText("Ничего не найдено");
    }
    if (filterType == "device" && devices) {
      getDevicesFilter(devices, typeFilterDevice, filterDevice);
      if (devicesFiltered.length == 0) setText("Ничего не найдено");
    }
  };

  const handleReset = () => {
    changeTypeUsersSearch("");
    changeFilterUsersSearch("");
    getUsersFilter(users.users);
    if (devices) getDevicesFilter(devices);
    setFirstSelect({ value: "", label: "" });
    setSecondSelect({ value: "", label: "" });
    setText("");
    setSecond([]);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "flex-end",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <p>Выбирете поле</p>
          <Select
            value={firstSelect}
            className={className}
            options={optionsType}
            onChange={(item: SingleValue<{ value: string; label: string }>) => onChangeType(item)}
          />
        </div>
        <div>
          <p>Выбирете параметр</p>
          <Select
            value={secondSelect}
            className={className}
            options={second}
            onChange={(item: SingleValue<{ value: string; label: string }>) =>
              onChangeTypeSecond(item)
            }
          />
        </div>
        <button className="button button--mint button--margin-bottom" onClick={handleClick}>
          Поиск
        </button>
        <button className="button button--mint button--margin-bottom" onClick={handleReset}>
          Сброс
        </button>
      </div>
      <div style={{ color: "red" }}>{text}</div>
    </>
  );
};

export default SearchAdmin;
