import { UserActions, UserActionTypes } from "./user_types";
import { toggleModal } from "../modals/modals_actions";
import { Dispatch } from "redux";
import api from "../../api";

export const userMe = () => {
  return async (dispatch: Dispatch<UserActions | any>) => {
    try {
      const result: any = await api.getMe();
      dispatch({ type: UserActionTypes.GET_USER, payload: result.data.user });
      dispatch({ type: UserActionTypes.USER_AUTH, payload: true });
      return result;
    } catch (e: any) {
      console.log("e", e.message);
      return e.response;
    } finally {
      dispatch(loadingUser(false));
    }
  };
};

export const userChangePassword = (old_password: string, new_password: string) => {
  return async (dispatch: Dispatch<UserActions | any>) => {
    await api.userChangePassword(old_password, new_password);
    dispatch(userMe());
    dispatch(toggleModal({ toggle: false, modal: "modal_edit_user_data" }));
  };
};

export const putUser = (full_name: string) => {
  return async (dispatch: Dispatch<UserActions | any>) => {
    const result: any = await api.putUser(full_name);
    dispatch(userMe());
    dispatch(toggleModal({ toggle: false, modal: "modal_edit_user_data" }));

    return result;
  };
};

export const loadingUser = (toggle: boolean) => {
  return async (dispatch: Dispatch<UserActions | any>) => {
    dispatch({
      type: UserActionTypes.USER_LOADING,
      payload: toggle,
    });
  };
};

// export const createNewUser = (name: string, email: string, password: string, profile_id: number) => {
//   return async (dispatch: Dispatch<UserActions | any>) => {
//     await api.createNewUser(name, email, password, profile_id);
//     dispatch(getUsers());
//   };
// };

export const getUsers = () => {
  return async (dispatch: Dispatch<UserActions | any>) => {
    try {
      const result: any = api.getUsers();
      dispatch({
        type: UserActionTypes.GET_USERS,
        payload: result.data.users,
      });
      return result;
    } catch (e: any) {
      return e.response;
    }
  };
};
